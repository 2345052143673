import { ActionCreator } from 'redux';

import * as reduxTypes from '../types/themes';
import { DataAction, QueryListPayload } from '.';

export const list: ActionCreator<DataAction> = (data: QueryListPayload) => ({
    type: reduxTypes.LIST,
    data,
});

export const listSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_SUCCESS,
    data,
});

export const listFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_FAILED,
    data,
});

