import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'forgotten_password.title',
        defaultMessage: 'Forgotten password',
        description: 'Forgotten password title',
    },
    successDescription: {
        id: 'forgotten_password.success.description',
        defaultMessage: 'A new password has been sent to { address }.<break>|</break> Please check your inbox.',
        description: 'Forgotten password page success description',
    },
    description: {
        id: 'forgotten_password.description',
        defaultMessage: 'Enter your email address below to receive a new password',
        description: 'Forgotten password page description',
    },
});
