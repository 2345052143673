import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { SortableContainer, SortableElement, SortableContainerProps } from 'react-sortable-hoc';
import { Divider, Button } from 'antd';
import { DateTime } from 'luxon';

import { Chapter, PresentationType } from '../../store/api/types';

import FormMessages from '../../locale/Form.messages';
import { IconAdd, IconTrash } from '../icons';
import messages from './Presentation.messages';
import { SortableListItem } from './PresentationCustomize';
import { ChapterCard } from '.';

interface ItemProps {
    itemIndex: number;
    item: SortableListItem;
    onClickDeleteItem: (itemIndex: number) => void;
    onClickSeeSubChapters?: (
        rowIndex: number | undefined,
        cellIndex: number | undefined,
        chapter: Chapter,
    ) => void;
}

const Item: FC<ItemProps> = ({ itemIndex, item, onClickDeleteItem, onClickSeeSubChapters }) => {
    let itemContent = null;

    if (item.isChapter && item.chapter) {
        itemContent = (
            <ChapterCard
                chapter={item.chapter}
                onClickRemove={onClickDeleteItem.bind(null, itemIndex)}
                onSeeSubChapters={onClickSeeSubChapters}
                selectable={false}
                removable
                sortable
            />
        );
    } else {
        itemContent = (
            <div className="presentation-card presentation-card-sortable">
                {item.isSelection && item.selection && (
                    <FormattedMessage
                        {...messages.productSelectionCard}
                        values={{
                            date: DateTime.fromISO(item.selection.updateAt!).toLocaleString({
                                day: 'numeric', month: 'long', year: 'numeric',
                            }),
                        }}
                    />
                )}
                {item.isPricing && (
                    <FormattedMessage
                        {...messages.pricing}
                    />
                )}
                <Divider />
                <div className="presentation-card-overlay">
                    <Button
                        className="presentation-remove-button"
                        onClick={onClickDeleteItem.bind(null, itemIndex)}
                        type="link"
                    >
                        <IconTrash />
                    </Button>
                </div>
            </div>
        );
    }

    return (
        <li>
            {itemContent}
            <p className="presentation-card-index">{itemIndex + 3}</p>
        </li>
    );
};

const SortableItem = SortableElement(Item);

interface PresentationSortableListProps extends SortableContainerProps {
    items: SortableListItem[];
    onClickAddPricing: () => void;
    onClickAddPage: () => void;
    onClickAddSelection: () => void;
    onClickOpenPreamble: () => void;
    onClickDeleteItem: (itemIndex: number) => void;
    onClickSeeSubChapters?: (
        rowIndex: number | undefined,
        cellIndex: number | undefined,
        chapter: Chapter,
    ) => void;
    presentationType: PresentationType;
}

const PresentationSortableList: FC<PresentationSortableListProps> = ({
    items, onClickAddPage, onClickAddPricing, onClickAddSelection, onClickOpenPreamble,
    onClickDeleteItem, onClickSeeSubChapters, presentationType,
}) => (
    <ul
        id="presentation-customize-list"
        className={presentationType === 'proposition' ? 'proposition' : undefined}
    >
        <li key="frontPage">
            <div className="presentation-card presentation-card-primary">
                <FormattedMessage {...messages.frontPage} />
                <Divider />
            </div>
            <p className="presentation-card-index">1</p>
        </li>
        <li key="tableOfContents">
            <div className="presentation-card">
                <FormattedMessage {...messages.tableOfContents} />
                <Divider />
            </div>
            <p className="presentation-card-index">2</p>
        </li>
        {items.map((item, index) =>
            item.isPreamble ?
                (
                    <li key="preamble">
                        {item.hasValue ?
                            (
                                <div className="presentation-card">
                                    <FormattedMessage {...messages.preamble} />
                                    <Divider />
                                    <div className="presentation-card-overlay">
                                        <Button
                                            className="presentation-center-button"
                                            onClick={onClickOpenPreamble}
                                        >
                                            <FormattedMessage {...FormMessages.edit} />
                                        </Button>
                                    </div>
                                </div>
                            ) :
                            (
                                <Button
                                    className="presentation-card add-card"
                                    onClick={onClickOpenPreamble}
                                >
                                    <IconAdd />
                                    <FormattedMessage {...messages.addPreamble} />
                                </Button>
                            )
                        }
                        <p className="presentation-card-index">{index + 3}</p>
                    </li>
                ) : (
                    <SortableItem
                        collection={item.isChapter ? 0 : 1}
                        index={index}
                        item={item}
                        itemIndex={index}
                        key={item.isChapter && item.chapter ? item.chapter.id : index}
                        onClickDeleteItem={onClickDeleteItem}
                        onClickSeeSubChapters={onClickSeeSubChapters}
                    />
                ),
        )}
        <li key="selection">
            <Button
                className="presentation-card add-card"
                onClick={onClickAddSelection}
            >
                <IconAdd />
                <FormattedMessage {...messages.productSelection} />
            </Button>
            <p className="presentation-card-index">{items.length + 3}</p>
        </li>
        {presentationType === 'presentation' && (
            <li key="pricing">
                <Button
                    className="presentation-card add-card"
                    onClick={onClickAddPricing}
                >
                    <IconAdd />
                    <FormattedMessage {...messages.pricing} />
                </Button>
                <p className="presentation-card-index">{items.length + 4}</p>
            </li>
        )}
        {presentationType === 'proposition' && (
            <li key="page">
                <Button
                    className="presentation-card add-card"
                    onClick={onClickAddPage}
                >
                    <IconAdd />
                    <FormattedMessage {...messages.page} />
                </Button>
                <p className="presentation-card-index">{items.length + 4}</p>
            </li>
        )}
    </ul>
);

export default SortableContainer(PresentationSortableList);
