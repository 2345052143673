import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="24" viewBox="0 0 30 24">
        <path fill="currentColor" fillRule="nonzero" d="M15 0c4.982 0 9.094 3.885 9.594 8.85 3.06.5 5.406 3.22 5.406 6.49a6.62 6.62 0 0 1-2.427 5.14l1.99 1.542c.455.353.574 1.037.266 1.526a.95.95 0 0 1-.638.438.95.95 0 0 1-.748-.192l-2.855-2.212a6.273 6.273 0 0 1-2.02.33H7.5c-4.136 0-7.5-3.44-7.5-7.668 0-3.614 2.464-6.706 5.84-7.47.044-.133.088-.263.135-.392L.437 2.09A1.176 1.176 0 0 1 .17.563c.31-.49.93-.6 1.386-.247l5.36 4.154C8.654 1.74 11.66 0 15 0zM7.725 7.998c-.107.44-.47.77-.91.824-2.662.33-4.672 2.663-4.672 5.422 0 3.02 2.404 5.478 5.357 5.478h15.688l-7.413-5.746V16.8c0 .47-.342.852-.765.852s-.766-.382-.766-.853v-4.01l-2.713-2.103-.187.225a.72.72 0 0 1-.742.273.806.806 0 0 1-.57-.594.92.92 0 0 1 .196-.842l.036-.045-2.48-1.922c-.02.073-.04.146-.057.218zM15 2.19c-2.654 0-5.033 1.408-6.37 3.61L11.6 8.1l2.85-3.405a.73.73 0 0 1 .56-.27.73.73 0 0 1 .56.27l4.224 5.05a.923.923 0 0 1-.037 1.207.712.712 0 0 1-1.082-.042l-2.9-3.467v3.89l10.007 7.758a4.405 4.405 0 0 0 2.075-3.754c0-2.418-1.922-4.383-4.286-4.383-.59 0-1.07-.49-1.07-1.096 0-4.228-3.364-7.67-7.5-7.67zm-.756 5.255l-1.373 1.64 1.374 1.065V7.445z"/>
    </svg>
);

const IconOfflineSync: React.FC<IconProps> = (props) => (
    <Icon component={svg} {...props} className={`anticon icon-offline-sync${props.className ? ` ${props.className}` : ''}`} />
);

export default IconOfflineSync;
