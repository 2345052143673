import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path fill="currentColor" d="M11.997.004a1.09 1.09 0 0 1 0 2.182H2.18V21.82h19.632v-9.643a1.09 1.09 0 0 1 2.182 0V22.91a1.09 1.09 0 0 1-1.09 1.09H1.09A1.09 1.09 0 0 1 0 22.91V1.094C0 .493.488.005 1.09.005h10.907zm7.318.317l4.362 4.364a1.09 1.09 0 0 1 0 1.548l-4.362 4.363a1.09 1.09 0 0 1-.774.317 1.09 1.09 0 0 1-.774-1.866l2.504-2.498h-.64c-5.42 0-9.814 4.396-9.814 9.817a1.09 1.09 0 1 1-2.182 0c0-6.626 5.372-11.998 11.997-11.998h.64L17.767 1.87a1.095 1.095 0 0 1 1.55-1.55z" />
    </svg>
);

const IconExport: React.FC<IconProps> = (props) => (
    <Icon component={svg} {...props} className={`icon-export${props.className ? ` ${props.className}` : ''}`} />
);

export default IconExport;
