import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

interface IconAngleProps extends IconProps {
    direction?: 'left' | 'top' | 'right' | 'bottom';
}

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="36" viewBox="0 0 22 36">
        <path fill="currentColor" stroke="currentColor" strokeWidth="3" d="M19.656 3.906L4.826 17.99l14.83 14.104a1.078 1.078 0 0 1 0 1.58 1.217 1.217 0 0 1-1.66 0L2.344 18.788a1.078 1.078 0 0 1 0-1.58L17.996 2.328a1.217 1.217 0 0 1 1.66 0 1.078 1.078 0 0 1 0 1.58z" />
    </svg>
);

const IconAngle: React.FC<IconAngleProps> = ({direction = 'left', ...props}) => (
    <Icon
        component={svg}
        className={`icon-angle rotate-${direction}`}
        {...props}
    />
);

export default IconAngle;
