import { call, takeLatest, put } from 'redux-saga/effects';
import * as sectorsActions from '../actions/sectors';
import * as reduxTypes from '../types/sectors';
import * as sectorsApi from '../api/sectors';

export function* listSaga() {
    try {
        const response = yield call(sectorsApi.list);

        return yield put(sectorsActions.listSuccess(response));
    } catch (error) {
        return yield put(sectorsActions.listFailed(error));
    }
}

export function* listMarketsSaga() {
    try {
        const response = yield call(sectorsApi.listMarkets);

        return yield put(sectorsActions.listMarketsSuccess(response.results));
    } catch (error) {
        return yield put(sectorsActions.listMarketsFailed(error));
    }
}

export default function* sectorsSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
    yield takeLatest(reduxTypes.LIST_MARKETS, listMarketsSaga);
}
