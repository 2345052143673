import React, { useState, useEffect } from 'react';
import { RouteChildrenProps, useParams, useLocation } from 'react-router';
import { useIntl, FormattedMessage } from 'react-intl';
import { Row, Col, Pagination } from 'antd';
import { PaginationProps } from 'antd/lib/pagination';

import '../../assets/styles/Products.less';

import { indices } from '../../store/configureStore';

import BreadCrumb from '../../components/BreadCrumb';
import { IconHeroInvertedTriangle } from '../../components/icons';
import ProductCard from '../../components/ProductCard';
import messages from '../../components/Search.messages';
import { getRoute, RoutePathName } from '../../routes';
import { constants, t } from '../../utils';
import ProductsMessages from './Products.messages';
import { Product } from '../../store/api/types';

interface MatchParams {
    query: string;
}

const ProductSearch: React.FC<RouteChildrenProps<MatchParams>> = () => {
    const { formatMessage, locale } = useIntl();
    const location = useLocation();
    const [products, setProducts] = useState<any>([]);
    const [page, setPage] = useState<number>(0);
    const { query } = useParams();
    const onChangePage: PaginationProps['onChange'] = (p) => {
        window.scroll({
            top: 0,
            behavior: 'smooth',
        });
        setPage(p - 1 < 1 ? 0 : p - 1);
    };

    useEffect(() => {
        if (indices.productsIndex && query) {
            setProducts(indices.productsIndex.search(query));
        } else {
            setProducts([]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [query, indices.productsIndex]);

    return (
        <>
            <BreadCrumb
                routes={[{
                    link: location.pathname,
                    text: formatMessage(messages.searchResults),
                }]}
            />
            <header className="page-hero product-hero">
                <div className="container">
                    <Row>
                        <Col xl={15} lg={18} md={16} className="page-hero-title-col">
                            <FormattedMessage
                                {...messages.searchResultsForQuery}
                                tagName="h2"
                            />
                            <FormattedMessage
                                {...messages.searchResultsQuery}
                                values={{ query }}
                                tagName="h1"
                            />
                        </Col>
                    </Row>
                    <div id="bottom-triangle">
                        <IconHeroInvertedTriangle />
                    </div>
                </div>
            </header>
            <div className="container m-b-56">
                <p id="product-search-results">
                    <FormattedMessage
                        {...ProductsMessages.searchResults}
                        values={{
                            b: (...m: string[]) => <strong>{m}</strong>,
                            count: products ? products.length : 0,
                        }}
                    />
                </p>
                <section id="products-wrapper">
                    {products.slice(
                        page * constants.PAGE_SIZE, (page * constants.PAGE_SIZE) + constants.PAGE_SIZE,
                    ).map((product: Product) => (
                        <ProductCard
                            name={t(product.label, locale)}
                            image={product.images && product.images[0]}
                            link={getRoute(
                                RoutePathName.product,
                                { id: product.id },
                            )}
                            key={product.id}
                            product={product}
                            withAddToSelection
                            isBig
                        />
                    ))}
                </section>
                {products && products.length > constants.PAGE_SIZE && (
                    <div id="products-pagination">
                        <Pagination
                            current={page + 1}
                            onChange={onChangePage}
                            pageSize={constants.PAGE_SIZE}
                            total={products.length}
                        />
                    </div>
                )}
            </div>
        </>
    );
};

export default ProductSearch;
