import { createSelector } from 'reselect';
import uniqBy from 'lodash/uniqBy';

import * as reduxTypes from '../types/sectors';
import { MainReducerState, RequestState } from '.';
import { Sector, Property, Name, SectorType } from '../api/types';
import { requestReducer } from './_generics';
import { getFavorites } from './favorites';
import { hasOwnProp } from '../../utils';
import { MergedFilters } from './products';

export type SectorsState = RequestState<Sector[]>;

const normalizer = (sector: Sector): Sector => ({
    ...sector,
    sectorParents: sector.sectorParents || [],
    excludedSectors: sector.excludedSectors || [],
});

export default requestReducer<Sector[]>({
    reduxTypes: {
        START: [reduxTypes.LIST, reduxTypes.LIST_MARKETS],
        SUCCESS: [reduxTypes.LIST_SUCCESS, reduxTypes.LIST_MARKETS_SUCCESS],
        FAILED: [reduxTypes.LIST_FAILED, reduxTypes.LIST_MARKETS_FAILED],
    },
    normalizer,
});

export const getSectorsState = (state: MainReducerState) => state.sectors;

export const getMarkets = (state: MainReducerState) =>
    state.sectors.data ?
        state.sectors.data.filter((sector) => !sector.sectorParents || !sector.sectorParents.length) :
        [];

export const getSectors = (state: MainReducerState) =>
    state.sectors.data ? state.sectors.data.filter((sector) => sector.sectorParents.length === 1) : [];

export const getSectorsByParentId = (state: MainReducerState, parentId: Sector['id']) =>
    state.sectors.data ? state.sectors.data.filter((sector) => sector.sectorParents.includes(parentId)) : [];

export const getSectorsAndSubSectorsByMarketId = (state: MainReducerState, parentId: Sector['id']) => {
    if (state.sectors.data) {
        const sectors = state.sectors.data.filter((sector) =>
            sector.type === SectorType.Sector && sector.sectorParents.includes(parentId),
        );
        const result: Sector[] = [
            ...sectors,
        ];

        if (sectors.length) {
            sectors.forEach((sector) => {
                result.push(...state.sectors.data!.filter((s) =>
                    s.type === SectorType.SubSector && s.sectorParents.includes(sector.id)),
                );
            });
        }

        return uniqBy(result, 'id');
    }

    return [];
};

export const getSectorsByParentIdSortedByFavorite = (state: MainReducerState, parentId: Sector['id']) => {
    const sectors = getSectorsByParentId(state, parentId);
    const favorites = getFavorites(state);

    if (favorites && !!favorites.favoritesSectors.length) {
        return sectors.sort((a, b) => {
            if (
                favorites.favoritesSectors.indexOf(a.id) === -1 &&
                favorites.favoritesSectors.indexOf(b.id) === -1
            ) {
                return 0;
            } else if (
                favorites.favoritesSectors.indexOf(a.id) !== -1 &&
                favorites.favoritesSectors.indexOf(b.id) === -1
            ) {
                return -1;
            } else if (
                favorites.favoritesSectors.indexOf(a.id) === -1 &&
                favorites.favoritesSectors.indexOf(b.id) !== -1
            ) {
                return 1;
            } else {
                return favorites.favoritesSectors.indexOf(a.id) - favorites.favoritesSectors.indexOf(b.id);
            }
        });
    }

    return sectors;
};

export const getSectorById = (state: MainReducerState, id: Sector['id']) =>
    state.sectors.data ? state.sectors.data.find((sector) => sector.id === id) : undefined;

export const getFavoritesSectors = (state: MainReducerState) => {
    const favorites = getFavorites(state);
    const sectorIds = favorites && favorites.favoritesSectors ? favorites.favoritesSectors : [];

    return sectorIds.map(getSectorById.bind(null, state)).filter(<T>(n?: T): n is T => Boolean(n));
};

const getSectorsData = (state: MainReducerState) => state.sectors.data;

const getDescendents = (sectorsData: Sector[] | undefined, parentId: Sector['id']) => {
    if (sectorsData) {
        const descendants = sectorsData.filter((sector) => sector.sectorParents.includes(parentId));
        const result: Sector[] = [
            ...descendants,
        ];

        if (descendants.length) {
            descendants.forEach((sector) => {
                result.push(...getDescendents(sectorsData, sector.id));
            });
        }

        return result;
    }

    return;
};

export const getDescendentSectors = createSelector(
    [
        getSectorsData,
        (state: MainReducerState, parentId: Sector['id']) => parentId,
    ],
    getDescendents,
);


export const getNonExcludedSectorsBySectorIds = (state: MainReducerState, sectorIds: Sector['id'][]) => {
    const parentId = sectorIds[sectorIds.length - 1];
    const descendantSectors = getDescendentSectors(state, parentId);

    if (descendantSectors) {
        return descendantSectors.filter((descendant) =>
            !sectorIds.some((sectorId) => descendant.excludedSectors.includes(sectorId)),
        );
    }

    return [];
};

export interface PropertyGroup {
    id: number;
    name: Name;
    children: {
        [key: number]: Property;
    };
    order: number;
}

export const getMergedFiltersBySectorIds = (state: MainReducerState, sectorIds: Sector['id'][]) => {
    const parentId = sectorIds[sectorIds.length - 1];
    const parentSector = getSectorById(state, parentId);
    const descendantSectors = getDescendentSectors(state, parentId);

    if (descendantSectors) {
        const nonExcludedSectors = [
            parentSector,
            ...descendantSectors.filter((descendant) =>
                !sectorIds.some((sectorId) => descendant.excludedSectors.includes(sectorId)),
            ),
        ];

        return nonExcludedSectors.reduce<MergedFilters>((acc, sector) => {
            sector!.arguments.forEach((argument) => {
                if (!hasOwnProp(acc.arguments, argument.id)) {
                    acc.arguments[argument.id] = argument;
                }
            });
            sector!.properties.forEach((property) => {
                if (property.group !== null) {
                    if (!hasOwnProp(acc.properties, property.group.id)) {
                        acc.properties[property.group.id] = {
                            ...property.group,
                            children: {
                                [property.id]: property,
                            },
                        };
                    } else if (
                        acc.properties[property.group.id].children &&
                        !hasOwnProp(acc.properties[property.group.id].children!, property.id)
                    ) {
                        acc.properties[property.group.id].children![property.id] = property;
                    }

                    const currentProperty: Property =
                        acc.properties[property.group.id].children![property.id];

                    if (currentProperty.values) {
                        currentProperty.valuesById =
                            currentProperty.values.reduce<Property['valuesById']>((valuesById, value) => {
                                if (valuesById) {
                                    valuesById[value.id] = {
                                        ...value,
                                        productCount: 0,
                                    };
                                }

                                return valuesById;
                            }, {});
                    }
                } else if (!hasOwnProp(acc.properties, property.id)) {
                    acc.properties[property.id] = property;

                    if (acc.properties[property.id].values) {
                        acc.properties[property.id].valuesById =
                            acc.properties[property.id].values!.reduce<Property['valuesById']>((valuesById, value) => {
                                if (valuesById) {
                                    valuesById[value.id] = {
                                        ...value,
                                        productCount: 0,
                                    };
                                }

                                return valuesById;
                            }, {});
                    }
                }
            });

            return acc;
        }, {
            arguments: {},
            properties: {},
        });
    }

    return undefined;
};
