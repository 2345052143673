import React, { useState, useEffect, useContext, KeyboardEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Drawer, List } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';

import '../../assets/styles/UserMenu.less';

import { Client } from '../../store/api/types';
import { getClients } from '../../store/reducers/clients';
import { MainReducerState } from '../../store/reducers';

import GenericMessages from '../../locale/Generic.messages';
import { getFullName } from '../../utils';
import { IconArrow } from '../icons';
import ListItem, { ListItemProps } from '../ListItem';
import messages from './UserMenu.messages';
import ClientDrawer from './ClientDrawer';
import ClientFormDrawer from './ClientFormDrawer';
import EmptyResult from '../EmptyResult';
import { UserMenuDrawerContext } from './UserMenuDrawerContext';
import { ButtonProps } from 'antd/lib/button';

interface ClientsDrawerProps extends DrawerProps {
    clients?: Client[];
}

const ClientsDrawer: React.FC<ClientsDrawerProps> = ({ clients, onClose, visible, zIndex }) => {
    const {
        isClientDrawerVisible, isClientFormDrawerVisible, setIsClientDrawerVisible,
        setIsClientFormDrawerVisible,
    } = useContext(UserMenuDrawerContext);
    const [list, setList] = useState(clients);
    const [filter, setFilter] = useState('date');
    const [selectedClientReference, setSelectedClientReference] = useState();
    const onCloseClientDrawer = () => {
        setIsClientDrawerVisible(false);
        setSelectedClientReference(undefined);
    };
    const onCloseClientFormDrawer = () => { setIsClientFormDrawerVisible(false); };
    const onBack: ButtonProps['onClick'] = () => {
        if (typeof onClose === 'function') {
            onClose({} as KeyboardEvent<HTMLDivElement>);
        }
    };
    const onClientClick: ListItemProps['onClick'] = (e) => {
        setSelectedClientReference(e.currentTarget.dataset.reference);
    };
    const onClickAddClient = () => {
        setIsClientFormDrawerVisible(true);
    };
    const onToggleSort = () => {
        setFilter(filter === 'name' ? 'date' : 'name');
    };

    useEffect(() => {
        if (selectedClientReference !== undefined) {
            setIsClientDrawerVisible(true);
        }
    }, [selectedClientReference, setIsClientDrawerVisible]);

    useEffect(() => {
        setList(clients);
    }, [clients]);

    useEffect(() => {
        setList(list && list.sort((a, b) => {
            if (filter === 'name') {
                return b.lastName.localeCompare(a.lastName);
            } else {
                return b.createAt.localeCompare(a.createAt);
            }
        }));
    }, [filter, list]);

    return (
        <>
            <Drawer
                title={(
                    <div className="drawer-header-with-back-button">
                        <Button
                            type="link"
                            onClick={onBack}
                        >
                            <IconArrow direction="left" />
                        </Button>
                        <FormattedMessage {...messages.files} tagName="span" />
                    </div>
                )}
                placement="right"
                width={385}
                maskStyle={{display: 'none'}}
                closable={false}
                visible={visible}
                zIndex={zIndex}
            >
                <div id="user-menu-clients" className="with-footer">
                    <section>
                        <div id="user-menu-clients-filter">
                            <p>
                                <FormattedMessage {...messages.sortBy} tagName="span" />
                                <span>
                                    <Button
                                        type="link"
                                        onClick={onToggleSort}
                                        className={filter === 'name' ? 'is-active' : undefined}
                                    >
                                        <FormattedMessage {...GenericMessages.name} />
                                    </Button>
                                    {' / '}
                                    <Button
                                        type="link"
                                        onClick={onToggleSort}
                                        className={filter === 'date' ? 'is-active' : undefined}
                                    >
                                        <FormattedMessage {...messages.date} />
                                    </Button>
                                </span>
                            </p>
                        </div>
                        <List>
                            {list && !!list.length && list.filter((c) => c.reference).map((client, index) => (
                                <List.Item key={`${client.reference}-${index}`}>
                                    <ListItem
                                        onClick={onClientClick}
                                        data-reference={client.reference}
                                        title={getFullName(client.firstName, client.lastName)}
                                        subTitle={client.company}
                                    />
                                </List.Item>
                            ))}
                            {list && !list.length && (
                                <List.Item key="empty">
                                    <EmptyResult />
                                </List.Item>
                            )}
                        </List>
                    </section>
                    <footer>
                        <Button
                            type="primary"
                            onClick={onClickAddClient}
                            size="large"
                            block
                        >
                            <FormattedMessage {...messages.addClient} />
                        </Button>
                    </footer>
                </div>
            </Drawer>
            <ClientDrawer
                clientReference={selectedClientReference}
                onClose={onCloseClientDrawer}
                visible={isClientDrawerVisible}
                zIndex={1401}
            />
            <ClientFormDrawer
                onClose={onCloseClientFormDrawer}
                visible={isClientFormDrawerVisible}
                zIndex={1401}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    clients: getClients(state),
});

export default connect(
    mapStateToProps,
)(ClientsDrawer);
