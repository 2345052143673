import api from './_client';
import { PaginationQuery } from '.';
import { Selection } from './types';
import { GeneratePdfPayload } from '../actions/selections';

export const history = (query?: PaginationQuery) =>
    api.get('/api/me/selections', { params: query });

export const current = () =>
    api.get('/api/me/selections/current');

export const generatePdf = (selectionId: Selection['id'], payload: GeneratePdfPayload) =>
    api.post(`/api/selections/${selectionId}/generatePdf`, payload);
