import { Action, ActionCreator } from 'redux';
import * as reduxTypes from '../types/terms';
import { DataAction } from '.';
import { Languages } from '../../components/IntlProvider';

export const fetch: ActionCreator<Action & { locale: Languages }> = (locale: Languages) => ({
    type: reduxTypes.FETCH,
    locale,
});

export const fetchSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.FETCH_SUCCESS,
    data,
});

export const fetchFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.FETCH_FAILED,
    data,
});

