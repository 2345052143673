import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="4" viewBox="0 0 12 4">
        <path fill="currentColor" d="M10.177 4H1.823c-.507 0-.937-.192-1.29-.576C.176 3.04 0 2.574 0 2.024 0 1.474.177 1 .532.6c.354-.4.784-.6 1.29-.6h8.355c.507 0 .937.2 1.29.6.356.4.533.875.533 1.424 0 .55-.177 1.015-.532 1.4a1.692 1.692 0 0 1-1.29.576z" />
    </svg>
);

const IconMinus: React.FC<IconProps> = (props) => (
    <Icon component={svg} {...props} className={`anticon icon-minus${props.className ? ` ${props.className}` : ''}`} />
);

export default IconMinus;
