import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Spin, Icon, message } from 'antd';
import { PersistGate } from 'redux-persist/es/integration/react';


import configureStore from './store/configureStore';

import App from './App';
import IntlProvider from './components/IntlProvider';
import ErrorBoundary from './components/ErrorBoundary';
import { constants } from './utils';
import * as serviceWorker from './serviceWorker';

import './assets/styles/AntOverride.less';

Spin.setDefaultIndicator(<Icon type="loading" spin />);

message.config({
    top: 95,
});

ReactGA.initialize(constants.GOOGLE_ANALYTICS);

const history = createBrowserHistory();

history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
});

const { persistor, store } = configureStore();

const render = (Component: React.ComponentType) => {
    ReactDOM.render(
        (
            <Provider store={store}>
                <PersistGate
                    loading={<Spin spinning />}
                    persistor={persistor}
                >
                    <IntlProvider>
                        <Router history={history}>
                            <ErrorBoundary>
                                <Component />
                            </ErrorBoundary>
                        </Router>
                    </IntlProvider>
                </PersistGate>
            </Provider>
        ),
        document.getElementById('root') as HTMLElement,
    );
};

render(App);

if (module.hot) {
    module.hot.accept('./App', () => {
        const NextApp = require('./App').default;
        render(NextApp);
    });
}

serviceWorker.register();
