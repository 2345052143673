import { defineMessages } from 'react-intl';

const messages = defineMessages({
    submit: {
        id: 'form.submit',
        defaultMessage: 'Submit',
        description: 'Form submit button',
    },
    cancel: {
        id: 'form.cancel',
        defaultMessage: 'Cancel',
        description: 'Form cancel button',
    },
    addField: {
        id: 'form.add_field',
        defaultMessage: 'Add field',
        description: 'Form add field button',
    },
    defaultValidationError: {
        id: 'form.validation.default_error',
        defaultMessage: 'Validation error',
        description: 'Form validation',
    },
    requiredError: {
        id: 'form.validation.required',
        defaultMessage: 'The field is required',
        description: 'Form validation',
    },
    enumError: {
        id: 'form.validation.enum',
        defaultMessage: '{value} must be one of {enum}',
        description: 'Form validation',
    },
    whitespaceError: {
        id: 'form.validation.whitespace',
        defaultMessage: 'The field cannot be empty',
        description: 'Form validation',
    },
    dateFormatError: {
        id: 'form.validation.date.format',
        defaultMessage: '{date} date is invalid for format {format}',
        description: 'Form validation',
    },
    dateParseError: {
        id: 'form.validation.date.parse',
        defaultMessage: '{date} date could not be parsed, it is invalid',
        description: 'Form validation',
    },
    dateInvalidError: {
        id: 'form.validation.date.invalid',
        defaultMessage: '{date} date is invalid',
        description: 'Form validation',
    },
    selectPlaceholder: {
        id: 'form.select.placeholder',
        defaultMessage: 'Select',
        description: 'Select placeholder',
    },
    validate: {
        id: 'form.validate',
        defaultMessage: 'Validate',
        description: 'Button text',
    },
    validateChanges: {
        id: 'form.validate_changes',
        defaultMessage: 'Validate changes',
        description: 'Button text',
    },
    edit: {
        id: 'form.edit',
        defaultMessage: 'Edit',
        description: 'Edit',
    },
});

export default messages;
