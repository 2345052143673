import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'how_it_works.title',
        defaultMessage: 'How it works?',
        description: 'How it works page title',
    },
    noSteps: {
        id: 'how_it_works.no_data',
        defaultMessage: 'No available steps for now.',
        description: 'How it works no steps available',
    },
});
