import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="70" viewBox="0 0 50 70">
        <path fill="currentColor" fillRule="evenodd" d="M7.644 16.707c0-.733.594-1.327 1.328-1.327h31.733a1.328 1.328 0 0 1 0 2.655H8.972c-.734 0-1.328-.594-1.328-1.328zm33.25 43.45H9.258L2.813 2.654H47.19l-6.297 57.503zm-30.83 7.188l-.507-4.534h31.046l-.498 4.535h-30.04zM49.662.44a1.325 1.325 0 0 0-.988-.44H1.33A1.328 1.328 0 0 0 .007 1.474L7.556 68.82c.075.673.644 1.18 1.32 1.18h32.42c.678 0 1.247-.51 1.32-1.182L49.992 1.47c.04-.373-.08-.748-.33-1.03z" />
    </svg>
);

const IconDrink: React.FC<IconProps> = (props) => (
    <Icon component={svg} {...props} className={`anticon icon-drink${props.className ? ` ${props.className}` : ''}`} />
);

export default IconDrink;
