import api from './_client';
import { PaginationQuery } from '.';
import { Sector } from './types';

export const list = (query?: PaginationQuery) =>
    api.get('/api/sectors', { params: query });

export const listMarkets = () =>
    api.get('/api/markets');

export const details = (id: Sector['id']) =>
    api.get(`/api/sectors/${id}`);
