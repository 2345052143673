export const LOGIN = 'auth/LOGIN';
export const LOGIN_SUCCESS = 'auth/LOGIN_SUCCESS';
export const LOGIN_FAILED = 'auth/LOGIN_FAILED';

export const LOGOUT = 'auth/LOGOUT';
export const LOGOUT_SUCCESS = 'auth/LOGOUT_SUCCESS';
export const LOGOUT_FAILED = 'auth/LOGOUT_FAILED';

export const REGISTER = 'auth/REGISTER';
export const REGISTER_SUCCESS = 'auth/REGISTER_SUCCESS';
export const REGISTER_FAILED = 'auth/REGISTER_FAILED';

export const FORGOTTEN_PASSWORD = 'auth/FORGOTTEN_PASSWORD';
export const FORGOTTEN_PASSWORD_SUCCESS = 'auth/FORGOTTEN_PASSWORD_SUCCESS';
export const FORGOTTEN_PASSWORD_FAILED = 'auth/FORGOTTEN_PASSWORD_FAILED';

export const UPDATE = 'auth/UPDATE';
export const UPDATE_SUCCESS = 'auth/UPDATE_SUCCESS';
export const UPDATE_FAILED = 'auth/UPDATE_FAILED';

export const CHECK_LOGIN_STATUS = 'auth/CHECK_LOGIN_STATUS';
export const CHECK_LOGIN_STATUS_SUCCESS = 'auth/CHECK_LOGIN_STATUS_SUCCESS';
export const CHECK_LOGIN_STATUS_FAILED = 'auth/CHECK_LOGIN_STATUS_FAILED';

export const FETCH_USER = 'auth/FETCH_USER';
export const FETCH_USER_SUCCESS = 'auth/FETCH_USER_SUCCESS';
export const FETCH_USER_FAILED = 'auth/FETCH_USER_FAILED';
