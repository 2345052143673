import React from 'react';
import { RouteChildrenProps } from 'react-router';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import { Row, Col } from 'antd';

import '../../assets/styles/Sector.less';

import { MainReducerState } from '../../store/reducers';
import { RoutePathName, getRoute } from '../../routes';
import { getSectorById } from '../../store/reducers/sectors';
import { Sector } from '../../store/api/types';
import BreadCrumb from '../../components/BreadCrumb';
import PDFViewer from '../../components/PDFViewer';
import Img from '../../components/Img';
import { t } from '../../utils';

interface MatchParams {
    id: string;
}

interface SalesAcademyProps extends RouteChildrenProps<MatchParams> {
    sector?: Sector;
}

const SectorPage: React.FC<SalesAcademyProps> = ({ sector }) => {
    const { locale } = useIntl();

    return (
        <>
            <BreadCrumb
                routes={[
                    {
                        link: getRoute(RoutePathName.sector, { id: sector ? sector.id : 0 }),
                        text: sector && t(sector.name, locale) ? t(sector.name, locale) : '',
                    },
                    {
                        link: getRoute(RoutePathName.salesAcademy, { id: sector ? sector.id : 0 }),
                        text: 'Elis sales academy',
                    },
                ]}
            />
            <header className="page-hero">
                {sector && (
                    <>
                        {sector && sector.image && <Img imageId={sector.image.id} alt={t(sector.name, locale)} />}
                        <div className="container">
                            <Row>
                                <Col md={20} className="page-hero-title-col">
                                    <h2>Elis Sales Academy</h2>
                                    <h1>{t(sector.name, locale)}</h1>
                                </Col>
                            </Row>
                        </div>
                    </>
                )}
            </header>
            <section
                id="sector-pdf-wrapper"
                className="p-t-24 m-b-86"
                style={{ minHeight: 'calc(100vw / 1.3268156425)' }}
            >
                {sector && (
                    <PDFViewer
                        file={sector.salesAcademy}
                    />
                )}
            </section>
        </>
    );
};

const mapStateToProps = (state: MainReducerState, { match }: SalesAcademyProps) => ({
    sector: getSectorById(state, parseInt(match!.params.id, 10)),
});

export default connect(
    mapStateToProps,
)(SectorPage);
