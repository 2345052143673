import { ActionCreator, Action } from 'redux';
import * as reduxTypes from '../types/presentations';
import { DataAction, IdAction, QueryListPayload } from '.';
import { PresentationPayload } from '../../components/PresentationContextProvider';
import { Presentation } from '../api/types';

export const preview: ActionCreator<DataAction<PresentationPayload>> = (data: PresentationPayload) => ({
    type: reduxTypes.PREVIEW,
    data,
});

export const previewSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.PREVIEW_SUCCESS,
    data,
});

export const previewFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.PREVIEW_FAILED,
    data,
});

export const create: ActionCreator<DataAction<PresentationPayload>> = (data: PresentationPayload) => ({
    type: reduxTypes.CREATE,
    data,
});

export const createSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_SUCCESS,
    data,
});

export const createFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_FAILED,
    data,
});

export const convert: ActionCreator<DataAction<PresentationPayload>> = (data: PresentationPayload) => ({
    type: reduxTypes.CONVERT,
    data,
});

export const convertSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CONVERT_SUCCESS,
    data,
});

export const convertFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CONVERT_FAILED,
    data,
});

export const list: ActionCreator<Action> = (data: QueryListPayload) => ({
    type: reduxTypes.LIST,
    data,
});

export const listSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_SUCCESS,
    data,
});

export const listFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_FAILED,
    data,
});

export const del: ActionCreator<IdAction> = (id: Presentation['id']) => ({
    type: reduxTypes.DELETE,
    id,
});

export const delSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DELETE_SUCCESS,
    data,
});

export const delFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DELETE_FAILED,
    data,
});

export const details: ActionCreator<IdAction> = (id: Presentation['id']) => ({
    type: reduxTypes.DETAILS,
    id,
});

export const detailsSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_SUCCESS,
    data,
});

export const detailsFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DETAILS_FAILED,
    data,
});

export const update: ActionCreator<IdAction & DataAction<PresentationPayload>> = (
    id: Presentation['id'],
    data: PresentationPayload,
) => ({
    type: reduxTypes.UPDATE,
    data,
    id,
});

export const updateSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_SUCCESS,
    data,
});

export const updateFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_FAILED,
    data,
});
