import React, { useCallback, useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Input, Table, Row, Col, ConfigProvider } from 'antd';

import '../../assets/styles/Nace.less';

import BreadCrumb from '../../components/BreadCrumb';
import { getRoute, RoutePathName } from '../../routes';
import { IconHeroInvertedTriangle } from '../../components/icons';
import messages from './Nace.messages';
import { MainReducerState } from '../../store/reducers';
import { getNaceState, NaceState } from '../../store/reducers/nace';
import { ColumnProps } from 'antd/lib/table';
import { Nace } from '../../store/api/types';
import EmptyResult from '../../components/EmptyResult';

interface NaceProps {
    nace: NaceState;
}

const NacePage: React.FC<NaceProps> = ({ nace }) => {
    const { formatMessage } = useIntl();
    const columns: ColumnProps<Nace>[] = [
        {
            title: 'NAF Rev2',
            dataIndex: 'naf.id',
            sorter: (a, b) => a.naf.id.localeCompare(b.naf.id),
        },
        {
            title: <FormattedMessage {...messages.nafLabel} />,
            dataIndex: 'naf.description',
            sorter: (a, b) => a.naf.description.localeCompare(b.naf.description),
        },
        {
            title: 'NACE',
            dataIndex: 'nace.id',
            sorter: (a, b) => a.nace.id - b.nace.id,
        },
        {
            title: <FormattedMessage {...messages.naceLabel} />,
            dataIndex: 'nace.description',
            sorter: (a, b) => a.naf.description.localeCompare(b.nace.description),
        },
        {
            title: <FormattedMessage {...messages.market} />,
            dataIndex: 'market',
            sorter: (a, b) => a.market.localeCompare(b.market),
        },
        {
            title: <FormattedMessage {...messages.sector} />,
            dataIndex: 'sector',
            sorter: (a, b) => a.sector.localeCompare(b.sector),
        },
        {
            title: <FormattedMessage {...messages.subSector} />,
            dataIndex: 'subSector',
            sorter: (a, b) => a.subSector.localeCompare(b.subSector),
        },
    ];
    const [tableData, setTableData] = useState<Nace[]>([]);
    const onSearch = useCallback((e) => {
        const value = e.target.value.trim();
        const searchRegex = RegExp(value.toLowerCase());

        if (nace.data && Array.isArray(nace.data) && value.length) {
            setTableData(nace.data.filter((datum) =>
                searchRegex.test(`${datum.naf.id}`.toLowerCase()) ||
                searchRegex.test(`${datum.naf.description}`.toLowerCase()) ||
                searchRegex.test(`${datum.nace.id}`.toLowerCase()) ||
                searchRegex.test(`${datum.nace.description}`.toLowerCase()) ||
                searchRegex.test(`${datum.market}`.toLowerCase()) ||
                searchRegex.test(`${datum.sector}`.toLowerCase()) ||
                searchRegex.test(`${datum.subSector}`.toLowerCase()),
            ));
        } else {
            setTableData(nace.data || []);
        }
    }, [nace.data]);
    const emptyRenderer = useCallback(() => <EmptyResult />, []);

    useEffect(() => {
        setTableData(nace.data || []);
    }, [nace.data]);

    return (
        <>
            <BreadCrumb
                routes={[{
                    link: getRoute(RoutePathName.nace),
                    text: 'NACE',
                }]}
            />
            <header className="page-hero product-hero">
                <div className="container">
                    <Row>
                        <Col xl={15} lg={18} md={16} className="page-hero-title-col">
                            <FormattedMessage {...messages.title} tagName="h1" />
                        </Col>
                    </Row>
                    <div id="bottom-triangle">
                        <IconHeroInvertedTriangle />
                    </div>
                </div>
            </header>
            <div id="nace" className="container m-b-56">
                <Input.Search
                    placeholder={formatMessage(messages.searchPlaceholder)}
                    onChange={onSearch}
                    enterButton
                />
                <ConfigProvider renderEmpty={emptyRenderer}>
                    <Table
                        columns={columns}
                        dataSource={tableData}
                        loading={nace.loading}
                        pagination={{ pageSize: 25 }}
                        scroll={{ x: 1205 }}
                        bordered
                    />
                </ConfigProvider>
            </div>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    nace: getNaceState(state),
});

export default connect(
    mapStateToProps,
)(NacePage);
