import { defineMessages } from 'react-intl';

export default defineMessages({
    tooltipTop: {
        id: 'home.tooltip.top',
        defaultMessage: 'Select',
        description: 'Home page tooltip top text',
    },
    tooltipBottom: {
        id: 'home.tooltip.bottom',
        defaultMessage: 'a sector',
        description: 'Home page tooltip bottom text',
    },
    howItWorksText: {
        id: 'home.how_it_works.text',
        defaultMessage: 'Some tips to start up with your sales support tool.',
        description: 'Home page how it works block text',
    },
    favoriteTitle: {
        id: 'home.favorite.title',
        defaultMessage: 'My favorite products',
        description: 'Home page favorite title',
    },
    favoriteLink: {
        id: 'home.favorite.link',
        defaultMessage: 'See all my favorite products',
        description: 'Home page favorite link text',
    },
    noFavorites: {
        id: 'home.favorite.no_favorites',
        defaultMessage: 'No favorites yet!',
        description: 'Home page favorite empty text',
    },
    newsTitle: {
        id: 'home.news.title',
        defaultMessage: 'Elis news',
        description: 'Home page news block title',
    },
    noNews: {
        id: 'home.news.no_news',
        defaultMessage: 'No news yet!',
        description: 'Home page news empty text',
    },
    newsMoreLink: {
        id: 'home.news.more_link',
        defaultMessage: 'See more',
        description: 'Home page news see more link',
    },
});
