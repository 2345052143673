import api, { UpdateData } from './_client';
import { User } from './types';

export interface LoginPayload {
    email: string;
    password: string;
}

export interface ForgottenPasswordPayload {
    email: string;
}

export interface RegisterPayload {
    email: string;
    marketId: number;
    profile: string;
    country: string;
}

export type UpdatePayload = Partial<User>;

export const login = (payload: LoginPayload) =>
    api.post('/api/login', payload);

export const register = (payload: RegisterPayload) =>
    api.post('/api/register', payload);

export const update = (id: User['id'], payload: UpdateData<User>) =>
    api.patch(`/api/users/${id}`, payload);

export const forgottenPassword = (payload: ForgottenPasswordPayload) =>
    api.post('/api/recovery', payload);

export const logout = () =>
    api.post('/api/logout');

export const getUserDetails = () =>
    api.get('/api/me');
