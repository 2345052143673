import { defineMessages } from 'react-intl';

export default defineMessages({
    welcome: {
        id: 'login.title',
        defaultMessage: 'Welcome,',
        description: 'Login page title',
    },
    description: {
        id: 'login.description',
        defaultMessage: 'Login <span>or <a>create an account</a></span>',
        description: 'Login page description above the login form',
    },
    emailLabel: {
        id: 'login.email.label',
        defaultMessage: 'Professional email address',
        description: 'Login email field label',
    },
    emailPlaceholder: {
        id: 'login.email.placeholder',
        defaultMessage: 'Enter your email address',
        description: 'Login email field placeholder',
    },
    emailError: {
        id: 'login.email.error',
        defaultMessage: 'Please enter a valid email',
        description: 'Login email field error',
    },
    invalidCredentials: {
        id: 'login.error.invalid_credentials',
        defaultMessage: 'Invalid credentials provided.',
        description: 'Login error message when credentials provided aren\'t valid',
    },
    passwordLabel: {
        id: 'login.password.label',
        defaultMessage: 'Password',
        description: 'Login password field label',
    },
    passwordPlaceholder: {
        id: 'login.password.placeholder',
        defaultMessage: 'Enter your password',
        description: 'Login password field placeholder',
    },
    passwordError: {
        id: 'login.password.error',
        defaultMessage: 'Please enter your password',
        description: 'Login password field error',
    },
    loginButton: {
        id: 'login.submit',
        defaultMessage: 'Log in',
        description: 'Login submit button text',
    },
    forgotPassword: {
        id: 'login.forgotten_password',
        defaultMessage: 'Forgot password?',
        description: 'Login forgot password link',
    },
    emailNotFound: {
        id: 'forgotten_password.email_not_found',
        defaultMessage: 'Are you sure you provided the correct address?',
        description: 'Forgotten password not found error',
    },
});
