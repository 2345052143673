import { RequestState } from '.';
import { Reducer } from 'redux';
import merge from 'lodash/merge';

export interface RequestReducerTypes {
    readonly START: string | string[];
    readonly SUCCESS: string | string[];
    readonly FAILED: string | string[];
    readonly CREATE_EFFECT?: string | string[];
    readonly CREATE_COMMIT?: string | string[];
    readonly CREATE_ROLLBACK?: string | string[];
    readonly UPDATE_EFFECT?: string | string[];
    readonly UPDATE_COMMIT?: string | string[];
    readonly UPDATE_ROLLBACK?: string | string[];
    readonly DELETE_EFFECT?: string | string[];
    readonly DELETE_COMMIT?: string | string[];
    readonly DELETE_ROLLBACK?: string | string[];
}

interface RequestReducerParams<T> {
    reduxTypes: RequestReducerTypes;
    dataAccessor?: string;
    normalizer?: (item: any) => any;
    customReducer?: Reducer<RequestState<T>>;
    createEffect?: (state: T | undefined, data: any) => T | undefined;
    createCommit?: (state: T | undefined, data: any, payload: any) => T | undefined;
    createRollback?: (state: T | undefined, data: any) => T | undefined;
    updateEffect?: (state: T | undefined, data: any) => T | undefined;
    updateCommit?: (state: T | undefined, data: any, payload: any) => T | undefined;
    updateRollback?: (state: T | undefined, data: any) => T | undefined;
    deleteEffect?: (state: T | undefined, data: any) => T | undefined;
    deleteCommit?: (state: T | undefined, data: any, payload: any) => T | undefined;
    deleteRollback?: (state: T | undefined, data: any) => T | undefined;
}

export const requestReducerInitialState: RequestState = {
    loading: false,
};

export type RequestReducer = <T>(params: RequestReducerParams<T>) => Reducer<RequestState<T>>;

export const requestReducer: RequestReducer = ({
    reduxTypes, dataAccessor, normalizer, createEffect, createCommit, createRollback, updateEffect,
    updateCommit, updateRollback, deleteEffect, deleteCommit, deleteRollback, customReducer,
}) =>
    (state = requestReducerInitialState, action) => {
        const { data, payload, type } = action;

        if (type === reduxTypes.START || (Array.isArray(reduxTypes.START) && reduxTypes.START.includes(type))) {
            return {
                ...state,
                loading: true,
                error: undefined,
                success: undefined,
            };
        }

        if (type === reduxTypes.SUCCESS || (Array.isArray(reduxTypes.SUCCESS) && reduxTypes.SUCCESS.includes(type))) {
            const rawData = dataAccessor ? data[dataAccessor] || [] : data || [];
            let processedData = rawData;

            if (typeof normalizer === 'function') {
                processedData = rawData.map(normalizer);
            }

            return {
                ...state,
                data: processedData,
                loading: false,
                success: true,
            };
        }

        if (type === reduxTypes.FAILED || (Array.isArray(reduxTypes.FAILED) && reduxTypes.FAILED.includes(type))) {
            return {
                ...state,
                loading: false,
                error: data && data.response && data.response.data ?
                    data.response.data :
                    true,
                response: data,
                success: false,
            };
        }

        if (
            type === reduxTypes.CREATE_EFFECT ||
            (Array.isArray(reduxTypes.CREATE_EFFECT) && reduxTypes.CREATE_EFFECT.includes(type))
        ) {
            return {
                ...state,
                loading: false,
                data: createEffect ? createEffect(state.data, data) : state.data,
                success: false,
            };
        }

        if (
            type === reduxTypes.CREATE_COMMIT ||
            (Array.isArray(reduxTypes.CREATE_COMMIT) && reduxTypes.CREATE_COMMIT.includes(type))
        ) {
            return {
                ...state,
                loading: false,
                data: createCommit ? createCommit(state.data, data, payload) : state.data,
                success: false,
            };
        }

        if (
            type === reduxTypes.CREATE_ROLLBACK ||
            (Array.isArray(reduxTypes.CREATE_ROLLBACK) && reduxTypes.CREATE_ROLLBACK.includes(type))
        ) {
            return {
                ...state,
                loading: false,
                data: createRollback ? createRollback(state.data, data) : state.data,
                success: false,
            };
        }

        if (
            type === reduxTypes.UPDATE_EFFECT ||
            (Array.isArray(reduxTypes.UPDATE_EFFECT) && reduxTypes.UPDATE_EFFECT.includes(type))
        ) {
            return {
                ...state,
                loading: false,
                data: updateEffect ? updateEffect(state.data, data) : state.data,
                success: false,
            };
        }

        if (
            type === reduxTypes.UPDATE_COMMIT ||
            (Array.isArray(reduxTypes.UPDATE_COMMIT) && reduxTypes.UPDATE_COMMIT.includes(type))
        ) {
            return {
                ...state,
                loading: false,
                data: updateCommit ? updateCommit(state.data, data, payload) : state.data,
                success: false,
            };
        }

        if (
            type === reduxTypes.UPDATE_ROLLBACK ||
            (Array.isArray(reduxTypes.UPDATE_ROLLBACK) && reduxTypes.UPDATE_ROLLBACK.includes(type))
        ) {
            return {
                ...state,
                loading: false,
                data: updateRollback ? updateRollback(state.data, data) : state.data,
                success: false,
            };
        }

        if (
            type === reduxTypes.DELETE_EFFECT ||
            (Array.isArray(reduxTypes.DELETE_EFFECT) && reduxTypes.DELETE_EFFECT.includes(type))
        ) {
            return {
                ...state,
                loading: false,
                data: deleteEffect ? deleteEffect(state.data, data) : state.data,
                success: false,
            };
        }

        if (
            type === reduxTypes.DELETE_COMMIT ||
            (Array.isArray(reduxTypes.DELETE_COMMIT) && reduxTypes.DELETE_COMMIT.includes(type))
        ) {
            return {
                ...state,
                loading: false,
                data: deleteCommit ? deleteCommit(state.data, data, payload) : state.data,
                success: false,
            };
        }

        if (
            type === reduxTypes.DELETE_ROLLBACK ||
            (Array.isArray(reduxTypes.DELETE_ROLLBACK) && reduxTypes.DELETE_ROLLBACK.includes(type))
        ) {
            return {
                ...state,
                loading: false,
                data: deleteRollback ? deleteRollback(state.data, data) : state.data,
                success: false,
            };
        }

        if (typeof customReducer === 'function') {
            return customReducer(state, action);
        }

        return state;
    };

export interface EntityReducerState<T> {
    [id: string]: T;
}

type EntityReducerType = <T>(
    successTypes: RequestReducerTypes['SUCCESS'][],
    entity: string,
) => Reducer<EntityReducerState<T>>;

export const entityReducer: EntityReducerType = (successTypes, entity) =>
    (state = {}, { type, data }) => {
        if (successTypes.indexOf(type) !== -1) {
            const { entities } = data;

            return merge({}, state, entities[entity]);
        } else {
            return state;
        }
    };
