import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Divider, Tabs } from 'antd';
import { TabsProps } from 'antd/lib/tabs';

import '../../assets/styles/Product.less';

import { Product } from '../../store/api/types';
import { getFilters, FiltersState } from '../../store/reducers/filters';
import { MainReducerState } from '../../store/reducers';

import { SectorColorContext } from '../../components/SectorColorContextProvider';
import Carousel from '../../components/Carousel';
import ProductCard from '../../components/ProductCard';
import { getRoute, RoutePathName } from '../../routes';
import { scrollToElement, t } from '../../utils';

interface AssociatedProductGroupsProps {
    product?: Product;
    sectorId?: string;
    subSectorId?: string;
    solutionId?: string;
    filters: FiltersState;
}

const AssociatedProductGroups: React.FC<AssociatedProductGroupsProps> = ({
    product, sectorId, solutionId, subSectorId, filters,
}) => {
    const { locale } = useIntl();
    const { color } = useContext(SectorColorContext);
    const associatedProductGroups = product && product.associatedProductGroups ?
        product.associatedProductGroups :
        [];
    const onChangeTab: TabsProps['onChange'] = (tab) => {
        const element = document.getElementById(`group-${tab}`);

        scrollToElement(element, 100);
    };

    return associatedProductGroups.length ? (
        <section id="associated-product-groups" className="m-b-56">
            <div id="associated-product-groups-tabs">
                <div className="container">
                    <Tabs defaultActiveKey="0" onChange={onChangeTab}>
                        {associatedProductGroups.map((group, index) => (
                            <Tabs.TabPane
                                tab={t(group.associatedProductGroup.name, locale)}
                                key={`${index}`}
                            />
                        ))}
                    </Tabs>
                </div>
            </div>
            <div className="container">
                {associatedProductGroups.map((group, index) => (
                    <div
                        className="associated-product-group"
                        id={`group-${index}`}
                        key={`${index}`}
                    >
                        <div className="associated-product-groups-title">
                            <h3 style={color ? { color } : undefined}>
                                {t(group.associatedProductGroup.name, locale)}
                            </h3>
                            <Divider style={color ? { backgroundColor: color } : undefined} />
                        </div>
                        <Carousel
                            lazyLoad="ondemand"
                            slidesToShow={group.associatedProducts.length < 4 ? group.associatedProducts.length : 4}
                            slidesToScroll={group.associatedProducts.length < 4 ? group.associatedProducts.length : 4}
                            responsive={[
                                {
                                    breakpoint: 1366,
                                    settings: {
                                        slidesToShow: group.associatedProducts.length < 4 ?
                                            group.associatedProducts.length :
                                            4,
                                        slidesToScroll: group.associatedProducts.length < 4 ?
                                            group.associatedProducts.length :
                                            4,
                                    },
                                },
                                {
                                    breakpoint: 1156,
                                    settings: {
                                        slidesToShow: group.associatedProducts.length < 3 ?
                                            group.associatedProducts.length :
                                            3,
                                        slidesToScroll: group.associatedProducts.length < 3 ?
                                            group.associatedProducts.length :
                                            3,
                                    },
                                },
                                {
                                    breakpoint: 884,
                                    settings: {
                                        slidesToShow: group.associatedProducts.length < 2 ?
                                            group.associatedProducts.length :
                                            2,
                                        slidesToScroll: group.associatedProducts.length < 2 ?
                                            group.associatedProducts.length :
                                            2,
                                    },
                                },
                                {
                                    breakpoint: 580,
                                    settings: {
                                        slidesToShow: 1,
                                        slidesToScroll: 1,
                                    },
                                },
                            ]}
                            isSmall
                        >
                            {group.associatedProducts.map((productId) => {
                                const p = product && product.associatedProductsFromGroupsPopulated ?
                                    product.associatedProductsFromGroupsPopulated[productId] :
                                    undefined;

                                if (!p) {
                                    return undefined;
                                }

                                return (
                                    <ProductCard
                                        key={p.id}
                                        link={!!sectorId && !!subSectorId && solutionId ?
                                            getRoute(
                                                RoutePathName.productFromSolution,
                                                {
                                                    sectorId,
                                                    subSectorId,
                                                    solutionId,
                                                    id: p.id,
                                                },
                                                Object.keys(filters).length ?
                                                    { filters: JSON.stringify(filters) } :
                                                    undefined,
                                            ) :
                                            getRoute(
                                                RoutePathName.product,
                                                { id: p.id },
                                                Object.keys(filters).length ?
                                                    { filters: JSON.stringify(filters) } :
                                                    undefined,
                                            )
                                        }
                                        image={p.images && p.images[0]}
                                        name={t(p.label, locale)}
                                    />
                                );
                            }).filter(Boolean)}
                        </Carousel>
                    </div>
                ))}
            </div>
        </section>
    ) : null;
};

const mapStateToProps = (state: MainReducerState) => ({
    filters: getFilters(state),
});

export default connect(
    mapStateToProps,
)(AssociatedProductGroups);
