import * as reduxTypes from '../types/allData';
import { MainReducerState } from '.';
import { Reducer } from 'redux';
import { persistReducer, PersistState } from 'redux-persist';
import Localforage from 'localforage';

export interface AllDataState {
    readonly dataMayBeStale: boolean;
    readonly error?: any;
    readonly hasCheckedData: boolean;
    readonly hasEnoughData: boolean;
    readonly loading: boolean;
    readonly lastFetchTimestamp?: number;
    readonly response?: any;
    readonly success?: boolean;
    readonly _persist?: PersistState;
}

const allDataInitialState = {
    dataMayBeStale: false,
    hasCheckedData: false,
    hasEnoughData: true,
    loading: false,
};

const allData: Reducer<AllDataState> = (state = allDataInitialState, { data, type }) => {
    switch (type) {
        case reduxTypes.FETCH:
            return {
                ...state,
                loading: true,
                error: undefined,
                success: undefined,
            };

        case reduxTypes.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
            };

        case reduxTypes.FETCH_FAILED:
            return {
                ...state,
                loading: false,
                error: data && data.response && data.response.data ?
                    data.response.data :
                    true,
                response: data,
                success: false,
            };

        case reduxTypes.CHECK_DATA_SUCCESS:
            return {
                ...state,
                hasCheckedData: true,
                dataMayBeStale: data.dataMayBeStale,
                hasEnoughData: data.hasEnoughData,
                lastFetchTimestamp: data.lastFetchTimestamp,
            };

        default:
            return state;
    }
};

export default persistReducer({
    key: 'allData',
    storage: Localforage,
    whitelist: ['lastFetchTimestamp'],
    serialize: false,
    debug: true,
}, allData);

export const getAllDataState = (state: MainReducerState) => state.allData;
