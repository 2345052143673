import React, { useCallback, useContext } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { Collapse, Slider, Button, Tag, Spin } from 'antd';
import cloneDeep from 'lodash/cloneDeep';

import * as FiltersActions from '../../store/actions/filters';
import { MergedFilters } from '../../store/reducers/products';

import { IconPlus, IconMinus, IconTrash } from '../../components/icons';
import { FiltersState } from '../../store/reducers/filters';
import ProductsMessages from './Products.messages';
import PropertyCollapse from './PropertyCollapse';
import { SectorColorContext } from '../../components/SectorColorContextProvider';
import { scrollToElement, t } from '../../utils';
import { Sector, Argument } from '../../store/api/types';

const removeFromFilters = (filters: FiltersState, valueId: number) => {
    const newFilters = cloneDeep(filters);
    const filteredProperties = newFilters.properties!.filter((property) =>
        property.valueId !== valueId,
    );

    if (filteredProperties && filteredProperties.length) {
        newFilters.properties = filteredProperties;
    } else {
        delete newFilters.properties;
    }

    if (!Object.keys(newFilters)) {
        return undefined;
    }

    return newFilters;
};

const scrollToTopOfList = () => {
    const element = document.getElementById('product-search-results');

    scrollToElement(element, 100);
};

interface ProductsSidebarProps {
    args?: MergedFilters['arguments'];
    filters: FiltersState;
    properties?: MergedFilters['properties'];
    setFilters: typeof FiltersActions.setFilters;
    solution?: Sector;
}

const ProductsSidebar: React.FC<ProductsSidebarProps> = ({
    args, filters, properties, setFilters, solution,
}) => {
    const { locale } = useIntl();
    const history = useHistory();
    const location = useLocation();
    const { color } = useContext(SectorColorContext);
    const onEmptyFilters = useCallback(() => {
        setFilters(undefined);
        history.push(location.pathname);
    }, [history, location.pathname, setFilters]);
    const CollapseExpandIcon = useCallback(({ isActive }: { isActive?: boolean }) =>
        isActive ?
            <IconMinus style={color ? { color } : undefined} /> :
            <IconPlus style={color ? { color } : undefined} />
    , [color]);
    const onRemoveFilter = useCallback((valueId) => {
        const newFilters = removeFromFilters(filters, valueId);
        const urlParams = new URLSearchParams({ filters: JSON.stringify(newFilters) });

        setFilters(newFilters);
        scrollToTopOfList();

        const search = newFilters ? urlParams.toString() : '';

        history.push({
            pathname: location.pathname,
            search,
        });
    }, [filters, history, location.pathname, setFilters]);
    const onChangeProperty = useCallback((e) => {
        const checked = e.target.checked;
        const data = e.target['data-id'];
        const propertyId = parseInt(data.split(':')[0], 10);
        const valueId = parseInt(data.split(':')[1], 10);
        let newFilters: FiltersState | undefined = cloneDeep(filters);

        if (!checked) {
            newFilters = removeFromFilters(filters, valueId);
        } else if (newFilters.properties) {
            newFilters.properties.push({ id: propertyId, valueId });
        } else {
            newFilters.properties = [{ id: propertyId, valueId }];
        }

        scrollToTopOfList();

        setFilters(newFilters);

        const urlParams = new URLSearchParams({ filters: JSON.stringify(newFilters) });
        const search = newFilters ? urlParams.toString() : '';

        history.push({
            pathname: location.pathname,
            search,
        });
    }, [filters, history, location.pathname, setFilters]);

    const onChangeArgument = (argumentId: number, argument: Argument, value: any) => {
        const newFilters = cloneDeep(filters);
        if (!newFilters.arguments) {
            newFilters.arguments = [{ id: argumentId, rating: value }];
        } else {
            newFilters.arguments = [
                ...newFilters.arguments.filter((arg) => arg.id !== argumentId),
                { id: argumentId, rating: value },
            ];
        }

        if (newFilters.arguments && !newFilters.arguments.length) {
            delete newFilters.arguments;
        }

        setFilters(newFilters);

        ReactGA.event({
            category: 'filtres vos motivations par besoin & solution',
            action: argument.name.fr ? argument.name.fr : t(argument.name, locale),
            label: solution ? `${solution.id}` : '',
        });

        const urlParams = new URLSearchParams({ filters: JSON.stringify(newFilters) });

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };

    return (
        <aside>
            {filters.properties && !!filters.properties.length && (
                <section id="current-filters" className="m-b-24">
                    <header>
                        <FormattedMessage {...ProductsMessages.activeFilters} tagName="p" />
                        <Button
                            type="link"
                            onClick={onEmptyFilters}
                        >
                            <IconTrash />
                            <FormattedMessage {...ProductsMessages.clear} />
                        </Button>
                    </header>
                    <div>
                        {filters.properties.map((filterProperty, index) => {
                            let name = '';
                            let val = '';

                            if (properties) {
                                let prop = properties[filterProperty.id];

                                if (!prop) {
                                    Object.keys(properties).forEach((property) => {
                                        if (properties[parseInt(property, 10)].children) {
                                            prop = properties[parseInt(property, 10)].children![filterProperty.id] ||
                                                prop;
                                        }
                                    });
                                }

                                if (prop && prop.valuesById) {
                                    const v = prop.valuesById[filterProperty.valueId];
                                    name = prop.name ? t(prop.name, locale) : '';

                                    if (v) {
                                        val = t(v.name, locale);
                                    }
                                }
                            }

                            return (
                                <Tag
                                    closable
                                    onClose={onRemoveFilter.bind(null, filterProperty.valueId)}
                                    key={`${filterProperty.id}${filterProperty.valueId}${index}`}
                                >
                                    {`${name} : ${val}`}
                                </Tag>
                            );
                        })}
                    </div>
                </section>
            )}
            {args && !!Object.keys(args).length && (
                <div className="m-b-24">
                    <Collapse
                        bordered={false}
                        defaultActiveKey={['1']}
                        expandIcon={CollapseExpandIcon}
                        expandIconPosition="right"
                    >
                        <Collapse.Panel
                            header={<FormattedMessage {...ProductsMessages.motivations} />}
                            key="1"
                        >
                            {Object.keys(args).map((argumentId) => {
                                let value = 1;

                                if (filters.arguments) {
                                    const match = filters.arguments.find((arg) =>
                                        `${arg.id}` === argumentId,
                                    );

                                    if (match) {
                                        value = match.rating;
                                    }
                                }
                                return (
                                    <div className="filter-slider-wrapper" key={argumentId}>
                                        <p>{t(args[parseInt(argumentId, 10)].name, locale)}</p>
                                        <div>
                                            <IconPlus />
                                            <Slider
                                                defaultValue={value}
                                                marks={{...[
                                                    undefined,
                                                    <FormattedMessage key="start" {...ProductsMessages.standard} />,
                                                    ...Array.from({
                                                        length: args[parseInt(argumentId, 10)].maxRating - 2,
                                                    }, () => ''),
                                                    <FormattedMessage key="end" {...ProductsMessages.reinforced} />,
                                                ]}}
                                                min={1}
                                                max={args[parseInt(argumentId, 10)].maxRating}
                                                onAfterChange={onChangeArgument.bind(
                                                    null,
                                                    parseInt(argumentId, 10),
                                                    args[parseInt(argumentId, 10)],
                                                )}
                                                step={null}
                                                tipFormatter={null}
                                            />
                                            <span>
                                                <IconPlus />
                                                <IconPlus />
                                            </span>
                                        </div>
                                    </div>
                                );
                            })}
                        </Collapse.Panel>
                    </Collapse>
                </div>
            )}
            {!args && <Spin spinning />}
            {properties && !!Object.keys(properties).length && (
                <Collapse
                    bordered={false}
                    defaultActiveKey={['1']}
                    expandIcon={CollapseExpandIcon}
                    expandIconPosition="right"
                    className="properties-filters"
                >
                    <Collapse.Panel
                        header={<FormattedMessage {...ProductsMessages.needs} />}
                        key="1"
                    >
                        <Collapse
                            bordered={false}
                            expandIcon={CollapseExpandIcon}
                            expandIconPosition="right"
                            accordion
                        >
                            {Object.keys(properties)
                                .map((propertyId) => properties[parseInt(propertyId, 10)])
                                .filter((property) => {
                                    if (property.children) {
                                        const childrenKeys = property.children ? Object.keys(property.children) : [];

                                        return !!childrenKeys.length && childrenKeys.some((propertyId) => {
                                            const valuesById = property.children![parseInt(propertyId, 10)].valuesById;

                                            return valuesById && Object.keys(valuesById).some((valueId) =>
                                                !!valuesById![valueId].productCount,
                                            );
                                        });
                                    } else {
                                        return property.valuesById && Object.keys(property.valuesById).some((valueId) =>
                                            !!property.valuesById![valueId].productCount,
                                        );
                                    }
                                })
                                .sort((a, b) => (a.order || 0) - (b.order || 0))
                                .map((property, propertyIndex) => (
                                    <PropertyCollapse
                                        filters={filters}
                                        onChange={onChangeProperty}
                                        property={property}
                                        header={
                                            property.name ?
                                                t(property.name!, locale) : ''
                                        }
                                        key={`${property.id}${propertyIndex}`}
                                    />
                                ))
                            }
                        </Collapse>
                    </Collapse.Panel>
                </Collapse>
            )}
        </aside>
    );
};

export default connect(
    undefined,
    {
        setFilters: FiltersActions.setFilters,
    },
)(ProductsSidebar);
