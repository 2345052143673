import React, { KeyboardEvent } from 'react';
import { FormattedMessage, IntlShape, injectIntl, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Drawer, Select, Input } from 'antd';
import Form, { FormComponentProps, FormProps } from 'antd/lib/form';
import { DrawerProps } from 'antd/lib/drawer';
import uuid from 'uuid/v4'; // eslint-disable-line

import '../../assets/styles/UserMenu.less';

import * as ClientsActions from '../../store/actions/clients';
import { MainReducerState } from '../../store/reducers';
import { Client, Sector } from '../../store/api/types';
import { getClientByReference } from '../../store/reducers/clients';

import messages from './UserMenu.messages';
import { IconArrow } from '../icons';
import EmptyResult from '../EmptyResult';
import FormMessages from '../../locale/Form.messages';
import LoginMessages from '../../pages/login/Login.messages';
import { getSectorsAndSubSectorsByMarketId } from '../../store/reducers/sectors';
import { getUser } from '../../store/reducers/auth';
import { t } from '../../utils';
import { SelectProps } from 'antd/lib/select';
import { ButtonProps } from 'antd/lib/button';

function hasErrors(fieldsError: Record<string, string[] | undefined>) {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

interface ClientFormDrawerProps extends DrawerProps, FormComponentProps {
    intl: IntlShape;
    clientReference?: Client['reference'];
    client?: Client;
    create: typeof ClientsActions.create;
    update: typeof ClientsActions.update;
    sectors: Sector[];
}

const ClientFormDrawer: React.FC<ClientFormDrawerProps> = ({
    client, create, form, intl, onClose, sectors, update, visible, zIndex,
}) => {
    const { locale } = useIntl();
    const { getFieldDecorator, getFieldsError } = form;
    const isEditing = client !== undefined;
    const onBack: ButtonProps['onClick'] = () => {
        if (typeof onClose === 'function') {
            form.resetFields();
            onClose({} as KeyboardEvent<HTMLDivElement>);
        }
    };
    const filterSectors: SelectProps['filterOption'] = (input, option) =>
        `${option.props.children || ''}`.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    const onSubmit: FormProps['onSubmit'] = (e) => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            if (isEditing) {
                update({
                    ...client,
                    ...values,
                    profileId: undefined,
                    profile: undefined,
                    sector: undefined,
                    userId: undefined,
                });
            } else {
                const ref = uuid();
                create({
                    ...values,
                    id: ref,
                    reference: ref,
                });
            }

            if (typeof onClose === 'function') {
                onClose({} as KeyboardEvent<HTMLDivElement>);
            }
        });
    };
    const drawerTitle = isEditing ? { ...messages.editClient } : { ...messages.addClient };
    const submitButton = isEditing ? { ...FormMessages.validateChanges } : { ...messages.createClient };

    return (
        <Drawer
            title={(
                <div className="drawer-header-with-back-button">
                    <Button
                        type="link"
                        onClick={onBack}
                    >
                        <IconArrow direction="left" />
                    </Button>
                    <FormattedMessage {...drawerTitle} tagName="span" />
                </div>
            )}
            placement="right"
            width={385}
            maskStyle={{display: 'none'}}
            closable={false}
            visible={visible}
            zIndex={zIndex}
        >
            <div id="client-form">
                <section>
                    <Form onSubmit={onSubmit}>
                        <Form.Item label={intl.formatMessage(messages.companyLabel)}>
                            {getFieldDecorator('company', {
                                rules: [{
                                    required: true,
                                    message: intl.formatMessage(FormMessages.requiredError),
                                }],
                                initialValue: client ? client.company : null,
                            })((
                                <Input
                                    placeholder={intl.formatMessage(messages.companyPlaceholder)}
                                />
                            ))}
                        </Form.Item>
                        <Form.Item label={intl.formatMessage(messages.lastNameLabel)}>
                            {getFieldDecorator('lastName', {
                                rules: [{
                                    required: true,
                                    message: intl.formatMessage(FormMessages.requiredError),
                                }],
                                initialValue: client ? client.lastName : null,
                            })((
                                <Input
                                    placeholder={intl.formatMessage(messages.lastNamePlaceholder)}
                                />
                            ))}
                        </Form.Item>
                        <Form.Item label={intl.formatMessage(messages.firstNameLabel)}>
                            {getFieldDecorator('firstName', {
                                rules: [{
                                    required: true,
                                    message: intl.formatMessage(FormMessages.requiredError),
                                }],
                                initialValue: client ? client.firstName : null,
                            })((
                                <Input
                                    placeholder={intl.formatMessage(messages.firstNamePlaceholder)}
                                />
                            ))}
                        </Form.Item>
                        <Form.Item label={intl.formatMessage(LoginMessages.emailLabel)}>
                            {getFieldDecorator('email', {
                                rules: [{
                                    required: true,
                                    type: 'email',
                                    message: intl.formatMessage(LoginMessages.emailError),
                                }],
                                initialValue: client ? client.email : null,
                                validateTrigger: 'onBlur',
                            })((
                                <Input
                                    placeholder={intl.formatMessage(LoginMessages.emailPlaceholder)}
                                    type="email"
                                />
                            ))}
                        </Form.Item>
                        <Form.Item label={intl.formatMessage(messages.sectorLabel)}>
                            {getFieldDecorator('sectorId', {
                                rules: [{
                                    required: true,
                                    message: intl.formatMessage(FormMessages.requiredError),
                                }],
                                initialValue: client ? client.sectorId : undefined,
                            })((
                                <Select
                                    placeholder={intl.formatMessage(FormMessages.selectPlaceholder)}
                                    notFoundContent={<EmptyResult />}
                                    filterOption={filterSectors}
                                    showSearch
                                >
                                    {sectors.map((sector) => (
                                        <Select.Option
                                            value={sector.id}
                                            key={sector.id}
                                        >
                                            {t(sector.name, locale)}
                                        </Select.Option>
                                    ))}
                                </Select>
                            ))}
                        </Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={hasErrors(getFieldsError())}
                            block
                        >
                            <FormattedMessage {...submitButton} />
                        </Button>
                    </Form>
                </section>
            </div>
        </Drawer>
    );
};

const ClientFormDrawerForm = Form.create<ClientFormDrawerProps>()(ClientFormDrawer);

const mapStateToProps = (state: MainReducerState, { clientReference }: { clientReference?: Client['reference'] }) => ({
    client: getClientByReference(state, clientReference !== undefined ? clientReference : '-1'),
    sectors: getSectorsAndSubSectorsByMarketId(state, (getUser(state) || { marketId: -1 }).marketId),
});

export default connect(
    mapStateToProps,
    {
        create: ClientsActions.create,
        update: ClientsActions.update,
    },
)(injectIntl(ClientFormDrawerForm));
