import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import { injectIntl, FormattedMessage, IntlShape, useIntl } from 'react-intl';
import ReactGA from 'react-ga';
import { FormComponentProps, FormProps } from 'antd/lib/form/Form';
import {
    Form, Input, Button, Row, Col, Select, Divider,
} from 'antd';
import { SelectProps, SelectValue } from 'antd/lib/select';

import '../../assets/styles/Login.less';

import { MainReducerState } from '../../store/reducers';
import * as AuthActions from '../../store/actions/auth';
import * as SectorsActions from '../../store/actions/sectors';
import * as ProfilesActions from '../../store/actions/profiles';
import { getAuth, AuthState } from '../../store/reducers/auth';
import { getProfilesState, ProfilesState } from '../../store/reducers/profiles';
import { getMarkets, getSectorsState, SectorsState } from '../../store/reducers/sectors';
import { Sector } from '../../store/api/types';

import { IconLogo, IconLogoText, IconLoginShape } from '../../components/icons';
import FormMessages from '../../locale/Form.messages';
import GenericMessages from '../../locale/Generic.messages';
import LoginMessages from '../login/Login.messages';
import messages from './Register.messages';
import { RoutePathName } from '../../routes';
import EmptyResult from '../../components/EmptyResult';
import ButtonLink from '../../components/ButtonLink';
import UserMenuMessages from '../../components/userMenu/UserMenu.messages';
import { t } from '../../utils';
import { usePrevious } from '../../hooks';

interface RegisterProps extends RouteComponentProps, FormComponentProps {
    auth: AuthState;
    fetchMarkets: typeof SectorsActions.listMarkets;
    fetchProfiles: typeof ProfilesActions.list;
    intl: IntlShape;
    markets: Sector[];
    profiles: ProfilesState;
    register: typeof AuthActions.register;
    sectorsState: SectorsState;
}

const Register: React.FC<RegisterProps> = ({
    auth, fetchMarkets, fetchProfiles, form, intl, markets, profiles, register, sectorsState,
}) => {
    const { locale } = useIntl();
    const previousMarkets = usePrevious({ markets });
    const [selectedMarket, setSelectedMarket] = useState<Sector | undefined>(
        markets.length ?
            markets[0] :
            undefined,
    );
    const onRegister: FormProps['onSubmit'] = (e) => {
        e.preventDefault();
        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            ReactGA.event({
                category: 'inscription',
                action: 'valider l\'inscription',
            });

            register(values);
        });
    };
    const onChangeSelect = (type: string, data: any, value: SelectValue) => {
        const item = data.find((i: any) => i.id === value);

        ReactGA.event({
            category: 'inscription',
            action: type,
            label: item ? item.id : '',
        });
    };
    const { getFieldDecorator } = form;
    let error = auth.registerError ? <FormattedMessage {...GenericMessages.error} /> : null;

    if (auth.registerError && auth.registerError.status && auth.registerError.status === 401) {
        error = <FormattedMessage {...LoginMessages.invalidCredentials} />;
    }

    if (auth.registerError && auth.registerError.status && auth.registerError.status === 409) {
        error = <FormattedMessage {...messages.emailAlreadyExists} />;
    }

    useEffect(() => {
        fetchMarkets();
        fetchProfiles();
    }, [fetchMarkets, fetchProfiles]);

    useEffect(() => {
        if (previousMarkets && !previousMarkets.markets.length && markets.length) {
            setSelectedMarket(markets[0]);
        }
    }, [markets]); // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeMarket: SelectProps['onChange'] = (value) => {
        const newMarket = markets.find((m) => m.id === value);
        ReactGA.event({
            category: 'inscription',
            action: 'marché',
            label: newMarket ? `${newMarket.id}` : '',
        });
        setSelectedMarket(newMarket);
    };

    return (
        <Row id="login-layout" className="register">
            <Col xs={24} lg={10} className="login-left">
                <div id="logo-wrapper">
                    <IconLogo />
                    <IconLogoText />
                </div>
                <Form onSubmit={onRegister} className={`login-form${auth.registerSuccess ? ' register-success' : ''}`}>
                    {auth.registerSuccess ? (
                        <>
                            <FormattedMessage {...messages.successTitle} tagName="h1" />
                            <Divider style={{ width: 56, minWidth: 56 }} />
                            <FormattedMessage
                                {...messages.successDescription}
                                values={{
                                    break: () => <><br /><br /></>,
                                    span: (...message: string[]) => <small>{message}</small>,
                                }}
                                tagName="p"
                            />
                            <ButtonLink
                                size="large"
                                to={RoutePathName.login}
                                block
                            >
                                <FormattedMessage {...LoginMessages.loginButton} />
                            </ButtonLink>
                        </>
                    ) : (
                        <>
                            <FormattedMessage {...LoginMessages.welcome} tagName="h1" />
                            <Divider style={{ width: 56, minWidth: 56 }} />
                            <FormattedMessage
                                {...messages.description}
                                values={{
                                    span: (...message: string[]) => <span>{message}</span>,
                                    a: (...message: string[]) => <Link to={RoutePathName.login}>{message}</Link>,
                                }}
                                tagName="p"
                            />
                            <Form.Item label={intl.formatMessage(UserMenuMessages.lastNameLabel)}>
                                {getFieldDecorator('lastName', {
                                    rules: [{
                                        required: true,
                                        message: intl.formatMessage(FormMessages.requiredError),
                                    }],
                                })((
                                    <Input
                                        placeholder={intl.formatMessage(UserMenuMessages.lastNamePlaceholder)}
                                    />
                                ))}
                            </Form.Item>
                            <Form.Item label={intl.formatMessage(UserMenuMessages.firstNameLabel)}>
                                {getFieldDecorator('firstName', {
                                    rules: [{
                                        required: true,
                                        message: intl.formatMessage(FormMessages.requiredError),
                                    }],
                                })((
                                    <Input
                                        placeholder={intl.formatMessage(UserMenuMessages.firstNamePlaceholder)}
                                    />
                                ))}
                            </Form.Item>
                            <Form.Item label={intl.formatMessage(LoginMessages.emailLabel)}>
                                {getFieldDecorator('email', {
                                    rules: [{
                                        required: true,
                                        type: 'email',
                                        message: intl.formatMessage(LoginMessages.emailError),
                                    }],
                                    validateTrigger: 'onBlur',
                                })((
                                    <Input
                                        placeholder={intl.formatMessage(LoginMessages.emailPlaceholder)}
                                        type="email"
                                    />
                                ))}
                            </Form.Item>
                            <Form.Item label={intl.formatMessage(messages.profileLabel)}>
                                {getFieldDecorator('profileId', {
                                    rules: [{
                                        required: true,
                                        message: intl.formatMessage(FormMessages.requiredError),
                                    }],
                                })((
                                    <Select
                                        placeholder={intl.formatMessage(FormMessages.selectPlaceholder)}
                                        notFoundContent={<EmptyResult />}
                                        onChange={onChangeSelect.bind(null, 'profil', profiles.data)}
                                        loading={profiles.loading}
                                        filterOption={false}
                                    >
                                        {profiles.data && profiles.data.map((profile) => (
                                            <Select.Option
                                                value={profile.id}
                                                key={profile.id}
                                            >
                                                {t(profile.name, locale)}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                ))}
                            </Form.Item>
                            <Form.Item label={intl.formatMessage(messages.marketLabel)}>
                                {getFieldDecorator('marketId', {
                                    rules: [{
                                        required: true,
                                        message: intl.formatMessage(FormMessages.requiredError),
                                    }],
                                    initialValue: markets.length ?
                                        markets[0].id :
                                        undefined,
                                })((
                                    <Select
                                        placeholder={intl.formatMessage(FormMessages.selectPlaceholder)}
                                        notFoundContent={<EmptyResult />}
                                        loading={sectorsState.loading}
                                        onChange={onChangeMarket}
                                        filterOption={false}
                                    >
                                        {markets && markets.map((market) => (
                                            <Select.Option
                                                value={market.id}
                                                key={market.id}
                                            >
                                                {t(market.name, locale)}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                ))}
                            </Form.Item>
                            {!!selectedMarket && (
                                <Form.Item label={intl.formatMessage(messages.countryLabel)}>
                                    {getFieldDecorator('countryId', {
                                        rules: [{
                                            required: true,
                                            message: intl.formatMessage(FormMessages.requiredError),
                                        }],
                                        initialValue: selectedMarket && selectedMarket.countries ?
                                            (selectedMarket.countries.find((country) =>
                                                t(country.name, locale) === 'France',
                                            ) || {}).id :
                                            undefined,
                                    })((
                                        <Select
                                            notFoundContent={<EmptyResult />}
                                            onChange={onChangeSelect.bind(null, 'pays', selectedMarket.countries)}
                                            placeholder={intl.formatMessage(FormMessages.selectPlaceholder)}
                                            filterOption={false}
                                        >
                                            {selectedMarket && selectedMarket.countries &&
                                                selectedMarket.countries.map((country) => (
                                                    <Select.Option
                                                        value={country.id}
                                                        key={country.id}
                                                    >
                                                        {t(country.name, locale)}
                                                    </Select.Option>
                                                ))
                                            }
                                        </Select>
                                    ))}
                                </Form.Item>
                            )}
                            {error ? (
                                <div className="login-error has-error">
                                    <span className="ant-form-explain">{error}</span>
                                </div>
                            ) : null}
                            <Button
                                type="primary"
                                htmlType="submit"
                                size="large"
                                loading={auth.loading}
                                block
                                style={{ marginTop: 0 }}
                            >
                                <FormattedMessage {...messages.registerButton} />
                            </Button>
                        </>
                    )}
                </Form>
            </Col>
            <Col xs={24} lg={14} className="login-right">
                <IconLoginShape />
            </Col>
        </Row>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    auth: getAuth(state),
    markets: getMarkets(state),
    profiles: getProfilesState(state),
    sectorsState: getSectorsState(state),
});

const RegisterForm = Form.create()(injectIntl(Register));

export default connect(
    mapStateToProps,
    {
        register: AuthActions.register,
        fetchMarkets: SectorsActions.listMarkets,
        fetchProfiles: ProfilesActions.list,
    },
)(RegisterForm);
