import { combineReducers } from 'redux';

import * as reduxTypes from '../types/presentations';
import { requestReducer } from './_generics';
import { MainReducerState, RequestState } from '.';
import { File, Presentation, Client, PresentationDetails, PresentationType } from '../api/types';

export interface PresentationsState {
    readonly convert: RequestState<PresentationDetails>;
    readonly create: RequestState<Presentation>;
    readonly list: RequestState<Presentation[]>;
    readonly preview: RequestState<File>;
    readonly deletes: RequestState;
    readonly details: RequestState<PresentationDetails>;
    readonly update: RequestState;
}

export default combineReducers<PresentationsState>({
    convert: requestReducer({
        reduxTypes: {
            START: reduxTypes.CONVERT,
            SUCCESS: reduxTypes.CONVERT_SUCCESS,
            FAILED: reduxTypes.CONVERT_FAILED,
        },
    }),
    create: requestReducer({
        reduxTypes: {
            START: reduxTypes.CREATE,
            SUCCESS: reduxTypes.CREATE_SUCCESS,
            FAILED: reduxTypes.CREATE_FAILED,
        },
    }),
    list: requestReducer({
        reduxTypes: {
            START: reduxTypes.LIST,
            SUCCESS: reduxTypes.LIST_SUCCESS,
            FAILED: reduxTypes.LIST_FAILED,
        },
    }),
    preview: requestReducer({
        reduxTypes: {
            START: reduxTypes.PREVIEW,
            SUCCESS: reduxTypes.PREVIEW_SUCCESS,
            FAILED: reduxTypes.PREVIEW_FAILED,
        },
    }),
    deletes: requestReducer({
        reduxTypes: {
            START: reduxTypes.DELETE,
            SUCCESS: reduxTypes.DELETE_SUCCESS,
            FAILED: reduxTypes.DELETE_FAILED,
        },
    }),
    details: requestReducer({
        reduxTypes: {
            START: reduxTypes.DETAILS,
            SUCCESS: reduxTypes.DETAILS_SUCCESS,
            FAILED: reduxTypes.DETAILS_FAILED,
        },
    }),
    update: requestReducer({
        reduxTypes: {
            START: reduxTypes.UPDATE,
            SUCCESS: reduxTypes.UPDATE_SUCCESS,
            FAILED: reduxTypes.UPDATE_FAILED,
        },
    }),
});

export const getPreviewState = (state: MainReducerState) => state.presentations.preview;
export const getCreateState = (state: MainReducerState) => state.presentations.create;
export const getConvertState = (state: MainReducerState) => state.presentations.convert;
export const getListState = (state: MainReducerState) => state.presentations.list;
export const getDeleteState = (state: MainReducerState) => state.presentations.deletes;
export const getDetailsState = (state: MainReducerState) => state.presentations.details;
export const getUpdateState = (state: MainReducerState) => state.presentations.update;
export const getPresentationsByCustomerReference = (
    state: MainReducerState,
    type: PresentationType,
    clientReference?: Client['reference'],
) => {
    if (state.presentations.list.data) {
        if (clientReference) {
            return state.presentations.list.data.filter((presentation) => {
                if (presentation && presentation.customer && presentation.type === type) {
                    return presentation.customer.reference === clientReference;
                }

                return false;
            });
        } else {
            return state.presentations.list.data.filter((p) => p.type === type);
        }
    }

    return [];
};
