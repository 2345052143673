import React, { createContext, useState, FC } from 'react';

interface SelectionBarDrawerContextTypes {
    isClientsDrawerVisible: boolean;
    isSelectedProductsDrawerVisible: boolean;
    setIsClientsDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setIsSelectedProductsDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SelectionBarDrawerContext = createContext<SelectionBarDrawerContextTypes>({
    isSelectedProductsDrawerVisible: false,
    isClientsDrawerVisible: false,
    setIsClientsDrawerVisible: () => undefined,
    setIsSelectedProductsDrawerVisible: () => undefined,
});

export const SelectionBarDrawerContextProvider: FC = ({ children }) => {
    const [isClientsDrawerVisible, setIsClientsDrawerVisible] = useState(false);
    const [isSelectedProductsDrawerVisible, setIsSelectedProductsDrawerVisible] = useState(false);

    const drawerContext = {
        isClientsDrawerVisible,
        isSelectedProductsDrawerVisible,
        setIsClientsDrawerVisible,
        setIsSelectedProductsDrawerVisible,
    };

    return (
        <SelectionBarDrawerContext.Provider value={drawerContext}>
            {children}
        </SelectionBarDrawerContext.Provider>
    );
};
