import { defineMessages } from 'react-intl';

export default defineMessages({
    selectedProductsCount: {
        id: 'selection_bar.products_count',
        defaultMessage: `
            {count, plural,
                =0 {products in your selection}
                one {product in your selection}
                other {products in your selection}
            }
        `,
        description: 'Selection bar text',
    },
    meetingWith: {
        id: 'selection_bar.meeting_with',
        defaultMessage: 'Current meeting with:',
        description: 'Selection bar client meeting text',
    },
    endMeeting: {
        id: 'selection_bar.end_meeting',
        defaultMessage: 'End meeting',
        description: 'Selection bar end meeting button text',
    },
    meetingSaved: {
        id: 'selection_bar.meeting_saved',
        defaultMessage: 'Meeting successfully saved',
        description: 'Selection bar end meeting success message',
    },
    productsDrawerTitle: {
        id: 'selection_bar.products_drawer.title',
        defaultMessage: 'My selection <b>({count})</b>',
        description: 'Selection bar products drawer title',
    },
    productsDrawerTitleWithClient: {
        id: 'selection_bar.products_drawer.title_with_client',
        defaultMessage: 'Client\'s selection <b>({count})</b>',
        description: 'Selection bar products drawer title when a client has been selected',
    },
    clear: {
        id: 'selection_bar.products_drawer.clear',
        defaultMessage: 'Clear',
        description: 'Selection bar products drawer clear button',
    },
    clearConfirm: {
        id: 'selection_bar.products_drawer.clear_confirm',
        defaultMessage: 'Once cleared, you cannot cancel the operation.',
        description: 'Selection bar products drawer clear popup message',
    },
    save: {
        id: 'selection_bar.products_drawer.save',
        defaultMessage: 'Save',
        description: 'Selection bar products drawer save button',
    },
    chooseClient: {
        id: 'selection_bar.clients_drawer.title',
        defaultMessage: 'Choose a client',
        description: 'Selection bar clients drawer title',
    },
});
