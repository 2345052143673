import { call, takeLatest, put } from 'redux-saga/effects';
import * as profilesActions from '../actions/profiles';
import * as reduxTypes from '../types/profiles';
import * as profilesApi from '../api/profiles';

export function* listSaga() {
    try {
        const response = yield call(profilesApi.list);

        return yield put(profilesActions.listSuccess(response.results));
    } catch (error) {
        return yield put(profilesActions.listFailed(error));
    }
}

export default function* profilesSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
}
