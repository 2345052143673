import { combineReducers } from 'redux';
import * as reduxTypes from '../types/selections';
import { MainReducerState, RequestState } from '.';
import { Selection, Client, Product, File } from '../api/types';
import { requestReducer, requestReducerInitialState } from './_generics';

export type SelectionsHistoryState = RequestState<Selection[]>;

export type SelectionsCurrentState = RequestState<Selection>;

export interface SelectionsState {
    readonly history: SelectionsHistoryState;
    readonly current: SelectionsCurrentState;
    readonly generatePdf: RequestState<File>;
}

export default combineReducers<SelectionsState>({
    history: requestReducer({
        reduxTypes: {
            START: reduxTypes.LIST,
            SUCCESS: reduxTypes.LIST_SUCCESS,
            FAILED: reduxTypes.LIST_FAILED,
        },
        customReducer: (state = requestReducerInitialState, { type, data, payload }) => {
            switch (type) {
                // VALIDATE CURRENT SELECTION
                //
                case reduxTypes.VALIDATE_CURRENT:
                    return {
                        ...state,
                        loading: false,
                        data: [
                            ...state.data,
                            {
                                ...data,
                                id: -(data.id + 1),
                                updateAt: (new Date()).toISOString(),
                                isValidated: true,
                            },
                        ],
                        success: false,
                    };

                case reduxTypes.VALIDATE_CURRENT_SUCCESS:
                    return {
                        ...state,
                        loading: false,
                        data: state.data ? [
                            ...state.data.filter((selection) => selection.id !== -(data.id + 1)),
                            {
                                ...payload,
                            },
                        ] : state.data,
                        success: false,
                    };

                case reduxTypes.VALIDATE_CURRENT_ROLLBACK:
                    return {
                        ...state,
                        loading: false,
                        data: state.data ? [
                            ...state.data.filter((selection) => selection.id !== -(data.id + 1)),
                        ] : [],
                        success: false,
                    };

                // DELETE SELECTION
                //
                case reduxTypes.DELETE_SELECTION:
                    return {
                        ...state,
                        loading: false,
                        data: state.data ?
                            state.data.filter((selection) => selection.id !== data.id) :
                            state.data,
                        rollback: state.data,
                        success: false,
                    };

                case reduxTypes.DELETE_SELECTION_ROLLBACK:
                    return {
                        ...state,
                        loading: false,
                        data: state.data ? [
                            ...state.data,
                            data,
                        ] : [],
                        success: false,
                    };

                default:
                    return state;
            }
        },
    }),
    current: requestReducer({
        reduxTypes: {
            START: reduxTypes.FETCH_CURRENT,
            SUCCESS: reduxTypes.FETCH_CURRENT_SUCCESS,
            FAILED: reduxTypes.FETCH_CURRENT_FAILED,
        },
        customReducer: (state = requestReducerInitialState, { type, data }) => {
            switch (type) {
                // CLEAR PRODUCTS FROM CURRENT SELECTION
                //
                case reduxTypes.CLEAR_PRODUCTS_CURRENT:
                    return {
                        ...state,
                        loading: false,
                        data: {
                            ...state.data,
                            products: [],
                            rollback: state.data,
                        } as Selection,
                        success: false,
                    };

                case reduxTypes.CLEAR_PRODUCTS_CURRENT_ROLLBACK: {
                    return {
                        ...state,
                        loading: false,
                        data: state.data ? {
                            ...state.data.rollback,
                        } as Selection : state.data,
                        success: false,
                    };
                }

                // VALIDATE CURRENT SELECTION
                //
                case reduxTypes.VALIDATE_CURRENT:
                    return {
                        ...state,
                        loading: false,
                        data: {
                            ...state.data,
                            id: -data.id + 1,
                            customerId: null,
                            customer: null,
                            products: [],
                            isValidated: false,
                            updateAt: '',
                            userId: state.data ? state.data.userId : -1,
                            rollback: {
                                ...data,
                            },
                        },
                        success: false,
                    };

                case reduxTypes.VALIDATE_CURRENT_ROLLBACK:
                    return {
                        ...state,
                        loading: false,
                        data: state.data ? {
                            ...state.data.rollback,
                        } as Selection : state.data,
                        success: false,
                    };

                // SET CURRENT SELECTION CLIENT
                //
                case reduxTypes.SET_CLIENT_CURRENT:
                    return {
                        ...state,
                        loading: false,
                        data: {
                            ...state.data,
                            customer: data,
                            customerId: data.id,
                            rollback: state.data,
                        } as Selection,
                        success: false,
                    };

                case reduxTypes.SET_CLIENT_CURRENT_ROLLBACK:
                    return {
                        ...state,
                        loading: false,
                        data: state.data ? {
                            ...state.data.rollback,
                        } as Selection : state.data,
                        success: false,
                    };

                // ADD PRODUCT TO CURRENT SELECTION
                //
                case reduxTypes.ADD_PRODUCT_CURRENT:
                    return {
                        ...state,
                        loading: false,
                        data: {
                            ...state.data,
                            products: state.data ? [
                                ...state.data.products,
                                data,
                            ] : [],
                            rollback: state.data,
                        } as Selection,
                        success: false,
                    };

                case reduxTypes.ADD_PRODUCT_CURRENT_ROLLBACK:
                    return {
                        ...state,
                        loading: false,
                        data: state.data ? {
                            ...state.data.rollback,
                        } as Selection : state.data,
                        success: false,
                    };

                // REMOVE PRODUCT TO CURRENT SELECTION
                //
                case reduxTypes.REMOVE_PRODUCT_CURRENT:
                    return {
                        ...state,
                        loading: false,
                        data: {
                            ...state.data,
                            products: state.data ?
                                state.data.products.filter((p) => p.id !== data.id) :
                                [],
                            rollback: state.data,
                        } as Selection,
                        success: false,
                    };

                case reduxTypes.REMOVE_PRODUCT_CURRENT_ROLLBACK:
                    return {
                        ...state,
                        loading: false,
                        data: state.data ? {
                            ...state.data.rollback,
                        } as Selection : state.data,
                        success: false,
                    };

                default:
                    return state;
            }
        },
    }),
    generatePdf: requestReducer({
        reduxTypes: {
            START: reduxTypes.GENERATE_PDF,
            SUCCESS: reduxTypes.GENERATE_PDF_SUCCESS,
            FAILED: reduxTypes.GENERATE_PDF_FAILED,
        },
    }),
});

export const getSelectionsState = (state: MainReducerState) => state.selections;

export const getCurrentSelection = (state: MainReducerState) =>
    state.selections.current.data;

export const getGeneratePdfState = (state: MainReducerState) =>
    state.selections.generatePdf;

export const getSelections = (state: MainReducerState) => state.selections.history.data || [];

export const getSelectionsByCustomerReference = (state: MainReducerState, clientReference: Client['reference']) => {
    if (state.selections.history.data) {
        return state.selections.history.data.filter((selection) => {
            if (selection && selection.customer) {
                return selection.customer.reference === clientReference;
            }

            return false;
        });
    }

    return [];
};

export const getSelectionById = (state: MainReducerState, id: Selection['id']) =>
    state.selections.history.data ?
        state.selections.history.data.find((selection) => selection.id === id) :
        undefined;

export const isProductInSelection = (state: MainReducerState, productId: Product['id']) =>
    state.selections.current.data ?
        state.selections.current.data.products.some((product) => product.id === productId) :
        false;
