import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="75" height="70" viewBox="0 0 75 70">
        <path fill="currentColor" fillRule="evenodd" d="M19.223 37.203C18.09 45.823 23.175 54.05 31.397 56.9c.426-.884.754-1.81.977-2.763a15.62 15.62 0 0 1-10.347-14.59 15.89 15.89 0 0 1 .127-1.953 13.49 13.49 0 0 0-2.93-.39m23.39 16.96c.222.955.55 1.882.977 2.763a18.544 18.544 0 0 0 12.497-17.38 18.448 18.448 0 0 0-.156-2.323c-.985.015-1.968.133-2.93.35.09.647.135 1.3.136 1.952a15.617 15.617 0 0 1-10.522 14.64M27.284 27.99c5.85-5.255 14.716-5.288 20.606-.08a14.3 14.3 0 0 0 1.71-2.38 18.406 18.406 0 0 0-24.055.088c.49.85 1.075 1.645 1.74 2.372m44.534 33.723a.977.977 0 0 1-1.625-1.084c4.436-6.626 3.628-15.594-1.92-21.322a16.955 16.955 0 0 0-11.98-5.188c-4.555-.1-8.867 1.624-12.14 4.79-.04.036-.085.05-.126.08-.03.04-.045.086-.08.122-6.553 6.748-6.39 17.563.366 24.11 5.753 5.574 14.755 6.378 21.408 1.913a.978.978 0 0 1 1.355.266.973.973 0 0 1-.267 1.355 18.96 18.96 0 0 1-10.585 3.206c-4.83 0-9.635-1.81-13.272-5.336a18.872 18.872 0 0 1-5.43-10.14 18.86 18.86 0 0 1-5.384 10.124C28.494 68.168 23.677 70 18.832 70a18.96 18.96 0 0 1-10.64-3.247.974.974 0 0 1-.26-1.355.978.978 0 0 1 1.356-.26c6.68 4.517 15.717 3.707 21.488-1.926 6.726-6.568 6.85-17.377.274-24.097-.04-.04-.056-.09-.088-.135-.034-.025-.073-.037-.104-.067-3.293-3.15-7.638-4.808-12.174-4.734a16.972 16.972 0 0 0-11.96 5.25C1.17 45.226.385 53.945 4.817 60.636a.975.975 0 0 1-.814 1.515.978.978 0 0 1-.816-.44c-4.94-7.455-4.064-17.172 2.128-23.63a18.91 18.91 0 0 1 13.328-5.852 19.08 19.08 0 0 1 6.93 1.154c-4.35-3.54-7.11-8.942-7.033-14.965C18.655 9.463 25.126 1.725 33.927.02a.976.976 0 1 1 .372 1.915c-7.895 1.53-13.702 8.473-13.807 16.506-.12 9.396 7.432 17.14 16.838 17.26.052.002.096.023.144.032.05-.01.093-.03.144-.03a16.96 16.96 0 0 0 11.994-5.153 16.916 16.916 0 0 0 4.834-12.116c-.108-8.03-5.913-14.97-13.806-16.5A.975.975 0 1 1 41.012.02C49.81 1.726 56.28 9.46 56.4 18.41a18.855 18.855 0 0 1-6.978 14.95c6.853-2.522 14.862-.978 20.258 4.594 6.184 6.387 7.083 16.38 2.14 23.765" />
    </svg>
);

const IconDasri: React.FC<IconProps> = (props) => (
    <Icon component={svg} {...props} className={`anticon icon-dasri${props.className ? ` ${props.className}` : ''}`} />
);

export default IconDasri;
