import { defineMessages } from 'react-intl';

const messages = defineMessages({
    titlePresentation: {
        id: 'presentation.title_presentation',
        defaultMessage: 'Edit a sales presentation',
        description: 'Presentation page title',
    },
    titleProposition: {
        id: 'presentation.title_proposition',
        defaultMessage: 'Edit a sales proposition',
        description: 'Proposition page title',
    },
    previewButton: {
        id: 'presentation.chapters.button.preview',
        defaultMessage: 'Preview selection',
        description: 'Presentation page chapters preview button',
    },
    customizePresentationButton: {
        id: 'presentation.chapters.button.presentation_customize',
        defaultMessage: 'Customize presentation',
        description: 'Presentation page chapters customize button',
    },
    customizePropositionButton: {
        id: 'presentation.chapters.button.proposition_customize',
        defaultMessage: 'Customize proposition',
        description: 'Presentation page chapters customize button for proposition',
    },
    chooseATheme: {
        id: 'presentation.chapters.themes.list.title',
        defaultMessage: 'Choose a theme',
        description: 'Presentation page chapters themes list title',
    },
    chooseThemeToStart: {
        id: 'presentation.chapters.choose_theme_to_start',
        defaultMessage: 'Start by choosing a theme.',
        description: 'Presentation page startup text',
    },
    amountOfSelectedChapters: {
        id: 'presentation.chapters.amount_of_selected_chapters',
        defaultMessage: `
            {count, plural,
                =0 {No chapter selected}
                one {{count} chapter selected}
                other {{count} chapters selected}
            }
        `,
        description: 'Presentation page when no theme is active but chapters have been selected',
    },
    availableChapters: {
        id: 'presentation.chapters.amount_available',
        defaultMessage: `
            {count, plural,
                =0 {No available chapters}
                one {{count} available chapter for {theme}}
                other {{count} available chapters for {theme}}
            }
        `,
        description: 'Presentation page chapters title',
    },
    seeSubChapters: {
        id: 'presentation.chapter_card.see_sub_chapters',
        defaultMessage: 'See sub-chapters',
        description: 'Presentation page chapter card button label for displaying sub chapters',
    },
    availableSubChapters: {
        id: 'presentation.sub_chapters.amount_available',
        defaultMessage: `
            {count, plural,
                =0 {No available sub-chapters}
                one {{count} available sub-chapter:}
                other {{count} available sub-chapters:}
            }
        `,
        description: 'Presentation page sub-chapters title',
    },
    previewTitle: {
        id: 'presentation.preview.title',
        defaultMessage: 'Preview your selection below:',
        description: 'Presentation preview page title',
    },
    editSelection: {
        id: 'presentation.preview.edit_selection',
        defaultMessage: 'Edit selection',
        description: 'Presentation preview page edit-selection button label',
    },
    editPresentation: {
        id: 'presentation.preview.edit_presentation',
        defaultMessage: 'Edit presentation',
        description: 'Presentation preview page edit-presentation button label',
    },
    validateInformations: {
        id: 'presentation.informations.validate',
        defaultMessage: 'Validate informations',
        description: 'Presentation informations page validate button label',
    },
    informationsPresentationTitle: {
        id: 'presentation.informations.presentation_title',
        defaultMessage: 'Enter a presentation title',
        description: 'Presentation informations page title',
    },
    informationsPropositionTitle: {
        id: 'presentation.informations.proposition_title',
        defaultMessage: 'Enter a proposition title',
        description: 'Presentation informations page title for propositions',
    },
    informationsCustomizeFrontPage: {
        id: 'presentation.informations.customize_front_page',
        defaultMessage: 'Customize front page',
        description: 'Presentation informations customize front page title',
    },
    informationsTitlePlaceholder: {
        id: 'presentation.informations.form.title.placeholder',
        defaultMessage: 'Name your presentation',
        description: 'Presentation informations form title field placeholder',
    },
    importLogo: {
        id: 'presentation.informations.form.logo.label',
        defaultMessage: 'Import a client\'s logo',
        description: 'Presentation informations form client logo label',
    },
    logoUploadInfo: {
        id: 'presentation.informations.form.logo.infos',
        defaultMessage: 'Click here or drop a logo file here to start uploading it',
        description: 'Presentation informations form client logo upload informations',
    },
    logoUploadSmallInfo: {
        id: 'presentation.informations.form.logo.small_infos',
        defaultMessage: 'png or jpg file format, 2MB maximum size',
        description: 'Presentation informations form client logo upload small informations',
    },
    chooseClientLabel: {
        id: 'presentation.informations.form.client.choose',
        defaultMessage: 'Choose between your existing clients',
        description: 'Presentation informations form client select section title',
    },
    newClientLabel: {
        id: 'presentation.informations.form.client.enter',
        defaultMessage: 'Manually enter informations',
        description: 'Presentation informations form client manual section title',
    },
    clientsSelectPlaceholder: {
        id: 'presentation.informations.form.client.select.placeholder',
        defaultMessage: 'Select a client',
        description: 'Presentation informations form client select placeholder',
    },
    noClientMatchingSector: {
        id: 'presentation.informations.form.client.select.no_matching_client',
        defaultMessage: 'No client matches current (sub-)sector',
        description: 'Presentation informations form client select no data',
    },
    imageUploadError: {
        id: 'presentation.informations.form.logo.upload_error',
        defaultMessage: 'An error occured when uploading the file.',
        description: 'Presentation informations form client logo upload error message',
    },
    imageUploadFormatError: {
        id: 'presentation.informations.form.logo.upload_error.format',
        defaultMessage: 'The file must be either of jpg or png format.',
        description: 'Presentation informations form client logo upload format error message',
    },
    imageUploadSizeError: {
        id: 'presentation.informations.form.logo.upload_error.size',
        defaultMessage: 'The file must be equal or lower than 2MB.',
        description: 'Presentation informations form client logo upload size error message',
    },
    presentationTitle: {
        id: 'presentation.customize.presentation_title',
        defaultMessage: 'Presentation title',
        description: 'Presentation customize presentation title',
    },
    propositionTitle: {
        id: 'presentation.customize.proposition_title',
        defaultMessage: 'Proposition title',
        description: 'Presentation customize proposition title',
    },
    customizeTitle: {
        id: 'presentation.customize.title',
        defaultMessage: 'Reorder the selected chapters to your liking.',
        description: 'Presentation customize title',
    },
    exportToProposition: {
        id: 'presentation.customize.export_to_proposition',
        defaultMessage: 'Export chapters to a proposition',
        description: 'Presentation customize export button label',
    },
    validateCustomize: {
        id: 'presentation.customize.validate_button',
        defaultMessage: 'Validate and save',
        description: 'Presentation customize validate button label',
    },
    frontPage: {
        id: 'presentation.customize.front_page',
        defaultMessage: 'Front page',
        description: 'Presentation customize front page card',
    },
    tableOfContents: {
        id: 'presentation.customize.table_of_contents',
        defaultMessage: 'Table of contents',
        description: 'Presentation customize table of contents card',
    },
    addPreamble: {
        id: 'presentation.customize.add_preamble',
        defaultMessage: 'Add a preamble',
        description: 'Presentation customize add preamble card',
    },
    preamble: {
        id: 'presentation.customize.preamble',
        defaultMessage: 'Preamble',
        description: 'Presentation customize preamble card',
    },
    productSelection: {
        id: 'presentation.customize.product_selection',
        defaultMessage: 'Products selection',
        description: 'Presentation customize products selection card',
    },
    pricing: {
        id: 'presentation.customize.pricing',
        defaultMessage: 'Pricing',
        description: 'Presentation customize pricing card',
    },
    page: {
        id: 'presentation.customize.page',
        defaultMessage: 'Ajouter une page',
        description: 'Presentation customize page card',
    },
    preambleHelp: {
        id: 'presentation.customize.preamble.help',
        defaultMessage: 'The text you add below will be added to the preamble page.',
        description: 'Presentation customize preamble modal help text',
    },
    subChaptersModalTitle: {
        id: 'presentation.customize.sub_chapters_modal.title',
        defaultMessage: 'Ordering the sub-chapters',
        description: 'Presentation customize sub chapters modal title',
    },
    productsSelections: {
        id: 'presentation.customize.products_selections_modal.title',
        defaultMessage: 'Products selections',
        description: 'Presentation customize products selections modal title',
    },
    productsSelectionsHelp: {
        id: 'presentation.customize.products_selections_modal.help',
        defaultMessage: 'You can select a products selection by choosing from the saved selections in your account or send a PDF file from your computer.',
        description: 'Presentation customize products selections modal title',
    },
    productsSelectionsLabel: {
        id: 'presentation.customize.products_selections_modal.label',
        defaultMessage: 'Choose among the saved selections',
        description: 'Presentation customize products selections modal title',
    },
    productsSelectionsUploadLabel: {
        id: 'presentation.customize.products_selections_modal.upload_label',
        defaultMessage: 'OR Import a file',
        description: 'Presentation customize products selections modal upload file label',
    },
    productSelectionCard: {
        id: 'presentation.customize.products_selections_card_title',
        defaultMessage: 'Products selection from {date}',
        description: 'Presentation customize products selection card title',
    },
    pdfUploadFormatError: {
        id: 'presentation.customize.form.selection.upload_error.format',
        defaultMessage: 'The file must be in PDF format.',
        description: 'Presentation customize form selection upload format error message',
    },
    pdfUploadSizeError: {
        id: 'presentation.customize.form.selection.upload_error.size',
        defaultMessage: 'The file must be equal or lower than 5MB.',
        description: 'Presentation customize form selection upload size error message',
    },
    pdfUploadError: {
        id: 'presentation.customize.form.selection.upload_error',
        defaultMessage: 'An error occured when uploading the file.',
        description: 'Presentation customize form selection upload error message',
    },
    pdfUploadInfo: {
        id: 'presentation.customize.form.pdf.infos',
        defaultMessage: 'Click here or drop a PDF file here to start uploading it',
        description: 'Presentation customize form client pdf upload informations',
    },
    pdfUploadSmallInfo: {
        id: 'presentation.customize.form.pdf.small_infos',
        defaultMessage: 'PDF file format, 5MB maximum size',
        description: 'Presentation customize form client pdf upload small informations',
    },
    pricingUploadInfo: {
        id: 'presentation.customize.form.pricing.infos',
        defaultMessage: 'Click here or drop an image file here to start uploading it',
        description: 'Presentation customize form pricing upload informations',
    },
    pricingUploadSmallInfo: {
        id: 'presentation.customize.form.pricing.small_infos',
        defaultMessage: 'png or jpg file format, 2MB maximum size',
        description: 'Presentation customize form pricing upload small informations',
    },
    pricingModalTitle: {
        id: 'presentation.customize.pricing_modal.title',
        defaultMessage: 'Pricing',
        description: 'Presentation customize pricing modal title',
    },
    pricingModalHelp: {
        id: 'presentation.customize.pricing_modal.help',
        defaultMessage: 'You can a pricing from an image.',
        description: 'Presentation customize pricing modal help text',
    },
    pageModalTitle: {
        id: 'presentation.customize.page_modal.title',
        defaultMessage: 'Add a page',
        description: 'Presentation customize page modal title',
    },
    pageModalHelp: {
        id: 'presentation.customize.page_modal.help',
        defaultMessage: 'You can a page from an image.',
        description: 'Presentation customize page modal help text',
    },
    convertPresentationModalTitle: {
        id: 'presentation.customize.convert_modal.presentation.title',
        defaultMessage: 'Save your presentation?',
        description: 'Presentation customize convert modal presentation title',
    },
    convertPropositionModalTitle: {
        id: 'presentation.customize.convert_modal.proposition.title',
        defaultMessage: 'Save your proposition?',
        description: 'Proposition customize convert modal proposition title',
    },
    convertPresentationModalSubTitle: {
        id: 'presentation.customize.convert_modal.presentation.subtitle',
        defaultMessage: 'Do you wish to save your presentation before exporting its chapters to a proposition?',
        description: 'Presentation customize convert modal presentation help text',
    },
    convertPropositionModalSubTitle: {
        id: 'presentation.customize.convert_modal.proposition.subtitle',
        defaultMessage: 'Do you wish to save your proposition before exporting its chapters to a presentation?',
        description: 'Proposition customize convert modal proposition help text',
    },
    exportWithoutSaving: {
        id: 'presentation.customize.convert_modal.export_without_saving',
        defaultMessage: 'Export without saving',
        description: 'Proposition customize convert modal button label',
    },
    saveAndExport: {
        id: 'presentation.customize.convert_modal.save_and_export',
        defaultMessage: 'Save and export',
        description: 'Proposition customize convert modal button label',
    },
    successCreatePresentationTitle: {
        id: 'presentation.success.create.presentation_title',
        defaultMessage: 'The presentation named <b>"{title}"</b> has been successfully created and saved.',
        description: 'Presentation create success title for presentations',
    },
    successCreatePropositionTitle: {
        id: 'presentation.success.create.proposition_title',
        defaultMessage: 'The proposition named <b>"{title}"</b> has been successfully created and saved.',
        description: 'Presentation create success title for propositions',
    },
    successUpdatePresentationTitle: {
        id: 'presentation.success.update.presentation_title',
        defaultMessage: 'The presentation named <b>"{title}"</b> has been successfully updated.',
        description: 'Presentation update success title for presentations',
    },
    successUpdatePropositionTitle: {
        id: 'presentation.success.update.proposition_title',
        defaultMessage: 'The proposition named <b>"{title}"</b> has been successfully updated.',
        description: 'Presentation update success title for propositions',
    },
    successPresentationDowload: {
        id: 'presentation.success.presentation_download',
        defaultMessage: 'Download the presentation',
        description: 'Presentation success download button for presentations',
    },
    successPropositionDowload: {
        id: 'presentation.success.proposition_download',
        defaultMessage: 'Download the proposition',
        description: 'Presentation success download button for propositions',
    },
    successLocation: {
        id: 'presentation.success.location_text',
        defaultMessage: 'You can find it anytime in your client file: <b>{client} - {title}</b>',
        description: 'Presentation success help text on where to find the presentation/proposition',
    },
});

export default messages;
