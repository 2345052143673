import { call, takeLatest, put } from 'redux-saga/effects';
import * as presentationsActions from '../actions/presentations';
import * as reduxTypes from '../types/presentations';
import * as presentationsApi from '../api/presentations';
import * as sectorsApi from '../api/sectors';
import * as clientsApi from '../api/clients';
import { DataAction, IdAction, QueryListPayload } from '../actions';
import { PresentationPayload } from '../../components/PresentationContextProvider';

export function* previewSaga(action: DataAction<PresentationPayload>) {
    try {
        if (action.data.logo) {
            delete action.data.logo;
        }
        const response = yield call(presentationsApi.preview, action.data);

        return yield put(presentationsActions.previewSuccess(response));
    } catch (error) {
        return yield put(presentationsActions.previewFailed(error));
    }
}

export function* createSaga(action: DataAction<PresentationPayload>) {
    try {
        if (action.data.logo) {
            delete action.data.logo;
        }
        const response = yield call(presentationsApi.create, action.data);

        return yield put(presentationsActions.createSuccess(response));
    } catch (error) {
        return yield put(presentationsActions.createFailed(error));
    }
}

export function* convertSaga(action: DataAction<PresentationPayload>) {
    try {
        const logo = action.data.logo;

        if (logo) {
            delete action.data.logo;
        }

        const response = yield call(presentationsApi.convert, action.data);

        const sector = yield call(sectorsApi.details, action.data.sectorId || -1);
        const customer = yield call(clientsApi.details, action.data.customerId || -1);

        const result = {
            ...action.data,
            ...response,
            logo: {
                ...logo,
                ...response.logo,
            },
            sector,
            customer,
        };

        return yield put(presentationsActions.convertSuccess(result));
    } catch (error) {
        return yield put(presentationsActions.convertFailed(error));
    }
}

export function* listSaga(action: DataAction<QueryListPayload>) {
    try {
        const response = yield call(presentationsApi.list, action.data);

        return yield put(presentationsActions.listSuccess(response.results));
    } catch (error) {
        return yield put(presentationsActions.listFailed(error));
    }
}

export function* delSaga(action: IdAction) {
    try {
        const response = yield call(presentationsApi.del, action.id);

        return yield put(presentationsActions.delSuccess(response.results));
    } catch (error) {
        return yield put(presentationsActions.delFailed(error));
    }
}

export function* detailsSaga(action: IdAction) {
    try {
        const response = yield call(presentationsApi.details, action.id);

        return yield put(presentationsActions.detailsSuccess(response));
    } catch (error) {
        return yield put(presentationsActions.detailsFailed(error));
    }
}

export function* updateSaga(action: IdAction & DataAction<PresentationPayload>) {
    try {
        const response = yield call(presentationsApi.update, action.id, action.data);

        return yield put(presentationsActions.updateSuccess(response));
    } catch (error) {
        return yield put(presentationsActions.updateFailed(error));
    }
}

export default function* presentationsSaga() {
    yield takeLatest(reduxTypes.CONVERT, convertSaga);
    yield takeLatest(reduxTypes.CREATE, createSaga);
    yield takeLatest(reduxTypes.LIST, listSaga);
    yield takeLatest(reduxTypes.PREVIEW, previewSaga);
    yield takeLatest(reduxTypes.DELETE, delSaga);
    yield takeLatest(reduxTypes.DETAILS, detailsSaga);
    yield takeLatest(reduxTypes.UPDATE, updateSaga);
}
