import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg width="400" height="400" viewBox="0 0 400 400"><path fill="#FFF" d="M400 0v400C400 181.295 224.478 3.586 6.615.054L0 0h400v400V0z" /></svg>
);

const IconLoginShape: React.FC<IconProps> = (props) => (
    <Icon component={svg} {...props} className={`anticon icon-login-shape${props.className ? ` ${props.className}` : ''}`} />
);

export default IconLoginShape;
