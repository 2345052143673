import { defineMessages } from 'react-intl';

export default defineMessages({
    sectorInformations: {
        id: 'sector.commercial_presentation.title',
        defaultMessage: '<b>Sector</b> informations',
        description: 'Sector hero commercial presentation block title',
    },
    tooltipTop: {
        id: 'sector.tooltip.top',
        defaultMessage: 'Select',
        description: 'Sector tooltip top text',
    },
    tooltipBottom: {
        id: 'sector.tooltip.bottom',
        defaultMessage: 'a sub-sector',
        description: 'Sector tooltip bottom text',
    },
    subSectorTooltipBottom: {
        id: 'sub_sector.tooltip.bottom',
        defaultMessage: 'Needs & Solutions',
        description: 'Sub-sector tooltip bottom text',
    },
});
