import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import '../assets/styles/EmptyResult.less';

import ListMessages from '../locale/List.messages';
import { IconPlaceholder } from './icons';

interface EmptyResultProps extends React.HTMLProps<JSX.Element> {
    text?: string | React.ReactNode;
}

const EmptyResult: React.FC<EmptyResultProps> = ({ text }) => (
    <div className="empty-result">
        <IconPlaceholder />
        {text ?
            <p>{text}</p> :
            <FormattedMessage {...ListMessages.noResult} />
        }
    </div>
);

export default EmptyResult;
