import * as reduxTypes from '../types/favorites';
import { MainReducerState, RequestState } from '.';
import { Favorites, Sector, Product } from '../api/types';
import { requestReducer } from './_generics';

export type FavoritesState = RequestState<Favorites>;

export default requestReducer<Favorites>({
    reduxTypes: {
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAILED,
        CREATE_EFFECT: reduxTypes.CREATE,
        CREATE_COMMIT: reduxTypes.CREATE_SUCCESS,
        CREATE_ROLLBACK: reduxTypes.CREATE_ROLLBACK,
        DELETE_EFFECT: reduxTypes.DELETE,
        DELETE_COMMIT: reduxTypes.DELETE_SUCCESS,
        DELETE_ROLLBACK: reduxTypes.DELETE_ROLLBACK,
    },
    createEffect: (state, data) => {
        const type = Object.keys(data)[0] === 'sectorId' ? 'favoritesSectors' : 'favoritesProducts';

        return state ?
            {
                ...state,
                [type]: [
                    ...state[type],
                    data[Object.keys(data)[0]],
                ],
            } as Favorites :
            {
                [type]: [data[Object.keys(data)[0]]],
            } as Favorites;
    },
    createCommit: (state) => state,
    createRollback: (state, data) => {
        const type = Object.keys(data)[0] === 'sectorId' ? 'favoritesSectors' : 'favoritesProducts';

        return state ?
            {
                ...state,
                [type]: [
                    ...state[type].filter((id) => id !== data[Object.keys(data)[0]]),
                ],
            } as Favorites :
            state;
    },
    deleteEffect: (state, data) => {
        const type = Object.keys(data)[0] === 'sectorId' ? 'favoritesSectors' : 'favoritesProducts';

        return state ?
            {
                ...state,
                [type]: [
                    ...state[type].filter((id) => id !== data[Object.keys(data)[0]]),
                ],
            } as Favorites :
            state;
    },
    deleteCommit: (state) => state,
    deleteRollback: (state, data) => {
        const type = Object.keys(data)[0] === 'sectorId' ? 'favoritesSectors' : 'favoritesProducts';

        return state ?
            {
                ...state,
                [type]: [
                    ...state[type],
                    data[Object.keys(data)[0]],
                ],
            } as Favorites :
            {
                [type]: [data[Object.keys(data)[0]]],
            } as Favorites;
    },
});

export const getFavoritesState = (state: MainReducerState) => state.favorites;

export const getFavorites = (state: MainReducerState) => state.favorites.data;

export const getFavoritesCount = (state: MainReducerState) =>
    state.favorites.data ?
        (state.favorites.data.favoritesProducts || []).length +
        (state.favorites.data.favoritesSectors || []).length  :
        0;

export const isSectorFavorite = (state: MainReducerState, sectorId: Sector['id']) =>
    state.favorites.data ? state.favorites.data.favoritesSectors.includes(sectorId) : false;

export const isProductFavorite = (state: MainReducerState, productId: Product['id']) =>
    state.favorites.data ? state.favorites.data.favoritesProducts.includes(productId) : false;
