import { call, put } from 'redux-saga/effects';
import * as favoritesActions from '../actions/favorites';
import * as favoritesApi from '../api/favorites';

export function* listSaga() {
    try {
        const response = yield call(favoritesApi.list);

        return yield put(favoritesActions.listSuccess(response));
    } catch (error) {
        return yield put(favoritesActions.listFailed(error));
    }
}
