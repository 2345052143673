import * as React from 'react';
import { Layout } from 'antd';
import { LayoutProps } from 'antd/lib/layout';

const Content: React.FC<LayoutProps> = ({ children, ...rest }) => (
    <Layout.Content id="content" {...rest}>
        {children}
    </Layout.Content>
);

export default Content;
