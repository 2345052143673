import { Reducer } from 'redux';
import { createSelector } from 'reselect';
import { persistReducer } from 'redux-persist';
import Localforage from 'localforage';

import { isBlob } from '../../utils';
import * as reduxTypes from '../types/images';
import { File } from '../api/types';
import { requestReducer } from './_generics';
import { MainReducerState, RequestState } from '.';

export interface Image {
    [key: number]: Blob;
}

export type ImagesState = RequestState<Image>;

const images: Reducer<ImagesState> = requestReducer({
    reduxTypes: {
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAILED,
    },
});

export default persistReducer({
    key: 'images',
    storage: Localforage,
    serialize: false,
    debug: true,
}, images);

export const getImagesState = (state: MainReducerState) => state.images;

export const getImageBlob = (imagesData: Image | undefined, imageId: File['id']) => {
    if (imagesData && isBlob(imagesData[imageId])) {
        return imagesData[imageId];
    }

    return undefined;
};

export const getImageBlobById = createSelector(
    [
        (state: MainReducerState) => state.images.data,
        (state: MainReducerState, imageId: File['id']) => imageId,
    ],
    getImageBlob,
);
