export const LIST = 'selections/LIST';
export const LIST_SUCCESS = 'selections/LIST_SUCCESS';
export const LIST_FAILED = 'selections/LIST_FAILED';

export const FETCH_CURRENT = 'selections/FETCH_CURRENT';
export const FETCH_CURRENT_SUCCESS = 'selections/FETCH_CURRENT_SUCCESS';
export const FETCH_CURRENT_FAILED = 'selections/FETCH_CURRENT_FAILED';
export const FETCH_CURRENT_ROLLBACK = 'selections/FETCH_CURRENT_ROLLBACK';

export const CLEAR_PRODUCTS_CURRENT = 'selections/CLEAR_PRODUCTS_CURRENT';
export const CLEAR_PRODUCTS_CURRENT_SUCCESS = 'selections/CLEAR_PRODUCTS_CURRENT_SUCCESS';
export const CLEAR_PRODUCTS_CURRENT_FAILED = 'selections/CLEAR_PRODUCTS_CURRENT_FAILED';
export const CLEAR_PRODUCTS_CURRENT_ROLLBACK = 'selections/CLEAR_PRODUCTS_CURRENT_ROLLBACK';

export const VALIDATE_CURRENT = 'selections/VALIDATE_CURRENT';
export const VALIDATE_CURRENT_SUCCESS = 'selections/VALIDATE_CURRENT_SUCCESS';
export const VALIDATE_CURRENT_FAILED = 'selections/VALIDATE_CURRENT_FAILED';
export const VALIDATE_CURRENT_ROLLBACK = 'selections/VALIDATE_CURRENT_ROLLBACK';

export const SET_CLIENT_CURRENT = 'selections/SET_CLIENT_CURRENT';
export const SET_CLIENT_CURRENT_SUCCESS = 'selections/SET_CUSTOMER_CURRENT_SUCCESS';
export const SET_CLIENT_CURRENT_FAILED = 'selections/SET_CLIENT_CURRENT_FAILED';
export const SET_CLIENT_CURRENT_ROLLBACK = 'selections/SET_CLIENT_CURRENT_ROLLBACK';

export const ADD_PRODUCT_CURRENT = 'selections/ADD_PRODUCT_CURRENT';
export const ADD_PRODUCT_CURRENT_SUCCESS = 'selections/ADD_PRODUCT_CURRENT_SUCCESS';
export const ADD_PRODUCT_CURRENT_FAILED = 'selections/ADD_PRODUCT_CURRENT_FAILED';
export const ADD_PRODUCT_CURRENT_ROLLBACK = 'selections/ADD_PRODUCT_CURRENT_ROLLBACK';

export const REMOVE_PRODUCT_CURRENT = 'selections/REMOVE_PRODUCT_CURRENT';
export const REMOVE_PRODUCT_CURRENT_SUCCESS = 'selections/REMOVE_PRODUCT_CURRENT_SUCCESS';
export const REMOVE_PRODUCT_CURRENT_FAILED = 'selections/REMOVE_PRODUCT_CURRENT_FAILED';
export const REMOVE_PRODUCT_CURRENT_ROLLBACK = 'selections/REMOVE_PRODUCT_CURRENT_ROLLBACK';

export const DELETE_SELECTION = 'selections/DELETE_SELECTION';
export const DELETE_SELECTION_SUCCESS = 'selections/DELETE_SELECTION_SUCCESS';
export const DELETE_SELECTION_FAILED = 'selections/DELETE_SELECTION_FAILED';
export const DELETE_SELECTION_ROLLBACK = 'selections/DELETE_SELECTION_ROLLBACK';

export const GENERATE_PDF = 'selections/GENERATE_PDF';
export const GENERATE_PDF_SUCCESS = 'selections/GENERATE_PDF_SUCCESS';
export const GENERATE_PDF_FAILED = 'selections/GENERATE_PDF_FAILED';
export const GENERATE_PDF_ROLLBACK = 'selections/GENERATE_PDF_ROLLBACK';
