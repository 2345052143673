import {
    applyMiddleware,
    createStore,
    compose,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createOffline } from '@redux-offline/redux-offline';
import offlineConfig from '@redux-offline/redux-offline/lib/defaults';
import { Config } from '@redux-offline/redux-offline/lib/types';
import { persistStore, persistReducer } from 'redux-persist';
import Localforage from 'localforage';
import { Index } from 'flexsearch';

import mainReducer from './reducers';
import mainSaga from './sagas';
import client from './api/_client';

Localforage.setDriver(Localforage.INDEXEDDB);

const actionSanitizer = (action: any) => (
    action.type === 'products/LIST_SUCCESS' && action.data ?
        { ...action, data: '<<TOO HEAVY FOR DEVTOOLS>>' } : action
);

const reduxDevtoolsParams = {
    actionSanitizer,
    stateSanitizer: (state: any) => state.products.data ? {
        ...state, products: { ...state.products, data: '<<TOO HEAVY FOR DEVTOOLS>>' },
    } : state,
};

const offlineEffect: Config['effect'] = (effect) => client(effect);
const offlineDiscard: Config['discard'] = (error) => {
    const { request, response } = error;
    if (!request) {
        throw error; // There was an error creating the request
    }
    if (!response) {
        return false; // There was no response
    }
    return 400 <= response.status && response.status < 500;
};

export default () => {
    const {
        middleware: offlineMiddleware,
        enhanceReducer: offlineEnhanceReducer,
        enhanceStore: offlineEnhanceStore,
    } = createOffline({
        ...offlineConfig,
        // @ts-ignore
        persist: false,
        effect: offlineEffect,
        discard: offlineDiscard,
    });

    const persistedReducer = persistReducer(
        {
            key: 'root',
            storage: Localforage,
            blacklist: ['allData', 'images', 'filters'],
            serialize: false,
            debug: true,
        },
        offlineEnhanceReducer(mainReducer),
    );

    const sagaMiddleware = createSagaMiddleware();
    const middlewares: any[] = [sagaMiddleware, offlineMiddleware];
    // @ts-ignore
    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        // @ts-ignore
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(reduxDevtoolsParams) : compose;

    const store = createStore(
        persistedReducer,
        composeEnhancers(
            offlineEnhanceStore, applyMiddleware(...middlewares),
        ),
    );

    const persistor = persistStore(store);

    sagaMiddleware.run(mainSaga);

    return {
        persistor,
        store,
    };
};

export const indices: {
    [key: string]: Index<any> | null;
} = {
    productsIndex: null,
};
