import React, { createContext, useState, FC, Dispatch, useEffect } from 'react';
import { useRouteMatch } from 'react-router';
import { routes } from '../routes';

interface SectorColorContextTypes {
    color: string | null;
    setColor: Dispatch<React.SetStateAction<string | null>>;
}

export const SectorColorContext = createContext<SectorColorContextTypes>({
    color: null,
    setColor: () => undefined,
});

export const SectorColorContextProvider: FC = ({ children }) => {
    const [color, setColor] = useState();
    const isProductsPage = useRouteMatch(routes.products);
    const isProductPage = useRouteMatch(routes.productFromSolution);

    useEffect(() => {
        if (color && !isProductPage && !isProductsPage) {
            setColor(undefined);
        }
    }, [color, isProductPage, isProductsPage, setColor]);

    const context = {
        color,
        setColor,
    };

    return (
        <SectorColorContext.Provider value={context}>
            {children}
        </SectorColorContext.Provider>
    );
};
