import React, { useContext, KeyboardEvent } from 'react';
import { FormattedMessage, IntlShape, injectIntl, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Drawer, Tabs, List } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';
import { TabsProps } from 'antd/lib/tabs';

import '../../assets/styles/UserMenu.less';

import * as FavoritesActions from '../../store/actions/favorites';
import { Product, Sector } from '../../store/api/types';
import { MainReducerState } from '../../store/reducers';
import { getFavoritesProducts } from '../../store/reducers/products';
import { getFavoritesSectors } from '../../store/reducers/sectors';

import messages from './UserMenu.messages';
import { IconArrow, IconCommerces, IconPlaceholder } from '../icons';
import ListItem from '../ListItem';
import { getRoute, RoutePathName } from '../../routes';
import { UserMenuDrawerContext, FavoriteDrawerTabs } from './UserMenuDrawerContext';
import EmptyResult from '../EmptyResult';
import Img from '../Img';
import { t } from '../../utils';

interface FavoritesDrawerProps extends DrawerProps {
    favoriteProducts: Product[];
    favoriteSectors: Sector[];
    intl: IntlShape;
    removeFromFavorites: typeof FavoritesActions.del;
}

const FavoritesDrawer: React.FC<FavoritesDrawerProps> = ({
    favoriteProducts, favoriteSectors, onClose, removeFromFavorites, visible, zIndex,
}) => {
    const { locale } = useIntl();
    const {
        favoritesTab, isFavoritesMaskVisible, setIsFavoritesMaskVisible, setFavoritesTab,
    } = useContext(UserMenuDrawerContext);
    const onBack: ButtonProps['onClick'] = (e) => {
        if (typeof onClose === 'function') {
            onClose({} as KeyboardEvent<HTMLDivElement>);
            if (isFavoritesMaskVisible) {
                setIsFavoritesMaskVisible(false);
            }
        }
    };
    const onSectorRemove = (id: Sector['id']) => {
        removeFromFavorites({ sectorId: id || -1 });
    };
    const onProductRemove = (id: Product['id']) => {
        removeFromFavorites({ productId: id || -1 });
    };
    const onChangeTag: TabsProps['onChange'] = (tabKey) => {
        setFavoritesTab(FavoriteDrawerTabs[tabKey as keyof typeof FavoriteDrawerTabs]);
    };

    return (
        <Drawer
            title={(
                <div className="drawer-header-with-back-button">
                    <Button
                        type="link"
                        onClick={onBack}
                    >
                        <IconArrow direction="left" />
                    </Button>
                    <FormattedMessage {...messages.favorites} tagName="span" />
                </div>
            )}
            placement="right"
            width={385}
            maskStyle={{ display: isFavoritesMaskVisible ? 'block' : 'none' }}
            closable={isFavoritesMaskVisible}
            visible={visible}
            zIndex={zIndex}
        >
            <div id="favorites">
                <Tabs activeKey={favoritesTab} onChange={onChangeTag}>
                    <Tabs.TabPane
                        tab={(
                            <FormattedMessage
                                {...messages.favoritesTabSectors}
                                values={{ count: favoriteSectors.length }}
                            />
                        )}
                        key={FavoriteDrawerTabs.sectors}
                    >
                        {!!favoriteSectors.length && (
                            <List>
                                {favoriteSectors.map((sector) => (
                                    <List.Item key={sector.id}>
                                        <ListItem
                                            icon={<IconCommerces />}
                                            onDelete={onSectorRemove.bind(null, sector.id)}
                                            title={(
                                                <Link to={getRoute(RoutePathName.sector, { id: sector.id })}>
                                                    {t(sector.name, locale)}
                                                </Link>
                                            )}
                                        />
                                    </List.Item>
                                ))}
                            </List>
                        )}
                        {!favoriteSectors.length && (
                            <EmptyResult />
                        )}
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={(
                            <FormattedMessage
                                {...messages.favoritesTabProducts}
                                values={{ count: favoriteProducts.length }}
                            />
                        )}
                        key={FavoriteDrawerTabs.products}
                    >
                        {!!favoriteProducts.length && (
                            <List>
                                {favoriteProducts.map((product) => (
                                    <List.Item key={product.id}>
                                        <ListItem
                                            icon={product.images && product.images[0] ? (
                                                <Img
                                                    imageId={product.images[0].id}
                                                    alt={product ? t(product.label, locale) : 'product'}
                                                />
                                            ) :
                                                <IconPlaceholder />
                                            }
                                            onDelete={onProductRemove.bind(null, product.id)}
                                            title={(
                                                <Link to={getRoute(RoutePathName.product, { id: product.id })}>
                                                    {t(product.label, locale)}
                                                </Link>
                                            )}
                                        />
                                    </List.Item>
                                ))}
                            </List>
                        )}
                        {!favoriteProducts.length && (
                            <EmptyResult />
                        )}
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </Drawer>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    favoriteSectors: getFavoritesSectors(state),
    favoriteProducts: getFavoritesProducts(state),
});

export default connect(
    mapStateToProps,
    {
        removeFromFavorites: FavoritesActions.del,
    },
)(injectIntl(FavoritesDrawer));
