import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="58" height="70" viewBox="0 0 58 70">
        <path fillRule="evenodd" fill="currentColor" d="M47.1 23.452a3.995 3.995 0 0 1-3.993 3.986h-28.88a3.995 3.995 0 0 1-3.994-3.986V21.34a3.995 3.995 0 0 1 3.994-3.988h28.88A3.995 3.995 0 0 1 47.1 21.34v2.112zm-3.993-8.942h-28.88c-3.772 0-6.84 3.062-6.84 6.83v2.112c0 3.765 3.068 6.828 6.84 6.828h28.88c3.772 0 6.84-3.063 6.84-6.828V21.34c0-3.768-3.068-6.83-6.84-6.83zm12.046 27.666a3.958 3.958 0 0 1-3.957 3.95H6.968a1.41 1.41 0 0 0-.22-.024c-.03 0-.054.007-.083.01-2.116-.076-3.818-1.806-3.818-3.936V6.79a3.957 3.957 0 0 1 3.957-3.948h44.392a3.957 3.957 0 0 1 3.957 3.948v35.386zm-5.445 6.79v17.86a2.45 2.45 0 0 1-.653-.484c-1.23-1.313-1.336-3.968-1.274-4.876a1.26 1.26 0 0 0 .005-.104V48.966h1.923zm-41.537 0H44.94v12.348c-.035.57-.14 3.518 1.214 5.844H8.172V48.966zM51.197 0H6.804C3.054 0 0 3.046 0 6.79v35.386c0 3.237 2.283 5.947 5.324 6.624v19.78c0 .783.637 1.42 1.424 1.42h44.384c.786 0 1.423-.637 1.423-1.42V48.83C55.658 48.2 58 45.454 58 42.175V6.79C58 3.046 54.948 0 51.196 0z" />
    </svg>
);

const IconSanitary: React.FC<IconProps> = (props) => (
    <Icon
        component={svg}
        {...props}
        className={`anticon icon-sanitary${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconSanitary;
