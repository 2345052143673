import React, { useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import useResizeObserver from 'use-resize-observer';
import { Props } from 'react-pdf/dist/Document';
import { Button, Input } from 'antd';
import { InputProps } from 'antd/lib/input';

import 'react-pdf/dist/Page/AnnotationLayer.css';

import { File } from '../store/api/types';
import '../assets/styles/PDFViewer.less';

import { IconAngle } from './icons';

pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.js`;

interface PDFViewerProps extends Props {
    download?: File | null;
}

const PDFViewer: React.FC<PDFViewerProps> = ({ download, file }) => {
    const [ref, width] = useResizeObserver();
    const [pageNumber, setPageNumber] = useState(1);
    const [pdfOptions, setPdfOptions] = useState({ numPages: 0 });
    const onDocumentLoadSuccess: Props['onLoadSuccess'] = (pdfData) => {
        setPdfOptions(pdfData);
    };
    const onPreviousPage = () => {
        setPageNumber(pageNumber - 1 < 1 ? 1 : pageNumber - 1);
    };
    const onNextPage = () => {
        setPageNumber(pageNumber + 1 === pdfOptions.numPages ? pdfOptions.numPages : pageNumber + 1);
    };
    const onChangePage: InputProps['onChange'] = (e) => {
        const page = parseInt(e.target.value, 10);
        setPageNumber(page <= pdfOptions.numPages && page > 0 ? page : pageNumber);
    };
    const downloadUrl = download !== null && download ?
        download.url :
        file !== null ? file.url : undefined;

    return (
        <section className="pdf-viewer" ref={ref}>
            <div className="pdf-viewer-toolbar">
                <div className="pdf-viewer-pagination">
                    <Button
                        onClick={onPreviousPage}
                        disabled={pageNumber === 1}
                    >
                        <IconAngle direction="left" />
                    </Button>
                    <Input
                        value={pageNumber}
                        onChange={onChangePage}
                        size="small"
                    />
                    <span>{` / ${pdfOptions.numPages}`}</span>
                    <Button
                        onClick={onNextPage}
                        disabled={pageNumber === pdfOptions.numPages}
                    >
                        <IconAngle direction="right" />
                    </Button>
                </div>
                {downloadUrl && (
                    <Button
                        href={downloadUrl}
                        className="pdf-viewer-download"
                        icon="download"
                        target="_blank"
                        type="primary"
                    />
                )}
            </div>
            <Document
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page
                    pageNumber={pageNumber}
                    width={width}
                    renderAnnotationLayer
                />
            </Document>
        </section>
    );
};

export default PDFViewer;
