import { combineReducers } from 'redux';

import * as reduxTypes from '../types/faq';
import { MainReducerState, RequestState } from '.';
import { requestReducer } from './_generics';
import { FAQ, FAQConfig } from '../api/types';

export interface FAQState {
    readonly list: RequestState<FAQ[]>;
    readonly config: RequestState<FAQConfig>;
}

export default combineReducers<FAQState>({
    list: requestReducer({
        reduxTypes: {
            START: reduxTypes.LIST,
            SUCCESS: reduxTypes.LIST_SUCCESS,
            FAILED: reduxTypes.LIST_FAILED,
        },
    }),
    config: requestReducer({
        reduxTypes: {
            START: reduxTypes.CONFIG,
            SUCCESS: reduxTypes.CONFIG_SUCCESS,
            FAILED: reduxTypes.CONFIG_FAILED,
        },
    }),
});

export const getFAQListState = (state: MainReducerState) => state.faq.list;
export const getFAQConfigState = (state: MainReducerState) => state.faq.config;


