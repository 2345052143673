import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import '../../assets/styles/Product.less';

import { Product } from '../../store/api/types';
import { FiltersState } from '../../store/reducers/filters';

import messages from './Product.messages';
import PieChart from '../../components/PieChart';
import { t } from '../../utils';

interface ProductColorsProps {
    filters?: FiltersState;
    product?: Product;
}

const ProductColors: React.FC<ProductColorsProps> = ({
    filters, product,
}) => {
    const { locale } = useIntl();
    let productColors = product ?
        product.properties.filter((productProperty) => productProperty.color !== null) :
        [];
    const filterPropertiesIds = filters && filters.properties ?
        filters.properties.map((filterProp) => filterProp.id) :
        [];

    if (
        filters &&
        filters.properties &&
        filterPropertiesIds.length &&
        productColors.some((productProperty) =>
            filterPropertiesIds.includes(productProperty.property.id),
        )
    ) {
        productColors = productColors.filter((productProperty) =>
            filters.properties!.some((filterProp) =>
                filterProp.id === productProperty.property.id &&
                filterProp.valueId === productProperty.id,
            ),
        );
    }

    return productColors.length ? (
        <div id="product-colors" className="m-b-32">
            <FormattedMessage
                {...messages.colors}
                values={{
                    colors: productColors.map((productProperty) => t(productProperty.name, locale)).join(' / '),
                }}
                tagName="p"
            />
            <ul className="list-unstyled list-inline">
                {productColors.map((productProperty) => {
                    const colors = productProperty.color ? productProperty.color.split('-') : ['#fff'];

                    return (
                        <li key={productProperty.id}>
                            <PieChart data={colors} />
                        </li>
                    );
                })}
            </ul>
        </div>
    ) : null;
};

export default ProductColors;
