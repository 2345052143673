import React, { FC, useEffect } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router';
import { DateTime } from 'luxon';
import { Row, Col, Pagination, Divider } from 'antd';
import { PaginationProps } from 'antd/lib/pagination';

import '../../assets/styles/News.less';

import * as NewsActions from '../../store/actions/news';
import { MainReducerState } from '../../store/reducers';
import { getUser } from '../../store/reducers/auth';
import { getNewsState, NewsState } from '../../store/reducers/news';

import { RoutePathName, getRoute } from '../../routes';
import BreadCrumb from '../../components/BreadCrumb';
import { IconHeroInvertedTriangle } from '../../components/icons';
import messages from './News.messages';
import { Sector } from '../../store/api/types';
import { t } from '../../utils';
import EmptyResult from '../../components/EmptyResult';
import HomeMessages from '../home/Home.messages';

interface NewsProps {
    fetchNews: typeof NewsActions.list;
    newsState: NewsState;
    userMarketId?: Sector['id'];
}

const News: FC<NewsProps> = ({ fetchNews, newsState, userMarketId }) => {
    const { formatMessage, locale } = useIntl();
    const history = useHistory();
    const location = useLocation();
    const urlParams = new URLSearchParams(location.search);
    const pageParam = urlParams.get('page');
    const currentPage = pageParam !== null ?
        parseInt(pageParam, 10) :
        0;
    const onPageChange: PaginationProps['onChange'] = (page) => {
        urlParams.set('page', `${(page || 1) - 1}`);

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    };

    useEffect(() => {
        fetchNews({
            page: currentPage,
            limit: 15,
            marketId: userMarketId || '',
        });
    }, [fetchNews, userMarketId, currentPage, location.pathname]);

    return (
        <>
            <BreadCrumb
                routes={[
                    {
                        link: getRoute(RoutePathName.news),
                        text: formatMessage(messages.title),
                    },
                ]}
            />
            <header className="page-hero product-hero">
                <div className="container">
                    <Row>
                        <Col xl={15} lg={18} md={16} className="page-hero-title-col">
                            <FormattedMessage {...messages.title} tagName="h1" />
                        </Col>
                    </Row>
                    <div id="bottom-triangle">
                        <IconHeroInvertedTriangle />
                    </div>
                </div>
            </header>
            <section id="news" className="container-small m-b-56">
                <ul id="news-list" className="list-unstyled">
                    {newsState.data && newsState.data.results ?
                        newsState.data.results.map((news) => (
                            <li key={news.id}>
                                <article className="news-article">
                                    <time dateTime={news.updateAt || news.createAt || undefined}>
                                        {
                                            DateTime.fromISO(
                                                news.updateAt || news.createAt || '',
                                            ).toLocaleString({ day: 'numeric', month: 'long', year: 'numeric' })
                                        }
                                    </time>
                                    <h1>{t(news.title, locale)}</h1>
                                    <div dangerouslySetInnerHTML={{ __html: t(news.text, locale) }} />
                                </article>
                                <Divider />
                            </li>
                        )) : null}
                </ul>
                {newsState.data && newsState.data.results && !newsState.data.results.length && (
                    <EmptyResult text={<FormattedMessage {...HomeMessages.noNews} />} />
                )}
                <Pagination
                    current={currentPage + 1}
                    onChange={onPageChange}
                    pageSize={15}
                    total={newsState.data ? newsState.data.total : 0}
                />
            </section>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => {
    const user = getUser(state);

    return {
        newsState: getNewsState(state),
        userMarketId: user ? user.marketId : undefined,
    };
};

export default connect(
    mapStateToProps,
    {
        fetchNews: NewsActions.list,
    },
)(News);
