import { defineMessages } from 'react-intl';

export default defineMessages({
    title: {
        id: 'nace.title',
        defaultMessage: 'NACE/NAF code selection',
        description: 'Nace page title',
    },
    searchPlaceholder: {
        id: 'nace.search.placeholder',
        defaultMessage: 'Enter a search criteria',
        description: 'Nace page search input placeholder',
    },
    nafLabel: {
        id: 'nace.table.header.naf_label',
        defaultMessage: 'NAF label',
        description: 'Nace page table header',
    },
    naceLabel: {
        id: 'nace.table.header.nace_label',
        defaultMessage: 'NACE label',
        description: 'Nace page table header',
    },
    market: {
        id: 'nace.table.header.market',
        defaultMessage: 'Market',
        description: 'Nace page table header',
    },
    sector: {
        id: 'nace.table.header.sector',
        defaultMessage: 'Sector',
        description: 'Nace page table header',
    },
    subSector: {
        id: 'nace.table.header.subsector',
        defaultMessage: 'Sub-sector',
        description: 'Nace page table header',
    },
});
