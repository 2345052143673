import React, { useContext, useCallback } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Badge, Button, message, Typography } from 'antd';

import '../../assets/styles/SelectionBar.less';

import * as SelectionsActions from '../../store/actions/selections';
import { MainReducerState } from '../../store/reducers';
import { getCurrentSelection } from '../../store/reducers/selections';
import { Selection } from '../../store/api/types';

import { SelectionBarDrawerContext } from './SelectionBarDrawerContext';
import messages from './SelectionBar.messages';
import { IconArrow, IconClients } from '../icons';
import SelectedProductsDrawer from './SelectedProductsDrawer';
import SelectedClientDrawer from './SelectedClientDrawer';
import { getFullName } from '../../utils';

interface SelectionBarProps {
    currentSelection?: Selection;
    validate: typeof SelectionsActions.validate;
}

const SelectionBar: React.FC<SelectionBarProps> = ({ currentSelection, validate }) => {
    const { formatMessage } = useIntl();
    const isVisible = currentSelection && currentSelection.products && !!currentSelection.products.length;
    const client = currentSelection && currentSelection.customer ? currentSelection.customer : null;
    const {
        setIsSelectedProductsDrawerVisible, setIsClientsDrawerVisible,
    } = useContext(SelectionBarDrawerContext);
    const onOpenSelectedProductsDrawer = useCallback(() => {
        setIsSelectedProductsDrawerVisible(true);
    }, [setIsSelectedProductsDrawerVisible]);
    const onClickChangeClient = useCallback(() => {
        setIsClientsDrawerVisible(true);
    }, [setIsClientsDrawerVisible]);
    const onEndMeeting = useCallback(() => {
        validate(currentSelection);
        message.success(formatMessage(messages.meetingSaved));
    }, [currentSelection, formatMessage, validate]);
    const productsButton = (
        <Button
            id="selection-bar-products-button"
            onClick={onOpenSelectedProductsDrawer}
        >
            <span>
                <Badge
                    count={currentSelection && currentSelection.products && currentSelection.products.length}
                    style={{ backgroundColor: '#00a5aa' }}
                />
                <FormattedMessage
                    {...messages.selectedProductsCount}
                    values={{
                        count: currentSelection && currentSelection.products && currentSelection.products.length,
                    }}
                />
            </span>
            {!client && (
                <span id="selection-bar-arrow">
                    <IconArrow direction="right" />
                </span>
            )}
        </Button>
    );

    return (
        <>
            {isVisible && (
                <div id="selection-bar" className={client ? 'with-client' : undefined}>
                    {client && (
                        <div id="selection-bar-client">
                            <Button
                                onClick={onClickChangeClient}
                            >
                                <IconClients />
                                <p>
                                    <FormattedMessage {...messages.meetingWith} /><br />
                                    <Typography.Paragraph ellipsis>
                                        {getFullName(client.firstName, client.lastName)}
                                        {` - ${client.company}`}
                                    </Typography.Paragraph>
                                </p>
                            </Button>
                            {productsButton}
                        </div>
                    )}
                    {!client && productsButton}
                    {client && (
                        <Button
                            type="danger"
                            onClick={onEndMeeting}
                        >
                            <FormattedMessage {...messages.endMeeting} />
                        </Button>
                    )}
                </div>
            )}
            <SelectedProductsDrawer />
            <SelectedClientDrawer />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    currentSelection: getCurrentSelection(state),
});

export default connect(
    mapStateToProps,
    {
        validate: SelectionsActions.validate,
    },
)(SelectionBar);
