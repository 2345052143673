import * as React from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, Link } from 'react-router-dom';
import { injectIntl, FormattedMessage, IntlShape } from 'react-intl';
import { FormComponentProps } from 'antd/lib/form/Form';
import {
    Form, Input, Button, Row, Col, Divider,
} from 'antd';

import '../../assets/styles/Login.less';

import * as Auth from '../../store/actions/auth';
import { getAuth, AuthState } from '../../store/reducers/auth';

import { IconLogo, IconLogoText, IconLoginShape } from '../../components/icons';
import genericMessages from '../../locale/Generic.messages';
import messages from './Login.messages';
import { getRoute, RoutePathName } from '../../routes';
import { MainReducerState } from '../../store/reducers';

interface LoginProps extends RouteComponentProps, FormComponentProps {
    auth: AuthState;
    intl: IntlShape;
    login: typeof Auth.login;
}

class Login extends React.Component<LoginProps> {

    public onLogin = (e: React.FormEvent): void => {
        const { form, login } = this.props;
        e.preventDefault();
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            login(values);
        });
    }

    public renderFrom() {
        const { auth, form, intl } = this.props;
        const { getFieldDecorator } = form;
        let error = auth.error ? <FormattedMessage {...genericMessages.error} /> : null;

        if (auth.error && auth.error.status && auth.error.status === 401) {
            error = <FormattedMessage {...messages.invalidCredentials} />;
        }

        return (
            <Form onSubmit={this.onLogin} className="login-form">
                <FormattedMessage {...messages.welcome} tagName="h1" />
                <Divider style={{ width: 56, minWidth: 56 }} />
                <FormattedMessage
                    {...messages.description}
                    values={{
                        span: (...chunks: React.ReactElement[]) => <span>{React.Children.toArray(chunks)}</span>,
                        a: (...chunks: React.ReactElement[]) => (
                            <Link to={RoutePathName.register}>
                                {React.Children.toArray(chunks)}
                            </Link>
                        ),
                    }}
                    tagName="p"
                />
                <Form.Item label={intl.formatMessage(messages.emailLabel)}>
                    {getFieldDecorator('email', {
                        rules: [{
                            required: true,
                            type: 'email',
                            message: intl.formatMessage(messages.emailError),
                        }],
                        validateTrigger: 'onBlur',
                    })((
                        <Input
                            placeholder={intl.formatMessage(messages.emailPlaceholder)}
                            type="email"
                        />
                    ))}
                </Form.Item>
                <Form.Item label={intl.formatMessage(messages.passwordLabel)} style={{marginBottom: 0}}>
                    {getFieldDecorator('password', {
                        rules: [{ required: true, message: intl.formatMessage(messages.passwordError) }],
                    })((
                        <Input.Password
                            placeholder={intl.formatMessage(messages.passwordPlaceholder)}
                        />
                    ))}
                </Form.Item>
                <Link to={getRoute(RoutePathName.forgottenPassword)}>
                    <FormattedMessage {...messages.forgotPassword} />
                </Link>
                {error ? (
                    <div className="login-error has-error">
                        <span className="ant-form-explain">{error}</span>
                    </div>
                ) : null}
                <Button type="primary" htmlType="submit" size="large" loading={auth.loading} block>
                    <FormattedMessage {...messages.loginButton} />
                </Button>
            </Form>
        );
    }

    public render() {
        const { auth } = this.props;

        return auth.isConnected ? (
            <Redirect to={getRoute(RoutePathName.home)} />
        ) : (
            <Row id="login-layout">
                <Col xs={24} lg={10} className="login-left">
                    <div id="logo-wrapper">
                        <IconLogo />
                        <IconLogoText />
                    </div>
                    {this.renderFrom()}
                </Col>
                <Col xs={24} lg={14} className="login-right">
                    <IconLoginShape />
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state: MainReducerState): any => ({
    auth: getAuth(state),
});

const LoginForm = Form.create()(injectIntl(Login));

export default connect(
    mapStateToProps,
    { login: Auth.login },
)(LoginForm);
