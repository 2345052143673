import React from 'react';
import { Card, Button, Spin } from 'antd';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';

import '../assets/styles/SectorCard.less';

import * as FavoritesActions from '../store/actions/favorites';
import { Sector } from '../store/api/types';

import { IconHeart } from './icons';
import { RoutePathName, getRoute } from '../routes';
import { useIntl } from 'react-intl';
import { MainReducerState } from '../store/reducers';
import { connect } from 'react-redux';
import { isSectorFavorite } from '../store/reducers/favorites';
import { sectorIconsMap } from '../pages/products';
import { t } from '../utils';
import { ButtonProps } from 'antd/lib/button';

interface SectorCardProps {
    addToFavorites: typeof FavoritesActions.create;
    isFavorite: boolean;
    removeFromFavorites: typeof FavoritesActions.del;
    sector?: Sector;
    subSector?: Sector;
    withFavorite?: boolean;
}

const SectorCard: React.FC<SectorCardProps> = ({
    addToFavorites, isFavorite, removeFromFavorites, sector, subSector, withFavorite,
}) => {
    const { locale } = useIntl();
    const onClickFavorite: ButtonProps['onClick'] = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (isFavorite) {
            removeFromFavorites({ sectorId: sector!.id });
        } else {
            ReactGA.event({
                category: 'secteur favoris',
                action: 'ajout',
                label: sector ? `${sector.id}` : (subSector ? `${subSector.id}` : ''),
            });
            addToFavorites({ sectorId: sector!.id });
        }
    };

    let Icon = null;
    let link = '';
    let title = '';

    if (sector) {
        Icon = sector.icon ?
            sectorIconsMap[sector.icon] :
            null;
        title = t(sector.name, locale);
        link = sector.skipSubLevel ?
            getRoute(RoutePathName.subSector, { sectorId: sector.id, id: sector.id }) :
            getRoute(RoutePathName.sector, { id: sector.id });
    }

    if (subSector) {
        Icon = subSector.icon ?
            sectorIconsMap[subSector.icon] :
            null;
        title = t(subSector.name, locale);
        link = getRoute(RoutePathName.subSector, { sectorId: sector ? sector.id : -1, id: subSector.id });
    }

    const onClickLink = () => {
        ReactGA.event({
            category: subSector ? 'sous-secteur' : 'secteur',
            action: 'clic',
            label: sector ? `${sector.id}` : (subSector ? `${subSector.id}` : ''),
        });
    };

    return (
        <div className="sector-card">
            <Link to={link} onClick={onClickLink}>
                <Spin spinning={false}>
                    <Card bordered={false}>
                        {withFavorite && !!sector && (
                            <Button
                                onClick={onClickFavorite}
                                type="link"
                            >
                                <IconHeart full={isFavorite} />
                            </Button>
                        )}
                        {Icon && <Icon />}
                        <p>{title}</p>
                    </Card>
                </Spin>
            </Link>
        </div>
    );
};

const mapStateToProps = (state: MainReducerState, { sector }: { sector?: Sector }) => ({
    isFavorite: isSectorFavorite(state, sector ? sector.id : -1),
});

export default connect(
    mapStateToProps,
    {
        addToFavorites: FavoritesActions.create,
        removeFromFavorites: FavoritesActions.del,
    },
)(SectorCard);
