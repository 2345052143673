import React, { HTMLAttributes, useState, useMemo } from 'react';
import { connect } from 'react-redux';

import { File } from '../store/api/types';
import { MainReducerState } from '../store/reducers';
import { getImageBlobById } from '../store/reducers/images';

interface ImgProps extends HTMLAttributes<HTMLImageElement> {
    alt: string;
    blob?: any;
    imageId: File['id'];
}

const Img: React.FC<ImgProps> = ({ alt, imageId, blob }) => {
    const [imageSrc, setImageSrc] = useState<string>();

    useMemo(() => {
        if (blob) {
            const reader = new FileReader();
            reader.onload = () => {
                setImageSrc(reader.result as string);
            };
            reader.readAsDataURL(blob);
        }
    }, [imageId]); // eslint-disable-line react-hooks/exhaustive-deps

    return <img alt={alt} src={imageSrc} />;
};

const mapStateToProps = (state: MainReducerState, { imageId }: { imageId: File['id'] }) => ({
    blob: getImageBlobById(state, imageId),
});

export default connect(
    mapStateToProps,
)(Img);
