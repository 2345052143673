import React, { useCallback, useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Tooltip } from 'antd';
import { NativeButtonProps } from 'antd/lib/button/button';

import '../assets/styles/ProductCard.less';

import * as SelectionsActions from '../store/actions/selections';
import { Product, Name, File, Sector } from '../store/api/types';
import { MainReducerState } from '../store/reducers';
import { isProductInSelection } from '../store/reducers/selections';

import ProductsMessages from '../pages/products/Products.messages';
import GenericMessages from '../locale/Generic.messages';
import { IconBestSeller, IconPlus, IconPlaceholder, IconMinus } from './icons';
import Img from './Img';
import { isProductBestSeller } from '../store/reducers/products';
import { SectorColorContext } from './SectorColorContextProvider';

interface ProductCardProps {
    addToSelection: typeof SelectionsActions.addProduct;
    argumentsOrder?: number[];
    className?: string;
    dataId?: number;
    image?: File;
    isBestSeller: boolean;
    isBig?: boolean;
    isInSelection: boolean;
    isSmall?: boolean;
    link?: string;
    name?: string;
    onClick?: NativeButtonProps['onClick'];
    onClickLink?: NativeButtonProps['onClick'];
    product?: Product;
    removeFromSelection: typeof SelectionsActions.removeProduct;
    sectorIds?: Sector['id'][];
    withAddToSelection?: boolean;
}

const ProductCard: React.FC<ProductCardProps> = ({
    addToSelection, argumentsOrder, className, dataId, image, isBestSeller, isBig, isInSelection,
    isSmall, link, name, onClick, onClickLink, product, removeFromSelection, withAddToSelection = false,
}) => {
    const { color } = useContext(SectorColorContext);
    const [sortedArgumentTextsIds, setSortedArgumentTextsIds] = useState<number[]>([]);
    const onClickSelectionButton = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();

        if (isInSelection) {
            removeFromSelection(product);
        } else {
            addToSelection(product, sortedArgumentTextsIds);
        }

        return false;
    }, [addToSelection, isInSelection, product, removeFromSelection, sortedArgumentTextsIds]);

    useEffect(() => {
        const args = product ?
            product.arguments.filter((arg) => !!arg.texts.length) :
            [];

        if (argumentsOrder && argumentsOrder.length) {
            setSortedArgumentTextsIds(
                args.sort((a, b) => {
                    if (argumentsOrder.indexOf(a.argument.id) === -1 && argumentsOrder.indexOf(b.argument.id) === -1) {
                        return 0;
                    } else if (
                        argumentsOrder.indexOf(a.argument.id) !== -1 &&
                        argumentsOrder.indexOf(b.argument.id) === -1
                    ) {
                        return -1;
                    } else if (
                        argumentsOrder.indexOf(a.argument.id) === -1 &&
                        argumentsOrder.indexOf(b.argument.id) !== -1
                    ) {
                        return 1;
                    } else {
                        return argumentsOrder.indexOf(a.argument.id) - argumentsOrder.indexOf(b.argument.id);
                    }
                })
                    .reduce<{ id: number; text: Name }[]>((acc, arg) => acc.concat(arg.texts), [])
                    .map((text) => text.id),
            );
        } else {
            setSortedArgumentTextsIds(
                args.reduce<{ id: number; text: Name }[]>((acc, arg) => acc.concat(arg.texts), [])
                    .map((text) => text.id),
            );
        }
    }, [argumentsOrder, product]);

    const card = (
        <div className={`product-card${isSmall ? ' product-card-small' : ''}${isBig ? ' product-card-big' : ''}${className ? ` ${className}` : ''}`}>
            {product && (
                <>
                    {product.isNew && (
                        <div className="product-card-new"><FormattedMessage {...GenericMessages.new} /></div>
                    )}
                    {isBestSeller && (
                        <div className="product-card-best-seller">
                            <Tooltip
                                title={<FormattedMessage {...GenericMessages.bestSeller} />}
                                mouseEnterDelay={0.3}
                            >
                                <IconBestSeller />
                            </Tooltip>
                        </div>
                    )}
                    {withAddToSelection && (
                        <Tooltip
                            title={
                                isInSelection ?
                                    <FormattedMessage {...ProductsMessages.removeFromSelection} /> :
                                    <FormattedMessage {...ProductsMessages.addToSelection} />
                            }
                            mouseEnterDelay={0.3}
                        >
                            <Button
                                onClick={onClickSelectionButton}
                                className={`product-card-add-to-selection${isInSelection ? ' product-card-add-to-selection-selected' : ''}`}
                                shape="circle"
                                style={{ '--sector-color': color || '#00a5aa' } as React.CSSProperties}
                                type="primary"
                            >
                                {isInSelection ? <IconMinus /> : <IconPlus />}
                            </Button>
                        </Tooltip>
                    )}
                </>
            )}
            <div className="product-card-image">
                {image ?
                    <Img imageId={image.id} alt={name || 'product'} /> :
                    <IconPlaceholder />
                }
            </div>
            <p><span>{name}</span></p>
        </div>
    );

    return link ? (
        <Link to={link} onClick={onClickLink}>
            {card}
        </Link>
    ) : (
        <Button
            className="product-card-button"
            onClick={onClick}
            data-id={dataId}
        >
            {card}
        </Button>
    );
};

const mapStateToProps = (state: MainReducerState, { product, sectorIds }:
{ product?: Product; sectorIds?: Sector['id'][] }) => ({
    isBestSeller: isProductBestSeller(state, product ? product.id : -1, sectorIds || []),
    isInSelection: isProductInSelection(state, product ? product.id : -1),
});

export default connect(
    mapStateToProps,
    {
        addToSelection: SelectionsActions.addProduct,
        removeFromSelection: SelectionsActions.removeProduct,
    },
)(ProductCard);
