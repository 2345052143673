import React, { useContext } from 'react';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Divider } from 'antd';

import '../../assets/styles/Products.less';

import { IconPlaceholder } from '../../components/icons';
import SectorMessages from '../sector/Sector.messages';
import Carousel from '../../components/Carousel';
import { getRoute, RoutePathName } from '../../routes';
import { Sector } from '../../store/api/types';
import { sectorIconsMap } from '.';
import { SectorColorContext } from '../../components/SectorColorContextProvider';
import { t } from '../../utils';


interface ProductsSolutionsFilterProps {
    solutions: Sector[];
}

const ProductsSolutionsFilter: React.FC<ProductsSolutionsFilterProps> = ({ solutions }) => {
    const { sectorId, subSectorId } = useParams();
    const { color } = useContext(SectorColorContext);
    const { locale } = useIntl();

    return (
        <section id="products-solutions" className="product-filter-carousel m-b-24">
            <h3 style={color ? { color } : undefined}>
                <FormattedMessage {...SectorMessages.subSectorTooltipBottom} />
            </h3>
            <Divider style={color ? { backgroundColor: color } : undefined} />
            {!!solutions.length && (
                <Carousel
                    lazyLoad="progressive"
                    slidesToShow={solutions.length < 6 ? solutions.length : 6}
                    slidesToScroll={solutions.length < 6 ? solutions.length : 6}
                    responsive={[
                        {
                            breakpoint: 1366,
                            settings: {
                                slidesToShow: solutions.length < 6 ? solutions.length : 6,
                                slidesToScroll: solutions.length < 6 ? solutions.length : 6,
                            },
                        },
                        {
                            breakpoint: 1150,
                            settings: {
                                slidesToShow: solutions.length < 4 ? solutions.length : 4,
                                slidesToScroll: solutions.length < 4 ? solutions.length : 4,
                            },
                        },
                        {
                            breakpoint: 884,
                            settings: {
                                slidesToShow: solutions.length < 2 ? solutions.length : 2,
                                slidesToScroll: solutions.length < 2 ? solutions.length : 2,
                            },
                        },
                        {
                            breakpoint: 580,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                            },
                        },
                    ]}
                    isSmall
                >
                    {[...solutions].sort((a, b) => (a.order || 0) - (b.order || 0)).map((solution) => {
                        const Icon = solution.icon ?
                            sectorIconsMap[solution.icon] :
                            IconPlaceholder;

                        return (
                            <NavLink
                                activeClassName="is-active"
                                key={solution.id}
                                to={getRoute(RoutePathName.products, {
                                    sectorId: sectorId || '-1',
                                    subSectorId: subSectorId || '-1',
                                    solutionId: solution.id,
                                    assortmentId: undefined,
                                })}
                                exact
                            >
                                <div
                                    className="solution-card solution-card-small"
                                    style={{ '--sector-color': color || '#00a5aa' } as React.CSSProperties}
                                >
                                    <div className="icon-wrapper">
                                        <Icon />
                                    </div>
                                    <h3>{t(solution.name, locale)}</h3>
                                    <Divider />
                                </div>
                            </NavLink>
                        );
                    })}
                </Carousel>
            )}
        </section>
    );
};

export default ProductsSolutionsFilter;
