import { Reducer } from 'redux';
import * as authTypes from '../types/auth';
import { MainReducerState, RequestState } from './';
import { User } from '../api/types';
import { getProfileById } from './profiles';

export interface AuthState {
    data?: User;
    error?: RequestState['error'];
    registerError?: RequestState['error'];
    registerSuccess: boolean;
    forgottenPasswordError?: RequestState['error'];
    forgottenPasswordSuccess: boolean;
    updateError?: RequestState['error'];
    updateSuccess: boolean;
    hasCheckedLoginStatus: boolean;
    isConnected: boolean;
    loading: boolean;
}

const authInitialState: AuthState = {
    // data: {
    //     firstName: 'Jean-Michel',
    //     lastName: 'Bécatresse',
    //     email: 'jean-michel@becatresse.com',
    //     profile: 'Responsable des achats',
    // },
    // hasCheckedLoginStatus: true,
    // isConnected: true,

    hasCheckedLoginStatus: false,
    isConnected: false,

    loading: false,
    registerSuccess: false,
    forgottenPasswordSuccess: false,
    updateSuccess: false,
};

const auth: Reducer<AuthState> = (state = authInitialState, action) => {
    switch (action.type) {
        case authTypes.LOGIN:
            return {
                ...state,
                error: undefined,
                loading: true,
            };

        case authTypes.LOGIN_SUCCESS:
            return {
                ...state,
                data: action.data,
                hasCheckedLoginStatus: true,
                isConnected: true,
                loading: false,
            };

        case authTypes.LOGIN_FAILED:
            return {
                ...state,
                error: action.data,
                hasCheckedLoginStatus: true,
                isConnected: false,
                loading: false,
            };

        case authTypes.REGISTER:
            return {
                ...state,
                registerError: undefined,
                registerSuccess: false,
                loading: true,
            };

        case authTypes.REGISTER_SUCCESS:
            return {
                ...state,
                registerSuccess: true,
                loading: false,
            };

        case authTypes.REGISTER_FAILED:
            return {
                ...state,
                registerError: action.data,
                registerSuccess: false,
                forgottenPasswordSuccess: false,
                loading: false,
            };

        case authTypes.FORGOTTEN_PASSWORD:
            return {
                ...state,
                forgottenPasswordError: undefined,
                forgottenPasswordSuccess: false,
                loading: true,
            };

        case authTypes.FORGOTTEN_PASSWORD_SUCCESS:
            return {
                ...state,
                forgottenPasswordSuccess: true,
                loading: false,
            };

        case authTypes.FORGOTTEN_PASSWORD_FAILED:
            return {
                ...state,
                forgottenPasswordError: action.data,
                loading: false,
            };

        case authTypes.UPDATE:
            return {
                ...state,
                updateError: undefined,
                updateSuccess: false,
                loading: true,
            };

        case authTypes.UPDATE_SUCCESS:
            return {
                ...state,
                updateSuccess: true,
                loading: false,
            };

        case authTypes.UPDATE_FAILED:
            return {
                ...state,
                updateError: action.data,
                loading: false,
            };

        case authTypes.LOGOUT_SUCCESS:
            return {
                ...state,
                hasCheckedLoginStatus: true,
                isConnected: false,
                registerSuccess: false,
                forgottenPasswordSuccess: false,
            };

        case authTypes.LOGOUT_FAILED:
            return {
                ...state,
                isConnected: true,
            };

        case authTypes.CHECK_LOGIN_STATUS:
            return {
                ...state,
                hasCheckedLoginStatus: false,
                isConnected: false,
            };

        case authTypes.CHECK_LOGIN_STATUS_SUCCESS:
            return {
                ...state,
                data: action.data,
                hasCheckedLoginStatus: true,
                isConnected: true,
            };

        case authTypes.CHECK_LOGIN_STATUS_FAILED:
            return {
                ...state,
                hasCheckedLoginStatus: true,
                isConnected: false,
                error: undefined,
            };

        case authTypes.FETCH_USER:
            return {
                ...state,
                loading: true,
            };

        case authTypes.FETCH_USER_SUCCESS:
            return {
                ...state,
                data: action.data,
                loading: false,
            };

        case authTypes.FETCH_USER_FAILED:
            return {
                ...state,
                loading: false,
            };

        default:
            return state;
    }
};

export default auth;

export const getAuth = (state: MainReducerState) => state.auth;

export const getUser = (state: MainReducerState) => {
    const userData = state.auth.data;

    return userData ? {
        ...userData,
        profile: getProfileById(state, userData.profileId),
    } as User : undefined;
};
