import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlnsXlink="http://www.w3.org/1999/xlink" width="34" height="15" viewBox="0 0 34 15">
        <path fillRule="evenodd" d="M19.74 13.42L34 0H0l14.26 13.42a4 4 0 0 0 5.48 0z" />
    </svg>
);

const IconTooltipTriangle: React.FC<IconProps> = (props) => (
    <Icon component={svg} {...props} className={`anticon icon-tooltip-triangle${props.className ? ` ${props.className}` : ''}`} />
);

export default IconTooltipTriangle;
