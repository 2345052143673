import { call, takeLatest, put } from 'redux-saga/effects';
import * as newsActions from '../actions/news';
import * as reduxTypes from '../types/news';
import * as newsApi from '../api/news';
import { Action } from 'redux';
import { SearchPaginationQuery } from '../api';

export function* listSaga(action: Action & SearchPaginationQuery) {
    try {
        const response = yield call(newsApi.list, action);

        return yield put(newsActions.listSuccess(response));
    } catch (error) {
        return yield put(newsActions.listFailed(error));
    }
}

export default function* newsSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
}
