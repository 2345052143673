import React, { FC, useContext, useState, useEffect, useCallback } from 'react';
import ReactGA from 'react-ga';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { SortEnd } from 'react-sortable-hoc';
import { Button, Modal, Divider, Input, Select, Form, Upload, Icon, message } from 'antd';
import { DateTime } from 'luxon';
import cloneDeep from 'lodash/cloneDeep';

import '../../assets/styles/Presentation.less';

import { MainReducerState } from '../../store/reducers';
import * as PresentationsActions from '../../store/actions/presentations';
import * as SelectionsActions from '../../store/actions/selections';
import { Chapter, File, Selection, FileSubType } from '../../store/api/types';
import { getPreviewState, PresentationsState, getCreateState, getConvertState, getUpdateState } from '../../store/reducers/presentations';
import {
    getSelectionsByCustomerReference, SelectionsState, getGeneratePdfState,
} from '../../store/reducers/selections';
import api from '../../store/api/_client';

import GenericMessages from '../../locale/Generic.messages';
import FormMessages from '../../locale/Form.messages';
import { getFullName, arrayMove } from '../../utils';
import { IconArrow, IconExport, IconClose } from '../icons';
import { PresentationContext, PresentationSteps, PresentationData } from '../PresentationContextProvider';
import messages from './Presentation.messages';
import { PresentationSortableList, PresentationSubChaptersSortableList } from './';
import EmptyResult from '../EmptyResult';
import { SelectProps } from 'antd/lib/select';
import { UploadProps } from 'antd/lib/upload';

export interface SortableListItem {
    chapter?: Chapter;
    hasValue?: boolean;
    isChapter?: boolean;
    isPage?: boolean;
    isPreamble?: boolean;
    isPricing?: boolean;
    isSelection?: boolean;
    fileId?: File['id'];
    selection?: Selection;
}

interface PresentationCustomizeProps {
    convert: typeof PresentationsActions.convert;
    convertState: PresentationsState['convert'];
    create: typeof PresentationsActions.create;
    createState: PresentationsState['create'];
    generatedPdf: SelectionsState['generatePdf'];
    generatePdf: typeof SelectionsActions.generatePdf;
    getPreview: typeof PresentationsActions.preview;
    presentation: PresentationData;
    previewState: PresentationsState['preview'];
    selections: Selection[];
    update: typeof PresentationsActions.update;
    updateState: PresentationsState['update'];
}

const PresentationCustomize: FC<PresentationCustomizeProps> = ({
    convert, convertState, create, createState, generatedPdf, generatePdf, getPreview, previewState,
    selections, update, updateState,
}) => {
    const { locale, formatMessage } = useIntl();
    const {
        isEditing, presentation, setStep, setPresentationData, setPreviewFrom, getPresentationPayload,
        setIsCreateDone, setIsConvertDone, setPresentationEdit, setIsUpdateDone, setIsEditing,
        setShouldInitiateSaveOnCustomizeStep, shouldInitiateSaveOnCustomizeStep,
    } = useContext(PresentationContext);
    const [isUploading, setIsUploading] = useState<boolean>(false);
    const [isWaitingForPreview, setIsWaitingForPreview] = useState<boolean>(false);
    const [isWaitingForCreate, setIsWaitingForCreate] = useState<boolean>(false);
    const [isWaitingForUpdate, setIsWaitingForUpdate] = useState<boolean>(false);
    const [isWaitingForCreateAndExport, setIsWaitingForCreateAndExport] = useState<boolean>(false);
    const [isWaitingForExport, setIsWaitingForExport] = useState<boolean>(false);
    const [subChaptersParent, setSubChaptersParent] = useState<Chapter | undefined>();
    const [isPreambleModalVisible, setIsPreambleModalVisible] = useState<boolean>(false);
    const [isSelectionModalVisible, setIsSelectionModalVisible] = useState<boolean>(false);
    const [isPricingModalVisible, setIsPricingModalVisible] = useState<boolean>(false);
    const [isPageModalVisible, setIsPageModalVisible] = useState<boolean>(false);
    const [isSaveBeforeConvertModalVisible, setIsSaveBeforeConvertModalVisible] = useState<boolean>(false);
    const [selectionValue, setSelectionValue] = useState<Selection | undefined>();
    const [selectionUploadValue, setSelectionUploadValue] = useState<File | undefined>();
    const [pricingUploadValue, setPricingUploadValue] = useState<File | undefined>();
    const [pageUploadValue, setPageUploadValue] = useState<File | undefined>();
    const [preambleValue, setPreambleValue] = useState<string | undefined>(presentation.preamble);
    const [items, setItems] = useState<SortableListItem[]>([
        { isPreamble: true, hasValue: !!presentation.preamble },
        ...presentation.selectedChapters.map((chapter) => ({
            isChapter: true,
            chapter,
        })),
        ...presentation.selectedSelections.map((selection) => ({
            isSelection: true,
            selection,
        })),
        ...(presentation.selectedPricings || []).map((fileId) => ({
            isPricing: true,
            fileId,
        })),
        ...(presentation.selectedPages || []).map((fileId) => ({
            isPage: true,
            fileId,
        })),
    ]);
    const onClickBack = () => {
        setStep(PresentationSteps.Informations);
    };
    const onClickOpenPreamble = () => setIsPreambleModalVisible(true);
    const onClickAddSelection = () => setIsSelectionModalVisible(true);
    const onClickAddPricing = () => setIsPricingModalVisible(true);
    const onClickAddPage = () => setIsPageModalVisible(true);
    const onClosePreambleModal = () => setIsPreambleModalVisible(false);
    const onCloseSelectionModal = () => setIsSelectionModalVisible(false);
    const onClosePricingModal = () => setIsPricingModalVisible(false);
    const onClosePageModal = () => setIsPageModalVisible(false);
    const onCloseSaveBeforeConvertModal = () => setIsSaveBeforeConvertModalVisible(false);
    const onCloseSubChapterModal = () => setSubChaptersParent(undefined);
    const onChangePreamble = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
        setPreambleValue(e.target.value);
    const onChangeSelection: SelectProps['onChange'] = (value) =>
        setSelectionValue(selections.find((s) => s.id === value));
    const onClickExport = () => {
        setIsSaveBeforeConvertModalVisible(true);
        ReactGA.event({
            category: 'présentation/proposition',
            action: 'exporter',
            value: 1,
        });
    };
    const onClickExportWithoutSaving = () => {
        setIsWaitingForExport(true);
        convert(getPresentationPayload(locale));
    };
    const onClickSaveAndExport = () => {
        setIsWaitingForCreateAndExport(true);
        if (isEditing) {
            update(presentation.id, getPresentationPayload(locale));
        } else {
            create(getPresentationPayload(locale));
        }
        convert(getPresentationPayload(locale));
    };
    const updatePresentationData = (values: SortableListItem[]) => {
        setPresentationData({
            selectedChapters: values
                .filter((i) => i.isChapter && i.chapter)
                .map((i) => i.chapter!),
            selectedSelections: values
                .filter((i) => i.isSelection)
                .map((i) => i.selection!),
            selectedPricings: values
                .filter((i) => i.isPricing)
                .map((i) => i.fileId!),
            selectedPages: values
                .filter((i) => i.isPage)
                .map((i) => i.fileId!),
        });
    };
    const onClickDeleteItem = (itemIndex: number) => {
        const newItems = cloneDeep(items);

        newItems.splice(itemIndex, 1);

        updatePresentationData(newItems);

        setItems(newItems);
    };
    const onClickDeleteSubChapter = (itemIndex: number) => {
        if (subChaptersParent) {
            const newParent = cloneDeep(subChaptersParent);
            const newItems = cloneDeep(items);
            const match = newItems.find((chapter) => chapter.chapter && chapter.chapter.id === subChaptersParent.id);

            if (match && match.chapter) {
                match.chapter.subChapters.splice(itemIndex, 1);
            }

            newParent.subChapters.splice(itemIndex, 1);

            setSubChaptersParent(newParent);

            updatePresentationData(newItems);

            setItems(newItems);
        }
    };
    const onSubmitPreamble = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const value = (preambleValue || '').trim();

        if (value === presentation.preamble) {
            setIsPreambleModalVisible(false);
            return;
        }

        const itemsPreamble = items.find((item) => item.isPreamble);

        setPresentationData({ preamble: value });

        setItems((i) => [
            {
                ...itemsPreamble,
                hasValue: !!value,
            },
            ...i.filter((item) => !item.isPreamble),
        ]);

        ReactGA.event({
            category: 'présentation/proposition',
            action: 'ajout préambule',
            value: 1,
        });

        setIsPreambleModalVisible(false);
    };
    const onSubmitSelection = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        ReactGA.event({
            category: 'présentation/proposition',
            action: 'ajout sélection',
            value: 1,
        });

        if (selectionValue) {
            generatePdf(selectionValue.id, {
                language: locale,
                orientation: presentation.type === 'presentation' ? 'landscape' : 'portrait',
            });
        } else if (selectionUploadValue) {
            const newSelection = {
                customer: presentation.client!,
                customerId: presentation.client ? presentation.client.id : -1,
                isValidated: true,
                products: [],
                updateAt: (new Date()).toISOString(),
                userId: -1,
                id: -((new Date()).getTime()),
                pdf: selectionUploadValue,
            };

            setPresentationData({
                selectedSelections: [
                    ...(presentation.selectedSelections || []),
                    newSelection,
                ],
            });

            setItems((i) => [
                ...i,
                {
                    isSelection: true,
                    selection: newSelection,
                },
            ]);

            setSelectionUploadValue(undefined);
            setIsSelectionModalVisible(false);
        }
    };
    const onSubmitPricing = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        ReactGA.event({
            category: 'présentation/proposition',
            action: 'ajout tarification',
            value: 1,
        });

        if (pricingUploadValue) {

            setPresentationData({
                selectedPricings: [
                    ...(presentation.selectedPricings || []),
                    pricingUploadValue.id,
                ],
            });

            setItems((i) => [
                ...i,
                {
                    isPricing: true,
                    fileId: pricingUploadValue.id,
                },
            ]);

            setPricingUploadValue(undefined);
            setIsPricingModalVisible(false);
        }
    };
    const onSubmitPage = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        ReactGA.event({
            category: 'présentation/proposition',
            action: 'ajout page',
            value: 1,
        });

        if (pageUploadValue) {

            setPresentationData({
                selectedPages: [
                    ...(presentation.selectedPages || []),
                    pageUploadValue.id,
                ],
            });

            setItems((i) => [
                ...i,
                {
                    isPage: true,
                    fileId: pageUploadValue.id,
                },
            ]);

            setPageUploadValue(undefined);
            setIsPageModalVisible(false);
        }
    };
    const onClickSeeSubChapters = (
        rowIndex: number | undefined,
        cellIndex: number | undefined,
        chapter: Chapter,
    ) => {
        setSubChaptersParent(chapter);
    };
    const onClickValidate = useCallback(() => {
        if (isEditing) {
            setIsWaitingForUpdate(true);
            update(presentation.id, getPresentationPayload(locale));
        } else {
            setIsWaitingForCreate(true);
            create(getPresentationPayload(locale));
        }
    }, [
        isEditing, setIsWaitingForUpdate, update, setIsWaitingForCreate, create, getPresentationPayload,
        locale, presentation.id,
    ]);
    const onClickPreview = () => {
        getPreview(getPresentationPayload(locale));
        setIsWaitingForPreview(true);
    };
    const onSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
        const newItems = cloneDeep(items);

        arrayMove(newItems, oldIndex, newIndex);

        updatePresentationData(newItems);

        setItems(newItems);
    };
    const onSubChaptersSortEnd = ({ oldIndex, newIndex }: SortEnd) => {
        if (subChaptersParent) {
            const newParent = cloneDeep(subChaptersParent);
            const newItems = cloneDeep(items);
            const match = newItems.find((chapter) => chapter.chapter && chapter.chapter.id === subChaptersParent.id);

            if (match && match.chapter) {
                arrayMove(match.chapter.subChapters, oldIndex, newIndex);
            }

            updatePresentationData(newItems);

            arrayMove(newParent.subChapters, oldIndex, newIndex);
            setItems(newItems);
            setSubChaptersParent(newParent);
        }
    };
    const beforeUploadSelection: UploadProps['beforeUpload'] = (file) => {
        const isPdf = file.type === 'application/pdf';

        if (!isPdf) {
            message.error(formatMessage(messages.pdfUploadFormatError));
        }
        const isLt5M = file.size / 1024 / 1024 < 5;

        if (!isLt5M) {
            message.error(formatMessage(messages.pdfUploadSizeError));
        }

        return isPdf && isLt5M;
    };
    const beforeUploadPricing: UploadProps['beforeUpload'] = (file) => {
        const isImage = file.type === 'image/jpeg' || file.type === 'image/png';

        if (!isImage) {
            message.error(formatMessage(messages.imageUploadFormatError));
        }
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isLt2M) {
            message.error(formatMessage(messages.imageUploadSizeError));
        }

        return isImage && isLt2M;
    };
    const customUploadRequest = (subType: File['subType'], options: any) => {
        const { onError, onProgress, onSuccess, file } = options;
        const data = new FormData();

        data.append('file', file);
        data.append('subType', subType);

        if (subType === FileSubType.Pricing) {
            data.append('orientation', 'landscape');
        }

        if (subType === FileSubType.Other) {
            data.append('orientation', 'portrait');
        }

        setIsUploading(true);

        const endpoint = subType === FileSubType.Pricing || subType === FileSubType.Other ?
            '/api/files/imageToPdf' :
            '/api/files';

        api.post(endpoint, data, {
            onUploadProgress: onProgress as any,
        }).then(
            (response: any) => {
                setIsUploading(false);

                if (subType === FileSubType.Selection) {
                    setSelectionUploadValue(response);
                } else if (subType === FileSubType.Pricing) {
                    setPricingUploadValue(response);
                } else if (subType === FileSubType.Other) {
                    setPageUploadValue(response);
                }

                onSuccess(response, file);
            },
            (error: any) => {
                setIsUploading(false);
                message.error(formatMessage(messages.pdfUploadError));
                onError(error);
            },
        );
    };

    useEffect(() => {
        if (isWaitingForPreview && !previewState.loading && previewState.success && previewState.data) {
            setIsWaitingForPreview(false);
            setPreviewFrom(PresentationSteps.Customize);
            setStep(PresentationSteps.Preview);
        }
    }, [isWaitingForPreview, previewState.loading, previewState.success, previewState.data, setStep, setPreviewFrom]);

    useEffect(() => {
        if (isWaitingForCreate && !createState.loading) {
            setIsWaitingForCreate(false);
            if (createState.success && createState.data) {
                setIsCreateDone(true);
                setStep(PresentationSteps.Success);
            } else if (createState.error) {
                message.error(formatMessage(GenericMessages.error));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isWaitingForCreate, createState.loading, createState.success, createState.data, setStep]);

    useEffect(() => {
        if (isWaitingForUpdate && !updateState.loading) {
            setIsWaitingForUpdate(false);
            if (updateState.success && updateState.data) {
                setIsUpdateDone(true);
                setStep(PresentationSteps.Success);
            } else if (updateState.error) {
                message.error(formatMessage(GenericMessages.error));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isWaitingForUpdate, updateState.loading, updateState.success, updateState.data, setStep]);

    useEffect(() => {
        if (isWaitingForExport && !convertState.loading) {
            setIsWaitingForExport(false);
            if (convertState.success && convertState.data) {
                setIsConvertDone(true);
                setIsEditing(false);
                setPresentationEdit(convertState.data);
                setStep(PresentationSteps.Chapters);
            } else if (convertState.error) {
                message.error(formatMessage(GenericMessages.error));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isWaitingForExport, convertState.loading, convertState.success, convertState.data, setStep]);

    useEffect(() => {
        if (
            isWaitingForCreateAndExport && !convertState.loading &&
            (isEditing ? !updateState.loading : !createState.loading)
        ) {
            setIsWaitingForCreateAndExport(false);
            if (
                convertState.success && convertState.data &&
                (isEditing ? updateState.success && updateState.data : createState.success && createState.data)
            ) {
                setIsConvertDone(true);
                setIsCreateDone(true);
                setIsEditing(false);
                setPresentationEdit(convertState.data);
                setStep(PresentationSteps.Chapters);
            } else if (convertState.error) {
                message.error(formatMessage(GenericMessages.error));
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isWaitingForCreateAndExport, convertState.loading, convertState.success, convertState.data,
        createState.loading, createState.success, createState.data, updateState.loading,
        updateState.success, updateState.data, setStep,
    ]);

    useEffect(() => {
        if (
            !generatedPdf.loading && generatedPdf.success && generatedPdf.data &&
            selectionValue && generatedPdf.data.selectionId === selectionValue.id
        ) {
            if (selectionValue && generatedPdf.data && generatedPdf.data.selectionId === selectionValue.id) {
                const newSelection = {
                    ...selectionValue,
                    pdf: { ...generatedPdf.data },
                };

                setPresentationData({
                    selectedSelections: [
                        ...(presentation.selectedSelections || []),
                        newSelection,
                    ],
                });

                setItems((i) => [
                    ...i,
                    {
                        isSelection: true,
                        selection: newSelection,
                    },
                ]);

                setSelectionValue(undefined);
                setIsSelectionModalVisible(false);
            }
        }
    }, [generatedPdf.loading]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (shouldInitiateSaveOnCustomizeStep) {
            setShouldInitiateSaveOnCustomizeStep(false);
            onClickValidate();
        }
    }, [shouldInitiateSaveOnCustomizeStep, setShouldInitiateSaveOnCustomizeStep, onClickValidate]);

    return (
        <>
            <Button
                onClick={onClickBack}
                id="presentation-back-button"
            >
                <div id="cancel-icon-wrapper">
                    <IconArrow direction="left" />
                </div>
                <FormattedMessage {...GenericMessages.back} />
            </Button>
            <section>
                <div id="presentation-customize-wrapper">
                    <header>
                        <p>
                            <FormattedMessage
                                {...(presentation.type === 'presentation' ?
                                    messages.presentationTitle :
                                    messages.propositionTitle
                                )}
                                tagName="span"
                            />
                            {presentation.title}
                        </p>
                        <p>
                            <FormattedMessage {...GenericMessages.client} tagName="span" />
                            {presentation.client ?
                                `${getFullName(presentation.client.firstName, presentation.client.lastName)} - ${presentation.client.company}` :
                                undefined
                            }
                        </p>
                    </header>
                    <FormattedMessage {...messages.customizeTitle} tagName="h1" />
                    <PresentationSortableList
                        axis="xy"
                        helperClass="sortable-list"
                        items={items}
                        onClickAddSelection={onClickAddSelection}
                        onClickAddPricing={onClickAddPricing}
                        onClickAddPage={onClickAddPage}
                        onClickOpenPreamble={onClickOpenPreamble}
                        onClickDeleteItem={onClickDeleteItem}
                        onClickSeeSubChapters={onClickSeeSubChapters}
                        onSortEnd={onSortEnd}
                        presentationType={presentation.type}
                    />
                    <Modal
                        closeIcon={<IconClose />}
                        onCancel={onClosePreambleModal}
                        footer={null}
                        visible={isPreambleModalVisible}
                        width={740}
                        wrapClassName="preamble-modal"
                    >
                        <p className="ant-modal-title"><FormattedMessage {...messages.preamble} /></p>
                        <Divider />
                        <FormattedMessage {...messages.preambleHelp} tagName="p" />
                        <form onSubmit={onSubmitPreamble}>
                            <Input.TextArea
                                maxLength={4000}
                                onChange={onChangePreamble}
                                value={preambleValue || ''}
                                rows={6}
                            />
                            <div className="ant-form-extra" style={{ textAlign: 'right' }}>
                                {preambleValue ? preambleValue.length : 0}/4000
                            </div>
                            <Button
                                htmlType="submit"
                                size="large"
                                type="primary"
                            >
                                <FormattedMessage {...FormMessages.validate} />
                            </Button>
                        </form>
                    </Modal>
                    <Modal
                        closeIcon={<IconClose />}
                        onCancel={onCloseSelectionModal}
                        footer={null}
                        visible={isSelectionModalVisible}
                        width={740}
                        wrapClassName="selection-modal"
                    >
                        <p className="ant-modal-title"><FormattedMessage {...messages.productsSelections} /></p>
                        <Divider />
                        <FormattedMessage {...messages.productsSelectionsHelp} tagName="p" />
                        <form onSubmit={onSubmitSelection}>
                            <Form.Item label={formatMessage(messages.productsSelectionsLabel)}>
                                <Select
                                    onChange={onChangeSelection}
                                    placeholder={formatMessage(FormMessages.selectPlaceholder)}
                                    notFoundContent={<EmptyResult />}
                                    value={selectionValue ? selectionValue.id : undefined}
                                >
                                    {selections.filter((s) =>
                                        !items.some((i) => i.selection && i.selection.id === s.id),
                                    ).map((selection) => (
                                        <Select.Option
                                            value={selection.id}
                                            key={selection.id}
                                        >
                                            {DateTime.fromISO(selection.updateAt!).toLocaleString({
                                                day: 'numeric', month: 'long', year: 'numeric',
                                            })}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item label={formatMessage(messages.productsSelectionsUploadLabel)}>
                                <Upload.Dragger
                                    beforeUpload={beforeUploadSelection}
                                    customRequest={customUploadRequest.bind(null, FileSubType.Selection)}
                                    listType="picture-card"
                                >
                                    {!selectionUploadValue && (
                                        <>
                                            <p className="ant-upload-drag-icon">
                                                <Icon type="inbox" />
                                            </p>
                                            <p className="ant-upload-text">
                                                <FormattedMessage {...messages.pdfUploadInfo} />
                                            </p>
                                            <p className="ant-upload-hint">
                                                <FormattedMessage {...messages.pdfUploadSmallInfo} />
                                            </p>
                                        </>
                                    )}
                                </Upload.Dragger>
                            </Form.Item>
                            <Button
                                disabled={!selectionValue && !selectionUploadValue}
                                htmlType="submit"
                                loading={generatedPdf.loading || isUploading}
                                size="large"
                                type="primary"
                            >
                                <FormattedMessage {...FormMessages.validate} />
                            </Button>
                        </form>
                    </Modal>
                    <Modal
                        closeIcon={<IconClose />}
                        onCancel={onClosePricingModal}
                        footer={null}
                        visible={isPricingModalVisible}
                        width={740}
                        wrapClassName="pricing-modal"
                    >
                        <p className="ant-modal-title"><FormattedMessage {...messages.pricingModalTitle} /></p>
                        <Divider />
                        <FormattedMessage {...messages.pricingModalHelp} tagName="p" />
                        <form onSubmit={onSubmitPricing}>
                            <Form.Item>
                                <Upload.Dragger
                                    beforeUpload={beforeUploadPricing}
                                    customRequest={customUploadRequest.bind(null, FileSubType.Pricing)}
                                    listType="picture-card"
                                    showUploadList={false}
                                >
                                    {pricingUploadValue ?
                                        (
                                            <img
                                                src={pricingUploadValue.thumbnail ? pricingUploadValue.thumbnail.url : ''}
                                                alt="pricing"
                                                style={{ width: '100%' }}
                                            />
                                        ) :
                                        (
                                            <>
                                                <p className="ant-upload-drag-icon">
                                                    <Icon type="inbox" />
                                                </p>
                                                <p className="ant-upload-text">
                                                    <FormattedMessage {...messages.pricingUploadInfo} />
                                                </p>
                                                <p className="ant-upload-hint">
                                                    <FormattedMessage {...messages.pricingUploadSmallInfo} />
                                                </p>
                                            </>
                                        )
                                    }
                                </Upload.Dragger>
                            </Form.Item>
                            <Button
                                disabled={!pricingUploadValue}
                                htmlType="submit"
                                loading={isUploading}
                                size="large"
                                type="primary"
                            >
                                <FormattedMessage {...FormMessages.validate} />
                            </Button>
                        </form>
                    </Modal>
                    <Modal
                        closeIcon={<IconClose />}
                        onCancel={onClosePageModal}
                        footer={null}
                        visible={isPageModalVisible}
                        width={740}
                        wrapClassName="pricing-modal"
                    >
                        <p className="ant-modal-title"><FormattedMessage {...messages.pageModalTitle} /></p>
                        <Divider />
                        <FormattedMessage {...messages.pageModalHelp} tagName="p" />
                        <form onSubmit={onSubmitPage}>
                            <Form.Item>
                                <Upload.Dragger
                                    beforeUpload={beforeUploadPricing}
                                    customRequest={customUploadRequest.bind(null, FileSubType.Other)}
                                    listType="picture-card"
                                    showUploadList={false}
                                >
                                    {pageUploadValue ?
                                        (
                                            <img
                                                src={pageUploadValue.thumbnail ? pageUploadValue.thumbnail.url : ''}
                                                alt="page"
                                                style={{ width: '100%' }}
                                            />
                                        ) :
                                        (
                                            <>
                                                <p className="ant-upload-drag-icon">
                                                    <Icon type="inbox" />
                                                </p>
                                                <p className="ant-upload-text">
                                                    <FormattedMessage {...messages.pricingUploadInfo} />
                                                </p>
                                                <p className="ant-upload-hint">
                                                    <FormattedMessage {...messages.pricingUploadSmallInfo} />
                                                </p>
                                            </>
                                        )
                                    }
                                </Upload.Dragger>
                            </Form.Item>
                            <Button
                                disabled={!pageUploadValue}
                                htmlType="submit"
                                loading={isUploading}
                                size="large"
                                type="primary"
                            >
                                <FormattedMessage {...FormMessages.validate} />
                            </Button>
                        </form>
                    </Modal>
                    <Modal
                        closeIcon={<IconClose />}
                        onCancel={onCloseSubChapterModal}
                        footer={null}
                        visible={!!subChaptersParent}
                        width={880}
                        wrapClassName="preamble-modal"
                    >
                        <p className="ant-modal-title"><FormattedMessage {...messages.subChaptersModalTitle} /></p>
                        <Divider style={{ marginBottom: 32 }} />
                        <PresentationSubChaptersSortableList
                            axis="xy"
                            helperClass="sortable-list"
                            items={subChaptersParent ? subChaptersParent.subChapters : []}
                            onClickDeleteItem={onClickDeleteSubChapter}
                            onSortEnd={onSubChaptersSortEnd}
                        />
                    </Modal>
                    <Modal
                        closeIcon={<IconClose />}
                        onCancel={onCloseSaveBeforeConvertModal}
                        footer={null}
                        visible={!!isSaveBeforeConvertModalVisible}
                        width={540}
                        wrapClassName="export-modal"
                    >
                        <p className="ant-modal-title">
                            <FormattedMessage
                                {...(presentation.type === 'presentation' ?
                                    messages.convertPresentationModalTitle :
                                    messages.convertPropositionModalTitle
                                )}
                            />
                        </p>
                        <FormattedMessage
                            {...(presentation.type === 'presentation' ?
                                messages.convertPresentationModalSubTitle :
                                messages.convertPropositionModalSubTitle
                            )}
                            tagName="p"
                        />
                        <div>
                            <Button
                                loading={isWaitingForExport}
                                onClick={onClickExportWithoutSaving}
                            >
                                <FormattedMessage {...messages.exportWithoutSaving} />
                            </Button>
                            <Button
                                loading={isWaitingForCreateAndExport}
                                onClick={onClickSaveAndExport}
                                type="primary"
                            >
                                <FormattedMessage {...messages.saveAndExport} />
                            </Button>
                        </div>
                    </Modal>
                </div>
            </section>
            <footer id="presentation-customize-footer">
                <Button
                    onClick={onClickExport}
                    type="link"
                >
                    <IconExport />
                    <FormattedMessage {...messages.exportToProposition} />
                </Button>
                <div>
                    <Button
                        onClick={onClickPreview}
                        loading={previewState.loading}
                        size="large"
                    >
                        <FormattedMessage {...messages.previewButton} />
                    </Button>
                    <Button
                        onClick={onClickValidate}
                        loading={createState.loading || updateState.loading}
                        type="primary"
                        size="large"
                    >
                        <FormattedMessage {...messages.validateCustomize} />
                    </Button>
                </div>
            </footer>
        </>
    );
};

const mapStateToProps = (state: MainReducerState, { presentation }: { presentation: PresentationData }) => ({
    createState: getCreateState(state),
    convertState: getConvertState(state),
    generatedPdf: getGeneratePdfState(state),
    previewState: getPreviewState(state),
    selections: getSelectionsByCustomerReference(state, presentation.client ? presentation.client.reference : '-1'),
    updateState: getUpdateState(state),
});

export default connect(
    mapStateToProps,
    {
        convert: PresentationsActions.convert,
        create: PresentationsActions.create,
        generatePdf: SelectionsActions.generatePdf,
        getPreview: PresentationsActions.preview,
        update: PresentationsActions.update,
    },
)(PresentationCustomize);
