import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="16" viewBox="0 0 13 16">
        <path fill="currentColor" fillRule="evenodd" d="M9.43 12.598V5.134a.538.538 0 0 0-.54-.533c-.3 0-.543.24-.543.534v7.464c0 .294.242.533.542.533.298 0 .54-.238.54-.532zm-2.388 0V5.134A.538.538 0 0 0 6.5 4.6c-.3 0-.542.24-.542.534v7.464c0 .294.243.533.542.533.3 0 .542-.238.542-.532zm-2.394 0V5.134a.538.538 0 0 0-.542-.533c-.3 0-.542.24-.542.534v7.464c0 .294.243.533.542.533.3 0 .542-.238.542-.532zM5.957 1.15c.383-.1.787-.1 1.17 0h-1.17zm6.5 0h-3.79A2.323 2.323 0 0 0 6.5.006 2.327 2.327 0 0 0 4.333 1.15H.543c-.3 0-.543.24-.543.535 0 .294.243.533.542.533h11.916c.3 0 .542-.24.542-.533a.537.537 0 0 0-.542-.534zm-1.18 1.723c-.3 0-.542.24-.542.534v9.927c0 .883-.728 1.6-1.625 1.6H3.923c-.898.02-1.642-.68-1.663-1.562V3.407a.538.538 0 0 0-.543-.534c-.3 0-.542.24-.542.534v9.927C1.175 14.806 2.388 16 3.883 16h5.195c1.496.017 2.723-1.162 2.74-2.634V3.407a.538.538 0 0 0-.54-.534z"/>
    </svg>
);

const IconTrash: React.FC<IconProps> = (props) => (
    <Icon component={svg} {...props} className={`anticon icon-trash${props.className ? ` ${props.className}` : ''}`} />
);

export default IconTrash;
