import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

interface IconThinAngleProps extends IconProps {
    direction?: 'left' | 'top' | 'right' | 'bottom';
}

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="48" viewBox="0 0 25 48">
        <path fill="currentColor" d="M24.576 47.597c-.25.256-.59.4-.945.403a1.286 1.286 0 0 1-.944-.403L.396 24.975a1.358 1.358 0 0 1 0-1.918L22.685.43c.333-.362.835-.51 1.308-.388.473.123.843.498.964.98.12.48-.025.988-.382 1.327L3.23 24.015l21.346 21.662a1.363 1.363 0 0 1 0 1.92z" />
    </svg>
);

const IconThinAngle: React.FC<IconThinAngleProps> = ({direction = 'left', ...props}) => (
    <Icon
        component={svg}
        className={`icon-thin-angle rotate-${direction}`}
        {...props}
    />
);

export default IconThinAngle;
