export const PREVIEW = 'presentations/PREVIEW';
export const PREVIEW_SUCCESS = 'presentations/PREVIEW_SUCCESS';
export const PREVIEW_FAILED = 'presentations/PREVIEW_FAILED';

export const CREATE = 'presentations/CREATE';
export const CREATE_SUCCESS = 'presentations/CREATE_SUCCESS';
export const CREATE_FAILED = 'presentations/CREATE_FAILED';

export const CONVERT = 'presentations/CONVERT';
export const CONVERT_SUCCESS = 'presentations/CONVERT_SUCCESS';
export const CONVERT_FAILED = 'presentations/CONVERT_FAILED';

export const LIST = 'presentations/LIST';
export const LIST_SUCCESS = 'presentations/LIST_SUCCESS';
export const LIST_FAILED = 'presentations/LIST_FAILED';

export const DELETE = 'presentations/DELETE';
export const DELETE_SUCCESS = 'presentations/DELETE_SUCCESS';
export const DELETE_FAILED = 'presentations/DELETE_FAILED';

export const DETAILS = 'presentations/DETAILS';
export const DETAILS_SUCCESS = 'presentations/DETAILS_SUCCESS';
export const DETAILS_FAILED = 'presentations/DETAILS_FAILED';

export const UPDATE = 'presentations/UPDATE';
export const UPDATE_SUCCESS = 'presentations/UPDATE_SUCCESS';
export const UPDATE_FAILED = 'presentations/UPDATE_FAILED';
