import React, { useCallback, useEffect, useState, useContext } from 'react';
import { RouteChildrenProps, useParams, useLocation, useHistory } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Row, Col, Pagination } from 'antd';

import '../../assets/styles/Products.less';

import * as FiltersActions from '../../store/actions/filters';
import { getProductsAndMergedFiltersBySectorIds, MergedFiltersAndProducts } from '../../store/reducers/products';
import { getSectorById, getSectorsByParentId } from '../../store/reducers/sectors';
import { MainReducerState } from '../../store/reducers';
import { Sector } from '../../store/api/types';

import BreadCrumb from '../../components/BreadCrumb';
import { getRoute, RoutePathName } from '../../routes';
import { IconHeroInvertedTriangle, IconPlaceholder } from '../../components/icons';
import ProductCard from '../../components/ProductCard';
import messages from './Products.messages';
import { ProductsSolutionsFilter, ProductsAssortmentsFilter, ProductsSidebar, sectorIconsMap } from '.';
import { constants, scrollToElement, t } from '../../utils';
import { FiltersState, getFilters } from '../../store/reducers/filters';
import Img from '../../components/Img';
import { SectorColorContext } from '../../components/SectorColorContextProvider';

interface MatchParams {
    sectorId: string;
    solutionId: string;
    subSectorId: string;
    assortmentId?: string;
}

interface ProductsProps extends RouteChildrenProps<MatchParams> {
    filters: FiltersState;
    gammes?: MergedFiltersAndProducts['gammes'];
    mergedFilters?: MergedFiltersAndProducts['mergedFilters'];
    products?: MergedFiltersAndProducts['products'];
    sector?: Sector;
    services?: MergedFiltersAndProducts['services'];
    setFilters: typeof FiltersActions.setFilters;
    solution?: Sector;
    solutions: Sector[];
    subSector?: Sector;
}

const Products: React.FC<ProductsProps> = ({
    filters, gammes, mergedFilters, products, sector, services, setFilters, solution, solutions,
    subSector,
}) => {
    const { locale } = useIntl();
    const history = useHistory();
    const location = useLocation();
    const { color, setColor } = useContext(SectorColorContext);
    const { sectorId, solutionId, subSectorId } = useParams();
    const [argumentsOrder, setArgumentsOrder] = useState<number[]>([]);
    const onChangePage = useCallback((page) => {
        const urlParams = new URLSearchParams(location.search);
        const element = document.getElementById('product-search-results');

        scrollToElement(element, 100);

        urlParams.set('page', page);

        history.push({
            pathname: location.pathname,
            search: urlParams.toString(),
        });
    }, [history, location.pathname, location.search]);

    // set filters from url query params when the component mounts
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const filtersParam = queryParams.get('filters');

        setFilters(filtersParam ? JSON.parse(filtersParam) as FiltersState : {});
    }, [location.pathname]); // eslint-disable-line react-hooks/exhaustive-deps

    // set arguments order from filters
    useEffect(() => {
        setArgumentsOrder(
            filters.arguments ?
                [...filters.arguments].sort((a, b) => b.rating - a.rating).map((i) => i.id) :
                [],
        );
    }, [filters]);

    // set color based on solution color (eg for pest control)
    useEffect(() => {
        if (solution) {
            setColor(solution.color);
        }
    }, [setColor, solution]);

    const routes = [];

    if (
        sector !== undefined &&
        sectorId !== undefined &&
        subSectorId !== undefined &&
        solutionId !== undefined
    ) {
        routes.push({
            link: getRoute(RoutePathName.sector, { id: sectorId }),
            text: sector && t(sector.name, locale) ? t(sector.name, locale) : '',
        },
        {
            link: getRoute(RoutePathName.products, {
                sectorId,
                subSectorId,
                solutionId,
                assortmentId: undefined,
            }),
            text: solution && t(solution.name, locale) ? t(solution.name, locale) : '',
        });

        if (!sector.skipSubLevel) {
            routes.splice(1, 0, {
                link: getRoute(RoutePathName.subSector, {
                    id: subSectorId,
                    sectorId,
                }),
                text: subSector && t(subSector.name, locale) ? t(subSector.name, locale) : '',
            });
        }
    }

    const Icon = solution && solution.icon ?
        sectorIconsMap[solution.icon] :
        IconPlaceholder;

    return (
        <>
            <BreadCrumb
                routes={routes}
            />
            <header className="page-hero product-hero" style={color ? { backgroundColor: color } : undefined}>
                {sector && sector.image && <Img imageId={sector.image.id} alt={t(sector.name, locale)} />}
                <Icon className="product-icon" />
                <div className="container">
                    <Row>
                        <Col xl={15} lg={18} md={16} className="page-hero-title-col">
                            <h2>{subSector ? t(subSector.name, locale) : ''}</h2>
                            <h1>{solution ? t(solution.name, locale) : ''}</h1>
                        </Col>
                    </Row>
                    <div id="bottom-triangle">
                        <IconHeroInvertedTriangle />
                    </div>
                </div>
            </header>
            <div className="container m-b-56">
                <Row gutter={32}>
                    <Col xl={6} lg={8} xs={10}>
                        <ProductsSidebar
                            args={mergedFilters && mergedFilters.arguments}
                            filters={filters}
                            properties={mergedFilters && mergedFilters.properties}
                            solution={solution}
                        />
                    </Col>
                    <Col xl={18} lg={16} xs={14}>
                        <ProductsSolutionsFilter solutions={solutions} />
                        <ProductsAssortmentsFilter
                            gammes={gammes}
                            services={services}
                            sectorId={sectorId}
                            subSectorId={subSectorId}
                            solutionId={solutionId}
                            solution={solution}
                        />
                        <p id="product-search-results">
                            <FormattedMessage
                                {...messages.searchResults}
                                values={{
                                    b: (...m: string[]) => <strong>{m}</strong>,
                                    count: products ? products.total : 0,
                                }}
                            />
                        </p>
                        <section id="products-wrapper">
                            {!!sectorId && !!subSectorId && !!solutionId && products && products.data.map((product) => (
                                <ProductCard
                                    argumentsOrder={argumentsOrder}
                                    name={t(product.label, locale)}
                                    image={product.images && product.images[0]}
                                    link={getRoute(
                                        RoutePathName.productFromSolution,
                                        {
                                            sectorId,
                                            subSectorId,
                                            solutionId,
                                            id: product.id,
                                        },
                                        Object.keys(filters).length ?
                                            { filters: JSON.stringify(filters) } :
                                            undefined,
                                    )}
                                    key={product.id}
                                    product={product}
                                    sectorIds={[
                                        parseInt(sectorId, 10),
                                        parseInt(subSectorId, 10),
                                        parseInt(solutionId, 10),
                                    ]}
                                    withAddToSelection
                                    isBig
                                />
                            ))}
                        </section>
                        {products && products.total > constants.PAGE_SIZE && (
                            <div id="products-pagination">
                                <Pagination
                                    current={products.page}
                                    onChange={onChangePage}
                                    pageSize={constants.PAGE_SIZE}
                                    total={products.total}
                                />
                            </div>
                        )}
                    </Col>
                </Row>
            </div>
        </>
    );
};

const mapStateToProps = (state: MainReducerState, { location, match }: ProductsProps) => {
    const sectorId = parseInt(match!.params.sectorId, 10);
    const subSectorId = parseInt(match!.params.subSectorId, 10);
    const solutionId = parseInt(match!.params.solutionId, 10);
    const assortmentId = match!.params.assortmentId !== undefined ?
        parseInt(match!.params.assortmentId, 10) :
        undefined;
    const queryParams = new URLSearchParams(location.search);
    const pageQuery = queryParams.get('page');
    const page = pageQuery ? parseInt(pageQuery, 10) : 1;
    const productsAndMergedFilters = getProductsAndMergedFiltersBySectorIds(state, [
        sectorId,
        subSectorId,
        solutionId,
    ], page, assortmentId);

    return {
        filters: getFilters(state),
        gammes: productsAndMergedFilters ? productsAndMergedFilters.gammes : undefined,
        mergedFilters: productsAndMergedFilters ? productsAndMergedFilters.mergedFilters : undefined,
        products: productsAndMergedFilters ? productsAndMergedFilters.products : undefined,
        sector: getSectorById(state, sectorId),
        services: productsAndMergedFilters ? productsAndMergedFilters.services : undefined,
        subSector: getSectorById(state, subSectorId),
        solution: getSectorById(state, solutionId),
        solutions: getSectorsByParentId(state, subSectorId),
    };
};

export default connect(
    mapStateToProps,
    {
        setFilters: FiltersActions.setFilters,
    },
)(Products);
