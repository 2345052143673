import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { OfflineState } from '@redux-offline/redux-offline/lib/types';
import { Spin, Tooltip, Badge, Button, Icon } from 'antd';

import '../assets/styles/OfflineBadge.less';

import * as AllDataActions from '../store/actions/allData';
import { MainReducerState, getOfflineState } from '../store/reducers';
import { getAllDataState, AllDataState } from '../store/reducers/allData';

import GenericMessages from '../locale/Generic.messages';
import { IconOffline, IconOfflineSync } from './icons';

interface OfflineBadgeProps {
    allData: AllDataState;
    offline?: OfflineState;
    synchronize: typeof AllDataActions.checkData;
}

const OfflineBadge: React.FC<OfflineBadgeProps> = ({ allData, offline, synchronize }) => {
    const { locale } = useIntl();
    const { online, outbox } = offline!;
    const [isOfflineSyncVisible, setIsOfflineSyncVisible] = useState(false);

    useEffect(() => {
        let timeout: any = 0;

        if (online) {
            if (!outbox.length) {
                clearTimeout(timeout);
                setIsOfflineSyncVisible(false);
            } else {
                timeout = setTimeout(() => {
                    setIsOfflineSyncVisible(true);
                }, 1000);
            }
        } else {
            clearTimeout(timeout);
            setIsOfflineSyncVisible(true);
        }

        return () => clearTimeout(timeout);
    }, [online, outbox.length]);

    return (
        <div id="offline-badge">
            {online && isOfflineSyncVisible && (
                <Tooltip
                    placement="bottomRight"
                    title={<FormattedMessage {...GenericMessages.offlineSaving} />}
                >
                    <Spin spinning>
                        <IconOfflineSync />
                    </Spin>
                </Tooltip>
            )}
            {online && !outbox.length && (
                <Tooltip
                    placement="bottomRight"
                    title={<FormattedMessage {...GenericMessages.offlineSyncButton} />}
                >
                    <Button type="link" onClick={synchronize.bind(null, locale, true)}>
                        <Icon type="sync" spin={allData.loading} />
                    </Button>
                </Tooltip>
            )}
            {!online && !outbox.length && (
                <Tooltip
                    placement="bottomRight"
                    title={allData.dataMayBeStale ?
                        <FormattedMessage {...GenericMessages.offlineStaleDataTooltip} /> :
                        <FormattedMessage {...GenericMessages.offlineTooltip} />
                    }
                >
                    {
                        allData.dataMayBeStale ? (
                            <Badge dot>
                                <IconOffline />
                            </Badge>
                        ) : (
                            <IconOffline />
                        )
                    }
                </Tooltip>
            )}
            {!online && !!outbox.length && isOfflineSyncVisible  && (
                <Tooltip
                    placement="bottomRight"
                    title={<FormattedMessage {...GenericMessages.offlineWithChangesTooltip} />}
                >
                    <IconOfflineSync />
                </Tooltip>
            )}
        </div>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    offline: getOfflineState(state),
    allData: getAllDataState(state),
});

export default connect(
    mapStateToProps,
    {
        synchronize: AllDataActions.checkData,
    },
)(OfflineBadge);
