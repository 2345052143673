import React, { FC, useState, useEffect, useContext } from 'react';
import ReactGA from 'react-ga';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, List, Spin } from 'antd';

import '../../assets/styles/Presentation.less';

import * as ThemesActions from '../../store/actions/themes';
import * as PresentationsActions from '../../store/actions/presentations';
import { Chapter, Theme, SubChapter } from '../../store/api/types';
import { MainReducerState } from '../../store/reducers';
import { getFilteredThemesState, ThemesState } from '../../store/reducers/themes';
import { getPreviewState, PresentationsState } from '../../store/reducers/presentations';

import { t } from '../../utils';
import { PresentationContext, PresentationSteps, PresentationData } from '../PresentationContextProvider';
import ListItem, { ListItemProps } from '../ListItem';
import EmptyResult from '../EmptyResult';
import { IconClose } from '../icons';
import messages from './Presentation.messages';
import ChapterCard from './ChapterCard';
import ThemeCheckbox from './ThemeCheckbox';

interface SubChaptersRow {
    chapter: Chapter;
    selectedCellIndex: number | undefined;
    rowIndex: number | undefined;
    subChapters: SubChapter[];
}

type UIChaptersRowItems = Chapter[] | SubChaptersRow;

interface PresentationChaptersProps {
    getPreview: typeof PresentationsActions.preview;
    listThemes: typeof ThemesActions.list;
    previewState: PresentationsState['preview'];
    sector?: PresentationData['sector'];
    themesState: ThemesState;
}

const PresentationChapters: FC<PresentationChaptersProps> = ({ getPreview, listThemes, previewState, themesState }) => {
    const { locale } = useIntl();
    const { getPresentationPayload, presentation, setStep, setPreviewFrom } = useContext(PresentationContext);
    const [isWaitingForPreview, setIsWaitingForPreview] = useState<boolean>(false);
    const [currentVisibleTheme, setCurrentVisibleTheme] = useState<Theme>();
    const [subChaptersRow, setSubChaptersRow] = useState<SubChaptersRow>();
    const [openChapterId, setOpenChapterId] = useState<Chapter['id']>();
    const onSeeSubChapters = (rowIndex: number | undefined, cellIndex: number | undefined, chapter: Chapter) => {
        setSubChaptersRow({
            chapter,
            selectedCellIndex: cellIndex,
            rowIndex,
            subChapters: chapter.subChapters,
        });
        setOpenChapterId(chapter.id);
    };
    const onClickCloseSubChapters = () => {
        setOpenChapterId(undefined);
        setSubChaptersRow(undefined);
    };
    const isChaptersRow = (row: Chapter[] | SubChaptersRow): row is Chapter[] => Array.isArray(row as Chapter[]);
    const onThemeClick: ListItemProps['onClick'] = (e) => {
        if (themesState.data) {
            const themeId = e.currentTarget.dataset.id ? parseInt(e.currentTarget.dataset.id, 10) : -1;
            setCurrentVisibleTheme(themesState.data.find((theme) => theme.id === themeId));
            onClickCloseSubChapters();
        }
    };
    const onClickPreview = () => {
        if (themesState.data) {
            getPreview(getPresentationPayload(locale));
            setIsWaitingForPreview(true);
        }
    };
    const onClickCustomize = () => {
        setStep(PresentationSteps.Informations);
        ReactGA.event({
            category: 'présentation/proposition',
            action: 'personnaliser',
            value: 1,
        });
    };

    useEffect(() => {
        if (isWaitingForPreview && !previewState.loading && previewState.success && previewState.data) {
            setIsWaitingForPreview(false);
            setPreviewFrom(PresentationSteps.Chapters);
            setStep(PresentationSteps.Preview);
        }
    }, [isWaitingForPreview, previewState.loading, previewState.success, previewState.data, setStep, setPreviewFrom]);

    useEffect(() => {
        listThemes({
            type: presentation.type,
            language: locale,
            sectorId: presentation.sector ? presentation.sector.id : undefined,
            limit: 1337,
        });
    }, [listThemes]); // eslint-disable-line react-hooks/exhaustive-deps

    const numberOfItemsPerRow = presentation.type === 'presentation' ? 4 : 6;

    const chapterRows: UIChaptersRowItems[] = currentVisibleTheme ?
        currentVisibleTheme.chapters.reduce<Chapter[][]>((result, chapter, index) => {
            const rowIndex = Math.floor(index / numberOfItemsPerRow);
            const cellIndex = index % numberOfItemsPerRow;

            if (cellIndex === 0) {
                result[rowIndex] = [chapter];
            } else {
                result[rowIndex].push(chapter);
            }

            return result;
        }, []) :
        [];

    if (subChaptersRow && subChaptersRow.rowIndex !== undefined) {
        chapterRows.splice(subChaptersRow.rowIndex + 1, 0, subChaptersRow);
    }

    return (
        <>
            <section>
                <div id="presentation-chapters-wrapper">
                    <div id="presentation-themes">
                        <FormattedMessage {...messages.chooseATheme} tagName="p" />
                        <Spin spinning={themesState.loading}>
                            <List>
                                {themesState.data && !!themesState.data.length && themesState.data.map((theme) => (
                                    <List.Item key={theme.id}>
                                        <ListItem
                                            data-id={theme.id}
                                            onClick={onThemeClick}
                                            title={theme.name[locale]}
                                            noOnClickIcon
                                        >
                                            <ThemeCheckbox theme={theme} />
                                        </ListItem>
                                    </List.Item>
                                ))}
                                {themesState.data && !themesState.data.length && (
                                    <List.Item key="empty">
                                        <EmptyResult />
                                    </List.Item>
                                )}
                            </List>
                        </Spin>
                    </div>
                    <div
                        id="presentation-chapters"
                        className={presentation.type === 'proposition' ? 'proposition' : undefined}
                    >
                        {!currentVisibleTheme && (
                            <div id="presentation-choose-theme">
                                {presentation.selectedChapters.length ? (
                                    <FormattedMessage
                                        {...messages.amountOfSelectedChapters}
                                        values={{
                                            count: presentation.selectedChapters.length,
                                        }}
                                        tagName="p"
                                    />
                                ) : (
                                    <FormattedMessage
                                        {...messages.chooseThemeToStart}
                                        tagName="p"
                                    />
                                )}
                            </div>
                        )}
                        {currentVisibleTheme && (
                            <>
                                <FormattedMessage
                                    {...messages.availableChapters}
                                    values={{
                                        count: currentVisibleTheme ? currentVisibleTheme.chapters.length : 0,
                                        theme: t(currentVisibleTheme.name, locale),
                                    }}
                                    tagName="p"
                                />
                                <div id="presentation-chapters-grid">
                                    {chapterRows.map((row, index: number) => {
                                        if (isChaptersRow(row)) {
                                            return (
                                                <div key={index} className="chapters-row">
                                                    {row.map((chapter, cellIndex) => (
                                                        <ChapterCard
                                                            cellIndex={cellIndex}
                                                            chapter={chapter}
                                                            key={chapter.id}
                                                            onSeeSubChapters={onSeeSubChapters}
                                                            rowIndex={index}
                                                            isOpen={chapter.id === openChapterId}
                                                        />
                                                    ))}
                                                </div>
                                            );
                                        } else {
                                            return (
                                                <div key={index} className="sub-chapters-row">
                                                    <div
                                                        className="sub-chapters-row-arrow"
                                                        style={row.selectedCellIndex !== undefined ? {
                                                            left: `${25 * (row.selectedCellIndex + 1) - 12.5}%`,
                                                        } : undefined}
                                                    />
                                                    <header>
                                                        <FormattedMessage
                                                            {...messages.availableSubChapters}
                                                            values={{
                                                                count: row.subChapters.length,
                                                            }}
                                                            tagName="p"
                                                        />
                                                        <Button onClick={onClickCloseSubChapters}>
                                                            <IconClose />
                                                        </Button>
                                                    </header>
                                                    <div key={index} className="chapters-row">
                                                        {row.subChapters.map((chapter) => (
                                                            <ChapterCard
                                                                chapter={chapter}
                                                                parent={row.chapter}
                                                                key={chapter.id}
                                                            />
                                                        ))}
                                                    </div>
                                                </div>
                                            );
                                        }
                                    })}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </section>
            <footer>
                <Button
                    disabled={!presentation.selectedChapters.length}
                    loading={previewState.loading}
                    onClick={onClickPreview}
                    size="large"
                >
                    <FormattedMessage {...messages.previewButton} />
                </Button>
                <Button
                    disabled={!presentation.selectedChapters.length}
                    onClick={onClickCustomize}
                    type="primary"
                    size="large"
                >
                    <FormattedMessage
                        {...(presentation.type === 'presentation' ?
                            messages.customizePresentationButton :
                            messages.customizePropositionButton
                        )}
                    />
                </Button>
            </footer>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    themesState: getFilteredThemesState(state),
    previewState: getPreviewState(state),
});

export default connect(
    mapStateToProps,
    {
        listThemes: ThemesActions.list,
        getPreview: PresentationsActions.preview,
    },
)(PresentationChapters);
