import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="60" viewBox="0 0 80 60">
        <g fill="none" fillRule="evenodd" opacity=".22"><path fill="#4F657A" d="M78.394 16.867c-.02-.024-.042-.047-.06-.07-.272-.324-.5-.514-.52-.533-.18-.168-14.682-13.9-15.803-15.012-.19-.19-.39-.348-.59-.48C60.21-.017 58.69 0 58.69 0H29.446c-.484 0-.775.016-.85.03-.633.03-1.084.287-1.37.638-.02.02-.037.052-.06.078-.627.793-.458 2.17.36 2.99 1.093 1.093 10.018 10.018 12.43 12.435 2.107 2.108 1.975 3.482 1.996 3.54.012.04.067.46-.225 1.165-.23.59-.698 1.414-1.658 2.41-.302.315-.73.755-1.212 1.237l-.533.54a31375.71 31375.71 0 0 1-10.767 10.77c-.905.906-1.018 2.442-.207 3.212.288.277.69.49 1.246.52.284.012.555.03.84.03H58.69s1.51.016 2.718-.765a3.62 3.62 0 0 0 .603-.487c1.122-1.108 15.622-14.84 15.803-15.012.02-.018.235-.193.495-.5a.897.897 0 0 1 .044-.05c.45-.547 1.142-1.577 1.142-2.91v-.143c0-1.297-.655-2.307-1.1-2.86" /><path fill="#7D8C9A" d="M29.44 42.406c-.277 0-.538-.01-.807-.026l-.184-.01a4.604 4.604 0 0 1-2.976-1.27c-.9-.857-1.404-2.06-1.42-3.387a5.44 5.44 0 0 1 1.578-3.89l1.004-1.006.39-.387 9.375-9.38.37-.37.07-.075c-.824-.79-1.43-1.367-1.615-1.555a3.753 3.753 0 0 0-.593-.48c-1.212-.788-2.73-.772-2.732-.772H2.66c-.486 0-.777.014-.854.03-.632.028-1.08.286-1.366.635-.024.024-.04.055-.06.08-.627.794-.458 2.17.36 2.99 1.093 1.094 10.02 10.02 12.43 12.433 2.105 2.108 1.974 3.482 1.994 3.543.012.04.067.46-.224 1.163-.234.588-.7 1.414-1.66 2.41-.41.43-1.013 1.043-1.742 1.773l-9.376 9.38C1.472 54.928.97 55.428.77 55.63c-.905.905-1.017 2.44-.207 3.212.288.274.69.487 1.243.52.286.012.558.028.844.028H31.9c.002 0 1.51.015 2.72-.763.205-.133.408-.295.605-.487 1.116-1.107 15.62-14.84 15.8-15.01.02-.02.233-.198.496-.503.013-.014.032-.035.043-.05.042-.05.088-.115.128-.17H29.44z" /></g>
    </svg>
);

const IconPlaceholder: React.FC<IconProps> = (props) => (
    <Icon component={svg} {...props} className={`anticon icon-placeholder${props.className ? ` ${props.className}` : ''}`} />
);

export default IconPlaceholder;
