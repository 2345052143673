import { defineMessages } from 'react-intl';

export default defineMessages({
    logout: {
        id: 'logout',
        defaultMessage: 'Log out',
        description: 'User menu item logout',
    },
    error: {
        id: 'generic_error',
        defaultMessage: 'An error occured, please try again later or contact support',
        description: 'Generic error mostly due to server 5xx error',
    },
    goBackToHome: {
        id: 'go_back_to_home',
        defaultMessage: 'Go back to the homepage',
        description: 'Button text to go back to the homepage',
    },
    copyright: {
        id: 'copyright',
        defaultMessage: '\u00A9{date} Elis - All rights reserved.',
        description: 'Footer copyright text',
    },
    terms: {
        id: 'terms',
        defaultMessage: 'Terms and conditions',
        description: 'Footer terms link text',
    },
    howItWorks: {
        id: 'how_it_works',
        defaultMessage: 'How it works?',
        description: 'Footer how it works link text',
    },
    readMore: {
        id: 'read_more',
        defaultMessage: 'Read more',
        description: 'Read more',
    },
    commercialPresentation: {
        id: 'commercial_presentation',
        defaultMessage: 'Commercial presentation',
        description: 'Commercial presentation',
    },
    home: {
        id: 'home',
        defaultMessage: 'Home',
        description: 'Home',
    },
    new: {
        id: 'new',
        defaultMessage: 'New',
        description: 'New',
    },
    bestSeller: {
        id: 'best_seller',
        defaultMessage: 'Best seller',
        description: 'Best seller',
    },
    name: {
        id: 'name',
        defaultMessage: 'Name',
        description: 'Name',
    },
    checkingData: {
        id: 'checking_data',
        defaultMessage: 'Checking site data',
        description: 'Data checking overlay message',
    },
    offlineTooltip: {
        id: 'offline_tooltip',
        defaultMessage: 'You are offline',
        description: 'Offline message',
    },
    offlineStaleDataTooltip: {
        id: 'offline_stale_data_tooltip',
        defaultMessage: 'You have been offline for some time, new data may be available.',
        description: 'Offline message',
    },
    offlineWithChangesTooltip: {
        id: 'offline_with_changes_tooltip',
        defaultMessage: 'You have non saved changes, they will be saved once you go online.',
        description: 'Offline message',
    },
    offlineSaving: {
        id: 'offline_saving',
        defaultMessage: 'Saving...',
        description: 'Offline message',
    },
    offlineSyncButton: {
        id: 'offline_sync_button',
        defaultMessage: 'Force synchronize',
        description: 'Offline message',
    },
    youMustBeOnline: {
        id: 'online_restricted',
        defaultMessage: 'You must be online to use this feature',
        description: 'Offline message',
    },
    areYouSure: {
        id: 'are_you_sure',
        defaultMessage: 'Are you sure?',
        description: 'Confirm title',
    },
    choose: {
        id: 'choose',
        defaultMessage: 'Choose',
        description: 'Choose',
    },
    change: {
        id: 'change',
        defaultMessage: 'Change',
        description: 'Change',
    },
    download: {
        id: 'download',
        defaultMessage: 'Download',
        description: 'Download',
    },
    cancel: {
        id: 'cancel',
        defaultMessage: 'Cancel',
        description: 'Cancel',
    },
    back: {
        id: 'back',
        defaultMessage: 'Back',
        description: 'Back',
    },
    client: {
        id: 'client',
        defaultMessage: 'Client',
        description: 'Client',
    },
    or: {
        id: 'or',
        defaultMessage: 'Or',
        description: 'Or',
    },
    notEnoughData: {
        id: 'not_enough_data',
        defaultMessage: 'You do not have sufficient data to use this app offline. Please connect to the internet to update the content.',
        description: 'Error message displayed when the app doesn\'t have enough locally stored data to be used.',
    },
    yes: {
        id: 'yes',
        defaultMessage: 'Yes',
        description: 'Yes',
    },
    no: {
        id: 'no',
        defaultMessage: 'No',
        description: 'No',
    },
});
