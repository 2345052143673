import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router';
import { Divider } from 'antd';

import '../../assets/styles/Products.less';

import { MergedFiltersAndProducts } from '../../store/reducers/products';

import messages from './Products.messages';
import Carousel from '../../components/Carousel';
import ProductCard from '../../components/ProductCard';
import { SectorColorContext } from '../../components/SectorColorContextProvider';
import { getRoute, RoutePathName } from '../../routes';
import { t } from '../../utils';
import { Sector } from '../../store/api/types';

interface ProductsAssortmentsFilterProps {
    gammes: MergedFiltersAndProducts['gammes'];
    services: MergedFiltersAndProducts['services'];
    sectorId?: string;
    subSectorId?: string;
    solutionId?: string;
    solution?: Sector;
}

const ProductsAssortmentsFilter: React.FC<ProductsAssortmentsFilterProps> = ({
    gammes, services, sectorId, subSectorId, solutionId,
}) => {
    const { color } = useContext(SectorColorContext);
    const { locale } = useIntl();
    const { assortmentId } = useParams();
    const selectedAssortmentId = assortmentId ? parseInt(assortmentId, 10) : undefined;
    const history = useHistory();

    let assortments: MergedFiltersAndProducts['gammes'] | MergedFiltersAndProducts['services'] | null = null;
    let filterDestination: 'gamme' | 'service' | null = null;

    if (gammes && gammes.length) {
        assortments = gammes;
        filterDestination = 'gamme';
    }

    if (!assortments && services && services.length) {
        assortments = services;
        filterDestination = 'service';
    }

    const onCardClick = (e: any) => {
        const valueId = parseInt(e.currentTarget.dataset.id, 10);
        const newValue = valueId === selectedAssortmentId ? undefined : valueId;

        if (assortments && sectorId && subSectorId && solutionId) {
            history.push(getRoute(RoutePathName.products, {
                sectorId,
                subSectorId,
                solutionId,
                assortmentId: newValue,
            }));
        }
    };

    if (!assortments) {
        return null;
    }

    return (
        <section id="products-assortments" className="bg-f0 m-b-32 product-filter-carousel">
            <h3 style={color ? { color } : undefined}>
                {filterDestination === 'gamme' ?
                    <FormattedMessage {...messages.assortments} /> :
                    <FormattedMessage {...messages.services} />
                }
            </h3>
            <Divider style={color ? { backgroundColor: color } : undefined} />
            <Carousel
                lazyLoad="ondemand"
                slidesToShow={assortments.length < 4 ? assortments.length : 4}
                slidesToScroll={assortments.length < 4 ? assortments.length : 4}
                responsive={[
                    {
                        breakpoint: 1366,
                        settings: {
                            slidesToShow: assortments.length < 4 ? assortments.length : 4,
                            slidesToScroll: assortments.length < 4 ? assortments.length : 4,
                        },
                    },
                    {
                        breakpoint: 992,
                        settings: {
                            slidesToShow: assortments.length < 3 ? assortments.length : 3,
                            slidesToScroll: assortments.length < 3 ? assortments.length : 3,
                        },
                    },
                    {
                        breakpoint: 884,
                        settings: {
                            slidesToShow: assortments.length < 2 ? assortments.length : 2,
                            slidesToScroll: assortments.length < 2 ? assortments.length : 2,
                        },
                    },
                    {
                        breakpoint: 580,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },
                ]}
                isSmall
            >
                {assortments.map((assortment) => (
                    <ProductCard
                        className={
                            selectedAssortmentId !== undefined ?
                                (selectedAssortmentId === assortment.id ?
                                    'product-card-active' :
                                    'product-card-inactive'
                                ) :
                                undefined
                        }
                        dataId={assortment.id}
                        image={assortment.image || undefined}
                        key={assortment.id}
                        name={t(assortment.name, locale)}
                        onClick={onCardClick}
                    />
                ))}
            </Carousel>
        </section>
    );
};

export default ProductsAssortmentsFilter;
