import { all, takeLatest, put, select } from 'redux-saga/effects';

import * as allDataActions from '../actions/allData';
import * as clientsActions from '../actions/clients';
import * as countriesActions from '../actions/countries';
import * as faqActions from '../actions/faq';
import * as presentationsActions from '../actions/presentations';
import * as profilesActions from '../actions/profiles';
import * as sectorsActions from '../actions/sectors';
import * as selectionsActions from '../actions/selections';
import * as termsActions from '../actions/terms';
import * as reduxTypes from '../types/allData';
import * as clientsApi from '../api/clients';
import * as countriesApi from '../api/countries';
import * as faqApi from '../api/faq';
import * as presentationsApi from '../api/presentations';
import * as profilesApi from '../api/profiles';
import * as sectorsApi from '../api/sectors';
import * as selectionsApi from '../api/selections';
import { Languages } from '../../components/IntlProvider';
import { getSaga as naceGetSaga } from './nace';
import { listSaga as favoritesListSaga } from './favorites';
import { configSaga as FAQConfigSaga } from './faq';
import { fetchSaga as termsFetchSaga } from './terms';
import fetchAllProducts, { createProductSearchIndex } from './products';
import fetchAllImages from './images';
import { fetchCurrentSaga as fetchCurrentSelection } from './selections';
import { fetchAllPagesSaga } from '.';
import { getProducts } from '../reducers/products';
import { getOfflineState } from '../reducers';
import { getSectorsState } from '../reducers/sectors';
import { getAllDataState } from '../reducers/allData';
import { getImagesState } from '../reducers/images';
import { getCountriesState } from '../reducers/countries';
import { getProfilesState } from '../reducers/profiles';
import { getClientsListState } from '../reducers/clients';
import { getSelectionsState } from '../reducers/selections';
import { getNaceState } from '../reducers/nace';
import { getFAQListState, getFAQConfigState } from '../reducers/faq';
import { getTermsState } from '../reducers/terms';

export function* fetchSaga(locale: Languages) {
    try {
        // console.info('[FETCH] Start');
        yield put(allDataActions.fetch());

        yield all([
            fetchAllPagesSaga(sectorsApi.list, {}, sectorsActions.listSuccess, sectorsActions.listFailed),
            fetchAllProducts(locale),
            fetchAllImages(),
            fetchAllPagesSaga(countriesApi.list, {}, countriesActions.listSuccess, countriesActions.listFailed),
            fetchAllPagesSaga(profilesApi.list, {}, profilesActions.listSuccess, profilesActions.listFailed),
            fetchAllPagesSaga(clientsApi.list, {}, clientsActions.listSuccess, clientsActions.listFailed),
            favoritesListSaga(),
            fetchAllPagesSaga(selectionsApi.history, {}, selectionsActions.historySuccess, selectionsActions.historyFailed), // eslint-disable-line
            fetchAllPagesSaga(presentationsApi.list, {}, presentationsActions.listSuccess, presentationsActions.listFailed), // eslint-disable-line
            fetchAllPagesSaga(faqApi.list, {}, faqActions.listSuccess, faqActions.listFailed),
            FAQConfigSaga(),
            termsFetchSaga(termsActions.fetch(locale)),
            fetchCurrentSelection(),
            naceGetSaga(),
        ]);

        // console.info('[FETCH] Success');

        return yield put(allDataActions.fetchSuccess());
    } catch (error) {
        // console.info('[FETCH] ERROR', error);
        return yield put(allDataActions.fetchFailed(error));
    }
}

export function* checkDataSaga({ locale, force }: any) {
    // console.group('[CHECK_DATA]');
    try {
        const state = yield select();
        const allDataState = getAllDataState(state);
        let lastFetchTimestamp = allDataState.lastFetchTimestamp;
        let dataMayBeStale = false;
        let hasEnoughData = false;

        // if we want to force synchronize, just fetch
        if (force) {
            // console.info('Force refresh');
            yield fetchSaga(locale);
            lastFetchTimestamp = (new Date()).getTime();
        } else {
            const offlineState = getOfflineState(state);
            const sectorsState = getSectorsState(state);
            const productsState = getProducts(state);
            const imagesState = getImagesState(state);
            const countriesState = getCountriesState(state);
            const profilesState = getProfilesState(state);
            const clientsListState = getClientsListState(state);
            const selectionsState = getSelectionsState(state);
            const FAQListState = getFAQListState(state);
            const FAQConfigState = getFAQConfigState(state);
            const naceState = getNaceState(state);
            const termsState = getTermsState(state);

            // console.group('check data');
            // console.info('sectors', !!sectorsState.data && !!sectorsState.data.length, sectorsState.data);
            // console.info('products', !!productsState.data && !!productsState.data.length, productsState.data);
            // console.info('images', !!imagesState.data && !!Object.keys(imagesState.data).length, imagesState.data);
            // console.info('countries', !!countriesState.data && !!countriesState.data.length, countriesState.data);
            // console.info('profiles', !!profilesState.data && !!profilesState.data.length, profilesState.data);
            // console.info('clients list',
            //    !!clientsListState.data && !!clientsListState.data.length, clientsListState.data);
            // console.info('selection current', !!selectionsState.current.data, selectionsState.current.data);
            // console.info('selection history', !!selectionsState.history.success, selectionsState.history);
            // console.info('nace', !!naceState.data && !!naceState.data.length, naceState.data);
            // console.info('faq list', !!FAQListState.data, FAQListState.data);
            // console.info('faq config', !!FAQConfigState.data, FAQConfigState.data);
            // console.info('terms', !!termsState.data, termsState.data);
            // console.groupEnd();

            // if we have data
            if (
                sectorsState.data && sectorsState.data.length &&
                productsState.data && productsState.data.length &&
                imagesState.data && Object.keys(imagesState.data).length &&
                countriesState.data && countriesState.data.length &&
                profilesState.data && profilesState.data.length &&
                clientsListState.data && clientsListState.data.length &&
                selectionsState.current.data &&
                selectionsState.history.success &&
                naceState.data && naceState.data.length &&
                FAQListState.data &&
                FAQConfigState.data &&
                termsState.data
            ) {
                // console.info('Have enough data');
                const isLastFetchOlderThanOneWeek = !allDataState.lastFetchTimestamp ||
                    ((new Date()).getTime() - allDataState.lastFetchTimestamp > 1000 * 60 * 60 * 24 * 7);

                // if we are online
                if (offlineState.online) {
                    // console.info('Online');
                    // if the last date of fetched data is older than 2 days, fetch all data
                    if (isLastFetchOlderThanOneWeek) {
                        // console.info('Last data fetch is over 2 days old');
                        yield fetchSaga(locale);
                        lastFetchTimestamp = (new Date()).getTime();
                    } else {
                        // console.info('Last data fetch is under 2 days old');
                        hasEnoughData = true;
                        createProductSearchIndex(productsState.data, locale);
                    }
                } else {
                    // if the last date of fetched data is older than 2 days, notify that data may be stale
                    if (isLastFetchOlderThanOneWeek) {
                        dataMayBeStale = true;
                    }

                    // console.info('Offline');

                    hasEnoughData = true;
                    createProductSearchIndex(productsState.data, locale);
                }
            } else if (offlineState.online) {
                // console.info('NOT enough data & online');
                // we don't have enough data but we are online, fetch all data
                yield fetchSaga(locale);
                lastFetchTimestamp = (new Date()).getTime();
                hasEnoughData = true;
                dataMayBeStale = false;
            } else {
                // console.info('NOT enough data & offline');
                // we don't have enough data & we are offline, display warning to ask the user to go online
                hasEnoughData = false;
            }
        }

        // console.info('result', {
        //     dataMayBeStale,
        //     hasEnoughData,
        //     lastFetchTimestamp,
        // });
        // console.groupEnd();
        return yield put(allDataActions.checkDataSuccess({
            dataMayBeStale,
            hasEnoughData,
            lastFetchTimestamp,
        }));
    } catch (error) {
        // console.info('ERROR', error);
        // console.groupEnd();
        return yield put(allDataActions.checkDataFailed(error));
    }
}

export default function* allDataSaga() {
    yield takeLatest(reduxTypes.CHECK_DATA, checkDataSaga);
}
