import { call, takeLatest, put } from 'redux-saga/effects';
import * as countriesActions from '../actions/countries';
import * as reduxTypes from '../types/countries';
import * as countriesApi from '../api/countries';

export function* listSaga() {
    try {
        const response = yield call(countriesApi.list);

        return yield put(countriesActions.listSuccess(response.results));
    } catch (error) {
        return yield put(countriesActions.listFailed(error));
    }
}

export default function* countriesSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
}
