import { defineMessages } from 'react-intl';

export default defineMessages({
    assortments: {
        id: 'products.gammes.title',
        defaultMessage: 'Assortments',
        description: 'Products page assortments block title',
    },
    services: {
        id: 'products.services.title',
        defaultMessage: 'Services',
        description: 'Products page assortments block title',
    },
    searchResults: {
        id: 'products.search_results',
        defaultMessage: '<b>{count} products</b> match your selection',
        description: 'Products page search result phrase',
    },
    addToSelection: {
        id: 'products.add_to_selection',
        defaultMessage: 'Add to selection',
        description: 'Product card add to selection tooltip',
    },
    removeFromSelection: {
        id: 'products.remove_to_selection',
        defaultMessage: 'Remove from selection',
        description: 'Product card remove from selection tooltip',
    },
    standard: {
        id: 'products.filters.standard',
        defaultMessage: 'Standard',
        description: 'Product filter',
    },
    reinforced: {
        id: 'products.filters.reinforced',
        defaultMessage: 'Reinforced',
        description: 'Product filter',
    },
    motivations: {
        id: 'products.filters.motivations',
        defaultMessage: 'Your motivations',
        description: 'Product filter',
    },
    needs: {
        id: 'products.filters.needs',
        defaultMessage: 'Your needs',
        description: 'Product filter',
    },
    activeFilters: {
        id: 'products.filters.active_filters',
        defaultMessage: 'Active filters',
        description: 'Product filter',
    },
    clear: {
        id: 'products.filters.clear',
        defaultMessage: 'Clear',
        description: 'Product filter',
    },
    noProperty: {
        id: 'products.filters.no_property',
        defaultMessage: 'No corresponding property.',
        description: 'Product filter not matching',
    },
});
