import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactGA from 'react-ga';
import { Divider } from 'antd';

import '../../assets/styles/Product.less';

import { Product } from '../../store/api/types';

import { getRoute, RoutePathName } from '../../routes';
import messages from './Product.messages';
import Carousel from '../../components/Carousel';
import ProductCard from '../../components/ProductCard';
import { MainReducerState } from '../../store/reducers';
import { connect } from 'react-redux';
import { getCrossSellingByProductId } from '../../store/reducers/products';
import { SectorColorContext } from '../../components/SectorColorContextProvider';
import { t } from '../../utils';
import { getFilters, FiltersState } from '../../store/reducers/filters';

interface CrossSellingProps {
    crossSellingProducts: Product[];
    productId?: Product['id'];
    sectorId?: string;
    subSectorId?: string;
    solutionId?: string;
    filters: FiltersState;
}

const CrossSelling: React.FC<CrossSellingProps> = ({
    crossSellingProducts, productId, sectorId, solutionId, subSectorId, filters,
}) => {
    const { locale } = useIntl();
    const { color } = useContext(SectorColorContext);

    return crossSellingProducts.length ? (
        <section id="product-cross-selling" className="bg-grey p-y-32 m-b-56">
            <div className="container">
                <h3 style={color ? { color } : undefined}>
                    <FormattedMessage {...messages.crossSelling} />
                </h3>
                <Divider style={color ? { backgroundColor: color } : undefined} />
                <Carousel
                    lazyLoad="ondemand"
                    slidesToShow={crossSellingProducts.length < 4 ? crossSellingProducts.length : 4}
                    slidesToScroll={crossSellingProducts.length < 4 ? crossSellingProducts.length : 4}
                    responsive={[
                        {
                            breakpoint: 1366,
                            settings: {
                                slidesToShow: crossSellingProducts.length < 4 ? crossSellingProducts.length : 4,
                                slidesToScroll: crossSellingProducts.length < 4 ? crossSellingProducts.length : 4,
                            },
                        },
                        {
                            breakpoint: 992,
                            settings: {
                                slidesToShow: crossSellingProducts.length < 3 ? crossSellingProducts.length : 3,
                                slidesToScroll: crossSellingProducts.length < 3 ? crossSellingProducts.length : 3,
                            },
                        },
                        {
                            breakpoint: 884,
                            settings: {
                                slidesToShow: crossSellingProducts.length < 2 ? crossSellingProducts.length : 2,
                                slidesToScroll: crossSellingProducts.length < 2 ? crossSellingProducts.length : 2,
                            },
                        },
                        {
                            breakpoint: 580,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                            },
                        },
                    ]}
                    isSmall
                >
                    {crossSellingProducts.map((p) => (
                        <ProductCard
                            key={p.id}
                            link={!!sectorId && !!subSectorId && solutionId && productId ?
                                getRoute(
                                    RoutePathName.productFromSolution,
                                    {
                                        sectorId,
                                        subSectorId,
                                        solutionId,
                                        id: p.id,
                                    },
                                    Object.keys(filters).length ?
                                        { filters: JSON.stringify(filters) } :
                                        undefined,
                                ) :
                                getRoute(
                                    RoutePathName.product,
                                    { id: p.id },
                                    Object.keys(filters).length ?
                                        { filters: JSON.stringify(filters) } :
                                        undefined,
                                )
                            }
                            // eslint-disable-next-line
                            onClickLink={() => {
                                ReactGA.event({
                                    category: 'vous aimerez aussi',
                                    action: 'clic produit',
                                    label: `${productId} -> ${p.id}`,
                                });
                            }}
                            image={p.images ? p.images[0] : undefined}
                            name={t(p.label, locale)}
                            isBig
                        />
                    ))}
                </Carousel>
            </div>
        </section>
    ) : null;
};

const mapStateToProps = (state: MainReducerState, {
    productId, sectorId, subSectorId, solutionId,
}: {
    productId?: Product['id'];
    sectorId?: string;
    subSectorId?: string;
    solutionId?: string;
}) => ({
    crossSellingProducts: getCrossSellingByProductId(state, productId || -1, [
        parseInt(sectorId || '-1', 10),
        parseInt(subSectorId || '-1', 10),
        parseInt(solutionId || '-1', 10),
    ]),
    filters: getFilters(state),
});

export default connect(
    mapStateToProps,
)(CrossSelling);
