import { Action, ActionCreator } from 'redux';
import * as reduxTypes from '../types/clients';
import { DataAction } from '.';
import { Client } from '../api/types';

export const list: ActionCreator<Action> = () => ({
    type: reduxTypes.LIST,
});

export const listSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_SUCCESS,
    data,
});

export const listFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_FAILED,
    data,
});

export const create: ActionCreator<DataAction> = (data: Client) => {
    const { id, ...dataWithoutId } = data;
    return {
        type: reduxTypes.CREATE,
        data,
        meta: {
            offline: {
                // the network action to execute:
                effect: { url: '/api/customers', method: 'POST', data: dataWithoutId },
                // action to dispatch when effect succeeds:
                commit: createSuccess(data),
                // action to dispatch if network action fails permanently:
                rollback: createRollback(data),
            },
        },
    };
};

export const createSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_SUCCESS,
    data,
});

export const createRollback: ActionCreator<DataAction> = (data: Client) => ({
    type: reduxTypes.CREATE_ROLLBACK,
    data,
});

export const update: ActionCreator<DataAction> = (data: Client) => {
    const { id, createAt, ...strippedData } = data;
    return {
        type: reduxTypes.UPDATE,
        data,
        meta: {
            offline: {
                // the network action to execute:
                effect: { url: `/api/customers/reference/${data.reference}`, method: 'PUT', data: strippedData },
                // action to dispatch when effect succeeds:
                commit: updateSuccess(data),
                // action to dispatch if network action fails permanently:
                rollback: updateRollback(data),
            },
        },
    };
};

export const updateSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_SUCCESS,
    data,
});

export const updateRollback: ActionCreator<DataAction> = (data: Client) => ({
    type: reduxTypes.UPDATE_ROLLBACK,
    data,
});
