import React, { FC, useContext } from 'react';
import ReactGA from 'react-ga';
import { Checkbox } from 'antd';

import { Theme } from '../../store/api/types';

import { PresentationContext } from '../PresentationContextProvider';
import { CheckboxProps } from 'antd/lib/checkbox';

interface ThemeCheckboxProps {
    theme: Theme;
}

const ThemeCheckbox: FC<ThemeCheckboxProps> = ({ theme }) => {
    const {
        presentation, onChangeSelectedChapters,
    } = useContext(PresentationContext);
    let isIndeterminate = false;
    const isChecked = theme.chapters.reduce<boolean>((result, chapter) => {
        let res = false;

        if (presentation.selectedChapters.find((c) => c.id === chapter.id)) {
            res = true;
            isIndeterminate = true;
        }

        result = res && result;

        return result;
    }, true);

    if (isChecked) {
        isIndeterminate = false;
    }

    const onCheckboxChange: CheckboxProps['onChange'] = (e) => {
        const method = e.target.checked ? 'add' : 'remove';

        if (method === 'add') {
            ReactGA.event({
                category: 'thèmes',
                action: 'clic',
                label: `${theme.id}`,
            });
        }

        onChangeSelectedChapters(method, theme.chapters);
    };

    if (isChecked) {
        isIndeterminate = false;
    }

    return (
        <Checkbox
            checked={isChecked}
            indeterminate={isIndeterminate}
            onChange={onCheckboxChange}
        />
    );
};

export default ThemeCheckbox;
