import { defineMessages } from 'react-intl';

const messages = defineMessages({
    drawerTitle: {
        id: 'user_menu.drawer_title',
        defaultMessage: 'My account',
        description: 'User menu drawer title',
    },
    files: {
        id: 'user_menu.my_files',
        defaultMessage: 'My files',
        description: 'User menu item',
    },
    filesSub: {
        id: 'user_menu.my_files.sub_title',
        defaultMessage: `
            {count, plural,
                =0 {{count} registered clients}
                one {{count} registered client}
                other {{count} registered clients}
            }
        `,
        description: 'User menu item',
    },
    presentations: {
        id: 'user_menu.my_presentations',
        defaultMessage: 'My presentations',
        description: 'User menu item',
    },
    presentationsSub: {
        id: 'user_menu.my_presentations.sub_title',
        defaultMessage: `
            {count, plural,
                =0 {{count} presentations created}
                one {{count} presentation created}
                other {{count} presentations created}
            }
        `,
        description: 'User menu item',
    },
    favorites: {
        id: 'user_menu.my_favorites',
        defaultMessage: 'My favorites',
        description: 'User menu item',
    },
    favoritesSub: {
        id: 'user_menu.my_favorites.sub_title',
        defaultMessage: `
            {count, plural,
                =0 {{count} saved favorites}
                one {{count} saved favorite}
                other {{count} saved favorites}
            }
        `,
        description: 'User menu item',
    },
    editProfile: {
        id: 'user_menu.edit_profile',
        defaultMessage: 'Edit my profile',
        description: 'User menu button text',
    },
    changePassword: {
        id: 'user_menu.change_password',
        defaultMessage: 'Change password',
        description: 'Edit profile change password section',
    },
    oldPasswordLabel: {
        id: 'user_menu.old_password.label',
        defaultMessage: 'Old password',
        description: 'field',
    },
    oldPasswordPlaceholder: {
        id: 'user_menu.old_password.placeholder',
        defaultMessage: 'Enter your old password',
        description: 'field',
    },
    newPasswordLabel: {
        id: 'user_menu.new_password.label',
        defaultMessage: 'New password',
        description: 'field',
    },
    newPasswordPlaceholder: {
        id: 'user_menu.new_password.placeholder',
        defaultMessage: 'Enter your new password',
        description: 'field',
    },
    favoritesTabSectors: {
        id: 'user_menu.favorites.tabs.sectors',
        defaultMessage: 'Sectors ({count})',
        description: 'Tab name',
    },
    favoritesTabProducts: {
        id: 'user_menu.favorites.tabs.products',
        defaultMessage: 'Products ({count})',
        description: 'Tab name',
    },
    addClient: {
        id: 'user_menu.clients.add_a_client',
        defaultMessage: 'Add a client',
        description: 'CTA',
    },
    sortBy: {
        id: 'user_menu.clients.sort_by',
        defaultMessage: 'Sort by:',
        description: 'filter',
    },
    date: {
        id: 'user_menu.clients.sort_by_date',
        defaultMessage: 'Date',
        description: 'filter',
    },
    clientFile: {
        id: 'user_menu.client.client_file',
        defaultMessage: 'Client file',
        description: 'drawer title',
    },
    editInformations: {
        id: 'user_menu.client.edit_informations',
        defaultMessage: 'Edit informations',
        description: 'CTA',
    },
    selections: {
        id: 'user_menu.client.client_selections',
        defaultMessage: 'Product selections',
        description: 'User menu item',
    },
    selectionsSub: {
        id: 'user_menu.client.client_selections.sub_title',
        defaultMessage: `
            {count, plural,
                =0 {{count} saved selections}
                one {{count} saved selection}
                other {{count} saved selections}
            }
        `,
        description: 'User menu item',
    },
    propositions: {
        id: 'user_menu.client.my_propositions',
        defaultMessage: 'My sales propositions',
        description: 'User menu item',
    },
    propositionsSub: {
        id: 'user_menu.client.my_propositions.sub_title',
        defaultMessage: `
            {count, plural,
                =0 {{count} saved propositions}
                one {{count} saved proposition}
                other {{count} saved propositions}
            }
        `,
        description: 'User menu item',
    },
    companyLabel: {
        id: 'user_menu.client.form.company.label',
        defaultMessage: 'Company name',
        description: 'Form',
    },
    companyPlaceholder: {
        id: 'user_menu.client.form.company.placeholder',
        defaultMessage: 'Enter the company name',
        description: 'Form',
    },
    lastNameLabel: {
        id: 'user_menu.client.form.last_name.label',
        defaultMessage: 'Last name',
        description: 'Form',
    },
    lastNamePlaceholder: {
        id: 'user_menu.client.form.last_name.placeholder',
        defaultMessage: 'Enter the last name name',
        description: 'Form',
    },
    firstNameLabel: {
        id: 'user_menu.client.form.first_name.label',
        defaultMessage: 'First name',
        description: 'Form',
    },
    firstNamePlaceholder: {
        id: 'user_menu.client.form.first_name.placeholder',
        defaultMessage: 'Enter the first name name',
        description: 'Form',
    },
    editClient: {
        id: 'user_menu.client.form.title',
        defaultMessage: 'Edit client',
        description: 'Drawer title',
    },
    sectorLabel: {
        id: 'user_menu.client.form.sector.label',
        defaultMessage: 'Activity sector',
        description: 'Form field',
    },
    createClient: {
        id: 'user_menu.client.form.create_client',
        defaultMessage: 'Create the client',
        description: 'Form submit button',
    },
    selectionProductsLength: {
        id: 'user_menu.client.selections.product_length',
        defaultMessage: `
            {count, plural,
                =0 {{count} saved products}
                one {{count} saved product}
                other {{count} saved products}
            }
        `,
        description: 'Client selections list item count of products',
    },
    hisSelections: {
        id: 'user_menu.client.selections.drawerTitle',
        defaultMessage: 'His selections <b>({count})</b>',
        description: 'Client selections list drawer title',
    },
    presentationsDrawerTitle: {
        id: 'user_menu.client.presentations.drawer_title',
        defaultMessage: 'My presentations <b>({count})</b>',
        description: 'My presentations list drawer title',
    },
    propositionsDrawerTitle: {
        id: 'user_menu.client.propositions.drawer_title',
        defaultMessage: 'My propositions <b>({count})</b>',
        description: 'My propositions list drawer title',
    },
});

export default messages;
