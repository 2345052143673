import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import ButtonLink from '../../components/ButtonLink';
import EmptyResult from '../../components/EmptyResult';
import messages from './Unauthorized.messages';
import genericMessages from '../../locale/Generic.messages';
import { getRoute, RoutePathName } from '../../routes';

const Unauthorized: React.FC = () => (
    <div className="container text-center" style={{ paddingTop: 64 }}>
        <FormattedMessage
            tagName="h1"
            {...messages.unauthorizedTitle}
        />
        <EmptyResult
            text={(
                <FormattedMessage {...messages.unauthorizedDescription} />
            )}
        />
        <ButtonLink to={getRoute(RoutePathName.home)} type="primary">
            <FormattedMessage {...genericMessages.goBackToHome} />
        </ButtonLink>
    </div>
);

export default Unauthorized;
