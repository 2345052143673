import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import { injectIntl, FormattedMessage, IntlShape } from 'react-intl';
import { FormComponentProps } from 'antd/lib/form/Form';
import {
    Form, Input, Button, Row, Col,
} from 'antd';

import '../../assets/styles/Login.less';

import * as Auth from '../../store/actions/auth';
import { getAuth, AuthState } from '../../store/reducers/auth';

import { IconLogo, IconLogoText, IconLoginShape, IconArrow } from '../../components/icons';
import GenericMessages from '../../locale/Generic.messages';
import FormMessages from '../../locale/Form.messages';
import LoginMessages from '../login/Login.messages';
import messages from './ForgottenPassword.messages';
import { RoutePathName } from '../../routes';
import { MainReducerState } from '../../store/reducers';
import ButtonLink from '../../components/ButtonLink';

interface ForgottenPasswordProps extends RouteComponentProps, FormComponentProps {
    auth: AuthState;
    intl: IntlShape;
    forgottenPassword: typeof Auth.forgottenPassword;
}

interface ForgottenPasswordState {
    address?: string;
}

class Register extends React.Component<ForgottenPasswordProps, ForgottenPasswordState> {
    public state = {
        address: undefined,
    };

    public onSubmit = (e: React.FormEvent): void => {
        const { form, forgottenPassword } = this.props;
        e.preventDefault();
        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            this.setState({address: values.email});

            forgottenPassword(values);
        });
    }

    public renderFrom() {
        const { auth, form, intl } = this.props;
        const { getFieldDecorator } = form;
        let error = auth.forgottenPasswordError ? <FormattedMessage {...GenericMessages.error} /> : null;

        if (
            auth.forgottenPasswordError &&
            auth.forgottenPasswordError.status &&
            auth.forgottenPasswordError.status === 404
        ) {
            error = <FormattedMessage {...LoginMessages.emailNotFound} />;
        }

        return (
            <Form
                onSubmit={this.onSubmit}
                className={`login-form forgotten-password${auth.forgottenPasswordSuccess ? ' forgotten-password-success' : ''}`}
            >
                <Link to={RoutePathName.login}>
                    <IconArrow />
                    <FormattedMessage {...messages.title} tagName="h1" />
                </Link>
                {auth.forgottenPasswordSuccess ? (
                    <>
                        <FormattedMessage
                            {...messages.successDescription}
                            values={{
                                break: () => <><br /><br /></>,
                                address: this.state.address,
                            }}
                            tagName="p"
                        />
                        <ButtonLink
                            size="large"
                            to={RoutePathName.login}
                            block
                        >
                            <FormattedMessage {...LoginMessages.loginButton} />
                        </ButtonLink>
                    </>
                ) : (
                    <>
                        <FormattedMessage
                            {...messages.description}
                            tagName="p"
                        />
                        <Form.Item label={intl.formatMessage(LoginMessages.emailLabel)}>
                            {getFieldDecorator('email', {
                                rules: [{
                                    required: true,
                                    type: 'email',
                                    message: intl.formatMessage(LoginMessages.emailError),
                                }],
                                validateTrigger: 'onBlur',
                            })((
                                <Input
                                    placeholder={intl.formatMessage(LoginMessages.emailPlaceholder)}
                                    type="email"
                                />
                            ))}
                        </Form.Item>
                        {error ? (
                            <div className="login-error has-error">
                                <span className="ant-form-explain">{error}</span>
                            </div>
                        ) : null}
                        <Button
                            type="primary"
                            htmlType="submit"
                            size="large"
                            loading={auth.loading}
                            block
                            style={{ marginTop: 0 }}
                        >
                            <FormattedMessage {...FormMessages.submit} />
                        </Button>
                    </>
                )}
            </Form>
        );
    }

    public render() {
        return (
            <Row id="login-layout" className="forgottenPassword">
                <Col xs={24} lg={10} className="login-left">
                    <div id="logo-wrapper">
                        <IconLogo />
                        <IconLogoText />
                    </div>
                    {this.renderFrom()}
                </Col>
                <Col xs={24} lg={14} className="login-right">
                    <IconLoginShape />
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = (state: MainReducerState): any => ({
    auth: getAuth(state),
});

const RegisterForm = Form.create()(injectIntl(Register));

export default connect(
    mapStateToProps,
    { forgottenPassword: Auth.forgottenPassword },
)(RegisterForm);
