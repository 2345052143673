import React, { useCallback, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga';
import { Button, Drawer, List, Modal } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';

import '../../assets/styles/SelectionBar.less';

import * as SelectionsActions from '../../store/actions/selections';
import { MainReducerState } from '../../store/reducers';
import { getCurrentSelection } from '../../store/reducers/selections';
import { Selection } from '../../store/api/types';

import { getRoute, RoutePathName } from '../../routes';
import { IconPlaceholder } from '../icons';
import ListItem from '../ListItem';
import messages from './SelectionBar.messages';
import { SelectionBarDrawerContext } from './SelectionBarDrawerContext';
import GenericMessages from '../../locale/Generic.messages';
import { getFullName, t } from '../../utils';
import Paragraph from 'antd/lib/typography/Paragraph';
import Img from '../Img';

interface SelectedProductsDrawerProps extends DrawerProps {
    clear: typeof SelectionsActions.clearProducts;
    currentSelection?: Selection;
    remove: typeof SelectionsActions.removeProduct;
}

const SelectedProductsDrawer: React.FC<SelectedProductsDrawerProps> = ({
    clear, currentSelection, onClose, remove, ...props
}) => {
    const { formatMessage, locale } = useIntl();
    const client = currentSelection && currentSelection.customer ? currentSelection.customer : null;
    const {
        isSelectedProductsDrawerVisible, setIsClientsDrawerVisible, setIsSelectedProductsDrawerVisible,
    } = useContext(SelectionBarDrawerContext);
    const onDrawerClose = useCallback(() => {
        setIsSelectedProductsDrawerVisible(false);
    }, [setIsSelectedProductsDrawerVisible]);
    const onProductRemove = (productId: number) => {
        remove({ id: productId });
    };
    const onClickClear = useCallback(() => {
        Modal.confirm({
            className: 'selected-products-drawer-confirm',
            content: formatMessage(messages.clearConfirm),
            icon: null,
            okButtonProps: { type: 'danger' },
            onOk() {
                clear();
            },
            okText: formatMessage(messages.clear),
            title: formatMessage(GenericMessages.areYouSure),
            width: 540,
        });
    }, [clear, formatMessage]);
    const onOpenClientsDrawer = useCallback(() => {
        setIsClientsDrawerVisible(true);
    }, [setIsClientsDrawerVisible]);
    const products = currentSelection && currentSelection.products && currentSelection.products ?
        currentSelection.products :
        [];
    const onClickSave = () => {
        ReactGA.event({
            category: 'sélection produit',
            action: 'enregistrer',
            value: products.length,
        });

        onOpenClientsDrawer();
    };

    return (
        <Drawer
            onClose={onDrawerClose}
            placement="right"
            title={client ? (
                <FormattedMessage
                    {...messages.productsDrawerTitleWithClient}
                    values={{
                        b: (...m: string[]) => <span>{m}</span>,
                        count: products.length,
                    }}
                />
            ) : (
                <FormattedMessage
                    {...messages.productsDrawerTitle}
                    values={{
                        b: (...m: string[]) => <span>{m}</span>,
                        count: products.length,
                    }}
                />
            )}
            visible={isSelectedProductsDrawerVisible}
            width={385}
            {...props}
        >
            <div id="selected-products-drawer" className="with-footer">
                <section>
                    {client && (
                        <div id="selected-products-client">
                            <div>
                                <Paragraph className="list-item-title" ellipsis>
                                    {getFullName(client.firstName, client.lastName)}
                                </Paragraph>
                                <Paragraph className="list-item-sub-title" ellipsis>
                                    {client.company}
                                </Paragraph>
                            </div>
                            <Button
                                onClick={onOpenClientsDrawer}
                            >
                                <FormattedMessage {...GenericMessages.change} />
                            </Button>
                        </div>
                    )}
                    <List>
                        {products.map((product) => (
                            <List.Item key={product.id}>
                                <ListItem
                                    icon={product.images && product.images[0] ?
                                        <Img imageId={product.images[0].id} alt={product ? t(product.label, locale) : 'product'} /> :
                                        <IconPlaceholder />
                                    }
                                    onDelete={() => onProductRemove(product.id)} // tslint:disable-line jsx-no-lambda
                                    data-id={product.id}
                                    title={(
                                        <Link to={getRoute(RoutePathName.product, { id: product.id })}>
                                            {t(product.label, locale)}
                                        </Link>
                                    )}
                                />
                            </List.Item>
                        ))}
                    </List>
                </section>
                <footer>
                    <Button
                        type="danger"
                        onClick={onClickClear}
                        size="large"
                        block
                    >
                        <FormattedMessage {...messages.clear} />
                    </Button>
                    {!client && (
                        <Button
                            type="primary"
                            onClick={onClickSave}
                            size="large"
                            block
                        >
                            <FormattedMessage {...messages.save} />
                        </Button>
                    )}
                </footer>
            </div>
        </Drawer>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    currentSelection: getCurrentSelection(state),
});

export default connect(
    mapStateToProps,
    {
        clear: SelectionsActions.clearProducts,
        remove: SelectionsActions.removeProduct,
    },
)(SelectedProductsDrawer);
