import React, { FC, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Spin } from 'antd';

import '../../assets/styles/Presentation.less';

import { MainReducerState } from '../../store/reducers';
import { getPreviewState, PresentationsState } from '../../store/reducers/presentations';

import GenericMessages from '../../locale/Generic.messages';
import { IconArrow } from '../icons';
import PDFViewer from '../PDFViewer';
import messages from './Presentation.messages';
import { PresentationContext, PresentationSteps } from '../PresentationContextProvider';

interface PresentationPreviewProps {
    previewState: PresentationsState['preview'];
}

const PresentationPreview: FC<PresentationPreviewProps> = ({ previewState }) => {
    const {
        setStep, previewFrom, presentation, setShouldInitiateSaveOnCustomizeStep,
    } = useContext(PresentationContext);
    const onClickBack = () => {
        setStep(previewFrom);
    };
    const onClickCustomize = () => {
        if (previewFrom === PresentationSteps.Chapters) {
            setStep(PresentationSteps.Informations);
        } else {
            setShouldInitiateSaveOnCustomizeStep(true);
            setStep(PresentationSteps.Customize);
        }
    };
    return (
        <>
            <Button
                onClick={onClickBack}
                id="presentation-back-button"
            >
                <div id="cancel-icon-wrapper">
                    <IconArrow direction="left" />
                </div>
                <FormattedMessage {...GenericMessages.back} />
            </Button>
            <section>
                <div id="presentation-preview-wrapper">
                    <FormattedMessage {...messages.previewTitle} tagName="h1" />
                    <Spin spinning={previewState.loading}>
                        <PDFViewer
                            file={previewState.data ? previewState.data.url : undefined}
                        />
                    </Spin>
                </div>
            </section>
            <footer>
                <Button
                    onClick={onClickBack}
                    size="large"
                >
                    {previewFrom === PresentationSteps.Chapters ?
                        <FormattedMessage {...messages.editSelection} /> :
                        <FormattedMessage {...messages.editPresentation} />
                    }
                </Button>
                <Button
                    onClick={onClickCustomize}
                    type="primary"
                    size="large"
                >
                    {previewFrom === PresentationSteps.Chapters ? (
                        <FormattedMessage
                            {...(presentation.type === 'presentation' ?
                                messages.customizePresentationButton :
                                messages.customizePropositionButton
                            )}
                        />
                    ) : <FormattedMessage {...messages.validateCustomize} />
                    }
                </Button>
            </footer>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    previewState: getPreviewState(state),
});

export default connect(
    mapStateToProps,
)(PresentationPreview);
