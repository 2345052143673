import { Action, ActionCreator } from 'redux';
import * as reduxTypes from '../types/faq';
import { DataAction } from '.';

export const list: ActionCreator<Action> = () => ({
    type: reduxTypes.LIST,
});

export const listSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_SUCCESS,
    data,
});

export const listFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_FAILED,
    data,
});

export const config: ActionCreator<Action> = () => ({
    type: reduxTypes.CONFIG,
});

export const configSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CONFIG_SUCCESS,
    data,
});

export const configFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CONFIG_FAILED,
    data,
});
