import { Reducer } from 'redux';
import cloneDeep from 'lodash/cloneDeep';

import * as reduxTypes from '../types/products';
import { MainReducerState, RequestState } from '.';
import { Product, Sector, Argument, Property, PropertyValue, PropertyType } from '../api/types';
import { requestReducer } from './_generics';
import { getFavorites } from './favorites';
import { getNonExcludedSectorsBySectorIds, PropertyGroup, getMergedFiltersBySectorIds } from './sectors';
import { getFilters } from './filters';
import { constants, hasOwnProp } from '../../utils';

export type ProductsState = RequestState<Product[]>;

const products: Reducer<ProductsState> = requestReducer({
    reduxTypes: {
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAILED,
    },
});

export default products;

export const getProducts = (state: MainReducerState) => state.products;

export const getProductById = (state: MainReducerState, withAssociated = true, id: Product['id']) => {
    if (state.products.data) {
        const match = state.products.data.find((p) => p.id === id);

        if (match) {
            const product: Product = {
                ...match,
            };

            if (withAssociated) {
                product.associatedProductsPopulated = match.associatedProducts
                    .map(getProductById.bind(null, state, false))
                    .filter(<T>(n?: T): n is T => Boolean(n));
                product.associatedProductsFromGroupsPopulated =
                    match.associatedProductGroups.reduce<{[id: string]: Product}>((acc, group) => {
                        group.associatedProducts.forEach((productId) => {
                            if (!acc[productId]) {
                                const p = getProductById(state, false, productId);

                                if (p) {
                                    acc[productId] = p;
                                }
                            }
                        });

                        return acc;
                    }, {});
            }

            return product;
        }
    }

    return undefined;
};

export const getFavoritesProducts = (state: MainReducerState) => {
    const favorites = getFavorites(state);
    const productIds = favorites ? favorites.favoritesProducts || [] : [];

    return productIds.map(getProductById.bind(null, state, false)).filter(<T>(n?: T): n is T => Boolean(n));
};

export const doesProductMatchFilters = (state: MainReducerState, product: Product) => {
    const filters = getFilters(state);

    if (
        (filters.arguments && filters.arguments.length) ||
        (filters.properties && filters.properties.length)
    ) {
        let matchesFilterArguments = true;
        let matchesFilterProperties = true;

        if (filters.arguments && filters.arguments.length) {
            matchesFilterArguments = filters.arguments.reduce<boolean>((matchesAll, filterArg) => {
                const matchesCurrent = product.arguments.some((argValue) =>
                    argValue.argument.id === filterArg.id &&
                    (argValue.rating >= filterArg.rating && argValue.rating > 0),
                );

                matchesAll = matchesCurrent && matchesAll;

                return matchesAll;
            }, true);
        }

        if (filters.properties && filters.properties.length) {
            matchesFilterProperties = filters.properties.reduce<boolean>((matchesAll, filterProp) => {
                const matchesCurrent = product.properties.some((propValue) =>
                    propValue.property.id === filterProp.id &&
                    propValue.id === filterProp.valueId,
                );

                matchesAll = matchesCurrent && matchesAll;

                return matchesAll;
            }, true);
        }

        return matchesFilterProperties &&
               matchesFilterArguments;
    }

    return true;
};

export interface MergedFilters {
    [key: string]: any;
    arguments: {
        [key: number]: Argument;
    };
    properties: {
        [key: number]: Partial<Property> & Partial<PropertyGroup> & {
            valuesById?: {
                [key: string]: PropertyValue;
            };
        };
    };
    gamme?: {
        [key: number]: Partial<Property> & Partial<PropertyGroup>;
    };
    service?: {
        [key: number]: Partial<Property> & Partial<PropertyGroup>;
    };
}

export interface MergedFiltersAndProducts {
    mergedFilters: MergedFilters;
    products: {
        data: Product[];
        page: number;
        total: number;
    };
    gammes?: Sector[];
    services?: Sector[];
}

type GetProductsAndMergedFiltersBySectorIds = (
    state: MainReducerState,
    sectorIds: Sector['id'][],
    page: number | undefined,
    assortmentId: number | undefined,
) => MergedFiltersAndProducts | undefined;

// filter products with sector ids and ui filters,
// aggregate arguments & properties from sectors
// filter properties values from products values with corresponding product count
export const getProductsAndMergedFiltersBySectorIds: GetProductsAndMergedFiltersBySectorIds = (
    state,
    sectorIds,
    page,
    assortmentId,
) => {
    if (state.products.data) {
        const sectorsHierarchy = assortmentId !== undefined ? [...sectorIds, assortmentId] : sectorIds;
        const nonExcludedSectors = getNonExcludedSectorsBySectorIds(state, sectorsHierarchy);
        const productSectorIds = [
            sectorsHierarchy[sectorsHierarchy.length - 1],
            ...nonExcludedSectors.map((sector) => sector.id),
        ];
        const mergedSectorFilters = getMergedFiltersBySectorIds(state, sectorsHierarchy);

        if (!mergedSectorFilters) {
            return undefined;
        }

        const result = state.products.data.reduce<MergedFiltersAndProducts>((acc, product) => {
            if (
                // if product sectors matches ui sectors & product matches ui filters
                productSectorIds.includes(product.sectorId) &&
                doesProductMatchFilters(state, product)
            ) {
                product.properties.forEach((propertyValue) => {
                    if (propertyValue.property.type === null || propertyValue.property.type === PropertyType.color) {
                        if (propertyValue.property.group !== null) {
                            if (!hasOwnProp(acc.mergedFilters.properties, propertyValue.property.group.id)) {
                                acc.mergedFilters.properties[propertyValue.property.group.id] = {
                                    ...propertyValue.property.group,
                                    children: {
                                        [propertyValue.property.id]: {
                                            ...propertyValue.property,
                                            valuesById: {
                                                [propertyValue.id]: {
                                                    ...propertyValue,
                                                    productCount: 1,
                                                },
                                            },
                                        },
                                    },
                                };
                            } else {
                                const currentValuesById = acc.mergedFilters.properties[propertyValue.property.group.id].children![propertyValue.property.id].valuesById!; // eslint-disable-line
                                currentValuesById[propertyValue.id] = {
                                    ...propertyValue,
                                    productCount: (currentValuesById[propertyValue.id].productCount || 0) + 1, // eslint-disable-line
                                };
                            }
                        } else {
                            if (!hasOwnProp(acc.mergedFilters.properties, propertyValue.property.id)) {
                                acc.mergedFilters.properties[propertyValue.property.id] = {
                                    ...propertyValue.property,
                                    valuesById: {
                                        [propertyValue.id]: {
                                            ...propertyValue,
                                            productCount: 1,
                                        },
                                    },
                                };
                            } else {
                                acc.mergedFilters.properties[propertyValue.property.id].valuesById = {
                                    ...acc.mergedFilters.properties[propertyValue.property.id].valuesById,
                                    [propertyValue.id]: {
                                        ...propertyValue,
                                        productCount: (
                                            acc.mergedFilters.properties[propertyValue.property.id].valuesById &&
                                            acc.mergedFilters.properties[propertyValue.property.id].valuesById![propertyValue.id] ? // eslint-disable-line
                                                (acc.mergedFilters.properties[propertyValue.property.id].valuesById![propertyValue.id].productCount || 0) : // eslint-disable-line
                                                0
                                        ) + 1,
                                    },
                                };
                            }
                        }
                    }
                });
                acc.products.data.push(product);
                acc.products.total += 1;
            }

            return acc;
        }, {
            mergedFilters: cloneDeep(mergedSectorFilters),
            products: {
                data: [],
                page: page || 1,
                total: 0,
            },
        });

        result.products.data = result.products.data.sort((a, b) => {
            const isABestSeller = isProductBestSeller(state, a.id, sectorIds);
            const isBBestSeller = isProductBestSeller(state, b.id, sectorIds);

            if (isABestSeller && !isBBestSeller) {
                return -1;
            } else if (!isABestSeller && isBBestSeller) {
                return 1;
            } else if (!isABestSeller && !isBBestSeller) {
                if (a.isNew && !b.isNew) {
                    return -1;
                } else if (!a.isNew && b.isNew) {
                    return 1;
                }

                return 0;
            }

            return 0;
        });

        const assortmentsSectors = assortmentId !== undefined ?
            getNonExcludedSectorsBySectorIds(state, sectorIds) :
            nonExcludedSectors;

        const gammes = assortmentsSectors.filter((sector) =>
            sector.sectorParents.includes(sectorIds[sectorIds.length - 1]),
        );

        if (gammes.length) {
            result.gammes = gammes;
        }

        const services = assortmentsSectors.filter((sector) =>
            sector.sectorParents.includes(sectorIds[sectorIds.length - 1]),
        );

        if (services.length) {
            result.services = services;
        }

        if (page !== undefined) {
            const offset = page ?
                (page - 1) * constants.PAGE_SIZE :
                0;

            if (result.products.data.length > constants.PAGE_SIZE) {
                result.products.data = result.products.data.slice(offset, offset + constants.PAGE_SIZE);
            }
        }

        return result;
    }

    return undefined;
};

export const getCrossSellingByProductId = (
    state: MainReducerState,
    productId: Product['id'],
    sectorIds: Sector['id'][],
) => {
    const product = getProductById(state, false, productId);

    if (product) {
        const filteredCrossSellingIds = product.crossSelling ?
            product.crossSelling.reduce<Product['id'][]>((ids, crossSelling) => {
                if (sectorIds.includes(crossSelling.sectorId)) {
                    ids = ids.concat(crossSelling.products);
                }

                return ids;
            }, []) :
            [];

        return filteredCrossSellingIds
            .map((id) => getProductById(state, false, id))
            .filter(<T>(n?: T): n is T => Boolean(n));
    }

    return [];
};

export const isProductBestSeller = (state: MainReducerState, productId: Product['id'], sectorIds: Sector['id'][]) => {
    const product = getProductById(state, false, productId);

    if (product && product.bestSellers.length) {
        return product.bestSellers.some((sectorIdBestSeller) => sectorIds.includes(sectorIdBestSeller));
    }

    return false;
};
