import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Divider } from 'antd';

import '../../assets/styles/Product.less';

import { Product } from '../../store/api/types';
import { MainReducerState } from '../../store/reducers';
import { getFilters, FiltersState } from '../../store/reducers/filters';

import { SectorColorContext } from '../../components/SectorColorContextProvider';
import ProductCard from '../../components/ProductCard';
import Carousel from '../../components/Carousel';
import { getRoute, RoutePathName } from '../../routes';
import { t } from '../../utils';
import messages from './Product.messages';

interface AssociatedProductsProps {
    product?: Product;
    sectorId?: string;
    subSectorId?: string;
    solutionId?: string;
    filters: FiltersState;
}

const AssociatedProducts: React.FC<AssociatedProductsProps> = ({
    product, sectorId, solutionId, subSectorId, filters,
}) => {
    const { locale } = useIntl();
    const { color } = useContext(SectorColorContext);
    const associatedProducts = product && product.associatedProductsPopulated ?
        product.associatedProductsPopulated :
        [];

    return associatedProducts.length ? (
        <section className="container m-b-56">
            <div id="associated-products">
                <div id="associated-products-title">
                    <h3 style={color ? { color } : undefined}>
                        <FormattedMessage {...messages.associatedProducts} />
                    </h3>
                    <Divider style={color ? { backgroundColor: color } : undefined} />
                </div>
                <Carousel
                    lazyLoad="ondemand"
                    slidesToShow={associatedProducts.length < 4 ? associatedProducts.length : 4}
                    slidesToScroll={associatedProducts.length < 4 ? associatedProducts.length : 4}
                    responsive={[
                        {
                            breakpoint: 1366,
                            settings: {
                                slidesToShow: associatedProducts.length < 4 ? associatedProducts.length : 4,
                                slidesToScroll: associatedProducts.length < 4 ? associatedProducts.length : 4,
                            },
                        },
                        {
                            breakpoint: 1156,
                            settings: {
                                slidesToShow: associatedProducts.length < 3 ? associatedProducts.length : 3,
                                slidesToScroll: associatedProducts.length < 3 ? associatedProducts.length : 3,
                            },
                        },
                        {
                            breakpoint: 884,
                            settings: {
                                slidesToShow: associatedProducts.length < 2 ? associatedProducts.length : 2,
                                slidesToScroll: associatedProducts.length < 2 ? associatedProducts.length : 2,
                            },
                        },
                        {
                            breakpoint: 580,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1,
                            },
                        },
                    ]}
                    isSmall
                >
                    {associatedProducts.map((p) => (
                        <ProductCard
                            key={p.id}
                            link={!!sectorId && !!subSectorId && solutionId ?
                                getRoute(
                                    RoutePathName.productFromSolution,
                                    {
                                        sectorId,
                                        subSectorId,
                                        solutionId,
                                        id: p.id,
                                    },
                                    Object.keys(filters).length ?
                                        { filters: JSON.stringify(filters) } :
                                        undefined,
                                ) :
                                getRoute(
                                    RoutePathName.product,
                                    { id: p.id },
                                    Object.keys(filters).length ?
                                        { filters: JSON.stringify(filters) } :
                                        undefined,
                                )
                            }
                            image={p.images && p.images[0]}
                            name={t(p.label, locale)}
                        />
                    ))}
                </Carousel>
            </div>
        </section>
    ) : null;
};

const mapStateToProps = (state: MainReducerState) => ({
    filters: getFilters(state),
});

export default connect(
    mapStateToProps,
)(AssociatedProducts);
