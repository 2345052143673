import { Action, ActionCreator } from 'redux';
import * as reduxTypes from '../types/favorites';
import { DataAction } from '.';
import { Client, Sector, Product } from '../api/types';

export const list: ActionCreator<Action> = () => ({
    type: reduxTypes.LIST,
});

export const listSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_SUCCESS,
    data,
});

export const listFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_FAILED,
    data,
});

export interface FavoritePayload {
    productId?: Product['id'];
    sectorId?: Sector['id'];
}

export const create: ActionCreator<DataAction> = (data: FavoritePayload) => ({
    type: reduxTypes.CREATE,
    data,
    meta: {
        offline: {
            // the network action to execute:
            effect: { url: '/api/favorites', method: 'POST', data },
            // action to dispatch when effect succeeds:
            commit: createSuccess(data),
            // action to dispatch if network action fails permanently:
            rollback: createRollback(data),
        },
    },
});

export const createSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CREATE_SUCCESS,
    data,
});

export const createRollback: ActionCreator<DataAction> = (data: Client) => ({
    type: reduxTypes.CREATE_ROLLBACK,
    data,
});

export const del: ActionCreator<DataAction> = (data: FavoritePayload) => ({
    type: reduxTypes.DELETE,
    data,
    meta: {
        offline: {
            // the network action to execute:
            effect: { url: '/api/favorites', method: 'DELETE', data },
            // action to dispatch when effect succeeds:
            commit: deleteSuccess(data),
            // action to dispatch if network action fails permanently:
            rollback: deleteRollback(data),
        },
    },
});

export const deleteSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.DELETE_SUCCESS,
    data,
});

export const deleteRollback: ActionCreator<DataAction> = (data: Client) => ({
    type: reduxTypes.DELETE_ROLLBACK,
    data,
});
