import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
        <path fill="currentColor" fillRule="evenodd" d="M13.26 1.95v-.082C13.26 1.222 13.832 0 16 0s2.74 1.222 2.74 1.868v.08h-1.233l-.004-.076c-.004-.075-.08-.735-1.503-.735-1.427 0-1.5.668-1.503.744l-.004.08-1.233-.01zM1.59 5.36h28.82V3.573H1.59V5.36zm1.062 16.244h26.696V6.822H2.652v14.782zm17.22 8.742c.026.11.135.19.26.19a.278.278 0 0 0 .055-.004.265.265 0 0 0 .167-.107.226.226 0 0 0 .036-.183l-1.7-7.176h-.544l1.726 7.28zm-8.277-.104a.226.226 0 0 0 .036.183.263.263 0 0 0 .168.107.284.284 0 0 0 .2-.033.248.248 0 0 0 .116-.153l1.726-7.28h-.544l-1.7 7.176zM20.127 32c-.353 0-.7-.094-1-.27a1.702 1.702 0 0 1-.81-1.073l-1.8-7.59h-1.05l-1.8 7.59a1.703 1.703 0 0 1-.81 1.072 1.967 1.967 0 0 1-1.398.23 1.85 1.85 0 0 1-1.168-.745 1.582 1.582 0 0 1-.25-1.285l1.626-6.864H1.06V6.822H.797c-.44 0-.796-.328-.796-.73V2.84c0-.402.357-.73.796-.73h30.408c.44 0 .796.328.796.73v3.25c0 .402-.357.73-.796.73h-.265v16.244H20.316l1.628 6.865c.106.446.016.902-.252 1.285a1.847 1.847 0 0 1-1.167.745c-.132.027-.266.04-.4.04z" />
    </svg>
);

const IconPresentation: React.FC<IconProps> = (props) => (
    <Icon component={svg} {...props} className={`anticon icon-presentation${props.className ? ` ${props.className}` : ''}`} />
);

export default IconPresentation;
