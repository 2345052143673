import {
    IconShirt, IconLinen, IconFloor, IconSanitary, IconDrink, IconDasri, IconWipers, IconRat,
    IconCleaner, IconCommerces, IconIndustry,
} from '../../components/icons';
import { IconProps } from 'antd/lib/icon';

export { default as Products } from './Products';
export { default as ProductSearch } from './ProductSearch';
export { default as ProductsSolutionsFilter } from './ProductsSolutionsFilter';
export { default as ProductsAssortmentsFilter } from './ProductsAssortmentsFilter';
export { default as ProductsSidebar } from './ProductsSidebar';

export const sectorIconsMap: {
    [key: string]: React.FunctionComponent<IconProps>;
} = {
    shirt: IconShirt,
    linen: IconLinen,
    floor: IconFloor,
    sanitary: IconSanitary,
    drink: IconDrink,
    dasri: IconDasri,
    wipers: IconWipers,
    rat: IconRat,
    cleaner: IconCleaner,
    commerce: IconCommerces,
    industry: IconIndustry,
};
