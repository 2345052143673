import React, { FC, useContext, useEffect } from 'react';
import ReactGA from 'react-ga';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Modal } from 'antd';

import '../../assets/styles/Presentation.less';

import * as ClientsActions from '../../store/actions/clients';
import * as PresentationsActions from '../../store/actions/presentations';
import { MainReducerState } from '../../store/reducers';
import { PresentationsState, getCreateState, getUpdateState } from '../../store/reducers/presentations';
import { Presentation } from '../../store/api/types';

import { IconClose } from '../icons';
import { PresentationContext } from '../PresentationContextProvider';
import messages from './Presentation.messages';
import { getFullName } from '../../utils';


interface PresentationSuccessProps {
    createState: PresentationsState['create'];
    listClients: typeof ClientsActions.list;
    listPresentations: typeof PresentationsActions.list;
    updateState: PresentationsState['update'];
}

const PresentationSuccess: FC<PresentationSuccessProps> = ({
    createState, listClients, listPresentations, updateState,
}) => {
    const {
        presentation, setIsPresentationOpen, reset, isCreateDone, isUpdateDone,
    } = useContext(PresentationContext);
    const onClickCancel = () => {
        reset();
        setIsPresentationOpen(false);
    };
    const onClickDownload = (data: Presentation) => {
        if (data && data.pdf) {
            window.open(data.pdf.url, '_blank');
        }
        ReactGA.event({
            category: 'présentation/proposition',
            action: 'télécharger',
            value: 1,
        });
    };

    useEffect(() => {
        listPresentations({ limit: 9999 });
        listClients({ limit: 9999 });
    }, [listPresentations, listClients]);

    return (
        <Modal
            closeIcon={<IconClose />}
            onCancel={onClickCancel}
            footer={null}
            visible={true}
            width={632}
            wrapClassName="success-modal"
        >
            {isCreateDone && createState.data && (
                <>
                    <FormattedMessage
                        {...(
                            presentation.type === 'presentation' ?
                                messages.successCreatePresentationTitle :
                                messages.successCreatePropositionTitle
                        )}
                        tagName="p"
                        values={{
                            b: (...m: string[]) => <span>{m}</span>,
                            title: presentation.title,
                        }}
                    />
                    <Button
                        onClick={onClickDownload.bind(null, createState.data)}
                        size="large"
                        type="primary"
                    >
                        <FormattedMessage
                            {...(
                                presentation.type === 'presentation' ?
                                    messages.successPresentationDowload :
                                    messages.successPropositionDowload
                            )}
                        />
                    </Button>
                </>
            )}
            {isUpdateDone && updateState.data && (
                <>
                    <FormattedMessage
                        {...(
                            presentation.type === 'presentation' ?
                                messages.successUpdatePresentationTitle :
                                messages.successUpdatePropositionTitle
                        )}
                        tagName="p"
                        values={{
                            b: (...m: string[]) => <span>{m}</span>,
                            title: presentation.title,
                        }}
                    />
                    <Button
                        onClick={onClickDownload.bind(null, updateState.data)}
                        size="large"
                        type="primary"
                    >
                        <FormattedMessage
                            {...(
                                presentation.type === 'presentation' ?
                                    messages.successPresentationDowload :
                                    messages.successPropositionDowload
                            )}
                        />
                    </Button>
                </>
            )}
            <FormattedMessage
                {...messages.successLocation}
                tagName="p"
                values={{
                    b: (...m: string[]) => <span>{m}</span>,
                    client: presentation.client ?
                        getFullName(presentation.client.firstName, presentation.client.lastName) :
                        '',
                    title: presentation.title,
                }}
            />
        </Modal>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    createState: getCreateState(state),
    updateState: getUpdateState(state),
});

export default connect(
    mapStateToProps,
    {
        listClients: ClientsActions.list,
        listPresentations: PresentationsActions.list,
    },
)(PresentationSuccess);
