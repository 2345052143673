import { Action, ActionCreator } from 'redux';
import * as reduxTypes from '../types/allData';
import { DataAction } from '.';
import { Languages } from '../../components/IntlProvider';
import { AllDataState } from '../reducers/allData';

export const fetch: ActionCreator<Action> = () => ({
    type: reduxTypes.FETCH,
});

export const fetchSuccess: ActionCreator<DataAction<number>> = (data) => ({
    type: reduxTypes.FETCH_SUCCESS,
    data,
});

export const fetchFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.FETCH_FAILED,
    data,
});

export const checkData: ActionCreator<Action> = (locale: Languages, force?: boolean) => ({
    type: reduxTypes.CHECK_DATA,
    locale,
    force,
});

export const checkDataSuccess: ActionCreator<DataAction<Partial<AllDataState>>> = (data) => ({
    type: reduxTypes.CHECK_DATA_SUCCESS,
    data,
});

export const checkDataFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CHECK_DATA_FAILED,
    data,
});
