import React, { useContext } from 'react';
import { RouteChildrenProps } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { Row, Col, Divider, Button, Tooltip } from 'antd';

import '../../assets/styles/Sector.less';

import salesAcademy from '../../assets/images/sales-academy.png';
import salesAcademy2x from '../../assets/images/sales-academy@2x.png';
import salesAcademy3x from '../../assets/images/sales-academy@3x.png';

import { MainReducerState, getOfflineState } from '../../store/reducers';
import { getSectorById, getSectorsByParentId } from '../../store/reducers/sectors';
import { Sector } from '../../store/api/types';

import GenericMessages from '../../locale/Generic.messages';
import BreadCrumb from '../../components/BreadCrumb';
import { IconPresentation, IconArrow, IconPlaceholder } from '../../components/icons';
import { PresentationContext } from '../../components/PresentationContextProvider';
import Info from '../../components/Info';
import Carousel from '../../components/Carousel';
import EmptyResult from '../../components/EmptyResult';
import { RoutePathName, getRoute } from '../../routes';
import messages from './Sector.messages';
import { sectorIconsMap } from '../products';
import Img from '../../components/Img';
import { t } from '../../utils';

interface MatchParams {
    id: string;
    sectorId: string;
}

interface SubSectorProps extends RouteChildrenProps<MatchParams> {
    isOnline: boolean;
    sector?: Sector;
    solutions: Sector[];
    subSector?: Sector;
}

const SubSectorPage: React.FC<SubSectorProps> = ({ isOnline, sector, solutions, subSector }) => {
    const { locale } = useIntl();
    const { setPresentationData, setIsPresentationOpen, reset } = useContext(PresentationContext);
    const onClickPresentation = () => {
        reset();
        setPresentationData({ sector: subSector });
        setIsPresentationOpen(true);
        ReactGA.event({
            category: 'bouton présentation commerciale header sous-secteur',
            action: 'clic',
            label: subSector ? `${subSector.id}` : '',
        });
    };
    const onClickSolution = (solution: Sector) => {
        ReactGA.event({
            category: 'clic besoin & solution depuis sous-secteur',
            action: solution.name.fr || t(solution.name, locale),
            label: subSector ? `${subSector.id}` : '',
        });
    };

    const links = [
        {
            link: getRoute(RoutePathName.sector, { id: sector ? sector.id : -1 }),
            text: sector && t(sector.name, locale) ? t(sector.name, locale) : '',
        },
    ];

    if (sector && !sector.skipSubLevel) {
        links.push({
            link: getRoute(RoutePathName.subSector, {
                id: subSector ? subSector.id : -1,
                sectorId: sector ? sector.id : -1,
            }),
            text: subSector && t(subSector.name, locale) ? t(subSector.name, locale) : '',
        });
    }

    const presentationButton = (
        <Button
            className="btn-white"
            disabled={!isOnline}
            onClick={onClickPresentation}
        >
            <IconPresentation />
            <FormattedMessage {...GenericMessages.commercialPresentation} />
        </Button>
    );

    return (
        <>
            <BreadCrumb
                routes={links}
            />
            <header className="page-hero">
                {sector && subSector && (
                    <>
                        {sector.image && <Img imageId={sector.image.id} alt={t(sector.name, locale)} />}
                        <div className="container">
                            <Row>
                                <Col xl={13} lg={18} md={16} className="page-hero-title-col">
                                    {sector && !sector.skipSubLevel && <h2>{t(sector.name, locale)}</h2>}
                                    <h1>{t(subSector.name, locale)}</h1>
                                </Col>
                                <Col xl={11} lg={6} md={8} id="sector-hero-right">
                                    <Link to={getRoute(RoutePathName.sectorInformations, { id: subSector.id })}>
                                        <div className="sector-hero-commercial-presentation">
                                            <FormattedMessage
                                                {...messages.sectorInformations}
                                                values={{
                                                    b: (...m: string[]) => <strong>{m}</strong>,
                                                }}
                                                tagName="p"
                                            />
                                            <Divider />
                                            <FormattedMessage {...GenericMessages.readMore}>
                                                {(txt) => (
                                                    <p className="arrow-link">
                                                        <IconArrow direction="right" />
                                                        {txt}
                                                    </p>
                                                )}
                                            </FormattedMessage>
                                        </div>
                                    </Link>
                                    {isOnline ?
                                        presentationButton :
                                        (
                                            <Tooltip
                                                title={<FormattedMessage {...GenericMessages.youMustBeOnline} />}
                                            >
                                                {presentationButton}
                                            </Tooltip>
                                        )
                                    }
                                </Col>
                            </Row>
                        </div>
                    </>
                )}
            </header>
            <section id="sector-carousel-wrapper" className="sub-sector bg-grey m-b-86">
                <div className="container">
                    {!!solutions.length && (
                        <>
                            <div className="sector-tooltip-wrapper">
                                <Info
                                    bottomText={<FormattedMessage {...messages.subSectorTooltipBottom} />}
                                />
                                <Link
                                    to={getRoute(RoutePathName.salesAcademy, { id: subSector ? subSector.id : -1 })}
                                    id="sales-academy-button"
                                >
                                    <img
                                        src={salesAcademy}
                                        srcSet={`${salesAcademy2x} 2x, ${salesAcademy3x} 3x`}
                                        alt="Elis Sales Academy"
                                    />
                                </Link>
                            </div>
                            <Carousel
                                id="sector-carousel"
                                slidesToShow={solutions.length < 4 ? solutions.length : 4}
                                slidesToScroll={solutions.length < 4 ? solutions.length : 4}
                                responsive={[
                                    {
                                        breakpoint: 1366,
                                        settings: {
                                            slidesToShow: solutions.length < 3 ? solutions.length : 3,
                                            slidesToScroll: solutions.length < 3 ? solutions.length : 3,
                                        },
                                    },
                                    {
                                        breakpoint: 884,
                                        settings: {
                                            slidesToShow: solutions.length < 2 ? solutions.length : 2,
                                            slidesToScroll: solutions.length < 2 ? solutions.length : 2,
                                        },
                                    },
                                    {
                                        breakpoint: 580,
                                        settings: {
                                            slidesToShow: 1,
                                            slidesToScroll: 1,
                                        },
                                    },
                                ]}
                            >
                                {[...solutions].sort((a, b) => (a.order || 0) - (b.order || 0)).map((solution) => {
                                    const Icon = solution.icon ?
                                        sectorIconsMap[solution.icon] :
                                        IconPlaceholder;

                                    return (
                                        <Link
                                            to={getRoute(RoutePathName.products, {
                                                sectorId: sector ? sector.id : 0,
                                                subSectorId: subSector ? subSector.id : 0,
                                                solutionId: solution.id,
                                                assortmentId: undefined,
                                            })}
                                            onClick={onClickSolution.bind(null, solution)}
                                            key={solution.id}
                                        >
                                            <div
                                                className="solution-card"
                                                style={{ '--sector-color': '#00a5aa' } as React.CSSProperties}
                                            >
                                                <div className="icon-wrapper">
                                                    <Icon />
                                                </div>
                                                <h3>{t(solution.name, locale)}</h3>
                                                <Divider />
                                            </div>
                                        </Link>
                                    );
                                })}
                            </Carousel>
                        </>
                    )}
                    {!solutions.length && (
                        <EmptyResult />
                    )}
                </div>
            </section>
        </>
    );
};

const mapStateToProps = (state: MainReducerState, { match }: SubSectorProps) => ({
    isOnline: getOfflineState(state).online,
    sector: getSectorById(state, parseInt(match!.params.sectorId, 10)),
    subSector: getSectorById(state, parseInt(match!.params.id, 10)),
    solutions: getSectorsByParentId(state, parseInt(match!.params.id, 10)),
});

export default connect(
    mapStateToProps,
)(SubSectorPage);
