import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0 0 20 24">
        <path fill="currentColor" fillRule="evenodd" d="M17.486 4.22l.504-.658-2.144-1.62-.528.625 2.168 1.654zm-4.912-1.328L14.46.657c.26-.31.62-.528 1.014-.614a1.79 1.79 0 0 1 1.466.314l2.42 1.83c.72.543.852 1.58.295 2.308l-1.836 2.4-5.246-4.003zM6.5 17.022c.196.135.323.33.374.545l7.812-9.534-2.19-1.67-7.556 9.586 1.56 1.073zm-3.563 3.49l2.612-1.836a.912.912 0 0 1-.17-.093l-1.36-.934-1.083 2.862zm-1.79 3.466a.952.952 0 0 1-.92-.302.91.91 0 0 1-.162-.94l2.56-6.716a.923.923 0 0 1 .132-.347l.002-.005.065-.092.008-.006 9.352-11.865 5.223 3.987L7.792 19.4l-6.284 4.418a1 1 0 0 1-.36.16z" />
    </svg>
);

const IconEdit: React.FC<IconProps> = (props) => (
    <Icon component={svg} {...props} className={`anticon icon-edit${props.className ? ` ${props.className}` : ''}`} />
);

export default IconEdit;
