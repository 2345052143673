import React, { useEffect, KeyboardEvent, useState  } from 'react';
import { FormattedMessage, IntlShape, injectIntl, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Drawer, Select, Input } from 'antd';
import Form, { FormComponentProps, FormProps } from 'antd/lib/form';
import { DrawerProps } from 'antd/lib/drawer';

import '../../assets/styles/UserMenu.less';

import * as AuthActions from '../../store/actions/auth';
import { getAuth, AuthState } from '../../store/reducers/auth';
import { MainReducerState } from '../../store/reducers';
import { CountriesState, getCountriesState } from '../../store/reducers/countries';
import { Sector } from '../../store/api/types';
import { ProfilesState, getProfilesState } from '../../store/reducers/profiles';
import { getMarkets, getSectorsState, SectorsState } from '../../store/reducers/sectors';

import messages from './UserMenu.messages';
import { IconArrow } from '../icons';
import EmptyResult from '../EmptyResult';
import GenericMessages from '../../locale/Generic.messages';
import FormMessages from '../../locale/Form.messages';
import LoginMessages from '../../pages/login/Login.messages';
import RegisterMessages from '../../pages/register/Register.messages';
import { t } from '../../utils';

function hasErrors(fieldsError: Record<string, string[] | undefined>) {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
}

interface EditProfileDrawerProps extends DrawerProps, FormComponentProps {
    auth: AuthState;
    fetchUser: typeof AuthActions.fetchUser;
    countries: CountriesState;
    intl: IntlShape;
    markets: Sector[];
    profiles: ProfilesState;
    sectorsState: SectorsState;
    update: typeof AuthActions.update;


}

const EditProfileDrawer: React.FC<EditProfileDrawerProps> = ({
    auth, fetchUser, countries, form, intl, markets, onClose, profiles, sectorsState, update,
    visible, zIndex,
}) => {
    const { locale } = useIntl();
    const { getFieldDecorator, getFieldsError } = form;
    const [shouldReload, setShouldReload] = useState(true);
    const onBack = () => {
        if (typeof onClose === 'function') {
            onClose({} as KeyboardEvent<HTMLDivElement>);
        }
    };
    const onSubmit: FormProps['onSubmit'] = (e) => {
        setShouldReload(true);
        e.preventDefault();
        form.validateFields((err, values) => {
            if (err || !auth.data) {
                return;
            }
            update(auth.data.id, values);
        });
    };
    const error = auth.registerError ? <FormattedMessage {...GenericMessages.error} /> : null;
    useEffect(() => {
        if (auth.updateSuccess && typeof onClose === 'function' && shouldReload) {
            fetchUser();
            onClose({} as KeyboardEvent<HTMLDivElement>);
            setShouldReload(false);
        }
    }, [auth.updateSuccess, fetchUser, onClose, shouldReload]);

    return (
        <Drawer
            title={(
                <div className="drawer-header-with-back-button">
                    <Button
                        type="link"
                        onClick={onBack}
                    >
                        <IconArrow direction="left" />
                    </Button>
                    <FormattedMessage {...messages.editProfile} tagName="span" />
                </div>
            )}
            placement="right"
            width={385}
            maskStyle={{display: 'none'}}
            closable={false}
            visible={visible}
            zIndex={zIndex}
        >
            <div id="edit-profile">
                <section>
                    <Form onSubmit={onSubmit}>
                        <Form.Item label={intl.formatMessage(messages.lastNameLabel)}>
                            {getFieldDecorator('lastName', {
                                rules: [{
                                    required: true,
                                    message: intl.formatMessage(FormMessages.requiredError),
                                }],
                                initialValue: auth.data ? auth.data.lastName : undefined,
                            })((
                                <Input
                                    placeholder={intl.formatMessage(messages.lastNamePlaceholder)}
                                />
                            ))}
                        </Form.Item>
                        <Form.Item label={intl.formatMessage(messages.firstNameLabel)}>
                            {getFieldDecorator('firstName', {
                                rules: [{
                                    required: true,
                                    message: intl.formatMessage(FormMessages.requiredError),
                                }],
                                initialValue: auth.data ? auth.data.firstName : undefined,
                            })((
                                <Input
                                    placeholder={intl.formatMessage(messages.firstNamePlaceholder)}
                                />
                            ))}
                        </Form.Item>
                        <Form.Item label={intl.formatMessage(LoginMessages.emailLabel)}>
                            {getFieldDecorator('email', {
                                rules: [{
                                    required: true,
                                    type: 'email',
                                    message: intl.formatMessage(LoginMessages.emailError),
                                }],
                                initialValue: auth.data ? auth.data.email : undefined,
                                validateTrigger: 'onBlur',
                            })((
                                <Input
                                    placeholder={intl.formatMessage(LoginMessages.emailPlaceholder)}
                                    type="email"
                                />
                            ))}
                        </Form.Item>
                        <Form.Item label={intl.formatMessage(RegisterMessages.marketLabel)}>
                            {getFieldDecorator('marketId', {
                                rules: [{
                                    required: true,
                                    message: intl.formatMessage(FormMessages.requiredError),
                                }],
                                initialValue: auth.data ? auth.data.marketId : undefined,
                            })((
                                <Select
                                    placeholder={intl.formatMessage(FormMessages.selectPlaceholder)}
                                    notFoundContent={<EmptyResult />}
                                    loading={sectorsState.loading}
                                    filterOption={false}
                                >
                                    {markets && markets.map((market) => (
                                        <Select.Option
                                            value={market.id}
                                            key={market.id}
                                        >
                                            {t(market.name, locale)}
                                        </Select.Option>
                                    ))}
                                </Select>
                            ))}
                        </Form.Item>
                        <Form.Item label={intl.formatMessage(RegisterMessages.profileLabel)}>
                            {getFieldDecorator('profileId', {
                                rules: [{
                                    required: true,
                                    message: intl.formatMessage(FormMessages.requiredError),
                                }],
                                initialValue: auth.data ? auth.data.profileId : undefined,
                            })((
                                <Select
                                    placeholder={intl.formatMessage(FormMessages.selectPlaceholder)}
                                    notFoundContent={<EmptyResult />}
                                    loading={profiles.loading}
                                    filterOption={false}
                                >
                                    {profiles.data && profiles.data.map((profile) => (
                                        <Select.Option
                                            value={profile.id}
                                            key={profile.id}
                                        >
                                            {t(profile.name, locale)}
                                        </Select.Option>
                                    ))}
                                </Select>
                            ))}
                        </Form.Item>
                        <Form.Item label={intl.formatMessage(RegisterMessages.countryLabel)}>
                            {getFieldDecorator('countryId', {
                                rules: [{
                                    required: true,
                                    message: intl.formatMessage(FormMessages.requiredError),
                                }],
                                initialValue: auth.data ? auth.data.countryId : undefined,
                            })((
                                <Select
                                    placeholder={intl.formatMessage(FormMessages.selectPlaceholder)}
                                    notFoundContent={<EmptyResult />}
                                    loading={countries.loading}
                                    filterOption={false}
                                >
                                    {countries.data && countries.data.map((country) => (
                                        <Select.Option
                                            value={country.id}
                                            key={country.id}
                                        >
                                            {t(country.name, locale)}
                                        </Select.Option>
                                    ))}
                                </Select>
                            ))}
                        </Form.Item>
                        <fieldset className="m-b-32">
                            <FormattedMessage {...messages.changePassword} tagName="legend" />
                            <Form.Item label={intl.formatMessage(messages.oldPasswordLabel)} style={{marginBottom: 24}}>
                                {getFieldDecorator('oldPassword')((
                                    <Input.Password
                                        placeholder={intl.formatMessage(messages.oldPasswordPlaceholder)}
                                    />
                                ))}
                            </Form.Item>
                            <Form.Item label={intl.formatMessage(messages.newPasswordLabel)} style={{marginBottom: 0}}>
                                {getFieldDecorator('password')((
                                    <Input.Password
                                        placeholder={intl.formatMessage(messages.newPasswordPlaceholder)}
                                    />
                                ))}
                            </Form.Item>
                        </fieldset>
                        {error ? (
                            <div className="login-error has-error">
                                <span className="ant-form-explain">{error}</span>
                            </div>
                        ) : null}
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={hasErrors(getFieldsError())}
                            loading={auth.loading}
                            block
                        >
                            <FormattedMessage {...FormMessages.validateChanges} />
                        </Button>
                    </Form>
                </section>
            </div>
        </Drawer>
    );
};

const EditProfileDrawerForm = Form.create<EditProfileDrawerProps>()(EditProfileDrawer);

const mapStateToProps = (state: MainReducerState) => ({
    auth: getAuth(state),
    countries: getCountriesState(state),
    markets: getMarkets(state),
    profiles: getProfilesState(state),
    sectorsState: getSectorsState(state),
    shouldReload: true,
});

export default connect(
    mapStateToProps,
    {
        fetchUser: AuthActions.fetchUser,
        update: AuthActions.update,
    },
)(injectIntl(EditProfileDrawerForm));
