import React, { useCallback, useRef, useState, useContext } from 'react';
import Slider, { CustomArrowProps } from 'react-slick';
import { Button } from 'antd';

import '../assets/styles/ProductImageCarousel.less';

import { Product } from '../store/api/types';
import Carousel from './Carousel';
import { IconThinAngle, IconPlaceholder } from './icons';
import { useIntl } from 'react-intl';
import Img from './Img';
import { SectorColorContext } from './SectorColorContextProvider';
import { t } from '../utils';


const PrevArrow: React.FC<CustomArrowProps> = ({ currentSlide, slideCount, ...props }) => (
    <Button
        aria-label="slide précédente"
        color="#88b4e1"
        {...props}
    >
        <IconThinAngle direction="left" />
    </Button>
);

const NextArrow: React.FC<CustomArrowProps> = ({ currentSlide, slideCount, ...props }) => (
    <Button
        aria-label="slide suivante"
        color="#88b4e1"
        {...props}
    >
        <IconThinAngle direction="right" />
    </Button>
);

interface ProductImageCarouselProps {
    product?: Product;
}

const ProductImageCarousel: React.FC<ProductImageCarouselProps> = ({ product }) => {
    const { locale } = useIntl();
    const { color } = useContext(SectorColorContext);
    const [currentSlide, setCurrentSlide] = useState(0);
    const carousel = useRef<Slider>(null);
    const onChangeSlide = useCallback((e) => {
        if (carousel.current) {
            setCurrentSlide(parseInt(e.currentTarget.dataset.index, 10));
            carousel.current.slickGoTo(e.currentTarget.dataset.index);
        }
    }, []);
    const onBeforeCarouselChange = useCallback((oldIndex, newIndex) => {
        setCurrentSlide(newIndex);
    }, []);

    return (
        <div
            id="product-image-carousel-wrapper"
            style={{ '--sector-color': color || '#00a5aa' } as React.CSSProperties}
        >
            <div id="product-image-carousel-nav">
                {product && product.images && !!product.images.length && product.images.map((image, index) => (
                    <Button
                        onClick={onChangeSlide}
                        data-index={index}
                        key={index}
                        className={currentSlide === index ? 'is-active' : undefined}
                    >
                        <Img imageId={image.id} alt={product ? t(product.label, locale) : 'product'} />
                    </Button>
                ))}
            </div>
            <div id="product-image-carousel">
                <Carousel
                    beforeChange={onBeforeCarouselChange}
                    lazyLoad="ondemand"
                    nextArrow={<NextArrow />}
                    prevArrow={<PrevArrow />}
                    ref={carousel}
                    swipeToSlide
                >
                    {
                        !product ||
                        (product && product.images && !product.images.length) ||
                        (product && !product.images) ?
                            (
                                <div className="product-image-carousel-slide">
                                    <IconPlaceholder />
                                </div>
                            ) : product && product.images && product.images.map((image, index) => (
                                <div className="product-image-carousel-slide" key={index}>
                                    <Img imageId={image.id} alt={product ? t(product.label, locale) : 'product'} />
                                </div>
                            ))
                    }
                </Carousel>
            </div>
        </div>
    );
};

export default ProductImageCarousel;
