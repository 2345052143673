import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="28" viewBox="0 0 32 28">
        <path fill="#00A5AA" fillRule="evenodd" d="M11.528 15.345a.862.862 0 0 1 .848.745c.548 3.993 3.227 4.07 3.34 4.07l.047.003.006-.002.04.002c.11 0 2.79-.077 3.337-4.07a.863.863 0 0 1 .847-.75c.163 0 .323.048.46.137a.508.508 0 0 0 .17.053c0-.018.164-.232.195-1.007.033-.84-.03-1.273-.08-1.477a.868.868 0 0 1-.546-.998c.005-.026.51-2.55-.776-4.164-.736-.924-1.97-1.413-3.665-1.454-1.678.04-2.91.53-3.647 1.454-1.285 1.61-.782 4.14-.777 4.164a.87.87 0 0 1-.548.997c-.05.203-.112.635-.078 1.476.035.85.227 1.025.23 1.027.017-.02.112-.06.146-.08a.85.85 0 0 1 .448-.128M5.363 28a.864.864 0 0 1-.26-.04.87.87 0 0 1-.554-1.093c.644-2.03 1.337-3.042 2.244-3.282 1.767-.468 5.542-2.618 5.58-2.64A.84.84 0 0 1 13 20.86c-.868-.672-1.717-1.776-2.145-3.593a1.627 1.627 0 0 1-.73-.188c-.698-.37-1.078-1.205-1.13-2.483-.06-1.474.114-2.37.562-2.854-.105-1.042-.13-3.25 1.212-4.938 1.004-1.264 2.548-1.967 4.588-2.088a.625.625 0 0 1 .166-.025c.052 0 .104.002.154.005l.09.004.078-.003c.05-.003.1-.005.153-.005.076 0 .138.017.174.027 2.013.11 3.57.813 4.58 2.085 1.34 1.688 1.318 3.896 1.213 4.938.447.483.62 1.38.56 2.854-.05 1.278-.43 2.113-1.128 2.482-.226.118-.472.18-.732.187-1.078 4.574-4.816 4.63-4.854 4.63l-.054-.003-.006.002h-.044c-.02 0-1.074-.016-2.223-.705l.03.042c.368.585 1.41 1.793 2.277 1.793.92 0 2.25-1.265 2.75-1.877a.846.846 0 0 1 1.082-.202c.037.02 3.81 2.17 5.58 2.64.673.178 1.233.796 1.74 1.93a14.546 14.546 0 0 0 3.343-9.306c0-7.983-6.41-14.477-14.29-14.477-7.88 0-14.29 6.494-14.29 14.477 0 2.753.766 5.43 2.214 7.743a.876.876 0 0 1-.264 1.197.85.85 0 0 1-1.18-.267A16.322 16.322 0 0 1 0 16.21C0 7.272 7.177 0 16 0c8.822 0 16 7.272 16 16.21 0 4.33-1.665 8.4-4.687 11.463a.8.8 0 0 1-.352.206.38.38 0 0 1-.077.034.855.855 0 0 1-1.075-.558c-.6-1.868-1.048-2.098-1.052-2.1-1.53-.403-4.113-1.77-5.375-2.467-.787.815-2.154 1.968-3.586 1.968-1.407 0-2.575-1.12-3.24-1.934-1.263.695-3.808 2.036-5.328 2.438.006 0-.447.243-1.05 2.137-.113.358-.44.6-.813.6" />
    </svg>
);

const IconUser: React.FC<IconProps> = (props) => (
    <Icon component={svg} {...props} className={`anticon icon-user${props.className ? ` ${props.className}` : ''}`} />
);

export default IconUser;
