import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
        <path fill="currentColor" d="M0 18c0-.786.637-1.424 1.422-1.424h15.18v-15.2a1.424 1.424 0 0 1 2.845 0v15.2h15.18a1.424 1.424 0 0 1 0 2.848h-15.18v15.2a1.424 1.424 0 0 1-2.844 0v-15.2H1.423A1.424 1.424 0 0 1 0 18z" />
    </svg>
);

const IconAdd: React.FC<IconProps> = (props) => (
    <Icon component={svg} {...props} className={`anticon icon-add${props.className ? ` ${props.className}` : ''}`} />
);

export default IconAdd;
