import React, { FC, useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';
import { Collapse, Checkbox } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox';
import { CollapsePanelProps } from 'antd/lib/collapse';

import { PropertyGroup } from '../../store/reducers/sectors';
import { Property, PropertyValue } from '../../store/api/types';
import { FiltersState } from '../../store/reducers/filters';

import { SectorColorContext } from '../../components/SectorColorContextProvider';
import { IconMinus, IconPlus } from '../../components/icons';
import PieChart from '../../components/PieChart';
import { t } from '../../utils';

interface PropertyCollapseProps extends CollapsePanelProps {
    filters: FiltersState;
    onChange: CheckboxProps['onChange'];
    property: Partial<Property> & Partial<PropertyGroup> & {
        valuesById?: {
            [key: string]: PropertyValue;
        };
    };
}

const PropertyCollapse: FC<PropertyCollapseProps> = ({ filters, onChange, property, ...restProps }) => {
    const { locale } = useIntl();
    const { color } = useContext(SectorColorContext);
    const CollapseExpandIcon = useCallback(({ isActive }: { isActive?: boolean }) =>
        isActive ?
            <IconMinus style={color ? { color } : undefined} /> :
            <IconPlus style={color ? { color } : undefined} />
    , [color]);
    const isGroup = !!property.children;
    const onCheckboxChange: CheckboxProps['onChange'] = (e) => {
        if (typeof onChange === 'function') {
            onChange(e);
        }

        const elements = document.querySelectorAll('.ant-collapse-content-box');

        elements.forEach((element) => {
            element.scroll({
                top: 0,
                behavior: 'smooth',
            });
        });
    };


    const propertiesSort = new Intl.Collator(locale, { numeric: true, sensitivity: 'base' });

    return (
        <Collapse.Panel
            key={`${property.id}`}
            className={`collapse-panel-checkboxes${isGroup ? ' is-group' : ''}`}
            {...restProps}
        >
            {
                isGroup ?
                    (
                        property.children &&
                        !!Object.keys(property.children).length && (
                            <Collapse
                                bordered={false}
                                expandIcon={CollapseExpandIcon}
                                expandIconPosition="right"
                                accordion
                            >
                                {Object.keys(property.children)
                                    .sort((a, b) =>
                                        property.children![parseInt(a, 10)].order -
                                        property.children![parseInt(b, 10)].order,
                                    )
                                    .filter((childPropertyId) => {
                                        const prop = property.children![parseInt(childPropertyId, 10)];

                                        return prop.valuesById && Object.keys(prop.valuesById).some((valueId) =>
                                            !!prop.valuesById![valueId].productCount,
                                        );
                                    })
                                    .map((childPropertyId, childPropertyIndex) => (
                                        <PropertyCollapse
                                            filters={filters}
                                            header={
                                                property.children![parseInt(childPropertyId, 10)].name ?
                                                    t(property.children![parseInt(childPropertyId, 10)].name!, locale) : ''
                                            }
                                            onChange={onChange}
                                            property={property.children![parseInt(childPropertyId, 10)]}
                                            key={`${childPropertyId}${childPropertyIndex}`}
                                        />
                                    ))
                                }
                            </Collapse>
                        )
                    ) :
                    (
                        property.valuesById && Object.keys(property.valuesById).length &&
                            Object.keys(property.valuesById)
                                .map((valueKey) => property.valuesById![parseInt(valueKey, 10)])
                                .sort((a, b) => propertiesSort.compare(t(a.name, locale), t(b.name, locale)))
                                .filter((value) => !!value.productCount)
                                .map((value) => {
                                    let order = -1;
                                    let checked = false;

                                    if (filters.properties) {
                                        checked = filters.properties.some((prop) =>
                                            prop.id === property.id &&
                                            prop.valueId === value.id,
                                        );
                                    }

                                    if (checked) {
                                        order = -2;
                                    }

                                    const colors = value.color ?
                                        value.color!.split('-') :
                                        null;

                                    return (
                                        <div
                                            key={value.id}
                                            className={`m-b-16${colors !== null ? ' is-color' : ''}`}
                                            style={{ order }}
                                        >
                                            <Checkbox
                                                checked={checked}
                                                onChange={onCheckboxChange}
                                                data-id={`${property.id}:${value.id}`}
                                            >
                                                {colors !== null ? (
                                                    <>
                                                        <PieChart data={colors} size={24} />
                                                        {t(value.name, locale)}
                                                    </>
                                                ) :
                                                    t(value.name, locale)
                                                }
                                                <span>({value.productCount})</span>
                                            </Checkbox>
                                        </div>
                                    );
                                })
                    )
            }
        </Collapse.Panel>
    );
};

export default PropertyCollapse;
