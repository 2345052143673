import React, { createContext, useState, FC } from 'react';

export enum FavoriteDrawerTabs {
    products = 'products',
    sectors = 'sectors',
}

interface UserMenuDrawerContextTypes {
    closeAll: () => void;
    favoritesTab: FavoriteDrawerTabs;
    isClientDrawerVisible: boolean;
    isClientFormDrawerVisible: boolean;
    isClientsDrawerVisible: boolean;
    isClientSelectionDrawerVisible: boolean;
    isClientSelectionsDrawerVisible: boolean;
    isEditProfileDrawerVisible: boolean;
    isFavoritesDrawerVisible: boolean;
    isFavoritesMaskVisible: boolean;
    isPresentationsDrawerVisible: boolean;
    isUserDrawerVisible: boolean;
    isUserPresentationsDrawerVisible: boolean;
    setFavoritesTab: React.Dispatch<React.SetStateAction<FavoriteDrawerTabs>>;
    setIsClientDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setIsClientFormDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setIsClientsDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setIsClientSelectionDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setIsClientSelectionsDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setIsEditProfileDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setIsFavoritesDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setIsFavoritesMaskVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setIsPresentationsDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setIsUserDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>;
    setIsUserPresentationsDrawerVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UserMenuDrawerContext = createContext<UserMenuDrawerContextTypes>({
    closeAll: () => undefined,
    favoritesTab: FavoriteDrawerTabs.sectors,
    isClientDrawerVisible: false,
    isClientFormDrawerVisible: false,
    isClientsDrawerVisible: false,
    isClientSelectionDrawerVisible: false,
    isClientSelectionsDrawerVisible: false,
    isEditProfileDrawerVisible: false,
    isFavoritesDrawerVisible: false,
    isFavoritesMaskVisible: false,
    isPresentationsDrawerVisible: false,
    isUserDrawerVisible: false,
    isUserPresentationsDrawerVisible: false,
    setFavoritesTab: () => undefined,
    setIsClientDrawerVisible: () => undefined,
    setIsClientFormDrawerVisible: () => undefined,
    setIsClientsDrawerVisible: () => undefined,
    setIsClientSelectionDrawerVisible: () => undefined,
    setIsClientSelectionsDrawerVisible: () => undefined,
    setIsEditProfileDrawerVisible: () => undefined,
    setIsFavoritesDrawerVisible: () => undefined,
    setIsFavoritesMaskVisible: () => undefined,
    setIsPresentationsDrawerVisible: () => undefined,
    setIsUserDrawerVisible: () => undefined,
    setIsUserPresentationsDrawerVisible: () => undefined,
});

export const UserMenuDrawerContextProvider: FC = ({ children }) => {
    const [favoritesTab, setFavoritesTab] = useState(FavoriteDrawerTabs.sectors);
    const [isClientDrawerVisible, setIsClientDrawerVisible] = useState(false);
    const [isClientFormDrawerVisible, setIsClientFormDrawerVisible] = useState(false);
    const [isClientsDrawerVisible, setIsClientsDrawerVisible] = useState(false);
    const [isClientSelectionDrawerVisible, setIsClientSelectionDrawerVisible] = useState(false);
    const [isClientSelectionsDrawerVisible, setIsClientSelectionsDrawerVisible] = useState(false);
    const [isEditProfileDrawerVisible, setIsEditProfileDrawerVisible] = useState(false);
    const [isFavoritesDrawerVisible, setIsFavoritesDrawerVisible] = useState(false);
    const [isFavoritesMaskVisible, setIsFavoritesMaskVisible] = useState(false);
    const [isPresentationsDrawerVisible, setIsPresentationsDrawerVisible] = useState(false);
    const [isUserPresentationsDrawerVisible, setIsUserPresentationsDrawerVisible] = useState(false);
    const [isUserDrawerVisible, setIsUserDrawerVisible] = useState(false);
    const closeAll: UserMenuDrawerContextTypes['closeAll'] = () => {
        setIsClientDrawerVisible(false);
        setIsClientFormDrawerVisible(false);
        setIsClientsDrawerVisible(false);
        setIsClientSelectionDrawerVisible(false);
        setIsClientSelectionsDrawerVisible(false);
        setIsEditProfileDrawerVisible(false);
        setIsFavoritesDrawerVisible(false);
        setIsFavoritesMaskVisible(false);
        setIsPresentationsDrawerVisible(false);
        setIsUserDrawerVisible(false);
        setIsUserPresentationsDrawerVisible(false);
    };

    const drawerContext = {
        closeAll,
        favoritesTab,
        isClientDrawerVisible,
        isClientFormDrawerVisible,
        isClientsDrawerVisible,
        isClientSelectionDrawerVisible,
        isClientSelectionsDrawerVisible,
        isEditProfileDrawerVisible,
        isFavoritesDrawerVisible,
        isFavoritesMaskVisible,
        isPresentationsDrawerVisible,
        isUserDrawerVisible,
        isUserPresentationsDrawerVisible,
        setFavoritesTab,
        setIsClientDrawerVisible,
        setIsClientFormDrawerVisible,
        setIsClientsDrawerVisible,
        setIsClientSelectionDrawerVisible,
        setIsClientSelectionsDrawerVisible,
        setIsEditProfileDrawerVisible,
        setIsFavoritesDrawerVisible,
        setIsFavoritesMaskVisible,
        setIsPresentationsDrawerVisible,
        setIsUserDrawerVisible,
        setIsUserPresentationsDrawerVisible,
    };

    return (
        <UserMenuDrawerContext.Provider value={drawerContext}>
            {children}
        </UserMenuDrawerContext.Provider>
    );
};
