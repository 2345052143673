import React from 'react';
import { IntlProvider as ReactIntlProvider, IntlConfig } from 'react-intl';
import { Spin, ConfigProvider } from 'antd';

import { hasOwnProp } from '../utils';

export enum Languages {
    fr = 'fr',
    en = 'en',
}

interface IntlProviderProps {
    children?: React.ReactNode;
}

interface IntlProviderState {
    antLocale?: any;
    locale: Languages;
    messages: IntlConfig['messages'] | null;
}

/**
 * Intl wrapper around the app
 */
export default class IntlProvider extends React.Component<IntlProviderProps, IntlProviderState> {
    constructor(props: IntlProviderProps) {
        super(props);
        this.state = {
            locale: Languages.en,
            messages: null,
        };
        this.setLang();
    }

    public render() {
        const { children } = this.props;
        const { antLocale, locale, messages } = this.state;

        if (messages === null) {
            return (
                <div id="initial-loader">
                    <Spin />
                </div>
            );
        }

        return (
            <ConfigProvider locale={antLocale}>
                <ReactIntlProvider
                    locale={locale}
                    messages={messages}
                >
                    {children}
                </ReactIntlProvider>
            </ConfigProvider>
        );
    }

    private async setLang() {
        const locale = navigator.language;
        let languageCode = locale.split('-')[0] as Languages;
        const reactIntlPluralLocaleData: { [id: string]: any } = {
            fr: () => import('@formatjs/intl-pluralrules/dist/locale-data/fr'),
            en: () => import('@formatjs/intl-pluralrules/dist/locale-data/en'),
        };
        const reactIntlRelativeLocaleData: { [id: string]: any } = {
            fr: () => import('@formatjs/intl-relativetimeformat/dist/locale-data/fr'),
            en: () => import('@formatjs/intl-relativetimeformat/dist/locale-data/en'),
        };
        const l10nFiles: { [id: string]: any } = {
            fr: () => import('../locale/l10n/fr.json'),
            en: () => import('../locale/l10n/en.json'),
        };
        const antdLocales: { [id: string]: any } = {
            fr: () => import('../locale/i18n/fr_FR'),
            en: () => import('antd/lib/locale/en_US'),
        };

        if (!hasOwnProp(l10nFiles, languageCode)) {
            languageCode = Languages.en;
        }

        if (!Intl.PluralRules) {
            await import('@formatjs/intl-pluralrules/polyfill');
            await reactIntlPluralLocaleData[languageCode]();
        }

        // @ts-ignore
        if (!Intl.RelativeTimeFormat) {
            await import('@formatjs/intl-relativetimeformat/polyfill');
            await reactIntlRelativeLocaleData[languageCode]();
        }

        const messages = await l10nFiles[languageCode]();
        const antLocale = await antdLocales[languageCode]();

        this.setState({
            antLocale,
            locale: Languages[languageCode],
            messages: messages.default,
        });

        document.getElementsByTagName('html')[0].setAttribute('lang', locale);
    }
}
