import React, { useContext, FC, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'antd';

import '../../assets/styles/Presentation.less';

import GenericMessages from '../../locale/Generic.messages';
import { PresentationContext, PresentationSteps } from '../PresentationContextProvider';
import { IconClose } from '../icons';
import messages from './Presentation.messages';
import PresentationChapters from './PresentationChapters';
import PresentationPreview from './PresentationPreview';
import PresentationInformations from './PresentationInformations';
import PresentationCustomize from './PresentationCustomize';
import { PresentationSuccess } from '.';

const Presentation: FC = () => {
    const { locale } = useIntl();
    const {
        isPresentationOpen, presentation, setIsPresentationOpen, step, reset,
    } = useContext(PresentationContext);
    const onClickCancel = () => {
        setIsPresentationOpen(false);
        reset();
    };

    useEffect(() => {
        const body = document.querySelector('body');

        body!.style.overflow = isPresentationOpen ? 'hidden' : '';
    }, [isPresentationOpen]);

    let content = null;

    switch (step) {
        case PresentationSteps.Chapters:
            content = <PresentationChapters />;
            break;

        case PresentationSteps.Preview:
            content = <PresentationPreview />;
            break;

        case PresentationSteps.Informations:
            content = <PresentationInformations presentation={presentation} />;
            break;

        case PresentationSteps.Customize:
            content = <PresentationCustomize presentation={presentation} />;
            break;

        case PresentationSteps.Success:
            content = <PresentationSuccess />;
            break;
    }

    return isPresentationOpen ? (
        <div
            id="presentation-wrapper"
            className={step === PresentationSteps.Success ? 'presentation-success' : undefined}
        >
            {step !== PresentationSteps.Success && (
                <header>
                    <div>
                        <FormattedMessage
                            {...(
                                presentation.type === 'presentation' ?
                                    messages.titlePresentation :
                                    messages.titleProposition
                            )}
                            tagName="p"
                        />
                        {presentation.sector && <p>{presentation.sector.name[locale]}</p>}
                    </div>
                    <Button
                        onClick={onClickCancel}
                        id="presentation-cancel-button"
                    >
                        <div id="cancel-icon-wrapper">
                            <IconClose />
                        </div>
                        <FormattedMessage {...GenericMessages.cancel} />
                    </Button>
                </header>
            )}
            {content}
        </div>
    ) : null;
};

export default Presentation;
