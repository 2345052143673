import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { OfflineState } from '@redux-offline/redux-offline/lib/types';
import { Spin } from 'antd';

import * as AuthActions from '../../store/actions/auth';
import { MainReducerState, getOfflineState } from '../../store/reducers';
import { getAuth, AuthState } from '../../store/reducers/auth';

interface ProtectedRouteProps {
    auth: AuthState;
    checkLoginStatus: typeof AuthActions.checkLoginStatus;
    loginPath: string;
    offline?: OfflineState;
    unauthorizedErrorOccured: typeof AuthActions.loginFailed;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({
    auth, checkLoginStatus, children, loginPath, offline, unauthorizedErrorOccured,
}) => {
    useEffect(() => {
        window.addEventListener('unauthorized.error', unauthorizedErrorOccured); // dispatched from axios interceptor

        if (offline && offline.online && !auth.hasCheckedLoginStatus) {
            checkLoginStatus();
        }

        return () => window.removeEventListener('unauthorized.error', unauthorizedErrorOccured);
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (auth.hasCheckedLoginStatus && !auth.isConnected) {
        return <Redirect to={loginPath} />;
    } else if (auth.hasCheckedLoginStatus && auth.isConnected) {
        return <>{children}</>;
    } else {
        return (
            <Route>
                <div id="initial-loader">
                    <Spin />
                </div>
            </Route>
        );
    }
};

const mapStateToProps = (state: MainReducerState) => ({
    auth: getAuth(state),
    offline: getOfflineState(state),
});

export default connect(
    mapStateToProps,
    {
        checkLoginStatus: AuthActions.checkLoginStatus,
        unauthorizedErrorOccured: AuthActions.loginFailed,
    },
)(ProtectedRoute);
