import { defineMessages } from 'react-intl';

const messages = defineMessages({
    inputPlaceholder: {
        id: 'search.input.placeholder',
        defaultMessage: 'What are you searching for? (product, service...)',
        description: 'Search input placeholder',
    },
    noResult: {
        id: 'search.no_result',
        defaultMessage: 'No result found. Please change your search criteria',
        description: 'Search no result phrase',
    },
    seeMoreForQuery: {
        id: 'search.see_more',
        defaultMessage: 'See more results for “<b>{query}</b>”',
        description: 'Search see more results link',
    },
    searchResults: {
        id: 'search.page.search_results',
        defaultMessage: 'Search results',
        description: 'Search page breadcrumb',
    },
    searchResultsForQuery: {
        id: 'search.page.search_results_for_query',
        defaultMessage: 'Search results for',
        description: 'Search page title',
    },
    searchResultsQuery: {
        id: 'search.page.query',
        defaultMessage: '“{query}”',
        description: 'Search page title',
    },
});

export default messages;
