import * as reduxTypes from '../types/terms';
import { requestReducer } from './_generics';
import { MainReducerState, RequestState } from '.';
import { Reducer } from 'redux';

export type TermsState = RequestState<string>;

const terms: Reducer<TermsState> = requestReducer({
    reduxTypes: {
        START: reduxTypes.FETCH,
        SUCCESS: reduxTypes.FETCH_SUCCESS,
        FAILED: reduxTypes.FETCH_FAILED,
    },
});

export default terms;

export const getTermsState = (state: MainReducerState) => state.terms;
