import React, { KeyboardEvent, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { Button, Drawer, List, Skeleton } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import { ButtonProps } from 'antd/lib/button';

import '../../assets/styles/UserMenu.less';

import { Client, Presentation, PresentationType } from '../../store/api/types';
import { getUser } from '../../store/reducers/auth';
import { MainReducerState, getOfflineState } from '../../store/reducers';
import { getClientByReference } from '../../store/reducers/clients';
import { getSelectionsByCustomerReference } from '../../store/reducers/selections';
import { getPresentationsByCustomerReference } from '../../store/reducers/presentations';

import { getFullName } from '../../utils';
import { IconPresentation, IconArrow, IconCirclePlus, IconNote } from '../icons';
import ListItem from '../ListItem';
import messages from './UserMenu.messages';
import ClientFormDrawer from './ClientFormDrawer';
import ClientSelectionsDrawer from './ClientSelectionsDrawer';
import PresentationsDrawer from './PresentationsDrawer';
import { UserMenuDrawerContext } from './UserMenuDrawerContext';

interface ClientDrawerProps extends DrawerProps {
    client?: Client;
    clientReference?: Client['reference'];
    isOnline: boolean;
    presentations: Presentation[];
    propositions: Presentation[];
    selectionsCount: number;
}

const ClientDrawer: React.FC<ClientDrawerProps> = ({
    client, clientReference, isOnline, onClose, presentations, propositions, selectionsCount,
    visible, zIndex,
}) => {
    const {
        isClientFormDrawerVisible, isUserPresentationsDrawerVisible, isClientSelectionsDrawerVisible,
        setIsClientFormDrawerVisible, setIsClientSelectionsDrawerVisible, setIsUserPresentationsDrawerVisible,
    } = useContext(UserMenuDrawerContext);
    const [presentationDrawerType, setPresentationDrawerType] = useState<PresentationType>('presentation');
    const onBack: ButtonProps['onClick'] = () => {
        if (typeof onClose === 'function') {
            onClose({} as KeyboardEvent<HTMLDivElement>);
        }
    };
    // const [isMySalesPropositionsDrawerVisible, setIsMySalesPropositionsDrawerVisible] = useState(false);

    const onCloseClientFormDrawer = () => { setIsClientFormDrawerVisible(false); };
    const onCloseClientSelectionsDrawer = () => { setIsClientSelectionsDrawerVisible(false); };
    const onCloseClientPresentationsDrawer = () => { setIsUserPresentationsDrawerVisible(false); };
    const onClickEditClient = () => { setIsClientFormDrawerVisible(true); };
    const onClickSelections = () => { setIsClientSelectionsDrawerVisible(true); };
    const onClickMyPresentations = () => {
        setPresentationDrawerType('presentation');
        setIsUserPresentationsDrawerVisible(true);
        ReactGA.event({
            category: 'menu présentations',
            action: 'clic',
            label: 'depuis menu client',
        });
    };
    const onClickMySalesPropositions = () => {
        setPresentationDrawerType('proposition');
        setIsUserPresentationsDrawerVisible(true);
        ReactGA.event({
            category: 'menu propositions',
            action: 'clic',
            label: 'depuis menu client',
        });
    };

    return (
        <>
            <Drawer
                title={(
                    <div className="drawer-header-with-back-button">
                        <Button
                            type="link"
                            onClick={onBack}
                        >
                            <IconArrow direction="left" />
                        </Button>
                        <FormattedMessage {...messages.clientFile} tagName="span" />
                    </div>
                )}
                placement="right"
                width={385}
                maskStyle={{ display: 'none' }}
                closable={false}
                visible={visible}
                zIndex={zIndex}
            >
                <div id="user-menu-client">
                    <section>
                        <div id="user-menu-user">
                            <div>
                                <Skeleton
                                    loading={!client}
                                >
                                    <span>
                                        <span>{client && getFullName(client.firstName, client.lastName)}</span>
                                        <span>{client && client.company}</span>
                                        <span>{client && client.email}</span>
                                    </span>
                                </Skeleton>
                            </div>
                            <Button
                                onClick={onClickEditClient}
                                disabled={client && client.id < 0}
                            >
                                <FormattedMessage {...messages.editInformations} />
                            </Button>
                        </div>
                        <List>
                            <List.Item>
                                <ListItem
                                    icon={<IconCirclePlus />}
                                    onClick={onClickSelections}
                                    subTitle={(
                                        <FormattedMessage
                                            {...messages.selectionsSub}
                                            values={{ count: selectionsCount }}
                                        />
                                    )}
                                    title={<FormattedMessage {...messages.selections} />}
                                />
                            </List.Item>
                            <List.Item>
                                <ListItem
                                    disabled={!isOnline}
                                    icon={<IconPresentation />}
                                    onClick={onClickMyPresentations}
                                    subTitle={(
                                        <FormattedMessage
                                            {...messages.presentationsSub}
                                            values={{ count: presentations.length }}
                                        />
                                    )}
                                    title={<FormattedMessage {...messages.presentations} />}
                                />
                            </List.Item>
                            <List.Item>
                                <ListItem
                                    disabled={!isOnline}
                                    icon={<IconNote />}
                                    onClick={onClickMySalesPropositions}
                                    subTitle={(
                                        <FormattedMessage
                                            {...messages.propositionsSub}
                                            values={{ count: propositions.length }}
                                        />
                                    )}
                                    title={<FormattedMessage {...messages.propositions} />}
                                />
                            </List.Item>
                        </List>
                    </section>
                </div>
            </Drawer>
            <ClientFormDrawer
                clientReference={clientReference}
                onClose={onCloseClientFormDrawer}
                visible={isClientFormDrawerVisible}
                zIndex={1402}
            />
            <ClientSelectionsDrawer
                clientReference={clientReference}
                onClose={onCloseClientSelectionsDrawer}
                visible={isClientSelectionsDrawerVisible}
                zIndex={1402}
            />
            <PresentationsDrawer
                clientReference={clientReference}
                onClose={onCloseClientPresentationsDrawer}
                type={presentationDrawerType}
                visible={isUserPresentationsDrawerVisible}
                zIndex={1402}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState, { clientReference }: { clientReference: Client['reference'] }) => ({
    client: getClientByReference(state, clientReference),
    isOnline: getOfflineState(state).online,
    presentations: getPresentationsByCustomerReference(state, 'presentation', clientReference),
    propositions: getPresentationsByCustomerReference(state, 'proposition', clientReference),
    selectionsCount: getSelectionsByCustomerReference(state, clientReference || '-1').length,
    user: getUser(state),
});

export default connect(
    mapStateToProps,
)(ClientDrawer);
