import React, { FC, useContext, useState, ChangeEventHandler } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { Button, Input, Upload, Icon, Select, Divider, Form, message, Spin } from 'antd';
import { OptionProps } from 'antd/lib/select';
import uuid from 'uuid/v4'; // eslint-disable-line

import '../../assets/styles/Presentation.less';

import * as ClientsActions from '../../store/actions/clients';
import { MainReducerState } from '../../store/reducers';
import { getClientsListStateForPresentation, ClientsListState } from '../../store/reducers/clients';
import { Client, File } from '../../store/api/types';
import api from '../../store/api/_client';

import GenericMessages from '../../locale/Generic.messages';
import FormMessages from '../../locale/Form.messages';
import { getFullName } from '../../utils';
import LoginMessages from '../../pages/login/Login.messages';
import UserMenuMessages from '../userMenu/UserMenu.messages';
import { PresentationContext, PresentationSteps, PresentationData } from '../PresentationContextProvider';
import { IconArrow } from '../icons';
import EmptyResult from '../EmptyResult';
import messages from './Presentation.messages';
import { UploadFile, UploadProps } from 'antd/lib/upload/interface';

const filterClients = (input: string, option: React.ReactElement<OptionProps>) => {
    if (input) {
        return option.props.children ?
            `${option.props.children}`.toLowerCase().indexOf(input.toLowerCase()) >= 0 :
            false;
    }

    return true;
};

interface PresentationInformationsProps {
    clientsListState: ClientsListState;
    createClient: typeof ClientsActions.create;
    createClientSuccess: typeof ClientsActions.createSuccess;
    presentation: PresentationData;
}

const PresentationInformations: FC<PresentationInformationsProps> = ({
    clientsListState, createClient, createClientSuccess,
}) => {
    const { formatMessage } = useIntl();
    const { isEditing, presentation, setStep, setPresentationData } = useContext(PresentationContext);
    const [isClientFormVisible, setIsClientFormVisible] = useState<boolean>(!isEditing);
    const [isCreatingClient, setIsCreatingClient] = useState<boolean>(false);
    const [clientFirstName, setClientFirstName] = useState<string | undefined>();
    const [clientLastName, setClientLastName] = useState<string | undefined>();
    const [clientCompany, setClientCompany] = useState<string | undefined>();
    const [clientEmail, setClientEmail] = useState<string | undefined>();
    const onChangeField = (field: string, e: React.ChangeEvent<HTMLInputElement>) =>  {
        switch (field) {
            case 'firstName':
                setClientFirstName(e.target.value);
                break;
            case 'lastName':
                setClientLastName(e.target.value);
                break;
            case 'company':
                setClientCompany(e.target.value);
                break;
            case 'email':
                setClientEmail(e.target.value);
                break;
        }
    };
    const [title, setTitle] = useState(presentation.title);
    const [client, setClient] = useState(presentation.client);
    const [logo, setLogo] = useState<UploadFile>();
    const [logoFile, setLogoFile] = useState<Partial<File> | undefined>(presentation.logo);
    const isSubmitEnabled =
        title &&
        (
            presentation.client ||
            client ||
            (clientFirstName && clientLastName && clientCompany && clientEmail)
        ) &&
        (presentation.logo || logoFile);
    const onClickBack = () => {
        setStep(PresentationSteps.Chapters);
    };
    const onChangeTitle: ChangeEventHandler<HTMLInputElement> = (e) => setTitle(e.target.value);
    const onChangeClient = (clientId: Client['id']) => {
        if (clientsListState.data) {
            setClient(clientsListState.data.find((c) => c.id === clientId));
        }
    };
    const onSubmit = async () => {
        const payload = {
            title: (title || '').trim(),
            logo: logoFile,
        };

        if (client) {
            setPresentationData({
                ...payload,
                client,
            });
            setStep(PresentationSteps.Customize);
        } else {
            customClientRequest({
                reference: uuid(),
                company: clientCompany,
                lastName: clientLastName,
                firstName: clientFirstName,
                email: clientEmail,
                sectorId: presentation.sector ? presentation.sector.id : undefined,
            }).then(
                (response) => {
                    setPresentationData({
                        ...payload,
                        client: response as any,
                    });
                    setStep(PresentationSteps.Customize);
                },
                (error) => {
                    message.error(formatMessage(GenericMessages.error));
                    console.warn(error);
                },
            );
        }
    };
    const customUploadRequest = (options: any) => {
        const { onError, onProgress, onSuccess, file } = options;
        const data = new FormData();

        data.append('file', file);
        data.append('subType', 'logo');

        api.post('/api/files', data, {
            onUploadProgress: onProgress as any,
        }).then(
            (response: any) => {
                setLogo(file);
                setLogoFile(response);

                onSuccess(response, file);
            },
            (error: any) => {
                message.error(formatMessage(messages.imageUploadError));
                onError(error);
            },
        );
    };
    const customClientRequest = (payload: any) => {
        setIsCreatingClient(true);

        return api.post('/api/customers', payload).then((response) => {
            setIsCreatingClient(false);
            createClientSuccess(response, response);

            return response;
        });
    };

    const beforeUpload: UploadProps['beforeUpload'] = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

        if (!isJpgOrPng) {
            message.error(formatMessage(messages.imageUploadFormatError));
        }
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isLt2M) {
            message.error(formatMessage(messages.imageUploadSizeError));
        }

        ReactGA.event({
            category: 'présentation/proposition',
            action: 'import logo',
            value: 1,
        });

        return isJpgOrPng && isLt2M;
    };
    const uploadButton = (
        <>
            <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">
                <FormattedMessage {...messages.logoUploadInfo} />
            </p>
            <p className="ant-upload-hint">
                <FormattedMessage {...messages.logoUploadSmallInfo} />
            </p>
        </>
    );

    return (
        <>
            <Button
                onClick={onClickBack}
                id="presentation-back-button"
            >
                <div id="cancel-icon-wrapper">
                    <IconArrow direction="left" />
                </div>
                <FormattedMessage {...GenericMessages.back} />
            </Button>
            <section>
                <div id="presentation-informations-wrapper" className="container-small">
                    <FormattedMessage
                        tagName="h1"
                        {...(presentation.type === 'presentation' ?
                            messages.informationsPresentationTitle :
                            messages.informationsPropositionTitle
                        )}
                    />
                    <Input
                        onChange={onChangeTitle}
                        placeholder={formatMessage(messages.informationsTitlePlaceholder)}
                        value={title}
                    />
                    <FormattedMessage {...messages.informationsCustomizeFrontPage} tagName="h2" />
                    {!isClientFormVisible && presentation.client && (
                        <>
                            <FormattedMessage {...GenericMessages.client} tagName="label" />
                            <p>
                                {getFullName(presentation.client.firstName, presentation.client.lastName)}
                                {` - ${presentation.client.company}`}
                            </p>
                            <Button
                                onClick={setIsClientFormVisible.bind(null, true)}
                            >
                                <FormattedMessage {...FormMessages.edit} />
                            </Button>
                        </>
                    )}
                    {isClientFormVisible && (
                        <div id="presentation-informations-client-form">
                            <div>
                                <FormattedMessage {...messages.chooseClientLabel} tagName="label" />
                                <Select
                                    filterOption={filterClients}
                                    loading={clientsListState.loading}
                                    notFoundContent={(
                                        <EmptyResult
                                            text={formatMessage(messages.noClientMatchingSector)}
                                        />
                                    )}
                                    onChange={onChangeClient}
                                    placeholder={formatMessage(messages.clientsSelectPlaceholder)}
                                    value={client ? client.id : undefined}
                                    showSearch
                                >
                                    {clientsListState.data ? clientsListState.data.map((c) => (
                                        <Select.Option
                                            value={c.id}
                                            key={c.id}
                                        >
                                            {getFullName(c.firstName, c.lastName)}
                                            {` - ${c.company}`}
                                        </Select.Option>
                                    )) : null}
                                </Select>
                                <FormattedMessage {...GenericMessages.or} tagName="p" />
                                <Divider aria-hidden dashed>
                                    <FormattedMessage {...GenericMessages.or} />
                                </Divider>
                            </div>
                            <div>
                                <FormattedMessage {...messages.newClientLabel} tagName="label" />
                                <Spin spinning={isCreatingClient}>
                                    <Form.Item label={formatMessage(UserMenuMessages.companyLabel)}>
                                        <Input
                                            onChange={onChangeField.bind(null, 'company')}
                                            placeholder={formatMessage(UserMenuMessages.companyPlaceholder)}
                                            value={clientCompany}
                                        />
                                    </Form.Item>
                                    <Form.Item label={formatMessage(UserMenuMessages.lastNameLabel)}>
                                        <Input
                                            onChange={onChangeField.bind(null, 'lastName')}
                                            placeholder={formatMessage(UserMenuMessages.lastNamePlaceholder)}
                                            value={clientLastName}
                                        />
                                    </Form.Item>
                                    <Form.Item label={formatMessage(UserMenuMessages.firstNameLabel)}>
                                        <Input
                                            onChange={onChangeField.bind(null, 'firstName')}
                                            placeholder={formatMessage(UserMenuMessages.firstNamePlaceholder)}
                                            value={clientFirstName}
                                        />
                                    </Form.Item>
                                    <Form.Item label={formatMessage(LoginMessages.emailLabel)}>
                                        <Input
                                            onChange={onChangeField.bind(null, 'email')}
                                            placeholder={formatMessage(LoginMessages.emailPlaceholder)}
                                            value={clientEmail}
                                            type="email"
                                        />
                                    </Form.Item>
                                </Spin>
                            </div>
                        </div>
                    )}
                    <FormattedMessage {...messages.importLogo} tagName="label" />
                    <Upload.Dragger
                        beforeUpload={beforeUpload}
                        customRequest={customUploadRequest}
                        defaultFileList={logo ? [logo] : undefined}
                        listType="picture-card"
                        showUploadList={false}
                    >
                        {logoFile ?
                            (
                                <img
                                    src={logoFile.url}
                                    alt="client logo"
                                    style={{ width: '100%' }}
                                />
                            ) :
                            uploadButton
                        }
                    </Upload.Dragger>
                </div>
            </section>
            <footer>
                <Button
                    disabled={!isSubmitEnabled}
                    loading={isCreatingClient}
                    onClick={onSubmit}
                    size="large"
                    type="primary"
                >
                    <FormattedMessage {...messages.validateInformations} />
                </Button>
            </footer>
        </>
    );
};

const mapStateToProps = (state: MainReducerState, { presentation }: { presentation: PresentationData }) => ({
    clientsListState: getClientsListStateForPresentation(state, presentation),
});

export default connect(
    mapStateToProps,
    {
        createClient: ClientsActions.create,
        createClientSuccess: ClientsActions.createSuccess,
    },
)(PresentationInformations);
