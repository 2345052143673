import React, { useContext } from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { Button, Drawer, List } from 'antd';

import '../../assets/styles/UserMenu.less';

import * as AuthActions from '../../store/actions/auth';
import { getUser } from '../../store/reducers/auth';
import { User, Client, Presentation } from '../../store/api/types';
import { MainReducerState, getOfflineState } from '../../store/reducers';
import { getPresentationsByCustomerReference } from '../../store/reducers/presentations';
import { getClients } from '../../store/reducers/clients';
import { getFavoritesCount } from '../../store/reducers/favorites';

import GenericMessages from '../../locale/Generic.messages';
import { getFullName, t } from '../../utils';
import { IconUser, IconClients, IconPresentation, IconHeart, IconClose } from '../icons';
import ListItem from '../ListItem';
import messages from './UserMenu.messages';
import EditProfileDrawer from './EditProfileDrawer';
import FavoritesDrawer from './FavoritesDrawer';
import ClientsDrawer from './ClientsDrawer';
import PresentationsDrawer from './PresentationsDrawer';
import { UserMenuDrawerContext } from './UserMenuDrawerContext';

interface UserMenuProps {
    clients?: Client[];
    favoritesCount: number;
    isOnline: boolean;
    logout: typeof AuthActions.logout;
    presentations: Presentation[];
    user?: User;
}

const UserMenu: React.FC<UserMenuProps> = ({
    clients, favoritesCount, isOnline, logout, presentations, user,
}) => {
    const { locale } = useIntl();
    const {
        isClientsDrawerVisible, isEditProfileDrawerVisible, isFavoritesDrawerVisible,
        isPresentationsDrawerVisible, isUserDrawerVisible, setIsClientsDrawerVisible,
        setIsEditProfileDrawerVisible, setIsFavoritesDrawerVisible, setIsPresentationsDrawerVisible,
        setIsUserDrawerVisible, closeAll,
    } = useContext(UserMenuDrawerContext);
    const onClickButtonDrawer = () => {
        setIsUserDrawerVisible(!isUserDrawerVisible);
    };

    const onDrawerClose = () => { setIsUserDrawerVisible(false); };
    const onCloseEditProfileDrawer = () => { setIsEditProfileDrawerVisible(false); };
    const onClosePresentationsDrawer = () => { setIsPresentationsDrawerVisible(false); };
    const onCloseFavoritesDrawer = () => {
        setIsFavoritesDrawerVisible(false);
    };
    const onCloseClientsDrawer = () => { setIsClientsDrawerVisible(false); };

    const onClickMyFiles = () => { setIsClientsDrawerVisible(true); };
    const onClickMyPresentations = () => {
        setIsPresentationsDrawerVisible(true);
        ReactGA.event({
            category: 'menu présentations',
            action: 'clic',
            label: 'depuis menu utilisateur',
        });
    };
    const onClickMyFavorites = () => {
        setIsFavoritesDrawerVisible(true);
        ReactGA.event({
            category: 'menu favoris',
            action: 'clic',
            label: 'depuis menu utilisateur',
        });
    };
    const onClickEditProfile = () => { setIsEditProfileDrawerVisible(true); };
    const onClickLogout = () => { logout(); };

    return (
        <>
            <Button id="user-menu-button" onClick={onClickButtonDrawer}>
                <IconUser />
                <span>
                    <span>{user ? getFullName(user.firstName, user.lastName) : ''}</span>
                    <span>{user && user.profile && t(user.profile.name, locale)}</span>
                </span>
            </Button>
            {(
                isClientsDrawerVisible ||
                isEditProfileDrawerVisible ||
                isFavoritesDrawerVisible ||
                isPresentationsDrawerVisible ||
                isUserDrawerVisible
            ) && (
                <>{createPortal((
                    <Button
                        id="drawer-close-all"
                        onClick={closeAll}
                        shape="circle"
                        type="primary"
                    >
                        <IconClose />
                    </Button>
                ), document.body)}</>
            )}
            <PresentationsDrawer
                key="presentation"
                onClose={onClosePresentationsDrawer}
                type="presentation"
                visible={isPresentationsDrawerVisible}
            />
            <Drawer
                title={<FormattedMessage {...messages.drawerTitle} />}
                placement="right"
                onClose={onDrawerClose}
                visible={isUserDrawerVisible}
                width={385}
            >
                <div id="user-menu" className="with-footer">
                    <section>
                        <div id="user-menu-user">
                            <div>
                                <IconUser />
                                <span>
                                    <span>{user && getFullName(user.firstName, user.lastName)}</span>
                                    <span>{user && user.profile && t(user.profile.name, locale)}</span>
                                    <span>{user && user.email}</span>
                                </span>
                            </div>
                            <Button
                                onClick={onClickEditProfile}
                            >
                                <FormattedMessage {...messages.editProfile} />
                            </Button>
                        </div>
                        <List>
                            <List.Item>
                                <ListItem
                                    icon={<IconClients />}
                                    onClick={onClickMyFiles}
                                    subTitle={(
                                        <FormattedMessage
                                            {...messages.filesSub}
                                            values={{ count: clients ? clients.length : 0 }}
                                        />
                                    )}
                                    title={<FormattedMessage {...messages.files} />}
                                />
                            </List.Item>
                            <List.Item>
                                <ListItem
                                    disabled={!isOnline}
                                    icon={<IconPresentation />}
                                    onClick={onClickMyPresentations}
                                    subTitle={(
                                        <FormattedMessage
                                            {...messages.presentationsSub}
                                            values={{ count: presentations.length }}
                                        />
                                    )}
                                    title={<FormattedMessage {...messages.presentations} />}
                                />
                            </List.Item>
                            <List.Item>
                                <ListItem
                                    icon={<IconHeart />}
                                    onClick={onClickMyFavorites}
                                    subTitle={(
                                        <FormattedMessage
                                            {...messages.favoritesSub}
                                            values={{ count: favoritesCount }}
                                        />
                                    )}
                                    title={<FormattedMessage {...messages.favorites} />}
                                />
                            </List.Item>
                        </List>
                    </section>
                    <footer>
                        <Button
                            type="danger"
                            onClick={onClickLogout}
                            size="large"
                            block
                        >
                            <FormattedMessage {...GenericMessages.logout} />
                        </Button>
                    </footer>
                </div>
            </Drawer>
            <EditProfileDrawer
                onClose={onCloseEditProfileDrawer}
                visible={isEditProfileDrawerVisible}
                zIndex={1400}
            />
            <FavoritesDrawer
                onClose={onCloseFavoritesDrawer}
                visible={isFavoritesDrawerVisible}
                zIndex={1400}
            />
            <ClientsDrawer
                onClose={onCloseClientsDrawer}
                visible={isClientsDrawerVisible}
                zIndex={1400}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    clients: getClients(state),
    favoritesCount: getFavoritesCount(state),
    isOnline: getOfflineState(state).online,
    presentations: getPresentationsByCustomerReference(state, 'presentation'),
    user: getUser(state),
});

export default connect(
    mapStateToProps,
    { logout: AuthActions.logout },
)(UserMenu);
