import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';
import { Button, Input } from 'antd';
import { InputProps } from 'antd/lib/input';

import '../assets/styles/Search.less';

import { IconSearch, IconClose } from './icons';
import { useIntl, FormattedMessage } from 'react-intl';
import messages from './Search.messages';
import { indices } from '../store/configureStore';
import { Product } from '../store/api/types';
import { getRoute, RoutePathName } from '../routes';
import { t } from '../utils';

const Search: React.FC = () => {
    const { formatMessage, locale } = useIntl();
    const history = useHistory();
    const [query, setQuery] = useState('');
    const [isOpen, setIsOpen] = useState(false);
    const [results, setResults] = useState<any>([]);
    const onToggleSearch = () => {
        setIsOpen(!isOpen);
    };
    const onSearch: InputProps['onChange'] = (e) => {
        setQuery(e.target.value);
    };
    const onClickOverlay = () => {
        setIsOpen(false);
    };
    const onLinkClick = () => {
        setIsOpen(false);
        setQuery('');
    };
    const onPressEnter = () => {
        if (results.length) {
            setIsOpen(false);
            history.push(getRoute(RoutePathName.productSearch, { query }));
        }
        ReactGA.event({
            category: 'recherche',
            action: 'envoi',
            value: 1,
        });
    };

    useEffect(() => {
        if (indices.productsIndex) {
            setResults(indices.productsIndex.search(query, { limit: 4 }));
        }
    }, [query]);

    return (
        <>
            <Button
                className={isOpen ? 'is-open' : undefined}
                id="search-button"
                onClick={onToggleSearch}
                type={isOpen ? 'primary' : undefined}
            >
                {isOpen ? <IconClose /> : <IconSearch />}
            </Button>
            {isOpen && (
                <div id="search-wrapper">
                    <div id="search-inner-wrapper">
                        <Input
                            placeholder={formatMessage(messages.inputPlaceholder)}
                            onChange={onSearch}
                            onPressEnter={onPressEnter}
                            value={query}
                            autoFocus
                            allowClear
                        />
                        {!!results.length && (
                            <ul className="list-unstyled">
                                {results.map((result: Product) => (
                                    <li key={result.id}>
                                        <Link
                                            onClick={onLinkClick}
                                            to={getRoute(RoutePathName.product, {
                                                id: result.id,
                                            })}
                                        >
                                            {t(result.label, locale)}
                                        </Link>
                                    </li>
                                ))}
                                <li id="search-see-more">
                                    <Link
                                        onClick={onLinkClick}
                                        to={getRoute(RoutePathName.productSearch, { query })}
                                    >
                                        <FormattedMessage
                                            {...messages.seeMoreForQuery}
                                            values={{
                                                b: (...m: string[]) => <strong>{m}</strong>,
                                                query,
                                            }}
                                        />
                                    </Link>
                                </li>
                            </ul>
                        )}
                        {!!query && !results.length && (
                            <p id="no-result">
                                <FormattedMessage {...messages.noResult} />
                            </p>
                        )}
                    </div>
                    <div id="search-overlay" onClick={onClickOverlay} />
                </div>
            )}
        </>
    );
};

export default Search;
