import React, { FC } from 'react';
import { SortableContainer, SortableElement, SortableContainerProps } from 'react-sortable-hoc';

import { ChapterCard } from '.';
import { SubChapter } from '../../store/api/types';

interface ItemProps {
    itemIndex: number;
    item: SubChapter;
    onClickDeleteItem: (itemIndex: number) => void;
}

const Item: FC<ItemProps> = ({ itemIndex, item, onClickDeleteItem }) => (
    <li>
        <ChapterCard
            chapter={item}
            onClickRemove={onClickDeleteItem.bind(null, itemIndex)}
            selectable={false}
            removable
            sortable
        />
        <p className="presentation-card-index">{itemIndex + 1}</p>
    </li>
);

const SortableItem = SortableElement(Item);

interface PresentationSubChaptersSortableListProps extends SortableContainerProps {
    items: SubChapter[];
    onClickDeleteItem: (itemIndex: number) => void;
}

const PresentationSubChaptersSortableList: FC<PresentationSubChaptersSortableListProps> = ({
    items, onClickDeleteItem,
}) => (
    <ul id="presentation-customize-list" className="presentation-sub-chapters-sortable">
        {items.map((item, index) => (
            <SortableItem
                index={index}
                item={item}
                itemIndex={index}
                key={item.id}
                onClickDeleteItem={onClickDeleteItem}
            />
        ))}
    </ul>
);

export default SortableContainer(PresentationSubChaptersSortableList);
