import React, { useCallback, useState, useEffect, useContext } from 'react';
import { RouteChildrenProps, useParams, useLocation } from 'react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactGA from 'react-ga';
import { Row, Col, Skeleton, Button } from 'antd';

import '../../assets/styles/Product.less';

import * as FavoritesActions from '../../store/actions/favorites';
import * as SelectionsActions from '../../store/actions/selections';
import { getProductById, isProductBestSeller } from '../../store/reducers/products';
import { getSectorById } from '../../store/reducers/sectors';
import { MainReducerState } from '../../store/reducers';
import { Sector, Product, Name } from '../../store/api/types';
import { isProductFavorite } from '../../store/reducers/favorites';
import { isProductInSelection } from '../../store/reducers/selections';
import { FiltersState } from '../../store/reducers/filters';

import { getRoute, RoutePathName } from '../../routes';
import BreadCrumb from '../../components/BreadCrumb';
import {
    IconHeroInvertedTriangle, IconArrow, IconBestSeller, IconAngle, IconPlaceholder,
} from '../../components/icons';
import ProductImageCarousel from '../../components/ProductImageCarousel';
import ProductsMessages from '../products/Products.messages';
import messages from './Product.messages';
import {
    AssociatedProducts, ProductColors, AssociatedProductGroups, CrossSelling,
} from './';
import { sectorIconsMap } from '../products';
import Img from '../../components/Img';
import { SectorColorContext } from '../../components/SectorColorContextProvider';
import { t } from '../../utils';

interface MatchParams {
    sectorId: string;
    solutionId: string;
    subSectorId: string;
    id: string;
}

interface ProductPageProps extends RouteChildrenProps<MatchParams> {
    addToSelection: typeof SelectionsActions.addProduct;
    addToFavorites: typeof FavoritesActions.create;
    isBestSeller: boolean;
    isFavorite: boolean;
    isInSelection: boolean;
    product?: Product;
    removeFromFavorites: typeof FavoritesActions.del;
    removeFromSelection: typeof SelectionsActions.removeProduct;
    sector?: Sector;
    subSector?: Sector;
    solution?: Sector;
}

const ProductPage: React.FC<ProductPageProps> = ({
    addToFavorites, addToSelection, isBestSeller, isFavorite, isInSelection, product,
    removeFromFavorites, removeFromSelection, sector, solution, subSector,
}) => {
    const { locale } = useIntl();
    const location = useLocation();
    const { id, sectorId, solutionId, subSectorId } = useParams();
    const { color, setColor } = useContext(SectorColorContext);
    const isFromSolution = sectorId !== undefined && solutionId !== undefined && subSectorId !== undefined;
    const [filters, setFilters] = useState<FiltersState>();
    const [argumentsOrder, setArgumentsOrder] = useState<number[]>([]);
    const [sortedArguments, setSortedArguments] = useState<any[]>([]);
    const routes = [];
    const onClickFavorite = useCallback((e) => {
        e.preventDefault();
        e.stopPropagation();
        if (isFavorite) {
            removeFromFavorites({ productId: product!.id });
        } else {
            addToFavorites({ productId: product!.id });
            ReactGA.event({
                category: 'Favoris',
                action: 'ajout',
                label: `${product!.id}`,
            });
        }
    }, [addToFavorites, isFavorite, removeFromFavorites, product]);
    const onAddToSelection = useCallback(() => {
        if (isInSelection) {
            removeFromSelection(product);
        } else {
            addToSelection(product, sortedArguments.map((text) => text.id));

            if (product) {
                ReactGA.event({
                    category: 'sélection produit',
                    action: 'ajout',
                    label: `${product.id}`,
                });
            }
        }
    }, [addToSelection, isInSelection, product, removeFromSelection, sortedArguments]);

    // set filters from url query params when the component mounts
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const filtersParam = queryParams.get('filters');

        setFilters(filtersParam ? JSON.parse(filtersParam) as FiltersState : {});
    }, [location]);

    // set arguments order from filters
    useEffect(() => {
        setArgumentsOrder(
            filters && filters.arguments ?
                filters.arguments.sort((a, b) => b.rating - a.rating).map((i) => i.id) :
                [],
        );
    }, [filters]);

    // sort product arguments based on arguments order
    useEffect(() => {
        const args = product ?
            product.arguments.filter((arg) => !!arg.texts.length) :
            [];

        setSortedArguments(
            argumentsOrder.length ?
                [...args].sort((a, b) => {
                    if (argumentsOrder.indexOf(a.argument.id) === -1 && argumentsOrder.indexOf(b.argument.id) === -1) {
                        return 0;
                    } else if (
                        argumentsOrder.indexOf(a.argument.id) !== -1 &&
                        argumentsOrder.indexOf(b.argument.id) === -1
                    ) {
                        return -1;
                    } else if (
                        argumentsOrder.indexOf(a.argument.id) === -1 &&
                        argumentsOrder.indexOf(b.argument.id) !== -1
                    ) {
                        return 1;
                    } else {
                        return argumentsOrder.indexOf(a.argument.id) - argumentsOrder.indexOf(b.argument.id);
                    }
                })
                    .reduce<{ id: number; text: Name }[]>((acc, arg) => acc.concat(arg.texts), []) :
                args.reduce<{ id: number; text: Name }[]>((acc, arg) => acc.concat(arg.texts), []),
        );
    }, [argumentsOrder, product]);

    // set color based on solution color (eg for pest control)
    useEffect(() => {
        if (solution) {
            setColor(solution.color);
        }
    }, [setColor, solution]);

    if (sectorId !== undefined && solutionId !== undefined && subSectorId !== undefined && id !== undefined) {
        routes.push(
            {
                link: getRoute(RoutePathName.sector, { id: sectorId }),
                text: sector && t(sector.name, locale) ? t(sector.name, locale) : '',
            },
            {
                link: getRoute(RoutePathName.subSector, {
                    id: subSectorId,
                    sectorId,
                }),
                text: subSector && t(subSector.name, locale) ? t(subSector.name, locale) : '',
            },
            {
                link: getRoute(RoutePathName.products, {
                    sectorId,
                    subSectorId,
                    solutionId,
                    assortmentId: undefined,
                }),
                text: solution && t(solution.name, locale) ? t(solution.name, locale) : '',
            },
            {
                link: getRoute(RoutePathName.productFromSolution, {
                    sectorId,
                    subSectorId,
                    solutionId,
                    id,
                }),
                text: product && t(product.label, locale) ? t(product.label, locale) : '',
            },
        );
    } else if (id !== undefined) {
        routes.push({
            link: getRoute(RoutePathName.product, { id }),
            text: product && t(product.label, locale) ? t(product.label, locale) : '',
        });
    }

    const Icon = solution && solution.icon ?
        sectorIconsMap[solution.icon] :
        IconPlaceholder;

    return (
        <div id="product">
            <BreadCrumb routes={routes} />
            <header className="page-hero product-hero" style={color ? { backgroundColor: color } : undefined}>
                {sector && sector.image && <Img imageId={sector.image.id} alt={t(sector.name, locale)} />}
                <Icon className="product-icon" />
                <div className="container">
                    <Row>
                        <Col xl={15} lg={18} md={16} className="page-hero-title-col">
                            <h2>{subSector ? t(subSector.name, locale) : ''}</h2>
                            <h1>{solution ? t(solution.name, locale) : ''}</h1>
                        </Col>
                    </Row>
                    <div id="bottom-triangle">
                        <IconHeroInvertedTriangle />
                    </div>
                </div>
            </header>
            <section className="container m-b-56">
                <Row gutter={56}>
                    <Col lg={13} xs={12}>
                        <Link
                            to={
                                !!sectorId && !!subSectorId && !!solutionId ?
                                    getRoute(RoutePathName.products, {
                                        sectorId,
                                        subSectorId,
                                        solutionId,
                                        assortmentId: undefined,
                                    }, filters && Object.keys(filters).length ?
                                        { filters: JSON.stringify(filters) } :
                                        undefined) :
                                    getRoute(RoutePathName.home)
                            }
                            className="arrow-link"
                        >
                            <IconArrow direction="left" />
                            <FormattedMessage
                                {...messages.back}
                                values={{
                                    b: (...m: string[]) => isFromSolution ? <>{'\u2006'}<span>{m}</span></> : <span />,
                                }}
                            />
                        </Link>
                        <ProductImageCarousel product={product} />
                    </Col>
                    <Col lg={11} xs={12} id="product-infos">
                        <Skeleton loading={!product}>
                            <Row type="flex" justify="space-between" style={{ flexWrap: 'nowrap' }} className="m-b-32">
                                <Col>
                                    <h1 style={color ? { color } : undefined}>
                                        {product && t(product.label, locale)}
                                    </h1>
                                    {product && product.reference && (
                                        <FormattedMessage {...messages.ean} values={{ ean: product.reference }} tagName="p" />
                                    )}
                                </Col>
                                {isBestSeller && (
                                    <Col>
                                        <div id="product-best-seller">
                                            <IconBestSeller />
                                            <span><span>BEST</span><br />SELLER</span>
                                        </div>
                                    </Col>
                                )}
                            </Row>
                            <ProductColors
                                filters={filters}
                                product={product}
                            />
                            {!!sortedArguments.length && (
                                <ul
                                    id="product-arguments"
                                    className="list-unstyled"
                                    style={color ? { borderColor: color } : undefined}
                                >
                                    {sortedArguments.map((arg) => (
                                        <li key={arg.id}>
                                            <IconAngle
                                                direction="right"
                                                style={color ? { color } : undefined}
                                            />
                                            {t(arg.text, locale)}
                                        </li>
                                    ))}
                                </ul>
                            )}
                            <div
                                id="product-actions"
                                style={{ '--sector-color': color || '#00a5aa' } as React.CSSProperties}
                            >
                                <Button
                                    onClick={onAddToSelection}
                                    type="primary"
                                >
                                    {isInSelection ?
                                        <FormattedMessage {...ProductsMessages.removeFromSelection} /> :
                                        <FormattedMessage {...ProductsMessages.addToSelection} />
                                    }
                                </Button>
                                <Button
                                    onClick={onClickFavorite}
                                    type={isFavorite ? 'primary' : 'default'}
                                >
                                    {isFavorite ?
                                        <FormattedMessage {...messages.removeFromFavorites} /> :
                                        <FormattedMessage {...messages.addToFavorites} />
                                    }
                                </Button>
                            </div>
                        </Skeleton>
                    </Col>
                </Row>
            </section>
            <AssociatedProducts
                product={product}
                sectorId={sectorId}
                subSectorId={subSectorId}
                solutionId={solutionId}
            />
            <AssociatedProductGroups
                product={product}
                sectorId={sectorId}
                subSectorId={subSectorId}
                solutionId={solutionId}
            />
            <CrossSelling
                productId={parseInt(id || '-1', 10)}
                sectorId={sectorId}
                subSectorId={subSectorId}
                solutionId={solutionId}
            />
        </div>
    );
};

const mapStateToProps = (state: MainReducerState, { match }: ProductPageProps) => {
    const productId = match && match.params.id ? parseInt(match.params.id, 10) : -1;
    const product = getProductById(state, true, productId);
    const sectorId = match && match.params.sectorId ? parseInt(match.params.sectorId, 10) : -1;
    const subSectorId = match && match.params.subSectorId ? parseInt(match.params.subSectorId, 10) : -1;
    const solutionId = match && match.params.solutionId ?
        parseInt(match.params.solutionId, 10) :
        (
            product ? product.sectorId : -1
        );

    return {
        isBestSeller: isProductBestSeller(state, productId, [
            sectorId, subSectorId, solutionId,
        ]),
        isFavorite: isProductFavorite(state, productId),
        isInSelection: isProductInSelection(state, productId),
        product,
        sector: getSectorById(state, match && match.params.sectorId ? parseInt(match.params.sectorId, 10) : -1),
        subSector: getSectorById(
            state, match && match.params.subSectorId ?
                parseInt(match.params.subSectorId, 10) :
                -1,
        ),
        solution: getSectorById(state, solutionId),
    };
};

export default connect(
    mapStateToProps,
    {
        addToFavorites: FavoritesActions.create,
        addToSelection: SelectionsActions.addProduct,
        removeFromFavorites: FavoritesActions.del,
        removeFromSelection: SelectionsActions.removeProduct,
    },
)(ProductPage);
