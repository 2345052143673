export const LIST = 'clients/LIST';
export const LIST_SUCCESS = 'clients/LIST_SUCCESS';
export const LIST_FAILED = 'clients/LIST_FAILED';

export const CREATE = 'clients/CREATE';
export const CREATE_SUCCESS = 'clients/CREATE_SUCCESS';
export const CREATE_FAILED = 'clients/CREATE_FAILED';
export const CREATE_ROLLBACK = 'clients/CREATE_ROLLBACK';

export const UPDATE = 'clients/UPDATE';
export const UPDATE_SUCCESS = 'clients/UPDATE_SUCCESS';
export const UPDATE_FAILED = 'clients/UPDATE_FAILED';
export const UPDATE_ROLLBACK = 'clients/UPDATE_ROLLBACK';
