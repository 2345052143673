import { combineReducers } from 'redux';
import { PersistPartial } from 'redux-persist';
import { OfflineState, AppState } from '@redux-offline/redux-offline/lib/types';

import allData from './allData';
import auth, { AuthState } from './auth';
import clients, { ClientsState } from './clients';
import countries, { CountriesState } from './countries';
import faq, { FAQState } from './faq';
import favorites, { FavoritesState } from './favorites';
import filters, { FiltersState } from './filters';
import images from './images';
import nace, { NaceState } from './nace';
import news, { NewsState } from './news';
import presentations, { PresentationsState } from './presentations';
import products, { ProductsState } from './products';
import profiles, { ProfilesState } from './profiles';
import sectors, { SectorsState } from './sectors';
import selections, { SelectionsState } from './selections';
import themes, { ThemesState } from './themes';
import terms, { TermsState } from './terms';

export interface RequestState<T = any> {
    readonly data?: T;
    readonly error?: any;
    readonly loading: boolean;
    readonly response?: any;
    readonly success?: boolean;
}

export interface DataStateHash<T> {
    readonly [id: number]: T;
}

export interface ListState<T> extends RequestState<T> {
    readonly data: T;
}

export interface PaginationState {
    readonly count: number;
    readonly filteredCount: number;
    readonly ids?: string[];
    readonly page: number;
    readonly pageSize: number;
    readonly totalCount: number;
    readonly fromDate?: string;
    readonly toDate?: string;
    readonly sort?: string;
    readonly sortOrder?: string;
}

export type PaginatedListState<T> = ListState<T> & PaginationState;

export interface DetailsStateById<T> {
    readonly error?: RequestState<T>['error'];
    readonly data?: T;
    readonly loading: RequestState<T>['loading'];
}

export interface DetailsState<T> {
    readonly [id: string]: DetailsStateById<T>;
}

export interface MainReducerState extends Partial<PersistPartial>, Partial<AppState> {
    readonly allData: any;
    readonly auth: AuthState;
    readonly clients: ClientsState;
    readonly countries: CountriesState;
    readonly faq: FAQState;
    readonly favorites: FavoritesState;
    readonly filters: FiltersState;
    readonly images: any;
    readonly nace: NaceState;
    readonly news: NewsState;
    readonly offline?: OfflineState;
    readonly presentations: PresentationsState;
    readonly products: ProductsState;
    readonly profiles: ProfilesState;
    readonly sectors: SectorsState;
    readonly selections: SelectionsState;
    readonly themes: ThemesState;
    readonly terms: TermsState;
}

const mainReducer = combineReducers<MainReducerState>({
    allData,
    auth,
    clients,
    countries,
    faq,
    favorites,
    filters,
    images,
    nace,
    news,
    presentations,
    products,
    profiles,
    sectors,
    selections,
    themes,
    terms,
});

export default mainReducer;

export const getOfflineState = (state: MainReducerState) => state.offline!;
