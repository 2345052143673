import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import GenericMessages from '../locale/Generic.messages';
import { RoutePathName, getRoute } from '../routes';
import { IconHome } from './icons';
import { SectorColorContext } from './SectorColorContextProvider';

interface BreadCrumbProps {
    routes: {
        link: string;
        text: string | React.ReactNode;
    }[];
}

const BreadCrumb: React.FC<BreadCrumbProps> = ({ routes }) => {
    const { color } = useContext(SectorColorContext);

    return (
        <div id="breadcrumb">
            <Link to={getRoute(RoutePathName.home)}>
                <IconHome style={color ? { color } : undefined} />
                <FormattedMessage {...GenericMessages.home} />
            </Link>
            <span className="separator">/</span>
            {routes.map((route, index) => (
                <React.Fragment key={index}>
                    <Link to={route.link}>
                        {route.text}
                    </Link>
                    {index !== routes.length - 1 && (
                        <span className="separator">/</span>
                    )}
                </React.Fragment>
            ))}
        </div>
    );
};

export default BreadCrumb;
