interface WithId {
    id: number;
}
interface WithRollback<T> {
    rollback?: T;
}

export interface Name extends WithId {
    [key: string]: any;
}

export interface Country extends WithId {
    createAt: string;
    updateAt?: string;
    name: Name;
}

export enum Role {
    SuperAdmin = 'superAdmin',
    Admin = 'admin',
    User = 'user',
}

export interface Market extends WithId {
    createAt: string;
    updateAt?: string;
    source?: string;
    color?: string;
    name: string;
}

export interface User extends WithId {
    firstName: string;
    lastName: string;
    profile?: Profile;
    profileId: Profile['id'];
    email: string;
    createAt: string;
    countryId: Country['id'];
    role: Role;
    marketId: Market['id'];
}

export interface Argument extends WithId {
    name: Name;
    maxRating: number;
    source: string;
}

export interface Profile extends WithId {
    name: Name;
}

export enum PropertyType {
    color = 'color',
    gamme = 'gamme',
    service = 'service',
}

export interface PropertyValue extends WithId {
    name: Name;
    color: string | null;
    productCount?: number;
    image?: File;
}

export interface Property extends WithId {
    color: string | null;
    group: {
        id: number;
        name: Name;
    } | null;
    name: Name;
    type: PropertyType | null;
    values?: PropertyValue[];
    valuesById?: {
        [key: string]: PropertyValue;
    };
    order: number;
}

export enum SectorType {
    Market = 'market',
    Sector = 'sector',
    SubSector = 'subSector',
    Solution = 'solution',
    Gamme = 'gamme',
    Service = 'service',
    SubGamme = 'subGamme',
    SubService = 'subService',
}

export interface Sector extends WithId {
    arguments: Argument[];
    color: string | null;
    countries?: Country[];
    excludedSectors: Sector['id'][];
    name: Name;
    order: number | null;
    icon: string | null;
    image: File | null;
    sectorInformation: File | null;
    sectorInformationWithoutLogos: File | null;
    properties: Property[];
    reference: string;
    salesAcademy: File | null;
    sectorParents: Sector['id'][];
    skipSubLevel: boolean;
    type: SectorType;
}

export enum FileSubType {
    Presentation = 'presentation',
    Selection = 'selection',
    Logo = 'logo',
    Pricing = 'pricing',
    Other = 'other',
    SectorInformation = 'sectorInformation',
    SectorInformationWithoutLogos = 'sectorInformationWithoutLogos',
    SalesAcademy = 'salesAcademy',
    Chapter = 'chapter',
}

export interface File extends WithId {
    createAt: string;
    expirationDate: string | null;
    filename: string;
    subType: FileSubType;
    url: string;
    type: string;
    language: string | null;
    orientation: string | null;
    source: string | null;
    productId: Product['id'] | null;
    selectionId: Selection['id'] | null;
    chapterFileId: File['id'] | null;
    sectorId: Sector['id'] | null;
    fileId: File['id'] | null;
    presentationId: Presentation['id'] | null;
    thumbnail: Pick<File, 'id' | 'filename' | 'subType' | 'language' | 'orientation' | 'url'> | null;
}

export interface ProductPropertyValue extends PropertyValue {
    property: Property;
}

export interface ProductGroup extends WithId {
    name: Name;
}

export interface Product extends WithId {
    arguments: {
        argument: Argument;
        id: number;
        rating: number;
        texts: {
            id: number;
            text: Name;
        }[];
    }[];
    associatedProductGroups: {
        id: number;
        associatedProductGroup: ProductGroup;
        associatedProducts: Product['id'][];
    }[];
    associatedProductsFromGroupsPopulated?: {
        [id: string]: Product;
    };
    associatedProducts: Product['id'][];
    associatedProductsPopulated?: Product[];
    bestSellers: Sector['id'][];
    crossSelling?: {
        id: number;
        sectorId: Sector['id'];
        products: Product['id'][];
    }[];
    images?: File[];
    isNew?: boolean;
    label: Name;
    properties: ProductPropertyValue[];
    reference: string;
    sectorId: Sector['id'];
}

export interface News extends WithId {
    createAt: string;
    link: string | null;
    market: Sector | null;
    text: Name;
    title: Name;
    updateAt: string | null;
}

export interface Assortment extends WithId {
    title: string;
    image: File;
    solutionId: number;
}

export interface Client extends WithId, WithRollback<Client> {
    reference: string;
    createAt: string;
    firstName: string;
    lastName: string;
    company: string;
    email: string;
    sectorId: Sector['id'];
    sector?: Sector | null;
    source?: string;
    profileId: Profile['id'] | null;
    profile?: Profile | null;
}

export interface Favorites {
    [key: string]: Sector['id'][] | Product['id'][];
    favoritesSectors: Sector['id'][];
    favoritesProducts: Product['id'][];
}

export interface Selection extends WithId, WithRollback<Selection> {
    createAt?: string;
    customer: Client | null;
    customerId: Client['id'] | null;
    isValidated: boolean;
    pdf?: File;
    products: Product[];
    updateAt: string;
    userId: User['id'];
}

export interface Nace {
    naf: {
        id: string;
        description: string;
    };
    nace: {
        id: number;
        description: string;
    };
    market: string;
    sector: string;
    subSector: string;
}

export interface SubChapter extends WithId {
    name: Name;
    chapterFiles: ChapterFile[];
    order: number;
}

export interface ChapterFile extends WithId {
    sectorId: Sector['id'] | null;
    language: string;
    type: PresentationType;
    pdf: Pick<File, 'id' | 'filename' | 'subType' | 'language' | 'orientation' | 'thumbnail' | 'url'>;
}
export interface Chapter extends WithId {
    name: Name;
    subChapters: SubChapter[];
    chapterFiles: ChapterFile[];
    order: number;
}

export interface Theme extends WithId {
    name: Name;
    chapters: Chapter[];
    order: number;
}

export type PresentationType = 'presentation' | 'proposition';

export interface Presentation extends WithId {
    createAt: string;
    language: string;
    orientation: 'portrait' | 'landscape';
    customerId: Client['id'];
    type: PresentationType;
    title: string;
    preamble: string;
    logo: File | null;
    customer: Client;
    pdf: File | null;
}

export interface PresentationDetails extends WithId {
    createAt: string;
    updateAt: string | null;
    language: string;
    orientation: 'portrait' | 'landscape';
    type: PresentationType;
    userId: User['id'];
    customerId: Client['id'];
    title: string;
    preamble: string | null;
    logoId: File['id'] | null;
    sectorId: Sector['id'] | null;
    sector: Sector | null;
    logo: Pick<File, 'id' | 'filename' | 'subType' | 'language' | 'orientation' | 'url'> | null;
    customer: Client;
    pdf: Pick<File, 'id' | 'filename' | 'subType' | 'language' | 'orientation' | 'url'> | null;
    fileOrders: {
        order: number;
        file: Pick<File, 'id' | 'filename' | 'subType' | 'language' | 'orientation' | 'url'> & {
            selection?: Selection;
            chapterFile: Pick<ChapterFile, 'id' | 'language' | 'sectorId' | 'type'> & {
                chapter: Pick<Chapter, 'id' | 'name'> & {
                    parent: Pick<Chapter, 'id' | 'name'> | null;
                };
            };
        };
    }[];
}

export interface FAQ extends WithId {
    createAt: string;
    updateAt: string | null;
    order: number;
    title: Name;
    text: Name;
}

export type FAQConfig = [
    {
        name: string;
        value: {
            videoUrl: string;
        };
    }
];
