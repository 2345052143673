import { call, takeLatest, put } from 'redux-saga/effects';
import * as themesActions from '../actions/themes';
import * as reduxTypes from '../types/themes';
import * as themesApi from '../api/themes';
import { DataAction } from '../actions';
import { Theme } from '../api/types';

export function* listSaga(action: DataAction) {
    try {
        let themes = yield call(themesApi.list, action.data);
        let index = 0;

        if (themes.results && Array.isArray(themes.results)) {
            themes = {
                ...themes,
                results: themes.results.map((theme: Theme) => ({
                    ...theme,
                    order: index++,
                    chapters: theme.chapters.map((chapter) => ({
                        ...chapter,
                        order: index++,
                        subChapters: chapter.subChapters.map((subChapter) => ({
                            ...subChapter,
                            order: index++,
                        })),
                    })),
                })),
            };
        }

        return yield put(themesActions.listSuccess(themes));
    } catch (error) {
        return yield put(themesActions.listFailed(error));
    }
}

export default function* themesSaga() {
    yield takeLatest(reduxTypes.LIST, listSaga);
}
