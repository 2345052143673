export const LIST = 'favorites/LIST';
export const LIST_SUCCESS = 'favorites/LIST_SUCCESS';
export const LIST_FAILED = 'favorites/LIST_FAILED';

export const CREATE = 'favorites/CREATE';
export const CREATE_SUCCESS = 'favorites/CREATE_SUCCESS';
export const CREATE_FAILED = 'favorites/CREATE_FAILED';
export const CREATE_ROLLBACK = 'favorites/CREATE_ROLLBACK';

export const DELETE = 'favorites/DELETE';
export const DELETE_SUCCESS = 'favorites/DELETE_SUCCESS';
export const DELETE_FAILED = 'favorites/DELETE_FAILED';
export const DELETE_ROLLBACK = 'favorites/DELETE_ROLLBACK';
