import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation } from 'react-router';
import { useScrollYPosition } from 'react-use-scroll-position';
import { OfflineState } from '@redux-offline/redux-offline/lib/types';
import { Layout, Spin, Alert } from 'antd';

import { MainReducerState, getOfflineState } from '../store/reducers';
import * as AllDataActions from '../store/actions/allData';
import { getAllDataState, AllDataState } from '../store/reducers/allData';

import GenericMessages from '../locale/Generic.messages';
import { UserMenuDrawerContextProvider } from './userMenu/UserMenuDrawerContext';
import { SelectionBarDrawerContextProvider } from './selectionBar/SelectionBarDrawerContext';
import SelectionBar from './selectionBar/SelectionBar';
import Presentation from './presentation/Presentation';
import { SectorColorContextProvider } from './SectorColorContextProvider';
import PresentationContextProvider from './PresentationContextProvider';
import Header from './Header';
import Content from './Content';
import Footer from './Footer';

interface MainLayoutProps {
    allDataState: AllDataState;
    checkData: typeof AllDataActions.checkData;
    offline?: OfflineState;
}

const MainLayout: React.FC<MainLayoutProps> = ({
    allDataState, checkData, children, offline, ...rest
}) => {
    const { locale } = useIntl();
    const { pathname } = useLocation();
    const scrollY = useScrollYPosition();
    const { hasCheckedData, hasEnoughData, loading } = allDataState;

    useEffect(() => {
        checkData(locale);
    }, [checkData, locale]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <UserMenuDrawerContextProvider>
            <SectorColorContextProvider>
                <PresentationContextProvider>
                    <Layout id="main-layout" {...rest} className={scrollY > 0 ? 'is-scrolled' : ''}>
                        <Layout>
                            <Header />
                            <SelectionBarDrawerContextProvider>
                                <SelectionBar />
                            </SelectionBarDrawerContextProvider>
                            <Content>
                                {children}
                            </Content>
                            <Footer />
                            <div id="data-sync-wrapper" className={!loading && hasCheckedData ? 'hidden' : undefined}>
                                <div id="data-sync-overlay" />
                                <div id="data-sync-body">
                                    {loading && (
                                        <>
                                            <Spin />
                                            <FormattedMessage {...GenericMessages.checkingData} />
                                        </>
                                    )}
                                    {!loading && !hasEnoughData && (
                                        <Alert
                                            message={<FormattedMessage {...GenericMessages.notEnoughData} />}
                                            type="warning"
                                        />
                                    )}
                                </div>
                            </div>
                            <Presentation />
                        </Layout>
                    </Layout>
                </PresentationContextProvider>
            </SectorColorContextProvider>
        </UserMenuDrawerContextProvider>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    allDataState: getAllDataState(state),
    offline: getOfflineState(state),
});

export default connect(
    mapStateToProps,
    {
        checkData: AllDataActions.checkData,
    },
)(MainLayout);
