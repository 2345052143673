import { defineMessages } from 'react-intl';

export default defineMessages({
    description: {
        id: 'register.description',
        defaultMessage: 'Register <span>or <a>login to your account</a></span>',
        description: 'Register page description above the register form',
    },
    marketLabel: {
        id: 'register.market.label',
        defaultMessage: 'Market',
        description: 'Form label',
    },
    profileLabel: {
        id: 'register.profile.label',
        defaultMessage: 'Profile',
        description: 'Form label',
    },
    countryLabel: {
        id: 'register.country.label',
        defaultMessage: 'Country',
        description: 'Form label',
    },
    registerButton: {
        id: 'register.button',
        defaultMessage: 'Validate account',
        description: 'Register page form submit button',
    },
    successTitle: {
        id: 'register.success.title',
        defaultMessage: 'Thank you.',
        description: 'Register page success title',
    },
    successDescription: {
        id: 'register.success.description',
        defaultMessage: 'Your account has been created.<break>|</break> You will shortly receive an email containing your password. <break>|</break><span>Nb: You will be able to change it on your first login.</span>',
        description: 'Register page success description',
    },
    emailAlreadyExists: {
        id: 'register.error.email_already_exists',
        defaultMessage: 'The email you chose is already in use.',
        description: 'Register error message when the email already exists',
    },
});
