import * as React from 'react';
import { Layout } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import GenericMessages from '../locale/Generic.messages';
import { RoutePathName, getRoute } from '../routes';

const Footer: React.FC = ({ ...rest }) => (
    <Layout.Footer id="content-footer" {...rest}>
        <FormattedMessage {...GenericMessages.copyright} values={{date: (new Date()).getFullYear()}} />
        <Link to={getRoute(RoutePathName.terms)}>
            <FormattedMessage {...GenericMessages.terms} />
        </Link>
        <Link to={getRoute(RoutePathName.howItWorks)}>
            <FormattedMessage {...GenericMessages.howItWorks} />
        </Link>
    </Layout.Footer>
);

export default Footer;
