import React, { Component } from 'react';
import { Typography, Card } from 'antd';
import { FormattedMessage } from 'react-intl';

import genericMessages from '../locale/Generic.messages';

interface ErrorBoundaryState {
    error: any;
    errorInfo: any;
}

class ErrorBoundary extends Component<{}, ErrorBoundaryState> {

    public state: ErrorBoundaryState = {
        error: null,
        errorInfo: null,
    };

    public componentDidCatch(error: any, errorInfo: any) {
        this.setState({ error, errorInfo });
    }

    public render() {
        if (this.state.error) {
            return (
                <div className="container">
                    <Typography.Title level={1}>
                        <FormattedMessage {...genericMessages.error} />
                    </Typography.Title>
                    <Card>
                        <details style={{ whiteSpace: 'pre-wrap' }}>
                            {this.state.error && this.state.error.toString()}
                            <br />
                            {this.state.errorInfo && this.state.errorInfo.componentStack}
                        </details>
                    </Card>
                </div>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;
