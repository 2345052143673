import React, { useEffect, useState, useContext, KeyboardEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Drawer, List } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import { DateTime } from 'luxon';
import { ButtonProps } from 'antd/lib/button';

import '../../assets/styles/UserMenu.less';

import * as SelectionsActions from '../../store/actions/selections';
import { Client, Selection } from '../../store/api/types';
import { MainReducerState } from '../../store/reducers';
import { getSelectionsByCustomerReference } from '../../store/reducers/selections';

import { IconArrow } from '../icons';
import ListItem, { ListItemProps } from '../ListItem';
import messages from './UserMenu.messages';
import EmptyResult from '../EmptyResult';
import ClientSelectionDrawer from './ClientSelectionDrawer';
import { UserMenuDrawerContext } from './UserMenuDrawerContext';

interface ClientSelectionsDrawerProps extends DrawerProps {
    clientReference?: Client['reference'];
    deleteSelection: typeof SelectionsActions.del;
    selections?: Selection[];
}

const ClientSelectionsDrawer: React.FC<ClientSelectionsDrawerProps> = ({
    deleteSelection, selections, onClose, visible, zIndex,
}) => {
    const {
        isClientSelectionDrawerVisible, setIsClientSelectionDrawerVisible,
    } = useContext(UserMenuDrawerContext);
    const [selectedSelectionId, setSelectedSelectionId] = useState();
    const onCloseClientDrawer = () => {
        setIsClientSelectionDrawerVisible(false);
        setSelectedSelectionId(undefined);
    };
    const onBack: ButtonProps['onClick'] = () => {
        if (typeof onClose === 'function') {
            onClose({} as KeyboardEvent<HTMLDivElement>);
        }
    };
    const onDeleteSelection = (id?: Selection['id']) => {
        setIsClientSelectionDrawerVisible(false);
        if (selections) {
            window.setTimeout(() => {
                deleteSelection(selections.find((selection) => selection.id === id));
            }, 200);
        }
    };
    const onClientClick: ListItemProps['onClick'] = (e) => {
        const id = e.currentTarget.dataset.id ? parseInt(e.currentTarget.dataset.id, 10) : -1;
        setSelectedSelectionId(id);
    };

    useEffect(() => {
        if (selectedSelectionId !== undefined) {
            setIsClientSelectionDrawerVisible(true);
        }
    }, [selectedSelectionId, setIsClientSelectionDrawerVisible]);

    return (
        <>
            <Drawer
                title={(
                    <div className="drawer-header-with-back-button">
                        <Button
                            type="link"
                            onClick={onBack}
                        >
                            <IconArrow direction="left" />
                        </Button>
                        <FormattedMessage
                            {...messages.hisSelections}
                            values={{
                                b: (...m: string[]) => <span>{m}</span>,
                                count: selections ? selections.length : 0,
                            }}
                            tagName="span"
                        />
                    </div>
                )}
                placement="right"
                width={385}
                maskStyle={{display: 'none'}}
                closable={false}
                visible={visible}
                zIndex={zIndex}
            >
                <div id="user-menu-selections">
                    <section>
                        <List>
                            {selections && !!selections.length && selections.map((selection) => (
                                <List.Item key={selection.id}>
                                    <ListItem
                                        onClick={onClientClick}
                                        data-id={selection.id}
                                        title={DateTime.fromISO(selection.updateAt!).toLocaleString({
                                            day: 'numeric', month: 'long', year: 'numeric',
                                        })}
                                        subTitle={(
                                            <FormattedMessage
                                                {...messages.selectionProductsLength}
                                                values={{ count: selection.products.length }}
                                            />
                                        )}
                                    />
                                </List.Item>
                            ))}
                            {selections && !selections.length && (
                                <List.Item key="empty">
                                    <EmptyResult />
                                </List.Item>
                            )}
                        </List>
                    </section>
                </div>
            </Drawer>
            <ClientSelectionDrawer
                selectionId={selectedSelectionId}
                onClose={onCloseClientDrawer}
                onDelete={onDeleteSelection}
                visible={isClientSelectionDrawerVisible}
                zIndex={1403}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState, { clientReference }: { clientReference?: Client['reference'] }) => ({
    selections: getSelectionsByCustomerReference(state, clientReference || '-1'),
});

export default connect(
    mapStateToProps,
    {
        deleteSelection: SelectionsActions.del,
    },
)(ClientSelectionsDrawer);
