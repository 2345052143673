import api from './_client';
import { Client } from './types';
import { PaginationQuery } from '.';

export const list = (query?: PaginationQuery) =>
    api.get('/api/favorites', { params: query });

export const add = (data: Client) =>
    api.post('/api/favorites', data);

export const del = (data: Client) =>
    api.delete('/api/favorites', {data});
