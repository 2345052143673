import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <path fill="currentColor" fillRule="evenodd" d="M5.755 14.855v-2.928H3.133C1.375 11.927 0 10.587 0 8.875s1.375-3.083 3.133-3.083h2.622V3.114C5.755 1.4 7.195 0 8.952 0c1.758 0 3.23 1.4 3.23 3.114v2.678h2.685C16.53 5.792 18 7.162 18 8.875s-1.47 3.052-3.133 3.052H12.18v2.928c0 1.712-1.47 3.145-3.228 3.145-1.758 0-3.197-1.433-3.197-3.145z" />
    </svg>
);

const IconPlus: React.FC<IconProps> = (props) => (
    <Icon component={svg} {...props} className={`anticon icon-plus${props.className ? ` ${props.className}` : ''}`} />
);

export default IconPlus;
