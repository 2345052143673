import React, { useEffect, KeyboardEvent } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Drawer, List, Tooltip } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import { DateTime } from 'luxon';

import * as SelectionsActions from '../../store/actions/selections';
import { MainReducerState, getOfflineState } from '../../store/reducers';
import { getSelectionById, getGeneratePdfState, SelectionsState } from '../../store/reducers/selections';
import { Selection } from '../../store/api/types';

import { getRoute, RoutePathName } from '../../routes';
import { IconPlaceholder, IconArrow, IconTrash } from '../icons';
import ListItem from '../ListItem';
import EmptyResult from '../EmptyResult';
import GenericMessages from '../../locale/Generic.messages';
import Img from '../Img';
import { t } from '../../utils';
import { ButtonProps } from 'antd/lib/button';

interface ClientSelectionDrawerProps extends DrawerProps {
    generatedPdf: SelectionsState['generatePdf'];
    generatePdf: typeof SelectionsActions.generatePdf;
    isOnline: boolean;
    onDelete: (id?: Selection['id']) => void;
    selectionId?: Selection['id'];
    selection?: Selection;
}

const ClientSelectionDrawer: React.FC<ClientSelectionDrawerProps> = ({
    generatedPdf, generatePdf, isOnline, onClose, onDelete, selection, visible, zIndex,
}) => {
    const { locale } = useIntl();
    const onBack: ButtonProps['onClick'] = () => {
        if (typeof onClose === 'function') {
            onClose({} as KeyboardEvent<HTMLDivElement>);
        }
    };
    const onClickDownload = () => {
        if (selection) {
            generatePdf(selection.id, { language: locale, orientation: 'portrait' });
        }
    };
    const onClickDelete = () => {
        onDelete(selection ? selection.id : undefined);
    };
    useEffect(() => {
        if (
            !generatedPdf.loading && generatedPdf.success && generatedPdf.data &&
            selection && generatedPdf.data.selectionId === selection.id
        ) {
            if (selection && generatedPdf.data && generatedPdf.data.selectionId === selection.id) {
                window.open(generatedPdf.data.url, '_blank');
            }
        }
    }, [generatedPdf.loading]); // eslint-disable-line react-hooks/exhaustive-deps

    const downloadButton = (
        <Button
            type="primary"
            disabled={!isOnline}
            onClick={onClickDownload}
            loading={generatedPdf.loading}
            size="large"
            block
        >
            <FormattedMessage {...GenericMessages.download} />
        </Button>
    );

    return (
        <Drawer
            title={(
                <div className="drawer-header-with-back-button">
                    <Button
                        type="link"
                        onClick={onBack}
                    >
                        <IconArrow direction="left" />
                    </Button>
                    {selection && (
                        <>
                            {DateTime.fromISO(selection.updateAt!).toLocaleString({
                                day: 'numeric', month: 'long', year: 'numeric',
                            })}
                            &nbsp;
                            {`(${selection.products.length})`}
                        </>
                    )}
                    {selection && selection.id > 0 && (
                        <Button
                            type="link"
                            onClick={onClickDelete}
                        >
                            <IconTrash />
                        </Button>
                    )}
                </div>
            )}
            placement="right"
            width={385}
            maskStyle={{ display: 'none' }}
            closable={false}
            visible={visible}
            zIndex={zIndex}
        >
            <div className="with-footer">
                <section>
                    <List>
                        {selection && selection.products.map((product) => (
                            <List.Item key={product.id}>
                                <ListItem
                                    icon={product.images && product.images[0] ?
                                        <Img imageId={product.images[0].id} alt={product ? t(product.label, locale) : 'product'} /> :
                                        <IconPlaceholder />
                                    }
                                    data-id={product.id}
                                    title={(
                                        <Link to={getRoute(RoutePathName.product, { id: product.id })}>
                                            {t(product.label, locale)}
                                        </Link>
                                    )}
                                />
                            </List.Item>
                        ))}
                        {selection && !selection.products.length && (
                            <List.Item key="empty">
                                <EmptyResult />
                            </List.Item>
                        )}
                    </List>
                </section>
                <footer>
                    {isOnline ?
                        downloadButton :
                        (
                            <Tooltip
                                title={<FormattedMessage {...GenericMessages.youMustBeOnline} />}
                            >
                                {downloadButton}
                            </Tooltip>
                        )
                    }
                </footer>
            </div>
        </Drawer>
    );
};

const mapStateToProps = (state: MainReducerState, { selectionId }: { selectionId: Selection['id'] }) => ({
    generatedPdf: getGeneratePdfState(state),
    isOnline: getOfflineState(state).online,
    selection: getSelectionById(state, selectionId),
});

export default connect(
    mapStateToProps,
    {
        generatePdf: SelectionsActions.generatePdf,
    },
)(ClientSelectionDrawer);
