import { put } from 'redux-saga/effects';
import FlexSearch from 'flexsearch';

import { Languages } from '../../components/IntlProvider';
import * as productsActions from '../actions/products';
import * as productsApi from '../api/products';
import { indices } from '../configureStore';
import { fetchAllPagesSaga } from '.';
import { Product } from '../api/types';

export const createProductSearchIndex = (products: Product[], locale?: Languages) => {
    try {
        // console.info('[PRODUCTS] Create search index');
        indices.productsIndex = FlexSearch.create({
            profile: 'memory',
            doc: {
                id: 'id',
                field: [
                    `label:${locale}`,
                    'reference',
                ],
            },
        });

        indices.productsIndex.info();
        indices.productsIndex.add(products);
        indices.productsIndex.info();
        // console.info('[PRODUCTS] Create search index Success');
    } catch (error) {
        // console.info('[PRODUCTS] Create search index ERROR', error);
    }
};

export default function* listAllSaga(locale?: Languages) {
    try {
        // console.info('[FETCH_PRODUCTS] Start');
        const products = yield fetchAllPagesSaga(productsApi.list);

        yield createProductSearchIndex(products, locale);

        // console.info('[FETCH_PRODUCTS] Success');
        return yield put(productsActions.listSuccess(products));
    } catch (error) {
        // console.info('[FETCH_PRODUCTS] ERROR', error);
        return yield put(productsActions.listFailed(error));
    }
}
