import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlnsXlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16">
        <path fill="currentColor" d="M13.475 14.532V7.836a.73.73 0 0 1 .216-.517L8.1 1.77l-6.48 6.43a.74.74 0 0 1 1.07.657v5.672h2.763V8.86c0-.406.333-.736.74-.736h3.975c.408 0 .74.33.74.735v5.672h2.567zM10.168 16a.738.738 0 0 1-.74-.734V9.593H6.933v5.673a.738.738 0 0 1-.74.734H1.95a.738.738 0 0 1-.74-.734V8.86c0-.12.03-.23.08-.33l-.027.026a.738.738 0 0 1-.523.215.738.738 0 0 1-.74-.733c0-.196.077-.38.217-.52L7.577.216A.738.738 0 0 1 8.102 0c.2 0 .385.076.524.215l7.16 7.102c.21.21.274.526.16.8a.737.737 0 0 1-.684.454h-.305v6.696a.738.738 0 0 1-.74.734h-4.047z" fillRule="evenodd" />
    </svg>
);

const IconHome: React.FC<IconProps> = (props) => (
    <Icon component={svg} {...props} className={`anticon icon-home${props.className ? ` ${props.className}` : ''}`} />
);

export default IconHome;
