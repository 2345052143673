import * as reduxTypes from '../types/countries';
import { requestReducer } from './_generics';
import { MainReducerState, RequestState } from '.';
import { Country } from '../api/types';
import { Reducer } from 'redux';

export type CountriesState = RequestState<Country[]>;

const countries: Reducer<CountriesState> = requestReducer({
    reduxTypes: {
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAILED,
    },
});

export default countries;

export const getCountriesState = (state: MainReducerState) => state.countries;
