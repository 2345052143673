import { call, fork, put } from 'redux-saga/effects';
import allData from './allData';
import auth from './auth';
import countries from './countries';
import faq from './faq';
import nace from './nace';
import news from './news';
import presentations from './presentations';
import profiles from './profiles';
import sectors from './sectors';
import terms from './terms';
import themes from './themes';
import selections from './selections';
import { constants } from '../../utils';
import { ActionCreator } from 'redux';
import { AxiosResponse } from 'axios';
import { PaginationQuery } from '../api';

export default function* mainSaga() {
    yield fork(allData);
    yield fork(auth);
    yield fork(countries);
    yield fork(faq);
    yield fork(nace);
    yield fork(news);
    yield fork(presentations);
    yield fork(profiles);
    yield fork(sectors);
    yield fork(selections);
    yield fork(terms);
    yield fork(themes);
}

export function* fetchAllPagesSaga(
    api: (query?: PaginationQuery) => Promise<AxiosResponse<any>>,
    query: PaginationQuery = {},
    successAction?: ActionCreator<any>,
    failedAction?: ActionCreator<any>,
) {
    try {
        const firstCallResponse = yield call(api, { ...query, limit: constants.FETCH_PAGE_SIZE });

        if (firstCallResponse.total > constants.FETCH_PAGE_SIZE) {
            let items = [...firstCallResponse.results];
            let itemsCount = constants.FETCH_PAGE_SIZE;
            let page = 1;

            while (itemsCount < firstCallResponse.total) {
                const res = yield call(api, { ...query, limit: constants.FETCH_PAGE_SIZE, page });

                items = [
                    ...items,
                    ...res.results,
                ];
                itemsCount += res.results.length;
                page += 1;
            }

            if (typeof successAction === 'function') {
                yield put(successAction(items));
            }

            return items;
        }

        if (typeof successAction === 'function') {
            yield put(successAction(firstCallResponse.results));
        }

        return firstCallResponse.results;
    } catch (error) {
        if (typeof failedAction === 'function') {
            yield put(failedAction(error));
        }
        return undefined;
    }
}
