import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';

import BreadCrumb from '../../components/BreadCrumb';
import { getRoute, RoutePathName } from '../../routes';
import { IconHeroInvertedTriangle } from '../../components/icons';
import messages from './Terms.messages';
import { MainReducerState } from '../../store/reducers';
import { getTermsState, TermsState } from '../../store/reducers/terms';

interface TermsProps {
    termsState: TermsState;
}

const Terms: React.FC<TermsProps> = ({ termsState }) => {
    const { formatMessage } = useIntl();

    return (
        <>
            <BreadCrumb
                routes={[{
                    link: getRoute(RoutePathName.howItWorks),
                    text: formatMessage(messages.title),
                }]}
            />
            <header className="page-hero product-hero">
                <div className="container">
                    <Row>
                        <Col xl={15} lg={18} md={16} className="page-hero-title-col">
                            <FormattedMessage {...messages.title} tagName="h1" />
                        </Col>
                    </Row>
                    <div id="bottom-triangle">
                        <IconHeroInvertedTriangle />
                    </div>
                </div>
            </header>
            <div
                id="terms"
                className="container-small m-b-56"
                dangerouslySetInnerHTML={{ __html: termsState.data || '' }}
            />
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    termsState: getTermsState(state),
});

export default connect(
    mapStateToProps,
)(Terms);
