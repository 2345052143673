import React, { ReactChild } from 'react';

import '../assets/styles/Info.less';

import { IconTooltipTriangle } from './icons';


interface InfoProps {
    topText?: ReactChild;
    bottomText: ReactChild;
}

const Info: React.FC<InfoProps> = ({topText, bottomText}) => (
    <div className="info-tooltip">
        <p>{topText || null}<span>{bottomText}</span></p>
        <IconTooltipTriangle />
    </div>
);

export default Info;
