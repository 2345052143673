import api from './_client';
import { Client } from './types';
import { PaginationQuery } from '.';

export const list = (query?: PaginationQuery) =>
    api.get('/api/me/customers', { params: query });

export const create = (data: Client) =>
    api.post('/api/customers', data);

export const details = (id: Client['id']) =>
    api.get(`/api/customers/${id}`);
