import React, { FC, useContext } from 'react';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import ReactGA from 'react-ga';
import { Button, Checkbox } from 'antd';
import { CheckboxProps } from 'antd/lib/checkbox';

import { Chapter, SubChapter } from '../../store/api/types';

import { PresentationContext } from '../PresentationContextProvider';
import { IconPlaceholder, IconTrash } from '../icons';
import messages from './Presentation.messages';

export interface ChapterCardProps {
    cellIndex?: number;
    chapter: Chapter | SubChapter;
    isOpen?: boolean;
    onClickRemove?: (
        chapter: Chapter | SubChapter,
        event: React.MouseEvent<HTMLElement, MouseEvent>,
    ) => void;
    onSeeSubChapters?: (
        rowIndex: number | undefined,
        cellIndex: number | undefined,
        chapter: Chapter,
    ) => void;
    parent?: Chapter;
    removable?: boolean;
    rowIndex?: number;
    selectable?: boolean;
    sortable?: boolean;
}

const ChapterCard: FC<ChapterCardProps> = ({
    cellIndex, chapter, isOpen, onClickRemove, onSeeSubChapters, parent, removable, rowIndex,
    selectable = true, sortable,
}) => {
    const { locale } = useIntl();
    const {
        presentation, onChangeSelectedChapters,
    } = useContext(PresentationContext);
    const isChapter = (item: Chapter | SubChapter): item is Chapter =>
        (item as Chapter).subChapters !== undefined;
    const onClickShowSubChapters = () => {
        if (isChapter(chapter) && typeof onSeeSubChapters === 'function') {
            onSeeSubChapters(rowIndex, cellIndex, chapter);
        }
    };
    const onCheckboxChange: CheckboxProps['onChange'] = (e) => {
        const method = e.target.checked ? 'add' : 'remove';

        if (isChapter(chapter)) {
            onChangeSelectedChapters(method, chapter);

            if (method === 'add') {
                ReactGA.event({
                    category: 'chapitre',
                    action: 'clic',
                    label: `${chapter.id}`,
                });
            }
        } else {
            onChangeSelectedChapters(method, chapter, parent);

            if (method === 'add') {
                ReactGA.event({
                    category: 'sous-chapitre',
                    action: 'clic',
                    label: `${chapter.id}`,
                });
            }
        }
    };
    const hasSubChapters = isChapter(chapter) ? !!chapter.subChapters.length : false;
    let image;

    if (isChapter(chapter)) {
        if (
            chapter.chapterFiles.length &&
            chapter.chapterFiles[0].pdf &&
            chapter.chapterFiles[0].pdf.thumbnail
        ) {
            image = chapter.chapterFiles[0].pdf.thumbnail.url;
        } else if (
            hasSubChapters &&
            chapter.subChapters[0].chapterFiles.length &&
            chapter.subChapters[0].chapterFiles[0].pdf &&
            chapter.subChapters[0].chapterFiles[0].pdf.thumbnail
        ) {
            image = chapter.subChapters[0].chapterFiles[0].pdf.thumbnail.url;
        }
    } else {
        if (
            chapter.chapterFiles.length &&
            chapter.chapterFiles[0].pdf &&
            chapter.chapterFiles[0].pdf.thumbnail
        ) {
            image = chapter.chapterFiles[0].pdf.thumbnail.url;
        }
    }

    let isIndeterminate = false;
    let isChecked = false;

    if (selectable) {
        if (isChapter(chapter)) {
            const match = presentation.selectedChapters.find((c) => c.id === chapter.id);

            if (match) {
                isIndeterminate = true;

                if (chapter.subChapters.length === match.subChapters.length) {
                    isChecked = true;
                    isIndeterminate = false;
                }
            }
        } else if (parent) {
            const match = presentation.selectedChapters.find((c) => c.id === parent.id);

            isChecked = !!match && !!match.subChapters.find((s) => s.id === chapter.id);
        }
    }

    return (
        <div
            className={`chapter-card${
                isChecked || isIndeterminate ? ' chapter-card-selected' : ''
            }${
                isOpen ? ' chapter-card-open' : ''
            }${
                presentation.type === 'proposition' ? ' chapter-card-vertical' : ''
            }${
                sortable ? ' chapter-card-sortable' : ''
            }`}
        >
            {image ? <img src={image} alt={chapter.name[locale]} /> : <IconPlaceholder />}
            {selectable && (
                <Checkbox
                    checked={isChecked}
                    indeterminate={isIndeterminate}
                    onChange={onCheckboxChange}
                />
            )}
            {(hasSubChapters || removable) && (
                <div className="chapter-card-button-overlay">
                    {hasSubChapters && (
                        <Button
                            className="chapter-card-sub-chapters-button"
                            onClick={onClickShowSubChapters}
                        >
                            <FormattedHTMLMessage {...messages.seeSubChapters} />
                        </Button>
                    )}
                    {removable && typeof onClickRemove === 'function' && (
                        <Button
                            className="chapter-card-remove-button"
                            onClick={onClickRemove.bind(null, chapter)}
                            type="link"
                        >
                            <IconTrash />
                        </Button>
                    )}
                </div>
            )}

            <p className="chapter-card-title">
                {chapter.name[locale]}
            </p>
        </div>
    );
};

export default ChapterCard;
