import { Reducer } from 'redux';

import * as reduxTypes from '../types/nace';
import { requestReducer } from './_generics';
import { MainReducerState, RequestState } from '.';
import { Nace } from '../api/types';

export type NaceState = RequestState<Nace[]>;

const nace: Reducer<NaceState> = requestReducer({
    reduxTypes: {
        START: reduxTypes.GET,
        SUCCESS: reduxTypes.GET_SUCCESS,
        FAILED: reduxTypes.GET_FAILED,
    },
});

export default nace;

export const getNaceState = (state: MainReducerState) => state.nace;
