import { Reducer } from 'redux';

import { Theme, Chapter } from '../api/types';
import * as reduxTypes from '../types/themes';
import { requestReducer } from './_generics';
import { MainReducerState, RequestState } from '.';

export type ThemesState = RequestState<Theme[]>;

const themes: Reducer<ThemesState> = requestReducer({
    reduxTypes: {
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAILED,
    },
    dataAccessor: 'results',
});

export default themes;

export const getThemesState = (state: MainReducerState) => state.themes;

// remove chapters/subChapters with empty chapterFiles and a theme if it contains only empty chapters/SubChapters
export const getFilteredThemesState = (state: MainReducerState) => ({
    ...state.themes,
    data: state.themes.data ?
        state.themes.data.reduce<Theme[]>((themesAcc, theme) => {
            if (
                theme.chapters.some((c) =>
                    c.chapterFiles.length ||
                    c.subChapters.some((s) => s.chapterFiles.length),
                )
            ) {
                themesAcc.push({
                    ...theme,
                    chapters: theme.chapters.reduce<Chapter[]>((chaptersAcc, chapter) => {
                        if (
                            chapter.chapterFiles.length ||
                            chapter.subChapters.some((s) => s.chapterFiles.length)
                        ) {
                            chaptersAcc.push({
                                ...chapter,
                                subChapters: chapter.subChapters.filter((s) => s.chapterFiles.length),
                            });
                        }
                        return chaptersAcc;
                    }, []),
                });
            }
            return themesAcc;
        }, []) :
        [],
});
