import { all, call, put } from 'redux-saga/effects';

import * as imagesActions from '../actions/images';
import * as imagesApi from '../api/images';
import { fetchAllPagesSaga } from '.';
import { File } from '../api/types';
import { isBlob, splitArrayInChunks } from '../../utils';

function* fetchImagesData(images: File[]) {
    try {
        // Improvement: implement checksum of each image in backend and only fetch new images or
        // images which checksum have changed.
        const chunks = splitArrayInChunks(images, 10);

        const data: Blob[] = [];

        for (const chunk of chunks) {
            const response = yield all(chunk.map((image) => call(imagesApi.fetchImage, image.url)));
            data.push(...response);
        }

        return images.reduce<{ [imageId: number]: Blob }>((res, image, index) => {
            if (isBlob(data[index])) {
                res[image.id] = data[index];
            }

            return res;
        }, {});
    } catch (error) {
        // console.info('[FETCH_IMAGES] ERROR', error);
        return yield put(imagesActions.listFailed(error));
    }
}

export default function* listAllSaga() {
    try {
        // console.info('[FETCH_IMAGES] Start');
        const images = yield fetchAllPagesSaga(imagesApi.list, { type: 'image', cache: true });

        if (!images) {
            return yield put(imagesActions.listSuccess({}));
        }

        const imagesData = yield fetchImagesData(images);

        // console.info('[FETCH_IMAGES] Success');
        return yield put(imagesActions.listSuccess(imagesData));
    } catch (error) {
        // console.info('[FETCH_IMAGES] ERROR', error);
        return yield put(imagesActions.listFailed(error));
    }
}
