import { call, takeLatest, put } from 'redux-saga/effects';
import ReactGA from 'react-ga';

import * as authActions from '../actions/auth';
import * as authTypes from '../types/auth';
import * as authApi from '../api/auth';
import { DataAction, IdAction } from '../actions';

export function* loginSaga(action: any) {
    try {
        const response = yield call(authApi.login, action.data);

        ReactGA.set({
            userId: response.id,
        });

        return yield put(authActions.loginSuccess(response));
    } catch (error) {
        return yield put(authActions.loginFailed(error));
    }
}

export function* registerSaga(action: any) {
    try {
        const response = yield call(authApi.register, {...action.data});

        return yield put(authActions.registerSuccess(response));
    } catch (error) {
        return yield put(authActions.registerFailed(error));
    }
}

export function* forgottenPasswordSaga(action: any) {
    try {
        const response = yield call(authApi.forgottenPassword, {...action.data});

        return yield put(authActions.forgottenPasswordSuccess(response));
    } catch (error) {
        return yield put(authActions.forgottenPasswordFailed(error));
    }
}

export function* updateSaga(action: DataAction & IdAction) {
    try {
        const response = yield call(authApi.update, action.id, action.data);

        return yield put(authActions.updateSuccess(response));
    } catch (error) {
        return yield put(authActions.updateFailed(error));
    }
}

export function* logoutSaga(action: any) {
    try {
        const response = yield call(authApi.logout);

        return yield put(authActions.logoutSuccess(response));
    } catch (error) {
        return yield put(authActions.logoutFailed(error));
    }
}

export function* checkLoginStatusSaga(action: any) {
    try {
        const response = yield call(authApi.getUserDetails);

        return yield put(authActions.checkLoginStatusSuccess(response));
    } catch (error) {
        return yield put(authActions.checkLoginStatusFailed(error));
    }
}

export function* fetchUserSaga(action: any) {
    try {
        const response = yield call(authApi.getUserDetails);

        return yield put(authActions.fetchUserSuccess(response));
    } catch (error) {
        return yield put(authActions.fetchUserFailed(error));
    }
}

export default function* authSaga() {
    yield takeLatest(authTypes.LOGIN, loginSaga);
    yield takeLatest(authTypes.LOGOUT, logoutSaga);
    yield takeLatest(authTypes.REGISTER, registerSaga);
    yield takeLatest(authTypes.FORGOTTEN_PASSWORD, forgottenPasswordSaga);
    yield takeLatest(authTypes.UPDATE, updateSaga);
    yield takeLatest(authTypes.CHECK_LOGIN_STATUS, checkLoginStatusSaga);
    yield takeLatest(authTypes.FETCH_USER, fetchUserSaga);
}
