import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <path fill="currentColor" fillRule="nonzero" d="M.317 19.683a1.083 1.083 0 0 0 1.53 0l8.166-8.166 8.166 8.166a1.083 1.083 0 0 0 1.53-1.53l-8.167-8.166L19.71 1.82A1.083 1.083 0 0 0 18.18.29l-8.167 8.167L1.847.29a1.083 1.083 0 0 0-1.53 1.53l8.166 8.167-8.166 8.166a1.083 1.083 0 0 0 0 1.53z" />
    </svg>
);

const IconClose: React.FC<IconProps> = (props) => (
    <Icon component={svg} {...props} className={`anticon icon-close${props.className ? ` ${props.className}` : ''}`} />
);

export default IconClose;
