import React from 'react';
import { Row, Col } from 'antd';

import { RoutePathName, getRoute } from '../../routes';
import BreadCrumb from '../../components/BreadCrumb';
import PDFViewer from '../../components/PDFViewer';
import { IconHeroInvertedTriangle } from '../../components/icons';


const BookDigital: React.FC = () => (
    <>
        <BreadCrumb
            routes={[
                {
                    link: getRoute(RoutePathName.bookDigital),
                    text: 'Book digital',
                },
            ]}
        />
        <header className="page-hero product-hero">
            <div className="container">
                <Row>
                    <Col xl={15} lg={18} md={16} className="page-hero-title-col">
                        <h1>Book Digital</h1>
                    </Col>
                </Row>
                <div id="bottom-triangle">
                    <IconHeroInvertedTriangle />
                </div>
            </div>
        </header>
        <section id="book-digital" className="p-t-24 m-b-86" style={{ minHeight: 'calc(100vw / 1.3268156425)' }}>
            <PDFViewer
                file={`${process.env.REACT_APP_API_BASE_URL || ''}/pdf/20190607_Elis-book ICS 2019.pdf`}
            />
        </section>
    </>
);

export default BookDigital;
