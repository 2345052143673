import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlnsXlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 20 20">
        <path fillRule="nonzero" fill="currentColor" d="M19.7 18.253l-4.168-4.168a8.7 8.7 0 1 0-1.448 1.447l4.17 4.168a1.024 1.024 0 1 0 1.446-1.447zM3.997 13.408a6.654 6.654 0 1 1 9.41 0 6.663 6.663 0 0 1-9.41 0z" />
    </svg>
);

const IconSearch: React.FC<IconProps> = (props) => (
    <Icon component={svg} {...props} className={`anticon icon-search${props.className ? ` ${props.className}` : ''}`} />
);

export default IconSearch;
