import { combineReducers } from 'redux';
import * as reduxTypes from '../types/clients';
import { MainReducerState, RequestState } from '.';
import { Client, SectorType, Sector } from '../api/types';
import { requestReducer } from './_generics';
import { PresentationData } from '../../components/PresentationContextProvider';
import { getDescendentSectors, getSectorById } from './sectors';

export type ClientsListState = RequestState<Client[]>;

export interface ClientsState {
    readonly list: ClientsListState;
    readonly updates: RequestState;
}

export default combineReducers<ClientsState>({
    list: requestReducer({
        reduxTypes: {
            START: reduxTypes.LIST,
            SUCCESS: reduxTypes.LIST_SUCCESS,
            FAILED: reduxTypes.LIST_FAILED,
            CREATE_EFFECT: reduxTypes.CREATE,
            CREATE_COMMIT: reduxTypes.CREATE_SUCCESS,
            CREATE_ROLLBACK: reduxTypes.CREATE_ROLLBACK,
            UPDATE_EFFECT: reduxTypes.UPDATE,
            UPDATE_COMMIT: reduxTypes.UPDATE_SUCCESS,
            UPDATE_ROLLBACK: reduxTypes.UPDATE_ROLLBACK,
        },
        createEffect: (state, data: Client) => [...state, data],
        createCommit: (state, data: Client, payload: Client) =>
            state ?
                // `payload || data` is a hack to be able to call CREATE_SUCCESS directly outside of the offline stack
                [...state.filter((client) => client.reference !== data.reference), payload || data] :
                [payload || data],
        createRollback: (state, data: Client) =>
            state ?
                state.filter((client) => client.reference !== data.reference) :
                state,
        updateEffect: (state, data: Client) => {
            if (state) {
                const rollback = state.find((client) => client.reference === data.reference);

                return [
                    ...state.filter((client) => client.reference !== data.reference),
                    {
                        ...data,
                        rollback,
                    },
                ];
            }

            return [data];
        },
        updateCommit: (state, data: Client, payload: Client) =>
            state ?
                [...state.filter((client) => client.reference !== data.reference), payload] :
                [payload],
        updateRollback: (state, data: Client) => {
            const restItems = state ? state.filter((client) => client.reference !== data.reference) : [] as Client[];
            const itemToRollBack = state ? state.find((client) => client.reference === data.reference)! : undefined;

            return [
                ...restItems,
                {
                    ...itemToRollBack!.rollback,
                } as Client,
            ];
        },
    }),
    updates: requestReducer({
        reduxTypes: {
            START: reduxTypes.UPDATE,
            SUCCESS: reduxTypes.UPDATE_SUCCESS,
            FAILED: reduxTypes.UPDATE_FAILED,
        },
    }),
});

export const getClientsListState = (state: MainReducerState) => state.clients.list;

export const getClientsListStateForPresentation = (state: MainReducerState, presentation: PresentationData) => {
    let data: Client[] = [];

    if (state.clients.list.data && presentation.sector && presentation.sector.id) {
        const presentationSector = getSectorById(state, presentation.sector.id);

        if (presentationSector) {
            let allSectors;

            if (presentationSector.type === SectorType.Sector) {
                allSectors = [
                    presentationSector,
                    ...getDescendentSectors(state, presentationSector.id),
                ];
            } else if (presentationSector.type === SectorType.SubSector) {
                allSectors = presentationSector.sectorParents.reduce<Sector[]>((acc, parentId) => ([
                    ...acc,
                    ...getDescendentSectors(state, parentId),
                ]), []);
            }

            const allSectorIds = allSectors ? allSectors.map((sector) => sector.id) : [];

            data = state.clients.list.data.filter((client) => allSectorIds.includes(client.sectorId));
        }
    }

    return {
        ...state.clients.list,
        data,
    };
};

export const getClients = (state: MainReducerState) => state.clients.list.data;

export const getClientById = (state: MainReducerState, id?: Client['id']) =>
    state.clients.list.data ? state.clients.list.data.find((client) => client.id === id) : undefined;

export const getClientByReference = (state: MainReducerState, reference?: Client['reference']) =>
    state.clients.list.data ? state.clients.list.data.find((client) => client.reference === reference) : undefined;

export const getUpdates = (state: MainReducerState) => state.clients.updates;
