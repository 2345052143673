import React from 'react';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';

import '../assets/styles/Header.less';

import { IconLogo } from './icons';
import { RoutePathName, getRoute } from '../routes';
import UserMenu from './userMenu/UserMenu';
import OfflineBadge from './OfflineBadge';
import Search from './Search';

const Header: React.FC = () => (
    <Layout.Header id="content-header">
        <nav>
            <ul className="list-unstyled list-inline">
                <li><Link to={getRoute(RoutePathName.nace)}>NACE</Link></li>
                <li><Link to={getRoute(RoutePathName.bookDigital)}>Book Digital</Link></li>
            </ul>
        </nav>
        <Link to={getRoute(RoutePathName.home)}>
            <IconLogo />
        </Link>
        <div>
            <Search />
            <UserMenu />
            <OfflineBadge />
        </div>
    </Layout.Header>
);

export default Header;
