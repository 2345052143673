import React, { createContext, useState, FC, Dispatch, useEffect } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';

import * as ThemesActions from '../store/actions/themes';
import {
    Sector, SubChapter, Client, File, Chapter, PresentationType, Selection, PresentationDetails,
    FileSubType,
} from '../store/api/types';
import { MainReducerState } from '../store/reducers';
import { getFilteredThemesState, ThemesState } from '../store/reducers/themes';

import { usePrevious } from '../hooks';

export enum PresentationSteps {
    Chapters = 'chapters',
    Preview = 'preview',
    Informations = 'informations',
    Customize = 'customize',
    Success = 'success',
}

export interface PresentationData {
    title?: string;
    preamble?: string;
    logo?: Partial<File>;
    logoId?: File['id'];
    logoUrl?: File['url'];
    client?: Pick<Client, 'id' | 'reference' | 'createAt' | 'firstName' | 'lastName' | 'email' |
    'company' | 'sectorId' | 'profileId' | 'profile' | 'sector'>;
    language?: string;
    sector?: Pick<Sector, 'id' | 'order' | 'type' | 'color' | 'icon' | 'reference' | 'skipSubLevel' |
    'name'>;
    selectedChapters: Chapter[];
    selectedSelections: Selection[];
    selectedPricings?: File['id'][];
    selectedPages?: File['id'][];
    type: PresentationType;
    id?: PresentationDetails['id'];
}

export interface PresentationPayload {
    title?: string;
    preamble?: string;
    logoId?: File['id'];
    logo?: Partial<File>;
    orientation?: 'portrait' | 'landscape';
    language?: string;
    type?: PresentationType;
    customerId?: Client['id'];
    fileOrders?: {
        fileId: File['id'];
        order: number;
    }[];
    sectorId?: Sector['id'];
}

interface PresentationContextTypes {
    isCreateDone: boolean;
    isConvertDone: boolean;
    isEditing: boolean;
    isPresentationOpen: boolean;
    isUpdateDone: boolean;
    getPresentationPayload: (lang: string) => PresentationPayload;
    onChangeSelectedChapters: (
        method: 'add' | 'remove',
        items: Chapter[] | Chapter | SubChapter,
        parentId?: Chapter,
    ) => void;
    presentation: PresentationData;
    previewFrom: PresentationSteps.Chapters | PresentationSteps.Customize;
    reset: () => void;
    setIsEditing: Dispatch<React.SetStateAction<boolean>>;
    setIsPresentationOpen: Dispatch<React.SetStateAction<boolean>>;
    setIsCreateDone: Dispatch<React.SetStateAction<boolean>>;
    setIsConvertDone: Dispatch<React.SetStateAction<boolean>>;
    setIsUpdateDone: Dispatch<React.SetStateAction<boolean>>;
    setPresentationData: (data: Partial<PresentationData>) => void;
    setPreviewFrom: (step: PresentationSteps.Chapters | PresentationSteps.Customize) => void;
    setPresentationEdit: (data?: PresentationDetails) => void;
    setShouldInitiateSaveOnCustomizeStep: Dispatch<React.SetStateAction<boolean>>;
    setStep: Dispatch<React.SetStateAction<PresentationSteps>>;
    shouldInitiateSaveOnCustomizeStep: boolean;
    step: PresentationSteps;
}

export const PresentationContext = createContext<PresentationContextTypes>({
    getPresentationPayload: () => ({}),
    isCreateDone: false,
    isConvertDone: false,
    isEditing: false,
    isPresentationOpen: false,
    isUpdateDone: false,
    onChangeSelectedChapters: () => undefined,
    presentation: {
        selectedChapters: [],
        selectedSelections: [],
        type: 'presentation',
    },
    previewFrom: PresentationSteps.Chapters,
    reset: () => undefined,
    setIsCreateDone: () => undefined,
    setIsConvertDone: () => undefined,
    setIsUpdateDone: () => undefined,
    setIsEditing: () => undefined,
    setIsPresentationOpen: () => undefined,
    setPresentationData: () => undefined,
    setPresentationEdit: () => undefined,
    setShouldInitiateSaveOnCustomizeStep: () => undefined,
    setPreviewFrom: () => undefined,
    setStep: () => undefined,
    shouldInitiateSaveOnCustomizeStep: false,
    step: PresentationSteps.Chapters,
});

const isChapter = (item: Chapter | SubChapter): item is Chapter =>
    (item as Chapter).subChapters !== undefined;

interface PresentationContextProviderProps {
    listThemes: typeof ThemesActions.list;
    themesState: ThemesState;
}

const PresentationContextProvider: FC<PresentationContextProviderProps> = ({ children, listThemes, themesState }) => {
    const { locale } = useIntl();
    const prevProps = usePrevious<Partial<PresentationContextProviderProps>>({ themesState });
    const [presentationEditToSet, setPresentationEditToSet] = useState<PresentationDetails | undefined>();
    const [isCreateDone, setIsCreateDone] = useState<PresentationContextTypes['isCreateDone']>(false);
    const [isConvertDone, setIsConvertDone] = useState<PresentationContextTypes['isConvertDone']>(false);
    const [isUpdateDone, setIsUpdateDone] = useState<PresentationContextTypes['isUpdateDone']>(false);
    const [isEditing, setIsEditing] = useState<PresentationContextTypes['isEditing']>(false);
    const [isPresentationOpen, setIsPresentationOpen] = useState<PresentationContextTypes['isPresentationOpen']>(false);
    const [previewFrom, setPreviewFrom] = useState<PresentationContextTypes['previewFrom']>(PresentationSteps.Chapters);
    const [presentation, setPresentation] = useState<PresentationData>({
        selectedChapters: [],
        selectedSelections: [],
        type: 'presentation',
    });
    const [step, setStep] = useState<PresentationContextTypes['step']>(PresentationSteps.Chapters);
    const [shouldInitiateSaveOnCustomizeStep, setShouldInitiateSaveOnCustomizeStep] = useState(false);
    const setPresentationData: PresentationContextTypes['setPresentationData'] = (data) => {
        setPresentation({
            ...presentation,
            ...data,
        });
    };
    const setPresentationEdit: PresentationContextTypes['setPresentationEdit'] = (data) => {
        if (data) {
            setPresentationEditToSet(data);
            const newData: PresentationData = {
                id: data.id || undefined,
                title: data.title,
                preamble: data.preamble || undefined,
                logo: data.logo || undefined,
                client: data.customer,
                language: data.language,
                sector: data.sector || undefined,
                type: data.type,
                selectedChapters: [],
                selectedSelections: [],
                selectedPricings: [],
                selectedPages: [],
            };

            data.fileOrders.forEach((fileOrder) => {
                if (fileOrder.file.subType === FileSubType.Selection && fileOrder.file.selection) {
                    newData.selectedSelections.push({
                        ...fileOrder.file.selection,
                        pdf: fileOrder.file,
                    } as any);
                }
                if (fileOrder.file.subType === FileSubType.Pricing) {
                    newData.selectedPricings!.push(fileOrder.file.id);
                }
                if (fileOrder.file.subType === FileSubType.Other) {
                    newData.selectedPages!.push(fileOrder.file.id);
                }
            });

            setPresentation(newData);
            listThemes({
                type: data.type,
                language: locale,
                sectorId: data.sectorId || undefined,
                limit: 1337,
            });
        }
    };

    useEffect(() => {
        if (
            prevProps && prevProps.themesState && prevProps.themesState.loading &&
            !themesState.loading && themesState.data && presentationEditToSet
        ) {
            const newData: Pick<PresentationData, 'selectedChapters'> = {
                selectedChapters: [],
            };
            const allChapters = themesState.data.reduce<Chapter[]>((acc, theme) =>
                acc.concat(theme.chapters)
            , []);

            presentationEditToSet.fileOrders.sort((a, b) => a.order - b.order).forEach((fileOrder) => {
                const isSubChapter = fileOrder.file.chapterFile && fileOrder.file.chapterFile.chapter.parent !== null;

                if (fileOrder.file.subType === FileSubType.Chapter) {
                    const match = allChapters.find((chapter) =>
                        chapter.chapterFiles.some((chapterFile) =>
                            chapterFile.pdf && chapterFile.pdf.id === fileOrder.file.id,
                        ) ||
                        chapter.subChapters.some((subChapter) =>
                            subChapter.chapterFiles.some((s) => s.pdf.id === fileOrder.file.id),
                        ),
                    );

                    if (match) {
                        const newDataMatch = newData.selectedChapters.find((c) => c.id === match.id);

                        if (!newDataMatch) {
                            newData.selectedChapters.push({
                                ...match,
                                subChapters: isSubChapter ?
                                    match.subChapters.filter((s) =>
                                        s.id === fileOrder.file.chapterFile.chapter.id,
                                    ) :
                                    match.subChapters,
                            });
                        } else if (isSubChapter) {
                            newDataMatch.subChapters = [
                                ...(newDataMatch.subChapters.filter((s) =>
                                    s.id !== fileOrder.file.chapterFile.chapter.id,
                                ) || []),
                                ...(match.subChapters.filter((s) =>
                                    s.id === fileOrder.file.chapterFile.chapter.id,
                                ) || []),
                            ];
                        }
                    }
                }
            });

            setPresentationData(newData);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [themesState.loading, presentationEditToSet]);

    const onChangeSelectedChapters: PresentationContextTypes['onChangeSelectedChapters'] =
    (method, items, parent) => {
        let result = cloneDeep(presentation.selectedChapters);

        if (Array.isArray(items)) {
            switch (method) {
                case 'add': {
                    items.forEach((item) => {
                        const match = result.find((chapter) => chapter.id === item.id);
                        if (!match) {
                            result.push(cloneDeep(item));
                        } else if (match.subChapters.length !== item.subChapters.length) {
                            match.subChapters = cloneDeep(item.subChapters)
                                .sort((a, b) => a.order - b.order);
                        }
                    });
                    break;
                }

                case 'remove':
                    items.forEach((item) => {
                        result = result.filter((chapter) => chapter.id !== item.id);
                    });
                    break;
            }
        } else if (isChapter(items)) {
            switch (method) {
                case 'add': {
                    const match = result.find((chapter) => chapter.id === items.id);
                    if (!match) {
                        result.push(cloneDeep(items));
                    } else if (match.subChapters.length !== items.subChapters.length) {
                        match.subChapters = cloneDeep(items.subChapters).sort((a, b) => a.order - b.order);
                    }
                    break;
                }

                case 'remove':
                    result = result.filter((chapter) => chapter.id !== items.id);
                    break;
            }
        } else {
            switch (method) {
                case 'add': {
                    if (parent) {
                        const matchingChapter = result.find((chapter) => chapter.id === parent.id);

                        if (matchingChapter) {
                            matchingChapter.subChapters.push(cloneDeep(items));
                            matchingChapter.subChapters.sort((a, b) => a.order - b.order);
                        } else {
                            result.push({
                                ...parent,
                                subChapters: [cloneDeep(items)],
                            });
                        }
                    }
                    break;
                }

                case 'remove':
                    result = result.reduce<Chapter[]>((acc, chapter) => {
                        if (chapter.subChapters.find((s) => s.id === items.id)) {
                            const newSubChapters = chapter.subChapters.filter((subChapter) =>
                                subChapter.id !== items.id,
                            ).sort((a, b) => a.order - b.order);

                            if (newSubChapters.length) {
                                acc.push({
                                    ...chapter,
                                    subChapters: newSubChapters,
                                });
                            }
                        } else {
                            chapter.subChapters.sort((a, b) => a.order - b.order);
                            acc.push(chapter);
                        }

                        return acc;
                    }, []);
                    break;
            }
        }

        console.warn(result.sort((a, b) => a.order - b.order));

        setPresentationData({ selectedChapters: result.sort((a, b) => a.order - b.order) });
    };

    const getPresentationPayload = (lang: string, presentationData?: PresentationData) => {
        const {
            title, preamble, logo, client, selectedChapters, selectedSelections, selectedPricings,
            selectedPages, type,
        } = presentationData || presentation;
        const payload: PresentationPayload = {
            fileOrders: [],
            language: lang,
            sectorId: presentation.sector ? presentation.sector.id : undefined,
            type,
            orientation: type === 'presentation' ? 'landscape' : 'portrait',
        };

        if (title !== undefined) {
            payload.title = title;
        }

        if (preamble !== undefined) {
            payload.preamble = preamble;
        }

        if (logo !== undefined) {
            payload.logo = logo;
            payload.logoId = logo.id;
        }

        if (client !== undefined) {
            payload.customerId = client.id;
        }

        if (selectedChapters.length) {
            selectedChapters.forEach((selectedChapter) => {
                if (selectedChapter.chapterFiles.length) {
                    payload.fileOrders!.push({
                        fileId: selectedChapter.chapterFiles[0].pdf.id,
                        order: payload.fileOrders!.length,
                    });
                }
                selectedChapter.subChapters.forEach((subChapter) => {
                    payload.fileOrders!.push({
                        fileId: subChapter.chapterFiles[0].pdf.id,
                        order: payload.fileOrders!.length,
                    });
                });
            });
        }

        if (selectedSelections.length) {
            selectedSelections.forEach((selection) => {
                payload.fileOrders!.push({
                    fileId: selection.pdf ? selection.pdf.id : -1,
                    order: payload.fileOrders!.length,
                });
            });
        }

        if (selectedPricings && selectedPricings.length) {
            selectedPricings.forEach((fileId) => {
                payload.fileOrders!.push({
                    fileId,
                    order: payload.fileOrders!.length,
                });
            });
        }

        if (selectedPages && selectedPages.length) {
            selectedPages.forEach((fileId) => {
                payload.fileOrders!.push({
                    fileId,
                    order: payload.fileOrders!.length,
                });
            });
        }

        return payload;
    };

    const reset = () => {
        setIsCreateDone(false);
        setIsConvertDone(false);
        setIsEditing(false);
        setPreviewFrom(PresentationSteps.Chapters);
        setStep(PresentationSteps.Chapters);
        setPresentation({
            selectedChapters: [],
            selectedSelections: [],
            type: 'presentation',
        });
        setPresentationEditToSet(undefined);
    };

    const context = {
        isCreateDone,
        isConvertDone,
        isEditing,
        isUpdateDone,
        isPresentationOpen,
        getPresentationPayload,
        onChangeSelectedChapters,
        presentation,
        previewFrom,
        reset,
        setIsCreateDone,
        setIsConvertDone,
        setIsEditing,
        setIsPresentationOpen,
        setIsUpdateDone,
        setPresentationData,
        setPresentationEdit,
        setPreviewFrom,
        setShouldInitiateSaveOnCustomizeStep,
        setStep,
        shouldInitiateSaveOnCustomizeStep,
        step,
    };

    return (
        <PresentationContext.Provider value={context}>
            {children}
        </PresentationContext.Provider>
    );
};


const mapStateToProps = (state: MainReducerState) => ({
    themesState: getFilteredThemesState(state),
});

export default connect(
    mapStateToProps,
    {
        listThemes: ThemesActions.list,
    },
)(PresentationContextProvider);
