import { Action } from 'redux';
import { call, takeLatest, put } from 'redux-saga/effects';

import * as termsActions from '../actions/terms';
import * as reduxTypes from '../types/terms';
import * as termsApi from '../api/terms';
import { Languages } from '../../components/IntlProvider';

export function* fetchSaga(action: Action & { locale: Languages }) {
    try {
        const terms = yield call(termsApi.fetch, action.locale);

        return yield put(termsActions.fetchSuccess(terms));
    } catch (error) {
        return yield put(termsActions.fetchFailed(error));
    }
}

export default function* termsSaga() {
    yield takeLatest(reduxTypes.FETCH, fetchSaga);
}
