import { Action, ActionCreator } from 'redux';
import * as reduxTypes from '../types/sectors';
import { DataAction } from '.';

export const list: ActionCreator<Action> = () => ({
    type: reduxTypes.LIST,
});

export const listSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_SUCCESS,
    data,
});

export const listFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_FAILED,
    data,
});

export const listMarkets: ActionCreator<Action> = () => ({
    type: reduxTypes.LIST_MARKETS,
});

export const listMarketsSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_MARKETS_SUCCESS,
    data,
});

export const listMarketsFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_MARKETS_FAILED,
    data,
});

