import * as reduxTypes from '../types/profiles';
import { requestReducer } from './_generics';
import { MainReducerState, RequestState } from '.';
import { Profile } from '../api/types';


export type ProfilesState = RequestState<Profile[]>;

export default requestReducer<Profile[]>({
    reduxTypes: {
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAILED,
    },
});

export const getProfilesState = (state: MainReducerState) => state.profiles;

export const getProfileById = (state: MainReducerState, id: Profile['id']) =>
    state.profiles.data ? state.profiles.data.find((profile) => profile.id === id) : undefined;
