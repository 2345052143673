import { Name } from '../store/api/types';

export { default as constants } from './constants';

export const getFullName = (firstname?: string, lastname?: string) => {
    if (!firstname && !lastname) {
        return '';
    }

    return `${firstname || ''}${lastname ? ` ${lastname}` : ''}`.trim();
};

export const capitalize = (str: string) => {
    if (typeof str !== 'string') {
        return '';
    }

    const lowerCased = str.toLowerCase();

    return `${lowerCased.charAt(0).toUpperCase()}${lowerCased.slice(1)}`;
};

export const capitalizeWords = (str: string) => {
    if (typeof str !== 'string') {
        return '';
    }

    return str.split(' ').map(capitalize).join(' ');
};

export const hasOwnProp = (obj: object, value: string | number | [], strict = true) => {
    let result = false;

    if (typeof obj === 'object') {
        if (Array.isArray(value)) {
            result = strict ?
                !value.some((property) => !Object.prototype.hasOwnProperty.call(obj, property)) :
                value.some((property) => Object.prototype.hasOwnProperty.call(obj, property));
        } else {
            result = Object.prototype.hasOwnProperty.call(obj, value);
        }
    }

    return result;
};

export const arrayMove = (arr: any[], oldIndex: number, newIndex: number) => {
    if (newIndex >= arr.length) {
        let k = newIndex - arr.length + 1;

        while (k--) {
            arr.push(undefined);
        }
    }

    arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);

    return arr;
};

export const scrollToElement = (element: HTMLElement | null, offset?: number) => {
    if (element) {
        const elementTop = element.getBoundingClientRect().top +
            (window.pageYOffset || document.documentElement.scrollTop);

        window.scrollTo({
            top: elementTop - (offset || 0),
            behavior: 'smooth',
        });
    }
};

export const isBlob = (item: any) => typeof item === 'object' && item.toString() === '[object Blob]';

export const t = (message: Name | undefined, locale: string) => {
    if (!message) {
        return '-';
    }

    const { id, ...langs } = message;

    if (message[locale]) {
        return `${message[locale]}`;
    } else if (message.en) {
        return `${message.en}`;
    } else if (Object.keys(langs).length) {
        return `[${Object.keys(langs)[0]}] ${langs[Object.keys(langs)[0]]}`;
    } else {
        return '<aucune traduction>';
    }
};

export const splitArrayInChunks = <T>(array: T[], size: number): T[][] =>
    Array(Math.ceil(array.length / size))
        .fill(undefined)
        .map((_, index) => index * size)
        .map((begin) => array.slice(begin, begin + size));
