import React, { useCallback, useContext } from 'react';
import { FormattedMessage, IntlShape, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Drawer, List } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';

import '../../assets/styles/UserMenu.less';

import * as SelectionsActions from '../../store/actions/selections';
import { Client } from '../../store/api/types';
import { getClients } from '../../store/reducers/clients';
import { MainReducerState } from '../../store/reducers';

import { getFullName } from '../../utils';
import { IconArrow } from '../icons';
import ListItem from '../ListItem';
import messages from './SelectionBar.messages';
import EmptyResult from '../EmptyResult';
import { SelectionBarDrawerContext } from './SelectionBarDrawerContext';
import GenericMessages from '../../locale/Generic.messages';

interface SelectedClientDrawerProps extends DrawerProps {
    intl: IntlShape;
    clients?: Client[];
    setClient: typeof SelectionsActions.setClient;
}

const SelectedClientDrawer: React.FC<SelectedClientDrawerProps> = ({
    clients, intl, onClose, setClient, ...props
}) => {
    const {
        isClientsDrawerVisible, setIsClientsDrawerVisible, setIsSelectedProductsDrawerVisible,
    } = useContext(SelectionBarDrawerContext);
    const onBack = useCallback(() => {
        setIsClientsDrawerVisible(false);
    }, [setIsClientsDrawerVisible]);
    const onChooseClient = useCallback((e) => {
        const client = clients!.find((c) => c.reference === e.currentTarget.dataset.reference);
        setClient(client);
        setIsClientsDrawerVisible(false);
        setIsSelectedProductsDrawerVisible(false);
    }, [clients, setClient, setIsClientsDrawerVisible, setIsSelectedProductsDrawerVisible]);

    return (
        <>
            <Drawer
                title={(
                    <div className="drawer-header-with-back-button">
                        <Button
                            type="link"
                            onClick={onBack}
                        >
                            <IconArrow direction="left" />
                        </Button>
                        <FormattedMessage {...messages.chooseClient} tagName="span" />
                    </div>
                )}
                placement="right"
                width={385}
                maskStyle={{display: 'none'}}
                closable={false}
                visible={isClientsDrawerVisible}
                {...props}
            >
                <div id="user-menu-clients">
                    <section>
                        <List>
                            {clients && !!clients.length && clients.map((client) => (
                                <List.Item key={client.id}>
                                    <ListItem
                                        title={getFullName(client.firstName, client.lastName)}
                                        subTitle={client.company}
                                    >
                                        <Button
                                            type="primary"
                                            className="show-on-hover"
                                            onClick={onChooseClient}
                                            data-reference={client.reference}
                                        >
                                            <FormattedMessage {...GenericMessages.choose} />
                                        </Button>
                                    </ListItem>
                                </List.Item>
                            ))}
                            {clients && !clients.length && (
                                <List.Item key="empty">
                                    <EmptyResult />
                                </List.Item>
                            )}
                        </List>
                    </section>
                </div>
            </Drawer>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    clients: getClients(state),
});

export default connect(
    mapStateToProps,
    {
        setClient: SelectionsActions.setClient,
    },
)(injectIntl(SelectedClientDrawer));
