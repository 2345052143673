import { Action, ActionCreator } from 'redux';

import * as reduxTypes from '../types/selections';
import { DataAction } from '.';
import { Client, Product, Selection } from '../api/types';

export const history: ActionCreator<Action> = () => ({
    type: reduxTypes.LIST,
});

export const historySuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_SUCCESS,
    data,
});

export const historyFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LIST_FAILED,
    data,
});

export const fetchCurrent: ActionCreator<Action> = () => ({
    type: reduxTypes.FETCH_CURRENT,
});

export const fetchCurrentSuccess: ActionCreator<DataAction<Selection>> = (data) => ({
    type: reduxTypes.FETCH_CURRENT_SUCCESS,
    data,
});

export const fetchCurrentFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.FETCH_CURRENT_FAILED,
    data,
});

export const clearProducts: ActionCreator<Action> = () => ({
    type: reduxTypes.CLEAR_PRODUCTS_CURRENT,
    meta: {
        offline: {
            // the network action to execute:
            effect: { url: '/api/me/selections/current', method: 'DELETE' },
            // action to dispatch when effect succeeds:
            commit: clearProductsSuccess(),
            // action to dispatch if network action fails permanently:
            rollback: clearProductsRollback(),
        },
    },
});

export const clearProductsSuccess: ActionCreator<Action> = () => ({
    type: reduxTypes.CLEAR_PRODUCTS_CURRENT_SUCCESS,
});

export const clearProductsRollback: ActionCreator<Action> = () => ({
    type: reduxTypes.CLEAR_PRODUCTS_CURRENT_ROLLBACK,
});

export const validate: ActionCreator<DataAction> = (data: Selection) => ({
    type: reduxTypes.VALIDATE_CURRENT,
    data,
    meta: {
        offline: {
            // the network action to execute:
            effect: { url: '/api/me/selections/current/validate', method: 'POST' },
            // action to dispatch when effect succeeds:
            commit: validateSuccess(data),
            // action to dispatch if network action fails permanently:
            rollback: validateRollback(data),
        },
    },
});

export const validateSuccess: ActionCreator<DataAction> = (data: Selection) => ({
    type: reduxTypes.VALIDATE_CURRENT_SUCCESS,
    data,
});

export const validateRollback: ActionCreator<Action> = (data: Selection) => ({
    type: reduxTypes.VALIDATE_CURRENT_ROLLBACK,
    data,
});

export const setClient: ActionCreator<DataAction> = (data: Client) => ({
    type: reduxTypes.SET_CLIENT_CURRENT,
    data,
    meta: {
        offline: {
            // the network action to execute:
            effect: { url: `/api/me/selections/current/customers/reference/${data.reference}`, method: 'POST' },
            // action to dispatch when effect succeeds:
            commit: setClientSuccess(),
            // action to dispatch if network action fails permanently:
            rollback: setClientRollback(),
        },
    },
});

export const setClientSuccess: ActionCreator<Action> = () => ({
    type: reduxTypes.SET_CLIENT_CURRENT_SUCCESS,
});

export const setClientRollback: ActionCreator<Action> = () => ({
    type: reduxTypes.SET_CLIENT_CURRENT_ROLLBACK,
});

export const addProduct: ActionCreator<DataAction> = (data: Product, argumentTextIds: number[]) => ({
    type: reduxTypes.ADD_PRODUCT_CURRENT,
    data,
    meta: {
        offline: {
            // the network action to execute:
            effect: {
                url: `/api/me/selections/current/products/${data.id}`,
                method: 'POST',
                data: { argumentTextIds },
            },
            // action to dispatch when effect succeeds:
            commit: addProductSuccess(),
            // action to dispatch if network action fails permanently:
            rollback: addProductRollback(),
        },
    },
});

export const addProductSuccess: ActionCreator<Action> = () => ({
    type: reduxTypes.ADD_PRODUCT_CURRENT_SUCCESS,
});

export const addProductRollback: ActionCreator<Action> = () => ({
    type: reduxTypes.ADD_PRODUCT_CURRENT_ROLLBACK,
});

export const removeProduct: ActionCreator<DataAction> = (data: Product) => ({
    type: reduxTypes.REMOVE_PRODUCT_CURRENT,
    data,
    meta: {
        offline: {
            // the network action to execute:
            effect: { url: `/api/me/selections/current/products/${data.id}`, method: 'DELETE' },
            // action to dispatch when effect succeeds:
            commit: removeProductSuccess(),
            // action to dispatch if network action fails permanently:
            rollback: removeProductRollback(),
        },
    },
});

export const removeProductSuccess: ActionCreator<Action> = () => ({
    type: reduxTypes.REMOVE_PRODUCT_CURRENT_SUCCESS,
});

export const removeProductRollback: ActionCreator<Action> = () => ({
    type: reduxTypes.REMOVE_PRODUCT_CURRENT_ROLLBACK,
});

export const del: ActionCreator<DataAction> = (data: Selection) => ({
    type: reduxTypes.DELETE_SELECTION,
    data,
    meta: {
        offline: {
            // the network action to execute:
            effect: { url: `/api/selections/${data.id}`, method: 'DELETE' },
            // action to dispatch when effect succeeds:
            commit: delSuccess(),
            // action to dispatch if network action fails permanently:
            rollback: delRollback(),
        },
    },
});

export const delSuccess: ActionCreator<Action> = () => ({
    type: reduxTypes.DELETE_SELECTION_SUCCESS,
});

export const delRollback: ActionCreator<Action> = () => ({
    type: reduxTypes.DELETE_SELECTION_ROLLBACK,
});

export interface GeneratePdfPayload {
    language: string;
    orientation: string;
}

export const generatePdf: ActionCreator<DataAction & { payload: GeneratePdfPayload }> = (
    data: Selection['id'],
    payload: GeneratePdfPayload,
) => ({
    type: reduxTypes.GENERATE_PDF,
    data,
    payload,
});

export const generatePdfSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GENERATE_PDF_SUCCESS,
    data,
});

export const generatePdfFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GENERATE_PDF_FAILED,
    data,
});
