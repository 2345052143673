import axios from 'axios';

import api from './_client';
import { PaginationQuery } from '.';

export const list = (query?: PaginationQuery) =>
    api.get('/api/files', { params: query });

export const fetchImage = (fileUrl: string) =>
    axios.get(fileUrl, { responseType: 'blob' })
        // .then((res) => new Blob([res.data], { type: 'image/jpeg' }), (err) => err);
        .then((res) => new Blob([res.data], { type: 'image/jpeg' }));
