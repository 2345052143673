import React, { useContext, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Row, Col, Card, Divider, Button, Spin } from 'antd';
import { DateTime } from 'luxon';

import '../../assets/styles/Home.less';

import * as NewsActions from '../../store/actions/news';
import * as FavoritesActions from '../../store/actions/favorites';
import { Sector, Product } from '../../store/api/types';
import { MainReducerState } from '../../store/reducers';
import { getSectorsByParentIdSortedByFavorite } from '../../store/reducers/sectors';

import heroImage from '../../assets/images/home-hero.jpg';
import heroImage2x from '../../assets/images/home-hero@2x.jpg';
import heroImage3x from '../../assets/images/home-hero@3x.jpg';
import { IconAngle, IconArrow } from '../../components/icons';
import Carousel from '../../components/Carousel';
import SectorCard from '../../components/SectorCard';
import Info from '../../components/Info';
import messages from './Home.messages';
import GenericMessages from '../../locale/Generic.messages';
import { RoutePathName, getRoute } from '../../routes';
import EmptyResult from '../../components/EmptyResult';
import ProductCard from '../../components/ProductCard';
import { getFavoritesProducts } from '../../store/reducers/products';
import { getUser } from '../../store/reducers/auth';
import { UserMenuDrawerContext, FavoriteDrawerTabs } from '../../components/userMenu/UserMenuDrawerContext';
import { getNewsState, NewsState } from '../../store/reducers/news';
import { t } from '../../utils';

interface HomeProps {
    favorites: Product[];
    fetchNews: typeof NewsActions.list;
    fetchFavorites: typeof FavoritesActions.list;
    newsState: NewsState;
    sectors: Sector[];
    userMarketId?: Sector['id'];
}

const Home: React.FC<HomeProps> = ({ favorites, fetchFavorites, fetchNews, newsState, sectors, userMarketId }) => {
    const { locale } = useIntl();
    const {
        setIsFavoritesDrawerVisible, setIsFavoritesMaskVisible, setFavoritesTab,
    } = useContext(UserMenuDrawerContext);
    const onClickShowFavorites = useCallback(() => {
        setIsFavoritesMaskVisible(true);
        setFavoritesTab(FavoriteDrawerTabs.products);
        setIsFavoritesDrawerVisible(true);
    }, [setFavoritesTab, setIsFavoritesDrawerVisible, setIsFavoritesMaskVisible]);

    useEffect(() => {
        fetchFavorites();
        fetchNews({ limit: 3, marketId: userMarketId || '' });
    }, [fetchFavorites, fetchNews, userMarketId]);

    return (
        <>
            <header id="home-hero" className="page-hero">
                <img
                    src={heroImage}
                    srcSet={`${heroImage2x} 2x, ${heroImage3x} 3x`}
                    alt="Employé devant un camion Elis et portant des serviettes"
                />
                <IconAngle direction="bottom" />
            </header>
            <section id="home-carousel-wrapper" className="bg-grey">
                <div className="container">
                    {!!sectors.length && (
                        <>
                            <Info
                                topText={<FormattedMessage {...messages.tooltipTop} />}
                                bottomText={<FormattedMessage {...messages.tooltipBottom} />}
                            />
                            <Carousel
                                id="home-carousel"
                                slidesToShow={sectors.length < 4 ? sectors.length : 4}
                                slidesToScroll={sectors.length < 4 ? sectors.length : 4}
                                responsive={[
                                    {
                                        breakpoint: 1366,
                                        settings: {
                                            slidesToShow: sectors.length < 3 ? sectors.length : 3,
                                            slidesToScroll: sectors.length < 3 ? sectors.length : 3,
                                        },
                                    },
                                    {
                                        breakpoint: 884,
                                        settings: {
                                            slidesToShow: sectors.length < 2 ? sectors.length : 2,
                                            slidesToScroll: sectors.length < 2 ? sectors.length : 2,
                                        },
                                    },
                                    {
                                        breakpoint: 580,
                                        settings: {
                                            slidesToShow: 1,
                                            slidesToScroll: 1,
                                        },
                                    },
                                ]}
                            >
                                {sectors.map((sector) => (
                                    <SectorCard
                                        key={sector.id}
                                        sector={sector}
                                        withFavorite
                                    />
                                ))}
                            </Carousel>
                        </>
                    )}
                    {!sectors.length && (
                        <EmptyResult />
                    )}
                </div>
            </section>
            <section className="container p-y-56" id="home-bottom">
                <Row gutter={32}>
                    <Col lg={8} md={24}>
                        <Card
                            id="home-how-it-works"
                            title={<FormattedMessage {...GenericMessages.howItWorks} />}
                            bordered={false}
                        >
                            <Divider />
                            <FormattedMessage tagName="p" {...messages.howItWorksText} />
                            <Link to={getRoute(RoutePathName.howItWorks)} className="arrow-link">
                                <IconArrow direction="right" />
                                <FormattedMessage {...GenericMessages.readMore} />
                            </Link>
                        </Card>
                    </Col>
                    <Col lg={8} md={24}>
                        <Card
                            id="home-favorites"
                            title={<FormattedMessage {...messages.favoriteTitle} />}
                            bordered={false}
                        >
                            <Divider />
                            <Row gutter={8}>
                                {favorites.slice(0, 3).map((product) => (
                                    <Col span={8} key={product.id}>
                                        <ProductCard
                                            name={t(product.label, locale)}
                                            image={product.images && product.images[0]}
                                            link={getRoute(RoutePathName.product, { id: product.id })}
                                            isSmall
                                        />
                                    </Col>
                                ))}
                                {!favorites.length && (
                                    <EmptyResult text={<FormattedMessage {...messages.noFavorites} />} />
                                )}
                            </Row>
                            <Button
                                type="link"
                                className="arrow-link"
                                onClick={onClickShowFavorites}
                            >
                                <IconArrow direction="right" />
                                <FormattedMessage {...messages.favoriteLink} />
                            </Button>
                        </Card>
                    </Col>
                    <Col lg={8} md={24}>
                        <Spin spinning={newsState.loading}>
                            <Card
                                id="home-news"
                                title={<FormattedMessage {...messages.newsTitle} />}
                                bordered={false}
                            >
                                <Divider />
                                {newsState.data && newsState.data.results ?
                                    newsState.data.results.slice(0, 3).map((news) => (
                                        <article key={news.id} className="home-news-article">
                                            <h1>{t(news.title, locale)}</h1>
                                            <p>
                                                <time dateTime={news.updateAt || news.createAt || undefined}>
                                                    {
                                                        DateTime.fromISO(
                                                            news.updateAt || news.createAt || '',
                                                        ).toLocaleString({ month: 'long', year: 'numeric' })
                                                    }
                                                </time>
                                                <Link to={getRoute(RoutePathName.news)}>
                                                    <FormattedMessage {...messages.newsMoreLink} />
                                                </Link>
                                            </p>
                                        </article>
                                    )) : null}
                                {newsState.data && newsState.data.results && !newsState.data.results.length && (
                                    <EmptyResult text={<FormattedMessage {...messages.noNews} />} />
                                )}
                            </Card>
                        </Spin>
                    </Col>
                </Row>
            </section>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => {
    const user = getUser(state);

    return {
        favorites: getFavoritesProducts(state),
        newsState: getNewsState(state),
        sectors: getSectorsByParentIdSortedByFavorite(state, (user || { marketId: -1 }).marketId),
        userMarketId: user ? user.marketId : undefined,
    };
};

export default connect(
    mapStateToProps,
    {
        fetchNews: NewsActions.list,
        fetchFavorites: FavoritesActions.list,
    },
)(Home);
