import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="350" height="283" viewBox="0 0 350 283">
        <path fill="currentColor" d="M261.1 156.13c4.644 0 8.42-3.792 8.42-8.454 0-4.663-3.776-8.454-8.42-8.454h-15.71c-4.644 0-8.42 3.79-8.42 8.454 0 4.662 3.776 8.454 8.42 8.454h15.71zM16.844 33.296c0 8.51 6.893 15.432 15.37 15.432 8.48 0 15.38-6.923 15.38-15.432V16.908h-30.75v16.388zm47.594 0c0 8.51 6.892 15.432 15.374 15.432 8.477 0 15.374-6.923 15.374-15.432V16.908h-30.75v16.388zm47.593 0c0 8.51 6.898 15.432 15.375 15.432s15.375-6.923 15.375-15.432V16.908h-30.75v16.388zm47.594 0c0 8.51 6.898 15.432 15.375 15.432 8.473 0 15.37-6.923 15.37-15.432V16.908h-30.745v16.388zm47.59 0c0 8.51 6.902 15.432 15.38 15.432 8.476 0 15.374-6.923 15.374-15.432V16.908h-30.753v16.388zm47.6 0c0 8.51 6.897 15.432 15.37 15.432 8.48 0 15.378-6.923 15.378-15.432V16.908h-30.75v16.388zm47.593 0c0 8.51 6.894 15.432 15.375 15.432 8.477 0 15.37-6.923 15.37-15.432V16.908H302.41v16.388zm-88.236 168.396V64.478a32.092 32.092 0 0 1-15.366-9.434c-6.052 6.716-14.605 10.592-23.806 10.592-9.2 0-17.746-3.872-23.797-10.584-6.05 6.712-14.596 10.584-23.797 10.584-9.2 0-17.746-3.872-23.797-10.584-6.05 6.712-14.6 10.584-23.796 10.584-9.206 0-17.755-3.876-23.806-10.592a32.104 32.104 0 0 1-15.37 9.434v137.214h173.536zm-173.537 64.4h173.537V218.6H40.637v47.492zm268.726 0V64.478a32.072 32.072 0 0 1-15.367-9.434c-6.055 6.716-14.604 10.592-23.81 10.592-9.197 0-17.75-3.876-23.8-10.592a32.092 32.092 0 0 1-15.367 9.434v201.614h78.343zM23.793 283V64.478C10 60.698 0 47.892 0 33.296V0h350v33.296c0 14.596-10 27.403-23.793 31.182V283H23.793z" fillRule="evenodd" />
    </svg>
);

const IconCommerces: React.FC<IconProps> = (props) => (
    <Icon
        component={svg}
        {...props}
        className={`anticon icon-commerces${props.className ? ` ${props.className}` : ''}`}
    />
);

export default IconCommerces;
