import React from 'react';
import {
    Route,
    Switch,
    Redirect,
} from 'react-router-dom';
import { injectIntl } from 'react-intl';
import './assets/styles/App.less';
import './assets/styles/List.less';
import './assets/styles/Typography.less';
import './assets/styles/Form.less';

import { ProtectedRoute } from './components/auth';
import MainLayout from './components/MainLayout';
import ErrorBoundary from './components/ErrorBoundary';
import NotFound from './pages/notFound';
import Unauthorized from './pages/unauthorized';
import Home from './pages/home';
import Login from './pages/login';
import Register from './pages/register';
import ForgottenPassword from './pages/forgottenPassword';
import { Sector, SalesAcademy, SectorInformations, SubSector } from './pages/sector';
import { Products, ProductSearch } from './pages/products';
import { Product } from './pages/product';
import Nace from './pages/nace';
import BookDigital from './pages/bookDigital';
import { getRawRoute, RoutePathName } from './routes';
import HowItWorks from './pages/howItWorks';
import Terms from './pages/terms';
import News from './pages/news';

const App: React.FC = () => (
    <Switch>
        <Route exact path={getRawRoute(RoutePathName.login)} component={Login} />
        <Route exact path={getRawRoute(RoutePathName.register)} component={Register} />
        <Route exact path={getRawRoute(RoutePathName.forgottenPassword)} component={ForgottenPassword} />
        <ProtectedRoute loginPath={getRawRoute(RoutePathName.login)}>
            <MainLayout>
                <ErrorBoundary>
                    <Switch>
                        <Route
                            exact
                            path={getRawRoute(RoutePathName.unauthorized)}
                        >
                            <Unauthorized />
                        </Route>

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.notFound)}
                        >
                            <NotFound />
                        </Route>

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.home)}
                        >
                            <Home />
                        </Route>

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.nace)}
                        >
                            <Nace />
                        </Route>

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.news)}
                        >
                            <News />
                        </Route>

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.bookDigital)}
                        >
                            <BookDigital />
                        </Route>

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.product)}
                            component={Product}
                        />

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.productFromSolution)}
                            component={Product}
                        />

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.salesAcademy)}
                            component={SalesAcademy}
                        />

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.sectorInformations)}
                            component={SectorInformations}
                        />

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.products)}
                            component={Products}
                        />

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.subSector)}
                            component={SubSector}
                        />

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.sector)}
                            component={Sector}
                        />

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.productSearch)}
                            component={ProductSearch}
                        />

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.howItWorks)}
                            component={HowItWorks}
                        />

                        <Route
                            exact
                            path={getRawRoute(RoutePathName.terms)}
                            component={Terms}
                        />

                        <Route>
                            <Redirect to={getRawRoute(RoutePathName.notFound)} />
                        </Route>
                    </Switch>
                </ErrorBoundary>
            </MainLayout>
        </ProtectedRoute>
    </Switch>
);

export default injectIntl(App);
