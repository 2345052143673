import { call, takeLatest, put } from 'redux-saga/effects';

import * as reduxActions from '../actions/selections';
import * as reduxTypes from '../types/selections';
import * as selectionsApi from '../api/selections';
import { DataAction } from '../actions';

export function* fetchCurrentSaga() {
    try {
        const response = yield call(selectionsApi.current);

        return yield put(reduxActions.fetchCurrentSuccess(response));
    } catch (error) {
        return yield put(reduxActions.fetchCurrentFailed(error));
    }
}

export function* generatePdfSaga(action: DataAction & { payload: reduxActions.GeneratePdfPayload }) {
    try {
        const response = yield call(selectionsApi.generatePdf, action.data, action.payload);

        return yield put(reduxActions.generatePdfSuccess(response));
    } catch (error) {
        return yield put(reduxActions.generatePdfFailed(error));
    }
}

export default function* selectionsSaga() {
    yield takeLatest(reduxTypes.FETCH_CURRENT, fetchCurrentSaga);
    yield takeLatest(reduxTypes.GENERATE_PDF, generatePdfSaga);
}
