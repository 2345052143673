import { call, takeLatest, put } from 'redux-saga/effects';
import * as naceActions from '../actions/nace';
import * as reduxTypes from '../types/nace';
import * as naceApi from '../api/nace';

export function* getSaga() {
    try {
        const nace = yield call(naceApi.get);

        return yield put(naceActions.getSuccess(nace));
    } catch (error) {
        return yield put(naceActions.getFailed(error));
    }
}

export default function* naceSaga() {
    yield takeLatest(reduxTypes.GET, getSaga);
}
