import { defineMessages } from 'react-intl';

export default defineMessages({
    back: {
        id: 'product.back',
        defaultMessage: 'Back<b> to the results</b>',
        description: 'Product page back link',
    },
    ean: {
        id: 'product.ean',
        defaultMessage: 'Product code: {ean}',
        description: 'Product page info',
    },
    colors: {
        id: 'product.colors',
        defaultMessage: 'Colors: {colors}',
        description: 'Product page info',
    },
    addToFavorites: {
        id: 'product.add_to_favorites',
        defaultMessage: 'Add to favorites',
        description: 'Product page actions',
    },
    removeFromFavorites: {
        id: 'product.remove_from_favorites',
        defaultMessage: 'Remove from favorites',
        description: 'Product page actions',
    },
    crossSelling: {
        id: 'product.cross_selling',
        defaultMessage: 'You will also like',
        description: 'Product page cross selling title',
    },
    associatedProducts: {
        id: 'product.associated_products',
        defaultMessage: 'Associated products',
        description: 'Product page associated products',
    },
});
