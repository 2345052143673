import { hasOwnProp } from './utils';

export enum RoutePathName {
    assortment = 'assortment',
    bookDigital = 'bookDigital',
    forgottenPassword = 'forgottenPassword',
    home = 'home',
    howItWorks = 'howItWorks',
    login = 'login',
    nace = 'nace',
    news = 'news',
    notFound = 'notFound',
    product = 'product',
    productFromSolution = 'productFromSolution',
    products = 'products',
    productSearch = 'search',
    register = 'register',
    salesAcademy = 'salesAcademy',
    sector = 'sector',
    sectorInformations = 'sectorInformations',
    subSector = 'subSector',
    terms = 'terms',
    unauthorized = 'unauthorized',
}

export type Routes = {
    [r in RoutePathName]: string;
};

export const routes: Routes = {
    [RoutePathName.assortment]: '/assortment/:id',
    [RoutePathName.bookDigital]: '/book-digital',
    [RoutePathName.forgottenPassword]: '/forgotten-password',
    [RoutePathName.home]: '/',
    [RoutePathName.howItWorks]: '/how-it-works',
    [RoutePathName.login]: '/login',
    [RoutePathName.nace]: '/nace',
    [RoutePathName.news]: '/news',
    [RoutePathName.notFound]: '/404',
    [RoutePathName.product]: '/product/:id',
    [RoutePathName.productFromSolution]: '/sector/:sectorId/:subSectorId/:solutionId/product/:id',
    [RoutePathName.products]: '/sector/:sectorId/:subSectorId/:solutionId/products/:assortmentId?',
    [RoutePathName.productSearch]: '/search/:query',
    [RoutePathName.register]: '/register',
    [RoutePathName.salesAcademy]: '/sector/:id/elis-sales-academy',
    [RoutePathName.sector]: '/sector/:id',
    [RoutePathName.sectorInformations]: '/sector/:id/informations',
    [RoutePathName.subSector]: '/sector/:sectorId/:id',
    [RoutePathName.terms]: '/terms-and-conditions',
    [RoutePathName.unauthorized]: '/unauthorized',
};

export interface RouteParams {
    [param: string]: string | number | undefined;
}

// returns raw react-router string path
export const getRawRoute = (path: RoutePathName) => {
    if (!hasOwnProp(routes, path)) {
        console.error(`[getRawRoute] Route not found for path "${path}", returning /404.`);
        return '/404';
    } else {
        return routes[path];
    }
};

// returns real-world path
export const getRoute = (
    path: RoutePathName,
    params?: RouteParams,
    queryParams?: string | string[][] | Record<string, string> | URLSearchParams | undefined,
) => {
    let route = getRawRoute(path);

    if (params && Object.keys(params).length) {
        Object.keys(params).forEach((routeParam: RouteParams['param']) => {
            const search = new RegExp(`:${routeParam}`);
            if (search.test(route)) {
                if (routeParam) {
                    route = route.replace(search, `${params[routeParam] === undefined ? '' : params[routeParam]}`);
                }
            } else {
                console.warn(`[getRoute] Route param not found in route "${route}", skipping param.`);
            }
        });
    }

    if (route[route.length - 1] === '?') {
        route = route.slice(0, -1);
    }

    if (queryParams && Object.keys(queryParams).length) {
        const urlQueryParams = new URLSearchParams(queryParams);
        route += `?${urlQueryParams.toString()}`;
    }

    return route;
};
