import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
        <path fill="currentColor" fillRule="evenodd" d="M6.414 1.76c-.248 0-.505.026-.762.078-1.907.385-3.934 2.304-3.934 5.104 0 3.36 1.907 5.216 4.793 8.027 1.3 1.264 2.892 2.814 4.49 4.766 1.6-1.953 3.19-3.503 4.49-4.767 2.885-2.812 4.793-4.67 4.793-8.028 0-2.8-2.028-4.72-3.935-5.104a3.868 3.868 0 0 0-.764-.078c-1.62 0-3 1.128-3.79 3.095a.863.863 0 0 1-.466.478l-.067.028-.047.007a.763.763 0 0 1-.42 0l-.035-.003-.084-.03a.866.866 0 0 1-.47-.48c-.787-1.967-2.17-3.095-3.79-3.095zM10.9 22l-.024-.01a.855.855 0 0 1-.207-.062l-.033-.013-.06-.034a.536.536 0 0 1-.11-.07l-.055-.045-.02-.03a.51.51 0 0 1-.064-.067c-1.73-2.24-3.467-3.932-5-5.425C2.35 13.343 0 11.053 0 6.943 0 3.202 2.74.632 5.32.112 5.694.04 6.07 0 6.435 0 8.28 0 9.875.936 11 2.658 12.125.936 13.72 0 15.565 0c.366 0 .74.04 1.115.114C19.26.634 22 3.204 22 6.942c0 4.112-2.35 6.4-5.327 9.3-1.533 1.493-3.27 3.185-5 5.424l-.05.065-.03.022a.59.59 0 0 1-.064.062l-.045.034-.08.042a.812.812 0 0 1-.404.11h-.1z" />
    </svg>
);

const svgFull = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
        <path fill="currentColor" fillRule="evenodd" d="M10.9 22l-.024-.01a.855.855 0 0 1-.207-.062l-.033-.013-.06-.034a.536.536 0 0 1-.11-.07l-.055-.045-.02-.03a.51.51 0 0 1-.064-.067c-1.73-2.24-3.467-3.932-5-5.425C2.35 13.343 0 11.053 0 6.943 0 3.202 2.74.632 5.32.112 5.694.04 6.07 0 6.435 0 8.28 0 9.875.936 11 2.658 12.125.936 13.72 0 15.565 0c.366 0 .74.04 1.115.114C19.26.634 22 3.204 22 6.942c0 4.112-2.35 6.4-5.327 9.3-1.533 1.493-3.27 3.185-5 5.424l-.05.065-.03.022a.59.59 0 0 1-.064.062l-.045.034-.08.042a.812.812 0 0 1-.404.11h-.1z" />
    </svg>
);

const IconLock: React.FC<IconProps & { full?: boolean }> = ({ full, ...props }) => (
    <Icon component={full ? svgFull : svg} className="anticon icon-heart" {...props} />
);

export default IconLock;
