import { Reducer } from 'redux';

import { requestReducer } from './_generics';
import * as reduxTypes from '../types/news';
import { News } from '../api/types';
import { MainReducerState, RequestState } from '.';
import { ListResponse } from '../api/_client';

export type NewsState = RequestState<ListResponse<News>>;

const news: Reducer<NewsState> = requestReducer({
    reduxTypes: {
        START: reduxTypes.LIST,
        SUCCESS: reduxTypes.LIST_SUCCESS,
        FAILED: reduxTypes.LIST_FAILED,
    },
});

export default news;

export const getNewsState = (state: MainReducerState) => state.news;
