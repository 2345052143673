import React, { forwardRef, useRef, useImperativeHandle, ReactNode } from 'react';
import ReactSlick, { Settings, CustomArrowProps } from 'react-slick';
import { Button } from 'antd';

import '../assets/styles/lib/react-slick.less';
import '../assets/styles/Carousel.less';

import IconArrow from './icons/IconArrow';

const PrevArrow: React.FC<CustomArrowProps> = ({currentSlide, slideCount, ...props}) => (
    <Button
        aria-label="slide précédente"
        type="primary"
        color="#88b4e1"
        {...props}
    >
        <IconArrow direction="left" />
    </Button>
);

const NextArrow: React.FC<CustomArrowProps> = ({currentSlide, slideCount, ...props}) => (
    <Button
        aria-label="slide suivante"
        type="primary"
        color="#88b4e1"
        {...props}
    >
        <IconArrow direction="right" />
    </Button>
);

interface CarouselProps extends Settings {
    children?: ReactNode | null;
    id?: string;
    isSmall?: boolean;
}

const Carousel: React.FC<CarouselProps> = (props, ref) => {
    const {
        children, id, isSmall, ...rest
    } = props;
    const slickRef = useRef<ReactSlick>(null);
    useImperativeHandle(ref, () => ({
        slickGoTo: (index: number) => {
            if (slickRef.current !== null) {
                slickRef.current.slickGoTo(index);
            }
        },
    }));

    return (
        <div className={`carousel-wrapper${isSmall ? ' carousel-small' : ''}`} id={id}>
            <ReactSlick
                ref={slickRef}
                dots={false}
                infinite={false}
                nextArrow={<NextArrow />}
                prevArrow={<PrevArrow />}
                pauseOnHover
                {...rest}
            >
                {children}
            </ReactSlick>
        </div>
    );
};


export default forwardRef<ReactSlick, CarouselProps>(Carousel);
