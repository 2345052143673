import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import YouTube from 'react-youtube';
import { Row, Col, Collapse, Icon } from 'antd';

import '../../assets/styles/HowItWorks.less';

import BreadCrumb from '../../components/BreadCrumb';
import { getRoute, RoutePathName } from '../../routes';
import { IconHeroInvertedTriangle } from '../../components/icons';
import messages from './HowItWorks.messages';
import { MainReducerState } from '../../store/reducers';
import { getFAQListState, getFAQConfigState, FAQState } from '../../store/reducers/faq';
import { t } from '../../utils';
import { CollapseProps } from 'antd/lib/collapse';
import EmptyResult from '../../components/EmptyResult';

interface HowItWorksProps {
    faq: FAQState['list'];
    config: FAQState['config'];
}

const collapseExpandIcon: CollapseProps['expandIcon'] = ({ isActive }) =>
    <Icon type="caret-right" rotate={isActive ? 90 : 0} />;

const HowItWorks: React.FC<HowItWorksProps> = ({ config, faq }) => {
    const { formatMessage, locale } = useIntl();

    return (
        <>
            <BreadCrumb
                routes={[{
                    link: getRoute(RoutePathName.howItWorks),
                    text: formatMessage(messages.title),
                }]}
            />
            <header className="page-hero product-hero how-it-works-hero">
                <div className="container">
                    <Row>
                        <Col xl={15} lg={18} md={16} className="page-hero-title-col">
                            <FormattedMessage {...messages.title} tagName="h1" />
                        </Col>
                    </Row>
                    <div id="bottom-triangle">
                        <IconHeroInvertedTriangle />
                    </div>
                </div>
            </header>
            <div id="how-it-works" className="m-b-56">
                <div className="header-wrapper">
                    <div className="container-small">
                        <div id="video-wrapper">
                            <YouTube
                                videoId={
                                    config.data && config.data.length && config.data[0].value.videoUrl.split('v=')[1]
                                }
                                opts={{
                                    width: '1920',
                                    height: '1080',
                                    // @ts-ignore
                                    host: 'https://www.youtube-nocookie.com',
                                    playerVars: {
                                        loop: 1,
                                        modestbranding: 1,
                                        rel: 0,
                                        showinfo: 0,
                                        // eslint-disable-next-line @typescript-eslint/camelcase
                                        iv_load_policy: 3,
                                        color: 'white',
                                    },
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className="container-small m-y-56">
                    {faq.data && !!faq.data.length && (
                        <Collapse
                            bordered={false}
                            defaultActiveKey={['1']}
                            expandIcon={collapseExpandIcon}
                            accordion
                        >
                            {[...faq.data].sort((a, b) => a.order - b.order).map((item, index) => (
                                <Collapse.Panel
                                    header={t(item.title, locale)}
                                    extra={<span className="how-it-works-item-number">{index + 1}</span>}
                                    key={item.id}
                                >
                                    <p dangerouslySetInnerHTML={{ __html: t(item.text, locale) }} />
                                </Collapse.Panel>
                            ))}
                        </Collapse>
                    )}
                    {faq.data && !faq.data.length && (
                        <EmptyResult text={formatMessage(messages.noSteps)} />
                    )}
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state: MainReducerState) => ({
    faq: getFAQListState(state),
    config: getFAQConfigState(state),
});

export default connect(
    mapStateToProps,
)(HowItWorks);
