import * as React from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { Button } from 'antd';
import { ButtonProps } from 'antd/lib/button';

type ButtonLinkProps = LinkProps & ButtonProps;

const ButtonLink: React.FC<ButtonLinkProps> = ({ children, to, ...rest }) => (
    <Button {...rest}>
        <Link to={to} tabIndex={-1}>
            {children}
        </Link>
    </Button>
);

export default ButtonLink;
