import { Action, ActionCreator } from 'redux';
import { LoginPayload, RegisterPayload, ForgottenPasswordPayload } from '../api/auth';
import * as reduxTypes from '../types/auth';
import { DataAction, IdAction } from '.';
import { User } from '../api/types';
import { UpdateData } from '../api/_client';

export const login: ActionCreator<DataAction> = (data: LoginPayload) => ({
    type: reduxTypes.LOGIN,
    data,
});

export const loginSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LOGIN_SUCCESS,
    data,
});

export const loginFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LOGIN_FAILED,
    data,
});

export const register: ActionCreator<DataAction> = (data: RegisterPayload) => ({
    type: reduxTypes.REGISTER,
    data,
});

export const registerSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.REGISTER_SUCCESS,
    data,
});

export const registerFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.REGISTER_FAILED,
    data,
});

export const forgottenPassword: ActionCreator<DataAction> = (data: ForgottenPasswordPayload) => ({
    type: reduxTypes.FORGOTTEN_PASSWORD,
    data,
});

export const forgottenPasswordSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.FORGOTTEN_PASSWORD_SUCCESS,
    data,
});

export const forgottenPasswordFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.FORGOTTEN_PASSWORD_FAILED,
    data,
});

export const update: ActionCreator<DataAction & IdAction> = (id: User['id'], data: UpdateData<User>) => ({
    type: reduxTypes.UPDATE,
    id,
    data,
});

export const updateSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_SUCCESS,
    data,
});

export const updateFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.UPDATE_FAILED,
    data,
});

export const logout: ActionCreator<Action> = () => ({
    type: reduxTypes.LOGOUT,
});

export const logoutSuccess: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LOGOUT_SUCCESS,
    data,
});

export const logoutFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.LOGOUT_FAILED,
    data,
});

export const checkLoginStatus: ActionCreator<{}> = () => ({
    type: reduxTypes.CHECK_LOGIN_STATUS,
});

export const checkLoginStatusSuccess: ActionCreator<DataAction> = (data: User) => ({
    type: reduxTypes.CHECK_LOGIN_STATUS_SUCCESS,
    data,
});

export const checkLoginStatusFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.CHECK_LOGIN_STATUS_FAILED,
    data,
});

export const fetchUser: ActionCreator<Action> = () => ({
    type: reduxTypes.FETCH_USER,
});

export const fetchUserSuccess: ActionCreator<DataAction> = (data: User) => ({
    type: reduxTypes.FETCH_USER_SUCCESS,
    data,
});

export const fetchUserFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.FETCH_USER_FAILED,
    data,
});
