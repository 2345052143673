import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="64" viewBox="0 0 80 64">
        <path fillRule="evenodd" fill="currentColor" d="M46.675 3.16c.77-.232 1.57-.348 2.384-.348 4.59 0 8.324 3.736 8.324 8.33 0 2.308-.92 4.45-2.592 6.04l-28.06 20.996c.957-6.075-2.94-11.96-9.02-13.353a14.378 14.378 0 0 0-2.53-.335L46.676 3.16zm-29.27 31.66a2.862 2.862 0 0 1 1.054 2.913 2.01 2.01 0 0 1-2.4 1.508 1.313 1.313 0 0 1-.826-.586 1.306 1.306 0 0 1-.17-.997.778.778 0 0 1 .93-.585 1.404 1.404 0 0 0 1.41-2.252zm61.704-3.302L57.364 22.94a1.404 1.404 0 0 0-1.822.793c-.285.723.07 1.54.792 1.823l19.536 7.707-33.404 27.795-28.088-6.96h-.014c-.002-.002-.005-.004-.01-.005a14.757 14.757 0 0 1-9.266-6.587 14.77 14.77 0 0 1-1.896-11.214c1.425-6.24 7.658-10.152 13.894-8.725 4.837 1.107 7.87 5.946 6.764 10.785a6.864 6.864 0 0 1-1.928 3.425l-1.37 1.024a6.857 6.857 0 0 1-4.99.757 5.264 5.264 0 0 1-3.948-6.297 3.926 3.926 0 0 1 4.317-3.02 3.592 3.592 0 0 0-3.605 2.79 4.11 4.11 0 0 0 .528 3.12 4.107 4.107 0 0 0 2.58 1.83 4.818 4.818 0 0 0 5.765-3.62 5.678 5.678 0 0 0-4.26-6.792 6.693 6.693 0 0 0-5.08.86 6.692 6.692 0 0 0-2.983 4.204c-.992 4.337 1.728 8.673 6.062 9.664 2.532.582 5.14.14 7.34-1.242a9.762 9.762 0 0 0 1.466-1.13l32.795-24.54c.04-.03.08-.063.117-.097 2.25-2.1 3.54-5.07 3.54-8.146C60.194 5 55.198 0 49.058 0c-1.173 0-2.33.182-3.435.54a1.35 1.35 0 0 0-.355.174L7.586 26.24c-.02.014-.035.03-.054.045-3.458 1.918-6.13 5.227-7.08 9.38A17.565 17.565 0 0 0 2.708 49c2.5 3.987 6.4 6.76 10.98 7.82l.016.008 28.776 7.13a1.404 1.404 0 0 0 1.236-.284L79.492 33.91c.382-.317.57-.814.49-1.304a1.41 1.41 0 0 0-.873-1.086z" />
    </svg>
);

const IconFloor: React.FC<IconProps> = (props) => (
    <Icon component={svg} {...props} className={`anticon icon-floor${props.className ? ` ${props.className}` : ''}`} />
);

export default IconFloor;
