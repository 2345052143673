import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

interface IconArrowProps extends IconProps {
    direction?: 'left' | 'top' | 'right' | 'bottom';
}

const svg = () => (
    <svg xmlnsXlink="http://www.w3.org/1999/xlink" width="24" height="20" viewBox="0 0 24 20">
        <path fill="currentColor" fillRule="nonzero" d="M24 10.02a1.54 1.54 0 0 0-1.548-1.53H5.48l6.29-5.804a1.52 1.52 0 0 0 .496-1.483A1.538 1.538 0 0 0 11.186.06 1.56 1.56 0 0 0 9.66.45L.492 8.9a1.523 1.523 0 0 0 0 2.24l9.166 8.45a1.56 1.56 0 0 0 2.188-.074 1.52 1.52 0 0 0-.076-2.165l-6.29-5.8H22.45A1.54 1.54 0 0 0 24 10.02z" />
    </svg>
);

const IconArrow: React.FC<IconArrowProps> = ({direction = 'left', ...props}) => (
    <Icon
        component={svg}
        className={`icon-arrow rotate-${direction}`}
        {...props}
    />
);

export default IconArrow;
