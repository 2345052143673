import React, { KeyboardEvent, useEffect, useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Drawer, List, message } from 'antd';
import { DrawerProps } from 'antd/lib/drawer';
import { DateTime } from 'luxon';

import '../../assets/styles/UserMenu.less';

import * as PresentationsActions from '../../store/actions/presentations';
import { Client, Presentation, PresentationType } from '../../store/api/types';
import { MainReducerState } from '../../store/reducers';
import { getPresentationsByCustomerReference, PresentationsState, getDeleteState, getDetailsState } from '../../store/reducers/presentations';

import { IconArrow } from '../icons';
import ListItem, { ListItemProps } from '../ListItem';
import messages from './UserMenu.messages';
import EmptyResult from '../EmptyResult';
import { usePrevious } from '../../hooks';
import { PresentationContext } from '../PresentationContextProvider';
import { UserMenuDrawerContext } from './UserMenuDrawerContext';
import GenericMessages from '../../locale/Generic.messages';

interface PresentationsDrawerProps extends DrawerProps {
    clientReference?: Client['reference'];
    deleteState: PresentationsState['deletes'];
    deletePresentation: typeof PresentationsActions.del;
    detailsState: PresentationsState['details'];
    fetchPresentation: typeof PresentationsActions.details;
    listPresentations: typeof PresentationsActions.list;
    presentations?: Presentation[];
    type: PresentationType;
}

const PresentationsDrawer: React.FC<PresentationsDrawerProps> = ({
    deletePresentation, deleteState, detailsState, fetchPresentation, listPresentations,
    presentations, onClose, type, visible, zIndex,
}) => {
    const { formatMessage } = useIntl();
    const { closeAll } = useContext(UserMenuDrawerContext);
    const {
        setPresentationEdit, setIsPresentationOpen, reset, setIsEditing,
    } = useContext(PresentationContext);
    const prevProps = usePrevious<Partial<PresentationsDrawerProps>>({ deleteState, detailsState });
    const onBack = () => {
        if (typeof onClose === 'function') {
            onClose({} as KeyboardEvent<HTMLDivElement>);
        }
    };
    const onDeletePresentation = (id: Presentation['id']) => {
        deletePresentation(id);
    };
    const onEditPresentation: ListItemProps['onEdit'] = (e) => {
        if (presentations) {
            const id = e.currentTarget.dataset.id ? parseInt(e.currentTarget.dataset.id, 10) : -1;
            fetchPresentation(id);
        }
    };

    useEffect(() => {
        if (
            prevProps && prevProps.deleteState && prevProps.deleteState.loading &&
            !deleteState.loading && deleteState.success
        ) {
            listPresentations({ limit: 9999 });
        }
    }, [deleteState.success]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (
            prevProps && prevProps.detailsState && prevProps.detailsState.loading &&
            !detailsState.loading
        ) {
            if (detailsState.success) {
                closeAll();
                reset();
                setIsEditing(true);
                setPresentationEdit(detailsState.data);
                setIsPresentationOpen(true);
            }
            if (detailsState.error) {
                message.error(formatMessage(GenericMessages.error));
            }
        }
    }, [
        detailsState.success, detailsState.error, closeAll, detailsState.data, detailsState.loading,
        formatMessage, prevProps, reset, setIsEditing, setIsPresentationOpen, setPresentationEdit,
    ]);

    return (
        <>
            <Drawer
                title={(
                    <div className="drawer-header-with-back-button">
                        <Button
                            type="link"
                            onClick={onBack}
                        >
                            <IconArrow direction="left" />
                        </Button>
                        <FormattedMessage
                            {...(
                                type === 'presentation' ?
                                    messages.presentationsDrawerTitle :
                                    messages.propositionsDrawerTitle
                            )}
                            values={{
                                b: (...m: string[]) => <span>{m}</span>,
                                count: presentations ? presentations.length : 0,
                            }}
                            tagName="span"
                        />
                    </div>
                )}
                placement="right"
                width={385}
                maskStyle={{display: 'none'}}
                closable={false}
                visible={visible}
                zIndex={zIndex}
            >
                <div id="user-menu-selections">
                    <section>
                        <List>
                            {presentations && !!presentations.length && presentations.map((presentation) => (
                                <List.Item key={presentation.id}>
                                    <ListItem
                                        isDeleting={deleteState.loading}
                                        isLoadingEdit={detailsState.loading}
                                        onEdit={onEditPresentation}
                                        onDelete={onDeletePresentation.bind(null, presentation.id)}
                                        data-id={presentation.id}
                                        title={presentation.title}
                                        subTitle={
                                            DateTime.fromISO(presentation.createAt).toLocaleString({
                                                day: 'numeric', month: 'long', year: 'numeric',
                                            })
                                        }
                                    />
                                </List.Item>
                            ))}
                            {presentations && !presentations.length && (
                                <List.Item key="empty">
                                    <EmptyResult />
                                </List.Item>
                            )}
                        </List>
                    </section>
                </div>
            </Drawer>
        </>
    );
};

const mapStateToProps = (state: MainReducerState, { clientReference, type }: Pick<PresentationsDrawerProps, 'clientReference' | 'type'>) => ({
    deleteState: getDeleteState(state),
    presentations: getPresentationsByCustomerReference(state, type, clientReference),
    detailsState: getDetailsState(state),
});

export default connect(
    mapStateToProps,
    {
        listPresentations: PresentationsActions.list,
        fetchPresentation: PresentationsActions.details,
        deletePresentation: PresentationsActions.del,
    },
)(PresentationsDrawer);
