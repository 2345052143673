import React, { FC, useCallback } from 'react';

interface PieChartProps {
    data: string[];
    size?: number;
}

const PieChart: FC<PieChartProps> = ({ data, size = 32 }) => {
    const slicePercentage = data.length ? 1 / data.length : 1;
    let cumulativePercent = 0;
    const getCoordinatesForPercent = useCallback((percent) => {
        const x = Math.cos(2 * Math.PI * percent);
        const y = Math.sin(2 * Math.PI * percent);

        return [x, y];
    }, []);

    return (
        <svg
            className="pie-chart"
            width={size}
            height={size}
            style={{ transform: 'rotate(-90deg)' }}
            viewBox="-1 -1 2 2"
            xmlns="http://www.w3.org/2000/svg"
        >
            {data.map((value, i) => {
                const [startX, startY] = getCoordinatesForPercent(cumulativePercent);

                cumulativePercent += slicePercentage;

                const [endX, endY] = getCoordinatesForPercent(cumulativePercent);
                const fill = value;
                const largeArcFlag = slicePercentage > .5 ? 1 : 0;

                return (
                    <path
                        key={`${value}${i}`}
                        style={{ fill }}
                        d={`M ${startX} ${startY} A 1 1 0 ${largeArcFlag} 1 ${endX} ${endY} L 0 0`}
                    />
                );
            })}
        </svg>
    );
};

export default PieChart;
