import api from './_client';
import { PresentationPayload } from '../../components/PresentationContextProvider';
import { File, Presentation } from './types';
import { PaginationQuery } from '.';

export const preview = (payload: PresentationPayload) =>
    api.post<File>('/api/presentations/preview', payload);

export const create = (payload: PresentationPayload) =>
    api.post<File>('/api/presentations', payload);

export const convert = (payload: PresentationPayload) =>
    api.post<File>('/api/presentations/convert', payload);

export const list = (query?: PaginationQuery) =>
    api.get('/api/me/presentations', { params: query });

export const del = (id: Presentation['id']) =>
    api.delete(`/api/presentations/${id}`);

export const details = (id: Presentation['id']) =>
    api.get(`/api/presentations/${id}`);

export const update = (id: Presentation['id'], data: PresentationPayload) =>
    api.put(`/api/presentations/${id}`, data);
