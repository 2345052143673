import { call, takeLatest, put } from 'redux-saga/effects';
import * as faqActions from '../actions/faq';
import * as reduxTypes from '../types/faq';
import * as faqApi from '../api/faq';

export function* configSaga() {
    try {
        const faq = yield call(faqApi.config);

        return yield put(faqActions.configSuccess(faq));
    } catch (error) {
        return yield put(faqActions.configFailed(error));
    }
}

export default function* faqSaga() {
    yield takeLatest(reduxTypes.CONFIG, configSaga);
}
