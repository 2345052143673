import React, { ReactChild } from 'react';
import { useIntl } from 'react-intl';

import '../assets/styles/ListItem.less';

import { IconAngle, IconTrash, IconEdit } from './icons';
import { Button, Popconfirm } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import { PopconfirmProps } from 'antd/lib/popconfirm';
import GenericMessages from '../locale/Generic.messages';


export interface ListItemProps {
    disabled?: boolean;
    icon?: ReactChild;
    isDeleting?: boolean;
    isLoadingEdit?: boolean;
    noOnClickIcon?: boolean;
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    onDelete?: PopconfirmProps['onConfirm'];
    onEdit?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    renderBeforeTitle?: () => ReactChild;
    subTitle?: string | ReactChild;
    title: string | ReactChild;
}

const ListItem: React.FC<ListItemProps> = ({
    children, disabled, icon, isDeleting, isLoadingEdit, noOnClickIcon, onClick, onDelete, onEdit,
    renderBeforeTitle, subTitle, title, ...rest
}) => {
    const { formatMessage } = useIntl();
    const hasOnClick = !disabled && typeof onClick === 'function';
    const hasOnDelete = !disabled && typeof onDelete === 'function';
    const hasOnEdit = !disabled && typeof onEdit === 'function';

    const content = (
        <>
            {icon && (
                <div className="list-item-icon">
                    {icon}
                </div>
            )}
            {typeof renderBeforeTitle === 'function' && renderBeforeTitle()}
            <div className="list-item-content">
                <Paragraph className="list-item-title" ellipsis>
                    {title}
                </Paragraph>
                {subTitle && (
                    <Paragraph className="list-item-sub-title" ellipsis>
                        {subTitle}
                    </Paragraph>
                )}
            </div>
            {children}
            {hasOnClick && !hasOnDelete && !noOnClickIcon && !hasOnEdit && (
                <div className="list-item-angle">
                    <IconAngle direction="right" />
                </div>
            )}
            {hasOnEdit && (
                <Button
                    className="list-item-edit"
                    loading={isLoadingEdit}
                    onClick={onEdit}
                    type="link"
                    {...rest}
                >
                    <IconEdit />
                </Button>
            )}
            {hasOnDelete && (
                <Popconfirm
                    cancelText={formatMessage(GenericMessages.no)}
                    okText={formatMessage(GenericMessages.yes)}
                    onConfirm={onDelete}
                    title={formatMessage(GenericMessages.areYouSure)}
                >
                    <Button
                        className="list-item-delete"
                        loading={isDeleting}
                        type="link"
                    >
                        <IconTrash />
                    </Button>
                </Popconfirm>
            )}
        </>
    );

    return hasOnClick && !hasOnDelete ? (
        <Button
            className={`list-item${disabled ? ' list-item-disabled' : ''}`}
            onClick={onClick}
            {...rest}
        >
            {content}
        </Button>
    ) : (
        <div className={`list-item${disabled ? ' list-item-disabled' : ''}`} {...rest}>
            {content}
        </div>
    );
};

export default ListItem;
