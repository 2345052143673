import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="52" viewBox="0 0 80 52">
        <path fillRule="evenodd" fill="currentColor" d="M38.784 26.47l-6.645-2.513a1.358 1.358 0 0 0-.28-.14L7.548 14.953 47.61 2.724l18.27 6.66 9.353 3.54c.084.03 2.042.762 1.756 3-.195.25-1.163 1.237-5.182 2.782l.002.004-.052.016-29.44 12.66c-.062-2.867-2.267-4.458-3.534-4.915m41.1 6.028c-.306-.67-1.11-.972-1.798-.674L38.036 49.14 4.507 37.137c-.082-.03-1.994-.746-1.764-2.92.08-.21.373-.82 1.08-1.167.835-.408 2.064-.347 3.56.178 1.592.56 4.89 1.782 8.726 3.22l21.153 8.005.4.152 40.51-17.647c.684-.3.992-1.084.685-1.752a1.374 1.374 0 0 0-1.798-.67L37.59 41.73c-2.407-.917-12.486-4.752-20.373-7.71L4.52 29.217c-.207-.08-1.996-.83-1.776-2.923.08-.208.373-.82 1.08-1.167.835-.41 2.066-.347 3.558.177 1.803.633 9.983 3.72 15.956 5.975l3.438 1.295c2.572.997 6.692 2.59 7.245 2.784 1.15.404 2.212.605 3.182.605.958 0 1.827-.196 2.597-.59.003 0 .006-.004.01-.006l33.028-14.204c4.664-1.797 6.92-3.44 6.865-5.01.38-3.375-2.125-5.213-3.504-5.71L66.863 6.91c-.005 0-.01-.004-.015-.006L48.124.08c-.014-.005-.027-.006-.04-.01a1.325 1.325 0 0 0-.145-.037c-.04-.01-.08-.02-.122-.024-.042-.007-.085-.007-.128-.008a1.332 1.332 0 0 0-.142 0c-.037.003-.075.01-.112.015a1.19 1.19 0 0 0-.156.032l-.04.006L3.274 13.48c-.016.004-.028.012-.044.017-.525.026-1.01.35-1.206.86-.264.687.093 1.45.798 1.707l25.643 9.346v.003l9.34 3.534c.21.08 2 .832 1.78 2.925-.083.21-.375.82-1.082 1.166-.834.407-2.065.346-3.56-.176-.484-.172-4.366-1.67-7.176-2.76h-.003l-.04-.016-1.998-.774-.007.017-1.4-.527c-5.984-2.258-14.182-5.352-16.014-5.996-2.283-.802-4.227-.806-5.778-.014C.66 23.746.144 25.47.092 25.663l-.034.164c-.34 2.497.885 4.168 2.12 5.102-1.57.976-2.037 2.476-2.086 2.657l-.034.164c-.475 3.475 2.084 5.362 3.508 5.876L38.132 52l41.06-17.75a1.315 1.315 0 0 0 .69-1.752" />
    </svg>
);

const IconLinen: React.FC<IconProps> = (props) => (
    <Icon component={svg} {...props} className={`anticon icon-linen${props.className ? ` ${props.className}` : ''}`} />
);

export default IconLinen;
