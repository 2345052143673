import React, { useContext } from 'react';
import { RouteChildrenProps } from 'react-router';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import ReactGA from 'react-ga';
import { Row, Col, Divider, Button, Tooltip } from 'antd';

import '../../assets/styles/Sector.less';

import salesAcademy from '../../assets/images/sales-academy.png';
import salesAcademy2x from '../../assets/images/sales-academy@2x.png';
import salesAcademy3x from '../../assets/images/sales-academy@3x.png';

import { MainReducerState, getOfflineState } from '../../store/reducers';
import { RoutePathName, getRoute } from '../../routes';
import { getSectorById, getSectorsByParentId } from '../../store/reducers/sectors';
import { Sector } from '../../store/api/types';
import messages from './Sector.messages';
import GenericMessages from '../../locale/Generic.messages';
import BreadCrumb from '../../components/BreadCrumb';
import { IconPresentation, IconArrow } from '../../components/icons';
import Info from '../../components/Info';
import Carousel from '../../components/Carousel';
import SectorCard from '../../components/SectorCard';
import EmptyResult from '../../components/EmptyResult';
import Img from '../../components/Img';
import { PresentationContext } from '../../components/PresentationContextProvider';
import { t } from '../../utils';

interface MatchParams {
    id: string;
}

interface SectorProps extends RouteChildrenProps<MatchParams> {
    isOnline: boolean;
    sector?: Sector;
    subSectors: Sector[];
}

const SectorPage: React.FC<SectorProps> = ({ isOnline, sector, subSectors }) => {
    const { locale } = useIntl();
    const { setPresentationData, setIsPresentationOpen, reset } = useContext(PresentationContext);
    const onClickPresentation = () => {
        reset();
        setPresentationData({ sector });
        setIsPresentationOpen(true);
        ReactGA.event({
            category: 'bouton présentation commerciale header secteur',
            action: 'clic',
            label: sector ? `${sector.id}` : '',
        });
    };

    const presentationButton = (
        <Button
            className="btn-white"
            disabled={!isOnline}
            onClick={onClickPresentation}
        >
            <IconPresentation />
            <FormattedMessage {...GenericMessages.commercialPresentation} />
        </Button>
    );

    return (
        <>
            <BreadCrumb
                routes={[
                    {
                        link: getRoute(RoutePathName.sector, { id: sector ? sector.id : 0 }),
                        text: sector && t(sector.name, locale) ? t(sector.name, locale) : '',
                    },
                ]}
            />
            <header className="page-hero">
                {sector && (
                    <>
                        {sector.image && <Img imageId={sector.image.id} alt={t(sector.name, locale)} />}
                        <div className="container">
                            <Row>
                                <Col xl={13} lg={18} md={16} className="page-hero-title-col">
                                    <h1>{t(sector.name, locale)}</h1>
                                </Col>
                                <Col xl={11} lg={6} md={8} id="sector-hero-right">
                                    <Link to={getRoute(RoutePathName.sectorInformations, { id: sector.id })}>
                                        <div className="sector-hero-commercial-presentation">
                                            <FormattedMessage
                                                {...messages.sectorInformations}
                                                values={{
                                                    b: (...m: string[]) => <strong>{m}</strong>,
                                                }}
                                                tagName="p"
                                            />
                                            <Divider />
                                            <FormattedMessage {...GenericMessages.readMore}>
                                                {(txt) => (
                                                    <p className="arrow-link">
                                                        <IconArrow direction="right" />
                                                        {txt}
                                                    </p>
                                                )}
                                            </FormattedMessage>
                                        </div>
                                    </Link>
                                    {isOnline ?
                                        presentationButton :
                                        (
                                            <Tooltip
                                                title={<FormattedMessage {...GenericMessages.youMustBeOnline} />}
                                            >
                                                {presentationButton}
                                            </Tooltip>
                                        )
                                    }
                                </Col>
                            </Row>
                        </div>
                    </>
                )}
            </header>
            <section id="sector-carousel-wrapper" className="bg-grey m-b-86">
                <div className="container">
                    {!!subSectors.length && (
                        <>
                            <div className="sector-tooltip-wrapper">
                                <Info
                                    topText={<FormattedMessage {...messages.tooltipTop} />}
                                    bottomText={<FormattedMessage {...messages.tooltipBottom} />}
                                />
                                <Link
                                    to={getRoute(RoutePathName.salesAcademy, { id: sector ? sector.id : -1 })}
                                    id="sales-academy-button"
                                >
                                    <img
                                        src={salesAcademy}
                                        srcSet={`${salesAcademy2x} 2x, ${salesAcademy3x} 3x`}
                                        alt="Elis Sales Academy"
                                    />
                                </Link>
                            </div>
                            <Carousel
                                id="sector-carousel"
                                slidesToShow={subSectors.length < 4 ? subSectors.length : 4}
                                slidesToScroll={subSectors.length < 4 ? subSectors.length : 4}
                                responsive={[
                                    {
                                        breakpoint: 1366,
                                        settings: {
                                            slidesToShow: subSectors.length < 3 ? subSectors.length : 3,
                                            slidesToScroll: subSectors.length < 3 ? subSectors.length : 3,
                                        },
                                    },
                                    {
                                        breakpoint: 884,
                                        settings: {
                                            slidesToShow: subSectors.length < 2 ? subSectors.length : 2,
                                            slidesToScroll: subSectors.length < 2 ? subSectors.length : 2,
                                        },
                                    },
                                    {
                                        breakpoint: 580,
                                        settings: {
                                            slidesToShow: 1,
                                            slidesToScroll: 1,
                                        },
                                    },
                                ]}
                            >
                                {subSectors.map((subSector) => (
                                    <SectorCard
                                        key={subSector.id}
                                        sector={sector}
                                        subSector={subSector}
                                    />
                                ))}
                            </Carousel>
                        </>
                    )}
                    {!subSectors.length && (
                        <EmptyResult />
                    )}
                </div>
            </section>
        </>
    );
};

const mapStateToProps = (state: MainReducerState, { match }: SectorProps) => ({
    isOnline: getOfflineState(state).online,
    sector: getSectorById(state, parseInt(match!.params.id, 10)),
    subSectors: getSectorsByParentId(state, parseInt(match!.params.id, 10)),
});

export default connect(
    mapStateToProps,
)(SectorPage);
