import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
        <path fill="currentColor" fillRule="evenodd" d="M12.984 18.395c-.653 0-1.184.532-1.184 1.185v.84c0 .654.53 1.186 1.184 1.186h4.427c.543 0 .985.44.985.984v4.426c0 .653.53 1.185 1.185 1.185h.84c.653 0 1.185-.53 1.185-1.184V22.59c0-.543.44-.984.984-.984h4.426c.653 0 1.185-.532 1.185-1.186v-.84c0-.653-.53-1.185-1.184-1.185H22.59a.985.985 0 0 1-.985-.985v-4.425c0-.654-.532-1.185-1.185-1.185h-.84c-.654 0-1.185.53-1.185 1.185v4.425a.986.986 0 0 1-.984.985h-4.426zM19.58 30.17a3.158 3.158 0 0 1-3.154-3.154v-3.442h-3.442A3.158 3.158 0 0 1 9.83 20.42v-.84a3.158 3.158 0 0 1 3.154-3.154h3.442v-3.44A3.158 3.158 0 0 1 19.58 9.83h.84a3.158 3.158 0 0 1 3.154 3.155v3.44h3.442a3.158 3.158 0 0 1 3.154 3.155v.84a3.158 3.158 0 0 1-3.154 3.154h-3.442v3.442a3.157 3.157 0 0 1-3.154 3.154h-.84zM20 1.97C10.058 1.97 1.97 10.057 1.97 20c0 9.942 8.088 18.03 18.03 18.03 9.942 0 18.032-8.088 18.032-18.03 0-9.942-8.09-18.03-18.032-18.03zM20 40C8.972 40 0 31.028 0 20S8.972 0 20 0c11.03 0 20 8.972 20 20s-8.97 20-20 20z" />
    </svg>
);

const IconCirclePlus: React.FC<IconProps> = (props) => (
    <Icon component={svg} {...props} className={`anticon icon-circle-plus${props.className ? ` ${props.className}` : ''}`} />
);

export default IconCirclePlus;
