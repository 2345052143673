import React from 'react';
import { Icon } from 'antd';
import { IconProps } from 'antd/lib/icon';

const svg = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="22" viewBox="0 0 48 22">
        <path fill="currentColor" fillRule="evenodd" d="M26.703 19.522L48 0v22H0V0l21.297 19.522a4 4 0 0 0 5.406 0z" />
    </svg>
);

const IconHeroInvertedTriangle: React.FC<IconProps> = (props) => (
    <Icon component={svg} {...props} className={`anticon icon-hero-inverted-triangle${props.className ? ` ${props.className}` : ''}`} />
);

export default IconHeroInvertedTriangle;
