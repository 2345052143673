import { Reducer } from 'redux';
import * as reduxTypes from '../types/filters';
import { MainReducerState } from './';
import { Argument, Property, PropertyValue } from '../api/types';

export interface FiltersState {
    arguments?: {
        id: Argument['id'];
        rating: number;
    }[];
    properties?: {
        id: Property['id'];
        valueId: PropertyValue['id'];
    }[];
    gamme?: {
        id: Property['id'];
        valueId: PropertyValue['id'];
    };
    service?: {
        id: Property['id'];
        valueId: PropertyValue['id'];
    };
}

const filtersInitialState: FiltersState = {
    arguments: [],
    properties: [],
};

const filters: Reducer<FiltersState> = (state = filtersInitialState, action) => {
    switch (action.type) {
        case reduxTypes.SET_FILTERS:
            return action.data || filtersInitialState;

        default:
            return state;
    }
};

export default filters;

export const getFilters = (state: MainReducerState) => state.filters;
