import { Action, ActionCreator } from 'redux';
import * as reduxTypes from '../types/nace';
import { DataAction } from '.';
import { Nace } from '../api/types';

export const get: ActionCreator<Action> = () => ({
    type: reduxTypes.GET,
});

export const getSuccess: ActionCreator<DataAction> = (data: Nace[]) => ({
    type: reduxTypes.GET_SUCCESS,
    data,
});

export const getFailed: ActionCreator<DataAction> = (data: any) => ({
    type: reduxTypes.GET_FAILED,
    data,
});
